import { Checkbox } from 'antd';
import { RequestType } from 'data/types/requests';
import { useState } from 'react';

type ApplicationChecklistProps = {
  isChecked: any;
  setIsChecked: (value: boolean) => void;
  requestType: number;
};

export default function ApplicationChecklist({
  isChecked,
  setIsChecked,
  requestType,
}: ApplicationChecklistProps) {
  const [checklist] = useState(
    requestType === RequestType.seller_management_add_store
      ? BusinessListingChecklist
      : requestType === RequestType.register_driver_as_carrier
        ? CaptainRegistrationChecklist
        : requestType === RequestType.seller_management_add_listing
          ? NewListingsRequestChecklist
          : requestType === RequestType.seller_management_edit_listing
            ? UpdateListingsRequestChecklist
            : NewListingsRequestChecklist,
  );

  return (
    <div className="right-side">
      <div className="top_row">
        <h3 className="poppins-font ">Application Checklist</h3>
      </div>
      <p>
        A checklist to help you verify that the listing request achieves Silal
        minium standards for high quality listing.
      </p>
      <br />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <Checkbox
          onChange={() => {
            setIsChecked({
              ...isChecked,
              Checkbox1: isChecked.Checkbox1 == 'card1' ? null : 'card1',
              Checkbox2: isChecked.Checkbox2 == 'card2' ? null : 'card2',
              Checkbox3: isChecked.Checkbox3 == 'card3' ? null : 'card3',
              Checkbox4: isChecked.Checkbox4 == 'card4' ? null : 'card4',
              Checkbox5: isChecked.Checkbox5 == 'card5' ? null : 'card5',
              Checkbox6: isChecked.Checkbox6 == 'card6' ? null : 'card6',
              Checkbox7: isChecked.Checkbox7 == 'card7' ? null : 'card7',
              Checkbox8: isChecked.Checkbox8 == 'card8' ? null : 'card8',
              Checkbox9: isChecked.Checkbox9 == 'card9' ? null : 'card9',
              Checkbox10: isChecked.Checkbox10 == 'card10' ? null : 'card10',
            });
          }}
          checked={
            isChecked.Checkbox1 &&
            isChecked.Checkbox2 &&
            isChecked.Checkbox3 &&
            isChecked.Checkbox4 &&
            isChecked.Checkbox5 &&
            isChecked.Checkbox6 &&
            isChecked.Checkbox7 &&
            isChecked.Checkbox8 &&
            isChecked.Checkbox9 &&
            isChecked.Checkbox10
              ? true
              : false
          }
        >
          Check All
        </Checkbox>
        {checklist.map((item, index) => {
          const name = `card${index + 1}`;
          const key = `Checkbox${index + 1}`;
          return (
            <Checkbox
              key={index}
              name={name}
              onChange={() => {
                setIsChecked({
                  ...isChecked,
                  [key]: isChecked[key] == name ? null : name,
                });
              }}
              checked={isChecked[key] === name ? true : false}
            >
              {item}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
}

const BusinessListingChecklist = [
  'All variations are for the same product',
  'Correct Specifications',
  'Different images for different values of imaged attributes',
  'Choosed correct category',
  'Valid description and title',
  'Reasonable price',
  'Reasonable dimensions and weight',
  'Choosed correct category',
  'Choosed correct category',
  'Choosed correct category',
];

const CaptainRegistrationChecklist = [
  'All variations are for the same product',
  'Correct Specifications',
  'Different images for different values of imaged attributes',
  'Choosed correct category',
  'Valid description and title',
  'Reasonable price',
  'Reasonable dimensions and weight',
  'Choosed correct category',
  'Choosed correct category',
  'Choosed correct category',
];

const NewListingsRequestChecklist = [
  'All variations are for the same product',
  'Correct Specifications',
  'Different images for different values of imaged attributes',
  'Choosed correct category',
  'Valid description and title',
  'Reasonable price',
  'Reasonable dimensions and weight',
  'Choosed correct category',
  'Choosed correct category',
  'Choosed correct category',
];

const UpdateListingsRequestChecklist = [
  'All variations are for the same product',
  'Correct Specifications',
  'Different images for different values of imaged attributes',
  'Choosed correct category',
  'Valid description and title',
  'Reasonable price',
  'Reasonable dimensions and weight',
  'Choosed correct category',
  'Choosed correct category',
  'Choosed correct category',
];
