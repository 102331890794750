import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { RootState } from 'data/types/state';

export default function NewOperatingLocationPopup({
  open,
  handleOpen,
  data,
  setData,
  onSubmit,
}: {
  open: boolean;
  handleOpen: (value: boolean) => void;
  data: any;
  setData: (value: any) => void;
  onSubmit: (newOperatingLocaiton: any) => void | boolean;
}) {
  const location = useSelector((state: RootState) => state.Locations);
  const handleClose = () => {
    handleOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const name_en = formJson.name_en;
            const name_ar = formJson.name_ar;
            const latitude = formJson.latitude;
            const longitude = formJson.longitude;
            const delivery_fee = formJson.delivery_fee;
            const newOperatingLocaiton = {
              ...data,
              name_ar: name_ar,
              name_en: name_en,
              lat: parseFloat(latitude),
              lng: parseFloat(longitude),
              delivery_fee: parseFloat(delivery_fee),
            };
            const res = onSubmit(newOperatingLocaiton);
            if (!res) return;
            handleClose();
          },
        }}
      >
        <DialogTitle>Update Existing Operating Location Info</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add all the data with careful inspection of each.
          </DialogContentText>
          <br />
          <TextField
            autoFocus
            required
            margin="dense"
            id="name_en"
            name="name_en"
            label="Name in English"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setData({ ...data, name_en: e.target.value })}
            value={data.name_en}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="name_ar"
            name="name_ar"
            label="Name in Arabic"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setData({ ...data, name_ar: e.target.value })}
            value={data.name_ar}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="delivery_fee"
            name="delivery_fee"
            label="Delivery Fee"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => setData({ ...data, delivery_fee: e.target.value })}
            value={data.delivery_fee}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="cross_order_delivery_fee_discount"
            name="cross_order_delivery_fee_discount"
            label="Delivery Fee"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) =>
              setData({
                ...data,
                cross_order_delivery_fee_discount: e.target.value,
              })
            }
            value={data.cross_order_delivery_fee_discount}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="longitude"
            name="longitude"
            label="Center Longitude"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setData({ ...data, lng: e.target.value })}
            value={data.lng}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="latitude"
            name="latitude"
            label="Center Latitude"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setData({ ...data, lat: e.target.value })}
            value={data.lat}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update location</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
