import { Link, useOutletContext } from 'react-router-dom';
import { StoreBrief } from 'silal_app_base_react/src/data/types/stores';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { useEffect, useState } from 'react';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import StoresRepository from 'data/repositories/stores_repository';
import Spinner from 'silal_app_base_react/src/components/spinner';
import CategoryModal from 'silal_app_base_react/src/pages/create_listings/modals/select_category_modal';

import { SolrBuyerItemsResponse } from 'silal_app_base_react/src/data/types/listings';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import Lightbox from 'react-image-lightbox';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import { Button, Input, Spacer } from '@nextui-org/react';
import { Search } from 'akar-icons';
import { SilalExpandable } from 'silal_app_base_react/src/pages/templates/components/search_components';
import { Table } from 'antd';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { UpdateNameOrDescriptionModal } from 'silal_app_base_react/src/pages/listing_profile/modals/update_name_desc_modal';
import { FaPencil } from 'react-icons/fa6';
import DataEditingRepository from 'data/repositories/data_editing_repository';
import { AbstractCategoryWithSubcategories } from 'silal_app_base_react/src/data/types/categories';
import { CategoryParentsBreadcrumbs } from 'silal_app_base_react/src/components/categories_breadcrubmbs';

function StoreListingsTab() {
  const dispatch = useDispatch();

  const store = useOutletContext<StoreBrief>();
  const [storeDetails, setStoreDetails] = useState<StoreDetails>();
  const [searchQuery, setSearchQuery] = useState('');
  const [show_category_modal, setShowCategoryModal] = useState(false);
  const [categoryID, setCategoryID] =
    useState<AbstractCategoryWithSubcategories>();

  const [image, setImage] = useState<
    | ImageType
    | {
        public_s3_url: string;
        alt: string;
      }
    | null
  >();

  const [solrItems, setSolrItems] = useState<SolrBuyerItemsResponse[]>([]);

  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to save the scroll position
  const saveScrollPosition = () => {
    setScrollPosition(window.scrollY);
  };

  // Function to restore scroll position after update
  const restoreScrollPosition = () => {
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
  };

  useEffect(() => {
    if (!minimalCategoriesList) dispatch(actionGetCategoriesMinimalList());
  }, []);

  useEffect(() => {
    async function fetchStoreDetails() {
      await StoresRepository.getStoreDetails(store.id).then((res) => {
        if (res) setStoreDetails(res);
      });
    }
    fetchStoreDetails();
  }, [store.id]);

  useEffect(() => {
    async function fetchListings() {
      await SolrRepository.searchBuyerQuery({
        query: searchQuery,
        filter: [
          `store_id:${store.id}`,
          categoryID ? `category_id:${categoryID.id}` : '',
        ],
      }).then((res: any) => {
        if (!res) return;
        setSolrItems(res);
      });
    }
    fetchListings();
  }, [searchQuery, categoryID]);

  interface TemplateData {
    key: React.Key;
    listing_id: number;
    name_en: string;
    name_ar: string;
    is_silal_owned: boolean;
    images: { public_s3_url: string; alt: string }[];
    description: string;
    attribute_text?: string; // For Silal-specific attributes
    category_id: number;
    item: SolrBuyerItemsResponse;
  }

  const filteredSilalTemplates = () => {
    return solrItems;
  };

  const unifiedTemplates: TemplateData[] = filteredSilalTemplates().map(
    (item) => ({
      key: item.id,
      listing_id: item.listing_id,
      name_en: item.name_en,
      name_ar: item.name_ar,
      is_silal_owned: item.is_silal_owned,
      images: [
        { public_s3_url: item.image_public_url, alt: 'listing main image' },
      ],
      description: item.attribute_text_en,
      category_id: item.category_id,
      item: item,
    }),
  );

  const columns = [
    {
      title: 'Name (EN/AR)',
      dataIndex: 'name_en',
      key: 'name_en',
      render: (_: string, record: TemplateData) => (
        <div className="flex items-center">
          <ShowImage
            imageSrc={record.images[0].public_s3_url}
            style={{
              width: '120px',
              height: '120px',
              borderRadius: '5px',
              marginRight: '1rem',
            }}
            onClick={() => setImage(record.images[0])}
          />

          <div
            className="flex items-start"
            style={{
              fontSize: '15px',
            }}
          >
            <Link
              to={`/products/listing-details/${record.listing_id.toString()}`}
              target="_blank"
              style={{
                color: Colors.greenMain,
                textDecoration: 'none',
                cursor: 'pointer',
                marginRight: '1rem',
              }}
            >
              #{record.listing_id.toString()}
            </Link>{' '}
            {record.name_en} <br /> {record.name_ar}
          </div>
        </div>
      ),
    },
    {
      title: 'Category',
      key: 'category',
      render: (_: string, record: TemplateData) => {
        const category = minimalCategoriesList?.find(
          (category) => category.id === record.item?.category_id,
        );
        return <CategoryParentsBreadcrumbs category={category!} />;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: TemplateData) => (
        <div
          className="flex flex-row"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <UpdateNameOrDescriptionModal
            dataEditingRepository={DataEditingRepository}
            listing={record.item}
            useSolrKeys
            typeOfText="name"
            onCancel={restoreScrollPosition}
            onUpdated={async (value: {
              new_value1: string;
              new_value2: string;
            }) => {
              // Do optimistic update
              const updatedListings = solrItems.map((solrItem) =>
                solrItem.listing_id === record.item.listing_id
                  ? {
                      ...solrItem,
                      name_en: value.new_value1,
                      name_ar: value.new_value2,
                    }
                  : solrItem,
              );
              setSolrItems(updatedListings);

              // Restore scroll position after update
              restoreScrollPosition();
            }}
            children={
              <div
                onClick={saveScrollPosition} // Save scroll position before opening modal
                style={{
                  fontFamily: 'Poppins',
                  color: Colors.greenAlternate,
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                Name
                <FaPencil className="ml-2 mt-1 cursor-pointer" />
              </div>
            }
          />
          <Spacer x={4} />
          <UpdateNameOrDescriptionModal
            dataEditingRepository={DataEditingRepository}
            listing={record.item}
            useSolrKeys
            onCancel={restoreScrollPosition}
            typeOfText="description"
            onUpdated={async (value: {
              new_value1: string;
              new_value2: string;
            }) => {
              // Do optimistic update
              const updatedListings = solrItems.map((solrItem) =>
                solrItem.listing_id === record.item.listing_id
                  ? {
                      ...solrItem,
                      listing_description_en: value.new_value1,
                      listing_description_ar: value.new_value2,
                    }
                  : solrItem,
              );
              setSolrItems(updatedListings);

              // Restore scroll position after update
              restoreScrollPosition();
            }}
            children={
              <div
                onClick={saveScrollPosition} // Save scroll position before opening modal
                style={{
                  fontFamily: 'Poppins',
                  color: Colors.greenAlternate,
                  display: 'flex',
                  cursor: 'pointer',
                }}
              >
                Description
                <FaPencil className="ml-2 mt-1 cursor-pointer" />
              </div>
            }
          />
        </div>
      ),
    },
  ];

  return storeDetails === undefined ? (
    <Spinner />
  ) : (
    <>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={image.alt}
          mainSrcThumbnail={image.public_s3_url}
          onCloseRequest={() => setImage(null)}
        />
      )}
      <CategoryModal
        isModalVisible={show_category_modal}
        hideModal={() => {
          setShowCategoryModal(false);
        }}
        onConfirm={async (c: AbstractCategoryWithSubcategories) => {
          setCategoryID(c);
          setShowCategoryModal(false);
        }}
        onClear={() => {
          setCategoryID(undefined);
          setShowCategoryModal(false);
        }}
      />
      <>
        <div className="d-flex justify-between">
          <Input
            isClearable
            variant="bordered"
            className="align-self-center justify-content-center  bg-white"
            placeholder="Search for listings in this store"
            startContent={<Search />}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Spacer x={5} />
          <Button
            onClick={() => setShowCategoryModal(true)}
            color="success"
            className="min-w-[400px]"
            variant="bordered"
          >
            {categoryID
              ? `Searching in ${categoryID.name_en}, click to change or clear`
              : 'Select specific category?'}
          </Button>
        </div>
        <div className="d-flex w-100 justify-content-center" />

        <div className="draft-section">
          <Table<TemplateData>
            columns={columns}
            pagination={{
              defaultPageSize: 50,
              pageSizeOptions: ['50', '100', '500', '1000'],
              position: ['topRight', 'bottomRight'],
            }}
            expandable={{
              expandRowByClick: true,
              expandedRowRender: (record) => (
                <SilalExpandable item={record.item} setImage={setImage} />
              ),
            }}
            dataSource={unifiedTemplates}
          />
        </div>
      </>
    </>
  );
}

export default StoreListingsTab;
