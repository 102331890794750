// Imports: Dependencies
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
// Imports: Redux Root Reducer and Root Saga
import RootReducer from './root_reducer';
import RootSaga from './root_saga';

// Middleware: Redux Saga
const sagaMiddleware = createSagaMiddleware();

// Redux Persist Configuration
const persistConfig = {
  key: 'silal-management-store',
  storage,
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

// Redux: Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          'payload.success',
          'cb',
        ], // ignoredPaths: ['payload.success', 'cb'],
        ignoredActionPaths: [
          'payload.success',
          'payload.failure',
          'payload.success',
          'payload.error',
        ],
      },
      immutableCheck: false, // TODO: tell saif
    }).concat(sagaMiddleware),
});

const persistor = persistStore(store);

// Run Redux Saga
sagaMiddleware.run(RootSaga);

export { store, persistor };
