export const GET_TEMPLATES = 'GET_TEMPLATES';
export const CREATE_TEMPLATES = 'CREATE_TEMPLATES';
export const TEMPLATES_FAILURE = 'TEMPLATES_FAILURE';
export const TEMPLATES_SUCCESS = 'TEMPLATES_SUCCESS';
export const SET_LAST_FETCHED_TEMPLATES = 'SET_LAST_FETCHED_TEMPLATES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const LOGOUT = 'LOGOUT';

export const actionGetTemplates = () => ({
  type: GET_TEMPLATES,
});

export const actionSetLastFetchedTemplates = (timestamp: number) => ({
  type: SET_LAST_FETCHED_TEMPLATES,
  timestamp,
});

export const actionCreateListing = (payload: any) => ({
  type: CREATE_TEMPLATES,
  payload,
});

export const actionCreateListingFail = (payload: any) => ({
  type: TEMPLATES_FAILURE,
  payload,
});

export const actionCreateListingSuccess = (payload: any) => ({
  type: TEMPLATES_SUCCESS,
  payload,
});

export const actionGetCategoryByIDSuccess = (payload: any) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});
export const actionGetCategoryByIDFail = (payload: any) => ({
  type: GET_CATEGORIES_FAILURE,
  payload,
});

export const actionDeleteTemplate = (payload: any) => ({
  type: DELETE_TEMPLATE,
  payload,
});

export const actionLogoutTemplates = () => ({
  type: LOGOUT,
});
