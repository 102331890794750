import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    .heading {
      width: 50%;
      gap: 10px;

      .back-arrow {
        cursor: pointer;
        margin: 0px 20px;
        width: 20px;
        height: 20px;
        margin-top: 5px;
      }
    }
    .buttons {
      width: 50%;
      gap: 10px;
      button {
        width: 33%;
      }
      button.cancel {
        background-color: var(--color-light-gray);
        color: #4c7061;
      }
    }
  }

  .bg-white {
    padding: 20px;
    background: white;
    border-radius: 20px;
  }

  .schedule {
    .caption {
      font-family: 'Lato';
      font-weight: 500;
      font-size: 13px;
      color: var(--color-text-gray);
    }
    .days {
      border-bottom: 2px solid var(--color-light-gray-low);
      /* box-shadow: 0px 0px 3px var(--color-light-gray-low); */
      -webkit-box-shadow: 0 8px 6px -6px var(--color-light-gray-low);
      -moz-box-shadow: 0 8px 6px -6px var(--color-light-gray-low);
      box-shadow: 0 8px 6px -6px var(--color-light-gray-low);
      padding: 0px 0px 5px 0px;
      overflow-x: scroll;
      width: 80vw;
      white-space: 'nowrap';
      display: flex;
      .wrap {
        font-weight: bold;
        cursor: pointer;
        width: 35px;
        margin: 0px 25px;
        .day_name {
          color: var(--color-text-gray);
        }
        .day_number {
          padding: 5px;
          border-radius: 50%;
        }
        &.active {
          .day_name {
            color: var(--app-color-dark);
          }
          .day_number {
            background: var(--app-color-dark);
            padding: 5px;
            border-radius: 50%;
            color: white;
          }
        }
      }
    }

    .single-day {
      padding: 10px 0px;
      position: relative;
      color: var(--color-light-gray);
      border-bottom: 1px solid var(--color-light-gray);

      .day_name {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 17px;
        letter-spacing: 0.092px;
      }

      .orders {
        padding: 4px 5px 4px 8px;
        border-radius: 5px;
        width: 95%;

        span {
          font-family: 'Lato';
          font-weight: 400;
          font-size: 15px;
          margin: 5px 5px;
          padding: 2px 5px;
          background: var(--color-light-gray-low);
          border-radius: 2px;
          width: max-content;
          cursor: pointer;
        }
      }

      .no-shift {
        font-family: 'Lato';
        font-weight: 500;
        font-size: 13px;
        color: var(--color-text-danger) !important;
      }

      .dropdown {
        position: absolute;
        z-index: 2;
        right: 20px;
        top: 15px;
        min-width: 30px;
        text-align: end;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        font-size: 20px;
        cursor: pointer;

        svg {
          color: var(--color-light-gray);
          :hover {
            color: black;
          }
        }

        .add-schedule {
          svg {
            color: var(--app-color-dark);
            font-size: 30px;
            :hover {
              color: var(--app-color-light);
            }
          }

          p {
            margin-right: 10px;
            color: var(--color-text-gray-light);
            font-size: 18px;
            letter-spacing: 1;
          }
        }

        .actions {
          button {
            text-align: start;
            border: none;
            background: var(--color-light-gray-low);
            border-radius: 5px;
            padding: 12px;
            margin-right: 20px;
            width: 150px;
            font-family: 'Lato';
            font-weight: 400;
            font-size: 15px;
            letter-spacing: -0.408px;
            color: #002733;

            &.active {
              transition: 0.5s ease-in;
              display: block;
            }
            :hover {
              transition: 0.5s ease-in;
              background-color: var(--color-light-gray);
              color: white;
            }
          }
          .active {
            transition: 0.5s ease-in;
            display: block;
          }
        }
      }

      &.active {
        color: var(--color-dark);
      }
    }
  }
`;

export default Wrapper;
