import { state as init } from '../global_state';
import {
  GET_CATEGORIES,
  SET_LAST_FETCHED_CATEGORIES,
  GET_CATEGORIES_FAILURE,
  GET_CATEGORIES_SUCCESS,
  GET_SINGLE_CATEGORY,
  LOGOUT,
} from './categories_actions';

const reducer = (
  state = {
    categories: [],
    lastFetchedCategories: 0,
    error: '',
  },
  action: any,
) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      return { ...state, error: '' };
    }
    case SET_LAST_FETCHED_CATEGORIES: {
      return { ...state, lastFetchedCategories: action.timestamp };
    }
    case GET_SINGLE_CATEGORY: {
      return { ...state, error: '' };
    }
    case GET_CATEGORIES_FAILURE: {
      return { ...state, error: action.payload };
    }
    case GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.payload?.categories,
        lastFetchedCategories: new Date().getTime(),
      };
    }
    case LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export default reducer;
