import { useEffect, useState } from 'react';
import Wrapper from './request_base_view.styled';

import { Icon } from '@iconify/react';

import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
//TODO to fix previous page issue use LocalStorage from react
// https://stackoverflow.com/questions/72418082/how-to-retain-state-of-previous-page-while-navigating-back-to-it-from-another-pa
import moment from 'moment';
import RejectionBox from './rejection_box';
import NewListingsRequestView from '../listings/new_listing_request_view';
import UpdateListingsRequestView from '../listings/update_listing_request_view';
import BusinessRequestView from '../businesses/business_request_view';
import DriverRequestView from '../drivers/driver_request_view';
import RequestsRepository from 'data/repositories/requests_repository';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { Request, RequestStatusMap, RequestType } from 'data/types/requests';
import { AcceptApplicationModal } from '../modals/accept_application_modal';
import { RejectApplicationModal } from '../modals/reject_application_modal';
import { ListingReviewModal } from '../modals/listing_review_modal';
import { RequestStatusTag } from './card';

export default function DefaultStartingView({
  requestID,
}: {
  requestID?: string;
}) {
  const [isChecked, setIsChecked] = useState<any>({});

  const location = useLocation();
  const [cardData, setCardData] = useState<Request | null>();
  const [loading, setLoading] = useState(true);
  const inquiry_type = location.pathname.split('/')[3];
  const { requestId } = useParams();
  const navigate = useNavigate();

  const getCardData = async () => {
    await RequestsRepository.getFullRequestDetails(
      parseInt(requestID || requestId!),
    ).then((response) => {
      if (!response) {
        navigate(`/inquiries/requests/${inquiry_type}`);
        return;
      }
      setCardData(response);
    });
    setLoading(false);
  };

  useEffect(() => {
    getCardData();
  }, [requestId, requestID]);

  return loading ? (
    <Spinner />
  ) : (
    <Wrapper>
      <div className="header">
        <div className="d-flex justify-content-between">
          <h4>
            {!requestID && (
              <Link to={`/inquiries/requests/${inquiry_type}`}>
                <Icon icon="akar-icons:arrow-left" />
              </Link>
            )}
            Request #{cardData?.id}
            {!(cardData?.status_name === 'resubmission') ? null : (
              <span
                style={{
                  background: '#8E7B9D',
                }}
              >
                {'this is a resubmission'}
              </span>
            )}
            <RequestStatusTag status={RequestStatusMap[cardData?.status!]} />
          </h4>

          <h4 style={{ color: '#CCD4D6' }}>
            {moment(
              //TODO ask saif about it
              cardData?.response?.response_date
                ? cardData?.response?.response_date
                : cardData?.request_date,
            ).format('YYYY-MM-D HH:mm')}
          </h4>
        </div>
        <p
          style={{
            margin: '0px 30px 0px 30px',
          }}
        >
          {cardData?.request_type ===
            RequestType.seller_management_add_listing ||
          cardData?.request_type === RequestType.seller_management_edit_listing
            ? `${(cardData as any).listing?.name_en + ' || ' + (cardData as any).listing?.name_ar}`
            : ''}
        </p>
      </div>
      {cardData?.status_name === 'pending' && (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            margin: '15px 30px 0px 30px',
          }}
        >
          <RejectApplicationModal
            requestID={cardData?.id}
            onRejectCallback={() => getCardData()}
          />
          <div className="w-2" />
          {cardData?.request_type ===
            RequestType.seller_management_add_listing && (
            <ListingReviewModal bulkRequests={[cardData?.id as number]} />
          )}
          <div className="w-2" />
          <AcceptApplicationModal
            requestID={cardData?.id}
            onAcceptCallback={() => getCardData()}
          />
        </div>
      )}
      {cardData?.status_name === 'rejected' && (
        <RejectionBox cardData={cardData} />
      )}
      <ShowAppropriateRequestBody
        inquiryType={inquiry_type}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        cardData={cardData}
      />
    </Wrapper>
  );
}

type ShowAppropriateRequestBodyProps = {
  inquiryType: string;
  isChecked: boolean;
  setIsChecked: any;
  cardData: any;
};

function ShowAppropriateRequestBody({
  inquiryType,
  isChecked,
  setIsChecked,
  cardData,
}: ShowAppropriateRequestBodyProps) {
  return inquiryType === 'businesses' ? (
    <BusinessRequestView
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      cardData={cardData}
    />
  ) : inquiryType === 'new-listings' ? (
    <NewListingsRequestView
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      cardData={cardData}
    />
  ) : inquiryType === 'update-listings' ? (
    <UpdateListingsRequestView
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      cardData={cardData}
    />
  ) : inquiryType === 'drivers' ? (
    <DriverRequestView
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      cardData={cardData}
    />
  ) : (
    inquiryType === 'promotions' && <h2>Not implemented yet</h2>
  );
}
