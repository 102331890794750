import { CSSProperties } from 'react';
import { Wrapper } from './sub_heading.styled';

type SubHeadingProps = {
  className?: string;
  style?: CSSProperties;
  text: string;
  bold?: boolean;
};

export default function SubHeading({
  className,
  style,
  text,
  bold,
}: SubHeadingProps): JSX.Element {
  return (
    <Wrapper>
      <p
        className={className}
        style={{
          ...style,
          fontWeight: bold ? 'bold' : 'normal',
        }}
      >
        {text}
      </p>
    </Wrapper>
  );
}
