import styled from 'styled-components';

export const Wrapper = styled.div`
  .body {
    padding: 20px 10px;
    .rdt_Table {
      label {
        display: inline;
      }
    }
    select {
      min-width: 200px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid grey;
    }
    input {
      min-width: 200px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid grey;
    }
    .description {
      margin-bottom: 20px;
      font-weight: bold !important;
    }
    .bg-white {
      background-color: var(--color-light);
      padding: 20px 10px;
      border-radius: 10px;
    }
    .attribute_search_head {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .heading {
        width: 30%;

        vertical-align: middle;
        line-height: 3;
      }
      .attribute_dropdown {
        width: 30%;
      }
      .search_bar {
        width: 40%;
        label {
          top: 60% !important;
        }
        input {
          padding: 14px 10px 13px 50px;
        }
      }
    }

    .set-value {
      .inputs {
        max-width: 50%;
        margin-left: 40px;
        display: flex;
        justify-content: start;
        gap: 5px;
        padding: 10px 0px;

        .title {
          margin-top: auto;
          margin-bottom: auto;
          p {
            font-weight: bold !important;
            font-size: 18px !important;
          }
        }
        .value-input {
          margin-left: auto;
          margin-right: auto;
          margin-top: auto;
          margin-bottom: auto;
        }
        .button {
          width: 40%;
          color: #05ae4b !important ;
          margin-top: 10px;
        }
      }
    }
  }
  .allowRowEvents {
    .dropdown {
      z-index: 99999;
    }
  }

  .table {
    padding: 10px 0px;
  }
  .submit-btn {
    display: flex;
    justify-content: end;
    button {
      min-width: 200px;
      margin-bottom: 20px;
    }
  }
`;
