import styled from 'styled-components';

interface WrapperProps {
  color: string;
}

const Wrapper = styled.div<WrapperProps>`
  .card {
    margin-right: 15px;
    border: none;
    margin-bottom: 15px;
    margin-top: 15px;
    float: left;
  }
  .card-small {
    margin-right: 15px;
    margin-bottom: 15px;
    margin-top: 5px;
    float: left;
  }
  .heads {
    background: rgba(204, 212, 214, 0.5);
    border-radius: 5px 5px 0px 0;
    width: 260px !important;
    height: 80px !important;
    h3 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: rgba(76, 104, 112, 0.5);

      strong {
        color: var(--color-dark);
      }
    }
    span {
      padding: 5px 10px;
      background: ${(props: WrapperProps) => props.color};
      border-radius: 5px;
      color: var(--color-light);
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 15px !important;
    }
  }

  .heads-small {
    background: #fff;
    border-radius: 5px 5px 0px 0;
    width: 260px !important;
    height: 50px !important;
    padding: 20px 10px 10px 12px;
    h3 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 17px;
      text-transform: uppercase;
      color: rgba(76, 104, 112, 0.5);

      strong {
        color: var(--color-dark);
      }
    }
    span {
      padding: 5px 10px;
      background: ${(props: WrapperProps) => props.color};
      border-radius: 5px;
      color: var(--color-light);
      text-transform: capitalize;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.4;
      margin-top: 15px !important;
    }
  }

  .body {
    background: var(--color-light);
    box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
    border-radius: 5px;
    // height: 80px;
    max-width: 260px;
    overflow: auto;
    table tbody tr {
      th {
        padding-left: 10px;
        text-align: left;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 14px;
        color: #ccd4d6;
        width: 40%;
      }
      td {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 14px;
        // overflow-x: scroll;
        overflow-y: hidden;
        color: var(--color-dark);
        width: 60%;
      }
    }
  }

  .body-small {
    background: var(--color-light);
    box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
    border-radius: 5px;
    height: 120px;
    max-width: 260px;
    padding: 0px 4px 4px 4px;
    overflow: auto;
    table tbody tr {
      th {
        padding-left: 10px;
        text-align: left;
        font-family: 'Lato';
        font-weight: 500;
        font-size: 16px;
        color: #ccd4d6;
        width: 40%;
      }
      td {
        font-family: 'Lato';
        font-weight: 700;
        font-size: 14px;
        color: var(--color-dark);
        width: 60%;
      }
    }
  }
`;

export default Wrapper;
