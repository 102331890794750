import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'silal_app_base_react/src/components/buttons';
import Card from './components/card';
import Wrapper from './components/request_base_view.styled';
import {
  business_constants,
  drivers_constants,
  new_listings_constants,
  update_listings_constants,
} from './constants';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { notFoundIllustration_path } from 'silal_app_base_react/src/assets';
import {
  BusinessAddListingRequestBrief,
  Request,
  RequestStatus,
  RequestType,
  RequestTypeMap,
  RequestTypeNameMap,
} from 'data/types/requests';
import SearchBox from 'components/search_box';
import { RequestsViewerModal } from './modals/requests_viewer_modal';
import { Collapse } from 'antd';
import RequestsRepository from 'data/repositories/requests_repository';
import { ListingReviewModal } from './modals/listing_review_modal';

export default function RequestsPage() {
  const [searchValue, setSearchValue] = useState('');
  const [currentState, setCurrentState] = useState(RequestStatus.pending);

  const [originalRequests, setOriginalRequests] = useState<Request[]>([]);
  const [requests, setRequests] = useState<Request[]>([]);
  const [lastThreeDaysRequests, setLastThreeDaysRequests] = useState(requests);
  const [lastWeekRequests, setLastWeekRequests] = useState(requests);
  const [lastTwoWeeks, setLastTwoWeeksRequests] = useState(requests);
  const [oldRequests, setOldRequests] = useState(requests);

  const [title, setTitle] = useState('');
  const [filterStores, setFilterStores] = useState<
    {
      store_id: string;
      store_name_en: string;
    }[]
  >([]);
  const [selectedStore, setSelectedStore] = useState(0);

  const requestTypeName = window.location.pathname.split('/').pop();
  const requestType =
    requestTypeName === 'businesses'
      ? RequestType.seller_management_add_store
      : requestTypeName === 'new-listings'
        ? RequestType.seller_management_add_listing
        : requestTypeName === 'update-listings'
          ? RequestType.seller_management_edit_listing
          : RequestType.register_driver_as_carrier;

  useEffect(() => {
    switchConstants(requestType!);
    async function fetchRequests() {
      // Fetch requests from the server
      await RequestsRepository.getRequests({
        statuses: [currentState],
        request_type: [requestType],
      }).then((response) => {
        if (!response) return;
        setFilterStores(getUniqueStores(response));
        setOriginalRequests(response);
        setRequests(response);
      });
    }
    fetchRequests();
  }, [requestType, currentState]);

  useEffect(() => {
    setLastThreeDaysRequests(
      requests.filter((item) => {
        return (
          RequestTypeMap[item.request_type] === requestType &&
          item.status === currentState &&
          new Date(item.request_date) >
            new Date(new Date().setDate(new Date().getDate() - 3))
        );
      }),
    );
    setLastWeekRequests(
      requests.filter((item) => {
        return (
          RequestTypeMap[item.request_type] === requestType &&
          item.status === currentState &&
          new Date(item.request_date) <
            new Date(new Date().setDate(new Date().getDate() - 3)) &&
          new Date(item.request_date) >
            new Date(new Date().setDate(new Date().getDate() - 7))
        );
      }),
    );
    setLastTwoWeeksRequests(
      requests.filter((item) => {
        return (
          RequestTypeMap[item.request_type] === requestType &&
          item.status === currentState &&
          new Date(item.request_date) <
            new Date(new Date().setDate(new Date().getDate() - 7)) &&
          new Date(item.request_date) >
            new Date(new Date().setDate(new Date().getDate() - 14))
        );
      }),
    );
    setOldRequests(
      requests.filter((item) => {
        return (
          RequestTypeMap[item.request_type] === requestType &&
          item.status === currentState &&
          new Date(item.request_date) <
            new Date(new Date().setDate(new Date().getDate() - 14))
        );
      }),
    );
  }, [requests]);

  useEffect(() => {
    setRequests(
      originalRequests
        .filter((item) => item.status === currentState)
        .filter((item) => {
          if (
            requestTypeName === 'new-listings' ||
            requestTypeName === 'update-listings'
          ) {
            if (
              !isNaN(selectedStore) &&
              (item as BusinessAddListingRequestBrief).store_id !==
                selectedStore
            ) {
              return false;
            }
          }
          return true;
        }),
    );
  }, [selectedStore]);

  function switchConstants(requestType: RequestType) {
    if (requestType === RequestType.seller_management_add_store) {
      setTitle(business_constants.TITLE);
    } else if (requestType === RequestType.seller_management_add_listing) {
      setTitle(new_listings_constants.TITLE);
    } else if (requestType === RequestType.seller_management_edit_listing) {
      setTitle(update_listings_constants.TITLE);
    } else if (requestType === RequestType.register_driver_as_carrier) {
      setTitle(drivers_constants.TITLE);
    }
  }

  const filterSearch = (value: string) => {
    setSearchValue(value);
    setRequests(
      originalRequests.filter((item) => {
        return (
          RequestTypeMap[item.request_type] === requestType &&
          item.status === currentState &&
          item.id.toString().includes(value)
        );
      }),
    );
  };

  const RequestCard = ({
    ele,
    ind,
    requestType,
  }: {
    ele: Request;
    ind: number;
    requestType: RequestType;
  }) => {
    return (
      <Link
        key={ind}
        to={`/inquiries/requests/${RequestTypeNameMap[requestType]}/${ele.id}`}
        style={{ textDecoration: 'none' }}
      >
        <Card key={ind} ele={ele} ind={ind} requestType={requestType} />
      </Link>
    );
  };

  const PeriodSection = (periodRequests: Request[]) => {
    return periodRequests.length > 0 ? (
      <div
        className={'cards_div'}
        style={{
          paddingTop: '10px',
        }}
      >
        {periodRequests.map((ele, ind) =>
          RequestCard({ ele, ind, requestType }),
        )}
      </div>
    ) : (
      <div>No Requests</div>
    );
  };

  const getUniqueStores = (requests: Request[]) => {
    const storeMap: {
      [key: string]: string;
    } = {};
    requests.forEach((request) => {
      if (
        requestTypeName === 'new-listings' ||
        requestTypeName === 'update-listings'
      ) {
        storeMap[(request as BusinessAddListingRequestBrief).store_id] = (
          request as BusinessAddListingRequestBrief
        ).store_name_en;
      }
    });
    return Object.entries(storeMap).map(([store_id, store_name_en]) => ({
      store_id,
      store_name_en,
    }));
  };

  return (
    <Wrapper>
      <div className="header">
        <h4 style={{ marginLeft: 0 }}>
          <Link to="/inquiries/requests">
            <Icon icon="akar-icons:arrow-left" />
          </Link>
          {title}
        </h4>
        <p style={{ marginLeft: '40px' }}>
          {
            requests.filter((item) => {
              return RequestTypeMap[item.request_type] === requestType;
            }).length
          }{' '}
          Requests
        </p>
      </div>
      <div className="body">
        <div className="head">
          <div className="tabs">
            <Button
              onClick={() => setCurrentState(RequestStatus.pending)}
              text={`Pending`}
              style={{
                backgroundColor:
                  currentState === RequestStatus.pending
                    ? Colors.greenMain
                    : 'transparent',
                color:
                  currentState === RequestStatus.pending
                    ? Colors.white
                    : Colors.grey,
              }}
            />
            <Button
              onClick={() => setCurrentState(RequestStatus.accepted)}
              text={`Accepted`}
              style={{
                backgroundColor:
                  currentState === RequestStatus.accepted
                    ? Colors.greenMain
                    : 'transparent',
                color:
                  currentState === RequestStatus.accepted
                    ? Colors.white
                    : Colors.grey,
              }}
            />
            <Button
              onClick={() => setCurrentState(RequestStatus.rejected)}
              text={`Rejected`}
              style={{
                backgroundColor:
                  currentState === RequestStatus.rejected
                    ? Colors.greenMain
                    : 'transparent',
                color:
                  currentState === RequestStatus.rejected
                    ? Colors.white
                    : Colors.grey,
              }}
            />
          </div>
          {requestType === RequestType.seller_management_add_listing &&
          requests.length > 0 ? (
            <ListingReviewModal
              bulkRequests={requests.map((ele: Request) => ele.id)}
            />
          ) : null}
          {requests.length > 0 ? (
            <RequestsViewerModal
              bulkRequests={requests.map((ele: Request) => ele.id)}
            />
          ) : null}
        </div>
        <br />
        <div className="head">
          <SearchBox
            type="text"
            searchValue={searchValue}
            className="mr-4"
            placeholder="Search by request ID"
            onChange={(e) => filterSearch(e.target.value)}
          />
          {(requestType === RequestType.seller_management_add_listing ||
            requestType === RequestType.seller_management_edit_listing) && (
            <select
              value={selectedStore}
              onChange={(e) => {
                // Open collapse

                setSelectedStore(parseInt(e.target.value));
              }}
            >
              <option value="">All Stores</option>
              {filterStores.map((store) => (
                <option key={store.store_id} value={store.store_id}>
                  {store.store_name_en as string}{' '}
                  {
                    requests.filter(
                      (item) =>
                        RequestTypeMap[item.request_type] === requestType &&
                        (
                          item as BusinessAddListingRequestBrief
                        ).store_id.toString() === store.store_id,
                    ).length
                  }
                </option>
              ))}
            </select>
          )}
        </div>

        {requests.length > 0 ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Collapse
              style={{
                marginTop: '20px',
                width: '100%',
              }}
              defaultActiveKey={['1', '2', '3', '4']}
              items={[
                {
                  key: '1',
                  label: `Last 3 days (${lastThreeDaysRequests.length})`,
                  children: PeriodSection(lastThreeDaysRequests),
                },
                {
                  key: '2',
                  label: `Three to Seven days ago (${lastWeekRequests.length})`,
                  children: PeriodSection(lastWeekRequests),
                },
                {
                  key: '3',
                  label: `Seven to fourteen days ago (${lastTwoWeeks.length})`,
                  children: PeriodSection(lastTwoWeeks),
                },
                {
                  key: '4',
                  label: `Older than fourteen days ago (${oldRequests.length})`,
                  children: PeriodSection(oldRequests),
                },
              ]}
            />
          </div>
        ) : (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={notFoundIllustration_path} />
            <br />
            <h4 className="not-found-text">No Requests Found</h4>
            <h6>Try changing the filters</h6>
          </div>
        )}
      </div>
    </Wrapper>
  );
}
