import { put, call, takeLatest } from 'redux-saga/effects';
import * as AuthenticationActions from './authentication_actions';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { ErrorHandler } from 'silal_app_base_react/src/config/errors/error_handler';
import { toast } from 'react-toastify';
import UsersRepository from 'data/repositories/users_repository';

export function* sagaLogin() {
  function* login(action: any) {
    try {
      const payload = {
        token: '',
        user: {
          bearer: '',
          current_user_id: '',
        },
      };

      const res: {
        bearer: string;
        current_user_id: number;
      } = yield AuthenticationRepository.loginEmailVerifyPassword(
        action.payload.data.email,
        action.payload.data.password,
      );

      if (!res) throw new Error('Login failed');

      if (res && res.bearer) {
        yield call(AuthenticationRepository.setUser, res);
        payload.user.bearer = res.bearer;
        payload.user.current_user_id = res.current_user_id.toString();
        yield put(AuthenticationActions.actionSetUser(payload));
        action.payload.success(res);
      }
    } catch (e: any) {
      const errorMessage: string = yield call(ErrorHandler, e);
      action.payload.error();
      toast.warn(errorMessage);
    }
  }
  yield takeLatest(AuthenticationActions.LOGIN, login);
}

export function* sagaLoginMFAVerifyOTP() {
  function* loginMFAVerifyOtp(action: any) {
    try {
      const payload = {
        token: '',
        user: {
          bearer: '',
          current_user_id: '',
        },
      };

      let res: {
        bearer: string;
        current_user_id: number;
      } = {
        bearer: '',
        current_user_id: 0,
      };
      if (action.payload.data.isPhone)
        res = yield AuthenticationRepository.loginPhoneMFAVerifyOTP(
          action.payload.data.temporar_token,
          action.payload.data.otp,
        );
      else
        res = yield AuthenticationRepository.loginEmailMFAVerifyOTP(
          action.payload.data.temporar_token,
          action.payload.data.otp,
        );

      if (!res) throw new Error('Login failed');

      if (res && res.bearer) {
        yield call(AuthenticationRepository.setUser, res);
        payload.user.bearer = res.bearer;
        payload.user.current_user_id = res.current_user_id.toString();
        yield put(AuthenticationActions.actionSetUser(payload));
        action.payload.success(res);
      }
    } catch (e: any) {
      const errorMessage: string = yield call(ErrorHandler, e);
      action.payload.error();
      toast.warn(errorMessage);
    }
  }
  yield takeLatest(
    AuthenticationActions.LOGIN_MFA_VERIFY_OTP,
    loginMFAVerifyOtp,
  );
}

export function* sagaLogout() {
  function* logout() {
    yield call(AuthenticationRepository.logout);
  }
  yield takeLatest(AuthenticationActions.LOGOUT, logout);
}
