import { Button, useDisclosure } from '@nextui-org/react';
import RequestsRepository from 'data/repositories/requests_repository';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';

export const AcceptApplicationModal = ({
  requestID,
  onAcceptCallback,
}: {
  requestID: number;
  onAcceptCallback?: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };
  return (
    <>
      <div className="flex flex-wrap gap-3">
        <Button
          color="success"
          onPress={async () => {
            await RequestsRepository.respondToRequest(
              requestID,
              true,
              'Accepted',
            ).then((res) => {
              if (!res) return;
              onClose();
              onAcceptCallback?.();
            });
          }}
        >
          Accept Application
        </Button>
      </div>
      {/* <PopupWithConfirmation
        open={isOpen}
        onButtonOneClick={() => {
          onClose();
          return;
        }}
        headerTitle="Accept Application?"
        bodyText="Accept this listing will directly add it to the platform and make it available to all customers."
        buttonTwoText="Accept"
        onButtonTwoClick={async () => {
          await RequestsRepository.respondToRequest(
            requestID,
            true,
            'Accepted',
          ).then((res) => {
            if (!res) return;
            onClose();
            onAcceptCallback?.();
          });
        }}
        withTimerOfNSeconds={null}
      /> */}
    </>
  );
};
