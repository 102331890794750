import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { Banner } from 'data/types/banners';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { AxiosInstance } from 'axios';
class BannersApiRepository extends AuthenticatedApiRepository {
  private static instance: BannersApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): BannersApiRepository {
    if (!BannersApiRepository.instance) {
      BannersApiRepository.instance = new BannersApiRepository(axiosInstance);
    }
    return BannersApiRepository.instance;
  }

  getBanners = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/banners/list/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.banners as Banner[];
    } catch (e: any) {
      return false;
    }
  };

  createBanner = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/banners/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: 'Banner Created Successfully!',
        },
      );
      return res.data.banner as Banner;
    } catch (e: any) {
      return false;
    }
  };

  updateBanner = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/banners/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: 'Banner Edited Successfully!',
        },
      );
      return res.data.banner as Banner;
    } catch (e: any) {
      return false;
    }
  };

  deleteBanner = async (banner_id: number) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('DELETE', `/banners/${banner_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: 'Banner Deleted Successfully!',
        },
      );
      return true;
    } catch (e: any) {
      return false;
    }
  };

  pauseBanner = async (banner: { paused: boolean; id: number }) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/banners/${banner.id}/${banner.paused ? 'unpause' : 'pause'}/`,
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: `Banner ${banner.paused ? 'Unpaused' : 'Paused'} Successfully!`,
        },
      );
      return true;
    } catch (e: any) {
      return false;
    }
  };
}

const BannersRepository = BannersApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default BannersRepository;
