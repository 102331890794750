import React, { useEffect, useMemo, useState } from 'react';
import {
  Tabs,
  Tab,
  Card,
  CardHeader,
  CardBody,
  ModalBody,
  ModalFooter,
  Button,
  ModalContent,
  Modal,
  useDisclosure,
  ModalHeader,
  Spacer,
  CardFooter,
  CheckboxGroup,
  Checkbox,
  Textarea,
  Table,
  Pagination,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  getKeyValue,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Spinner,
  ScrollShadow,
} from '@nextui-org/react';
import {
  BusinessAddListingRequest,
  RequestStatus,
  RequestStatusMap,
} from 'data/types/requests';
import {
  formatAttributeValues,
  formatDecimals,
  formatPrice,
  sanitizeHTML,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { SanitizedHTMLStyle } from 'silal_app_base_react/src/styles/style';
import { CategoryParentsBreadcrumbs } from 'silal_app_base_react/src/components/categories_breadcrubmbs';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { useDispatch } from 'react-redux';
import { AbstractCategory } from 'silal_app_base_react/src/data/types/categories';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';

import { RequestAttributeSection } from '../listings/new_listing_request_view';
import { WrapperViewDetail } from '../components/request_base_view.styled';
import { AnimatePresence, motion } from 'framer-motion';
import Lightbox from 'react-image-lightbox';
import {
  FaArrowRight,
  FaChevronLeft,
  FaChevronRight,
  FaLeftLong,
  FaPencil,
  FaRightLong,
} from 'react-icons/fa6';
import DataEditingRepository from 'data/repositories/data_editing_repository';
import { IoCheckmark, IoChevronDownOutline } from 'react-icons/io5';
import { RejectionReasons } from './reject_application_modal';
import RequestsRepository from 'data/repositories/requests_repository';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { RequestStatusTag } from '../components/card';
import { UpdateNameOrDescriptionModal } from 'silal_app_base_react/src/pages/listing_profile/modals/update_name_desc_modal';
import { toast } from 'react-toastify';
import { RiForbidLine } from 'react-icons/ri';
import { GoChecklist } from 'react-icons/go';
import { SimilarListingsCard } from '../components/similar_items_card';

const infoController = {
  name_en: {
    correct_spelling_and_capitalization_en: {
      value: false,
      label: 'English name spelling & capitalization',
    },
    if_variant_no_attribute_in_name_en: {
      value: false,
      label: 'If variant, no attribute in English name',
    },
    no_weird_characters_en: {
      value: false,
      label: 'No weird characters in English name',
      attentionRequired: () => {
        const weirdCharacters = /[^a-zA-Z0-9\s(]/g;
        return weirdCharacters.test('');
      },
    },
  },
  name_ar: {
    correct_name_translation_ar: {
      value: false,
      label: 'Correct name translation',
    },
    if_variant_no_attribute_in_name_ar: {
      value: false,
      label: 'If variant, no attribute in Arabic name',
    },
    no_weird_characters_ar: {
      value: false,
      label: 'No weird characters in Arabic name',
      attentionRequired: () => {
        const weirdCharacters = /[^a-zA-Z0-9\s(]/g;
        return weirdCharacters.test('');
      },
    },
  },
  category: {
    choose_correct_category: {
      value: false,
      label: 'Choose correct category',
    },
  },
  cover_image: {
    padded: {
      value: false,
      label: 'Padded cover image',
    },
    white_or_transparent_background: {
      value: false,
      label: 'White or transparent background cover image',
    },
  },
  shipping: {
    reasonable_dimensions_and_weight: {
      value: false,
      label: 'Reasonable shipping info',
    },
  },
  description_en: {
    en_description_is_for_the_item: {
      value: false,
      label: 'English description is for the item',
    },
    correct_spelling_and_capitalization_desc_en: {
      value: false,
      label: 'Correct spelling and capitalization',
    },
    no_weird_characters_desc_en: {
      value: false,
      label: 'Good shape & no weird characters',
    },
  },
  description_ar: {
    ar_description_is_for_the_item: {
      value: false,
      label: 'Arabic description is for the item',
    },
    correct_descriptions_translation_desc_ar: {
      value: false,
      label: 'Correct descriptions translation',
    },
    no_weird_characters_desc_ar: {
      value: false,
      label: 'Good shape & no weird characters',
    },
  },
  photos: {
    main_image_is_good: {
      value: false,
      label: 'Main image is square and transparent',
    },
    images_match_each_item: {
      value: false,
      label: 'Images match each item',
    },
    images_are_not_watermarked: {
      value: false,
      label: 'Images are not watermarked',
    },
    images_are_not_duplicate: {
      value: false,
      label: 'Images are not duplicate',
    },
    images_are_not_low_quality: {
      value: false,
      label: 'Images are not low quality',
    },
    ar_en_hb_text_only: {
      value: false,
      label: 'Arabic, English, Hebrew text only',
    },
  },
  specifications: {
    custom_attributes_value: {
      value: false,
      label: 'Custom attributes, if available, are correct',
    },
    custom_attributes: {
      value: false,
      label:
        'Custom attributes, if available, names and values spelling and capitalization',
    },

    attributes_images_are_correct: {
      value: false,
      label: 'Attributes images are same theme, correct and match the value',
    },
  },
  combs: {
    consistent_variants_images: {
      value: false,
      label: 'Variants images match the attributes combination',
    },
    reasonable_price: {
      value: false,
      label: 'Reasonable prices',
    },
  },
};

const perTabInfoControllers = {
  overview: {
    ...infoController.name_en,
    ...infoController.name_ar,
    ...infoController.category,
    ...infoController.cover_image,
    ...infoController.shipping,
  },
  description: {
    ...infoController.description_en,
    ...infoController.description_ar,
  },
  photos: infoController.photos,
  specifications: infoController.specifications,
  combs: infoController.combs,
};

const perTabInfoControllerNames = {
  overview: ['name_en', 'name_ar', 'category', 'cover_image', 'shipping'],
  description: ['description_en', 'description_ar'],
  photos: ['photos'],
  specifications: ['specifications'],
  combs: ['combs'],
};

const infoControllerNames = {
  name_en: 'Name (English)',
  name_ar: 'Name (Arabic)',
  category: 'Category',
  cover_image: 'Cover Image',
  shipping: 'Shipping',
  description_en: 'Description (English)',
  description_ar: 'Description (Arabic)',
  photos: 'Photos',
  specifications: 'Specifications',
  combs: 'Combinations',
};

const tabClassNames = 'pb-0 mb-0 w-full';
const cardClassNames = 'min-h-[640px] ';
const rejectionReasons = RejectionReasons.map((reason, i) => ({
  key: i.toString(),
  label: reason,
}));
const rowsPerPage = 3;

const SectionSubtitle = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: JSX.Element;
}) => (
  <>
    <h6
      style={{
        color: Colors.primaryText,
        marginBottom: '5px',
        fontFamily: Fonts.primary,
      }}
    >
      {title}
    </h6>
    {subtitle}
  </>
);

export function ListingReviewModal({
  bulkRequests = [],
}: {
  bulkRequests?: number[];
}) {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { requestId } = useParams();
  const navigate = useNavigate();

  const [requestIndex, setRequestIndex] = useState(0);
  const [fullScreen, setFullScreen] = useState(true);

  const [category, setCategory] = useState<AbstractCategory>();
  const [selectedTab, setSelectedTab] = React.useState('overview');
  const [page, setPage] = React.useState(1);

  const [cardData, setCardData] = useState<BusinessAddListingRequest | null>();

  const [selected, setSelected] = useState<string[]>(
    // Filter keys based on their value being true
    [
      ...Object.keys(infoController.name_en).filter(
        (key) =>
          infoController.name_en[key as keyof typeof infoController.name_en]
            .value,
      ),
      ...Object.keys(infoController.name_ar).filter(
        (key) =>
          infoController.name_ar[key as keyof typeof infoController.name_ar]
            .value,
      ),
      ...Object.keys(infoController.category).filter(
        (key) =>
          infoController.category[key as keyof typeof infoController.category]
            .value,
      ),
      ...Object.keys(infoController.cover_image).filter(
        (key) =>
          infoController.cover_image[
            key as keyof typeof infoController.cover_image
          ].value,
      ),
      ...Object.keys(infoController.shipping).filter(
        (key) =>
          infoController.shipping[key as keyof typeof infoController.shipping]
            .value,
      ),
      ...Object.keys(infoController.description_en).filter(
        (key) =>
          infoController.description_en[
            key as keyof typeof infoController.description_en
          ].value,
      ),
      ...Object.keys(infoController.description_ar).filter(
        (key) =>
          infoController.description_ar[
            key as keyof typeof infoController.description_ar
          ].value,
      ),
      ...Object.keys(infoController.photos).filter(
        (key) =>
          infoController.photos[key as keyof typeof infoController.photos]
            .value,
      ),
      ...Object.keys(infoController.specifications).filter(
        (key) =>
          infoController.specifications[
            key as keyof typeof infoController.specifications
          ].value,
      ),
      ...Object.keys(infoController.combs).filter(
        (key) =>
          infoController.combs[key as keyof typeof infoController.combs].value,
      ),
    ],
  );
  const [lines, setLines] = useState(cardData?.response?.msg || '');

  const [selectedKeys, setSelectedKeys] = useState<Set<string>>(new Set([]));

  const [index, setIndex] = useState(-1);
  const image = cardData?.listing?.images?.[index];
  const currentImage = cardData?.listing?.images?.[index];
  const nextIndex = (index + 1) % (cardData?.listing?.images?.length || 0);
  const nextImage = cardData?.listing?.images?.[nextIndex] || currentImage;
  const prevIndex =
    (index + (cardData?.listing?.images?.length || 0) - 1) %
    (cardData?.listing?.images?.length || 0);
  const prevImage = cardData?.listing?.images?.[prevIndex] || currentImage;
  const handleClick = (index: number) => setIndex(index);
  const handleClose = () => {
    setIndex(-1);
  };
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    if (bulkRequests[requestIndex] || parseInt(requestId!)) getCardData();
  }, [requestIndex, bulkRequests]);

  useEffect(() => {
    setLines(cardData?.response?.msg || '');
  }, [cardData?.id]);

  useEffect(() => {
    dispatch(
      actionGetCategoriesMinimalList({
        success: (minimalCategoriesList: AbstractCategory[]) =>
          setCategory(
            minimalCategoriesList.find(
              (c) => c.id === cardData?.listing?.category_id,
            ),
          ),
      }),
    );
  }, [cardData, cardData?.listing?.category_id]);

  const selectedValue = useMemo(
    () =>
      rejectionReasons
        .filter((item) => selectedKeys.has(item.key))
        .map((item) => item.label)
        .join(',\n ')
        .replaceAll('_', ' '),
    [selectedKeys],
  );

  const items = React.useMemo(() => {
    const start = (page - 1) * rowsPerPage;
    const end = start + rowsPerPage;

    return cardData?.listing?.items?.slice(start, end);
  }, [page, cardData?.listing?.items]);

  const getCardData = async () => {
    await RequestsRepository.getFullRequestDetails(
      bulkRequests[requestIndex] || parseInt(requestId!),
    ).then((response) => {
      if (!response) {
        navigate(`/inquiries/requests/new-listings`);
        return;
      }
      setCardData(response as BusinessAddListingRequest);
    });
  };

  function handleNextTab() {
    switch (selectedTab) {
      case 'overview':
        setSelectedTab('description');
        break;
      case 'description':
        setSelectedTab('photos');
        break;
      case 'photos':
        setSelectedTab('specifications');
        break;
      case 'specifications':
        setSelectedTab('combs');
        break;
      case 'combs':
        setSelectedTab('overview');
        break;
      default:
        setSelectedTab('overview');
        break;
    }
  }

  function handlePreviousTab() {
    switch (selectedTab) {
      case 'overview':
        setSelectedTab('combs');
        break;
      case 'description':
        setSelectedTab('overview');
        break;
      case 'photos':
        setSelectedTab('description');
        break;
      case 'combs':
        setSelectedTab('specifications');
        break;
      case 'specifications':
        setSelectedTab('photos');
        break;
      default:
        setSelectedTab('overview');
        break;
    }
  }

  function handleNextRequest() {
    if (requestIndex < bulkRequests.length - 1) {
      setRequestIndex(requestIndex + 1);
    } else {
      onClose();
    }
    setSelectedKeys(new Set([]));
    setLines('');
    setSelectedTab('overview');
    setSelected([]);
  }

  function handlePreviousRequest() {
    if (requestIndex > 0) {
      setRequestIndex(requestIndex - 1);
    }
    setLines('');
    setSelectedKeys(new Set([]));
    setSelectedTab('overview');
  }

  async function handleRespond(accepted: boolean) {
    if (accepted) console.log(`accepting request ${cardData?.id}`);
    else console.log(`rejecting request ${cardData?.id} reason ${lines}`);

    if (!cardData?.id) return;
    if (lines !== '' && accepted) {
      toast.warn(
        'We noticed that some rejection reasons were added, remove before accepting the request to continue',
      );
      return;
    }

    if (lines === '' && !accepted) {
      toast.warn('Please add rejection reasons before rejecting the request');
      return;
    }

    // if (accepted && selectedTab !== 'combs') {
    //   toast.info('Please view all sections before accepting the request');
    //   return;
    // }

    await RequestsRepository.respondToRequest(
      cardData?.id,
      accepted,
      accepted ? 'Request accepted' : lines,
    ).then((res) => {
      if (!res) return;
      handleNextRequest();
    });
  }

  const CheckboxGroupForTab = ({
    tab,
    withoutSelectAll = false,
    withoutGroupValues = false,
    verticalList = false,
  }: {
    tab: keyof typeof infoController;
    withoutSelectAll?: boolean;
    withoutGroupValues?: boolean;
    verticalList?: boolean;
  }) => {
    const numOfCheckboxes = Object.keys(infoController[tab]).length;
    const isBicolumn = numOfCheckboxes === 2;
    const isMultiColumn = numOfCheckboxes > 2; // Determine if multi-column layout is needed

    return (
      <CheckboxGroup
        label={
          !withoutSelectAll && (
            <Checkbox
              isSelected={Object.keys(infoController[tab]).every((key) =>
                selected.includes(key),
              )}
              size="lg"
              defaultChecked
              color="primary"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelected((prev) => [
                    ...prev,
                    ...Object.keys(infoController[tab]),
                  ]);
                } else {
                  setSelected((prev) =>
                    prev.filter(
                      (key) => !Object.keys(infoController[tab]).includes(key),
                    ),
                  );
                }
              }}
            >
              {infoControllerNames[tab]}
            </Checkbox>
          )
        }
        color="primary"
        defaultValue={Object.keys(infoController[tab])}
        value={selected}
        onValueChange={setSelected}
      >
        <div
          style={{
            display: verticalList ? 'flex' : 'grid',
            gridTemplateColumns: isMultiColumn
              ? 'repeat(3, 1fr)'
              : isBicolumn
                ? 'repeat(2, 1fr)'
                : '1fr',
            gap: '10px',
            marginTop: withoutSelectAll ? '10px' : '0',
            flexDirection: verticalList ? 'column' : 'row',
            marginLeft: verticalList ? '10p' : '0px',
          }}
        >
          {!withoutGroupValues &&
            Object.keys(infoController[tab]).map((key) => (
              <>
                <Checkbox key={key} value={key}>
                  {
                    (
                      infoController[tab][
                        key as keyof (typeof infoController)[typeof tab]
                      ] as any
                    ).label
                  }
                </Checkbox>
              </>
            ))}
          {verticalList && (
            <div
              style={{
                margin: '10px 0',
                width: '100%',
                height: '1px',
                backgroundColor: '#e0e0e0',
              }}
            />
          )}
        </div>
      </CheckboxGroup>
    );
  };

  const Header = () => {
    return (
      <ModalHeader className="flex flex-col w-full pb-2">
        <div className="flex justify-between">
          <div className="flex flex-col">
            Request #{cardData?.id}: {cardData?.listing?.name_en} -{' '}
            {cardData?.listing?.name_ar} -{' '}
            <p className="text-sm text-gray-400">
              Listing info are grouped according to their relevance, checkboxes
              are here for guidance purpose only{' '}
              <span
                style={{
                  color: Colors.orange,
                }}
              >
                (not validated)
              </span>
              .<br />
              At each section, you can add more details to the rejection list on
              the left or check the checkbox to add the whole section to the
              rejection list{' '}
              <span
                style={{
                  color: Colors.orange,
                }}
              >
                (filled checkboxes will not be added){' '}
              </span>
              .
              <br /> NOTE:{' '}
              <span
                style={{
                  color: Colors.tomatoRed,
                }}
              >
                NO CONFIRMATION POPUPS{' '}
              </span>
              WILL BE SHOWN
            </p>
          </div>{' '}
          {cardData?.status && (
            <RequestStatusTag status={RequestStatusMap[cardData?.status!]} />
          )}
          <div className="flex flex-col">
            <p className="text-sm text-gray-400">
              Submitted: {formateServerDateToLocaleDate(cardData?.request_date)}
            </p>
            <p className="text-sm text-gray-400">
              Store:{' '}
              <Link
                to={`/databases/stores/${cardData?.store_id}/profile`}
                target="_blank"
                style={{
                  color: Colors.greenMain,
                }}
              >
                {' '}
                #{cardData?.store_id}
              </Link>
            </p>
            <p className="text-sm text-gray-400">
              Listing ID:{' '}
              <Link
                target="_blank"
                to={`/products/listing-details/${cardData?.listing?.id}`}
                style={{
                  color: Colors.greenMain,
                }}
              >
                {' '}
                #{cardData?.listing_id}
              </Link>
            </p>
          </div>
        </div>
      </ModalHeader>
    );
  };

  const FirstColumn = () => {
    return (
      <Card className="min-h-full">
        <CardHeader className="mb-0 pb-0">
          Rejection Info
          <RiForbidLine size={25} color="red" className="ml-1" />
        </CardHeader>
        <CardBody>
          <Dropdown closeOnSelect shouldBlockScroll={false} showArrow>
            <DropdownTrigger>
              <Button
                variant="bordered"
                className="flex justify-between w-full"
                style={{
                  height: 'fit-content',
                  minHeight: '3rem',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                endContent={
                  <IoChevronDownOutline className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                }
              >
                {selectedValue.length > 0
                  ? selectedValue
                  : 'Select rejection reasons'}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              disallowEmptySelection={false}
              aria-label="Dynamic Actions"
              items={rejectionReasons}
              selectionMode={'multiple'}
              closeOnSelect={false}
            >
              {(item) => (
                <DropdownItem
                  key={item.key}
                  selectedIcon={() => {
                    return selectedKeys.has(item.key) ? <IoCheckmark /> : null;
                  }}
                  onClick={() => {
                    const newKeys = new Set(selectedKeys);
                    if (selectedKeys.has(item.key)) {
                      newKeys.delete(item.key);
                    } else {
                      newKeys.add(item.key);
                      setLines((prev) => prev + item.label + ': \n\n\n');
                    }
                    setSelectedKeys(newKeys);
                  }}
                  closeOnSelect
                >
                  {item.label}
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
          <Spacer y={4} />
          <Textarea
            key={cardData?.id}
            minRows={20}
            maxRows={25}
            readOnly={cardData?.status !== RequestStatus.pending}
            label="Rejection reasons"
            labelPlacement="outside"
            disableAnimation
            placeholder="Enter rejection reasons"
            value={lines}
            onChange={(e) => setLines(e.target.value)}
          />
        </CardBody>
      </Card>
    );
  };

  const LastColumn = () => {
    const tabs =
      perTabInfoControllerNames[
        selectedTab as keyof typeof perTabInfoControllers
      ];
    function handleClick() {
      const unchecked = Object.keys(
        perTabInfoControllers[
          selectedTab as keyof typeof perTabInfoControllers
        ],
      );

      if (unchecked.map((key) => selected.includes(key)).includes(false)) {
        for (const key of unchecked) {
          const val = (
            perTabInfoControllers[
              selectedTab as keyof typeof perTabInfoControllers
            ] as Record<string, { value: boolean; label: string }>
          )[key];
          if (!val.value) {
            setLines((prev) => prev + '\n' + val.label + ': \n');
          }
        }
      } else {
        toast.info('All checkboxes are already checked');
      }
    }
    return (
      <Card className="min-h-full">
        <CardHeader>
          Section Checklist
          <GoChecklist size={25} color="green" className="ml-1" />
        </CardHeader>
        <CardBody className="align-top">
          <ScrollShadow size={200} className="max-h-[600px]">
            {tabs.map((tab, i) => (
              <CheckboxGroupForTab
                tab={tab as keyof typeof infoController}
                verticalList
                key={i}
              />
            ))}
          </ScrollShadow>
        </CardBody>
        <CardFooter className="w-full justify-center">
          <Button color="danger" variant="flat" onPress={handleClick}>
            Add Unchecked to Rejection Reasons
          </Button>
        </CardFooter>
      </Card>
    );
  };

  const Footer = () => {
    return (
      <ModalFooter className="mt-0 pt-2 flex flex-row justify-between">
        <Button
          isDisabled={cardData?.status !== RequestStatus.pending}
          color="danger"
          variant={'bordered'}
          onPress={() => handleRespond(false)}
        >
          Reject Application
        </Button>
        <div
          style={{
            flex: 1,
          }}
        ></div>
        {bulkRequests.length > 1 && (
          <>
            <Button
              isDisabled={requestIndex === 0}
              onClick={handlePreviousRequest}
              color="warning"
              variant="light"
            >
              <FaLeftLong />
              Previous Application
            </Button>
            <Button color="default" variant="flat" onPress={handlePreviousTab}>
              <FaChevronLeft /> Previous Section
            </Button>
            <p
              style={{
                margin: 'auto 10px',
                color: Colors.alternateText,
                fontSize: '1rem',
              }}
            >
              {requestIndex + 1 + ' / ' + bulkRequests.length}
            </p>
            <Button color="default" variant="flat" onPress={handleNextTab}>
              Next Section <FaChevronRight />
            </Button>
            <Button
              isDisabled={requestIndex === bulkRequests.length - 1}
              onClick={handleNextRequest}
              endContent={<FaRightLong />}
              color="warning"
              variant="light"
            >
              Next Application
            </Button>
          </>
        )}
        <div
          style={{
            flex: 1,
          }}
        ></div>
        <Button
          isIconOnly
          onClick={() => setFullScreen(!fullScreen)}
          variant="light"
        >
          {fullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
        </Button>
        <Button
          color="success"
          isDisabled={cardData?.status !== RequestStatus.pending}
          variant={'solid'}
          onPress={() => handleRespond(true)}
        >
          Accept Application
        </Button>
      </ModalFooter>
    );
  };

  return !cardData ? (
    <Spinner />
  ) : (
    <>
      <Button
        onPress={onOpen}
        color="primary"
        variant="flat"
        className="mx-2 min-w-[230px]"
      >
        Open NEW Requests Reviewer
      </Button>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={`# ${currentImage?.id} ` + (image?.alt || 'No Alt Text')}
          mainSrcThumbnail={image.public_s3_url}
          nextSrc={
            nextImage?.public_s3_url || nextImage?.get_object_presigned_url
          }
          nextSrcThumbnail={nextImage?.alt}
          prevSrc={
            prevImage?.public_s3_url || prevImage?.get_object_presigned_url
          }
          prevSrcThumbnail={prevImage?.alt}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <Modal
        isDismissable={false}
        isOpen={isOpen}
        onClose={onClose}
        isKeyboardDismissDisabled={true}
        size={fullScreen ? 'full' : undefined}
        style={
          fullScreen
            ? {
                width: '100%',
                maxWidth: '100%',
                maxHeight: '100vh',
              }
            : {
                width: '90%',
                maxWidth: '90%',
                maxHeight: '95vh',
              }
        }
        scrollBehavior="inside"
      >
        <ModalContent>
          {(_) => (
            <>
              {Header()}
              <ModalBody className="flex flex-row justify-between mb-0 pb-2 mt-0 pt-1 flex-grow">
                <div className="first-column w-[20%]">{FirstColumn()}</div>
                <div className="middle-column w-[60%]">
                  <Tabs
                    aria-label="Options"
                    placement={'top'}
                    selectedKey={selectedTab}
                    onSelectionChange={(selectedKey) => {
                      setSelectedTab(selectedKey as string);
                    }}
                    classNames={{
                      base: 'flex justify-center min-h-full',
                    }}
                  >
                    <Tab
                      className={tabClassNames}
                      key="overview"
                      title={` Listing Overview (${cardData?.listing?.items?.length} items)`}
                    >
                      <Card className={cardClassNames}>
                        <CardBody className="justify-between">
                          <div key={cardData?.listing?.id}>
                            <SectionSubtitle title="Name" />
                            <UpdateNameOrDescriptionModal
                              dataEditingRepository={DataEditingRepository}
                              listing={cardData?.listing as any}
                              typeOfText="name"
                              onUpdated={async () => {
                                await getCardData();
                              }}
                              children={
                                <Textarea
                                  isReadOnly
                                  variant="bordered"
                                  labelPlacement="outside"
                                  value={cardData.listing?.name_en}
                                  maxRows={1}
                                  minRows={1}
                                  className="min-w-[430px] w-full"
                                  endContent={
                                    <FaPencil
                                      className="cursor-pointer"
                                      color="gray"
                                    />
                                  }
                                />
                              }
                            />
                            <Spacer y={4} />
                            <UpdateNameOrDescriptionModal
                              dataEditingRepository={DataEditingRepository}
                              listing={cardData?.listing as any}
                              typeOfText="name"
                              onUpdated={async () => {
                                await getCardData();
                              }}
                              children={
                                <Textarea
                                  isReadOnly
                                  variant="bordered"
                                  labelPlacement="outside"
                                  value={cardData.listing?.name_ar}
                                  maxRows={1}
                                  minRows={1}
                                  className="min-w-[430px]"
                                  style={{
                                    direction: 'rtl',
                                  }}
                                  endContent={
                                    <FaPencil
                                      className="ml-2 cursor-pointer"
                                      color="gray"
                                    />
                                  }
                                />
                              }
                            />
                          </div>
                          <div>
                            <SectionSubtitle title="Category" />
                            {category && (
                              <CategoryParentsBreadcrumbs category={category} />
                            )}
                          </div>
                          <div>
                            <SectionSubtitle title="Shipping" />
                            <p>
                              {parseFloat(cardData?.listing?.weight) > 1000
                                ? (
                                    parseFloat(cardData?.listing?.weight) / 1000
                                  ).toFixed(3) + 'kg'
                                : formatDecimals(cardData?.listing?.weight) +
                                  'g'}
                              {' --- '}
                              {formatDecimals(cardData?.listing?.width) + 'cm'}
                              {'*'}
                              {formatDecimals(cardData?.listing?.length) + 'cm'}
                              {'*'}
                              {formatDecimals(cardData?.listing?.height) + 'cm'}
                            </p>
                            <p>{cardData?.listing?.whats_included}</p>
                          </div>
                          <Spacer y={4} />
                          <div>
                            <SectionSubtitle title="Cover Image" />
                            {cardData?.listing?.items?.length > 1 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '10px',
                                }}
                              >
                                {cardData?.listing?.items.map((item, index) => (
                                  <ShowImage
                                    key={index}
                                    image={item.image}
                                    onClick={() => handleClick(index)}
                                    style={{
                                      background: Colors.grayLight,
                                      flex: 1,
                                      width: '100%',
                                      height: '200px',
                                      border: '1px dashed green',
                                      borderRadius: '10px',
                                    }}
                                  />
                                ))}
                              </div>
                            ) : (
                              <ShowImage
                                key={cardData?.listing?.id}
                                image={cardData?.listing?.images.find(
                                  (image) =>
                                    image.id ===
                                    cardData?.listing?.cover_image_id,
                                )}
                                onClick={() =>
                                  setIndex(
                                    cardData?.listing?.images.findIndex(
                                      (image) =>
                                        image.id ===
                                        cardData?.listing?.cover_image_id,
                                    ),
                                  )
                                }
                                style={{
                                  background: Colors.grayLight,
                                  flex: 1,
                                  width: '100%',
                                  height: '200px',
                                  border: '1px dashed green',
                                  borderRadius: '10px',
                                }}
                              />
                            )}
                          </div>
                        </CardBody>
                      </Card>
                      <SimilarListingsCard
                        listing_name_en={cardData.listing?.name_en}
                        listing_name_ar={cardData.listing?.name_ar}
                        store_id={cardData.store_id.toString()}
                      />
                    </Tab>
                    <Tab
                      key="description"
                      title="Description"
                      className={tabClassNames}
                    >
                      <Card className={cardClassNames}>
                        <CardBody>
                          {' '}
                          <SectionSubtitle title="Description" />
                          <UpdateNameOrDescriptionModal
                            key={cardData?.listing?.id}
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              await getCardData();
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <SanitizedHTMLStyle
                                  style={{
                                    overflow: 'auto',
                                    minHeight: '220px',
                                    maxHeight: '300px',
                                    border: '1px solid #eaeaea',
                                    borderRadius: '10px',
                                    margin: '10px 0',
                                    padding: '10px',
                                  }}
                                  spellCheck
                                  dangerouslySetInnerHTML={sanitizeHTML(
                                    cardData?.listing?.rich_text_description_en,
                                  )}
                                />
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                          <UpdateNameOrDescriptionModal
                            key={cardData?.listing?.id}
                            dataEditingRepository={DataEditingRepository}
                            listing={cardData?.listing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              await getCardData();
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <SanitizedHTMLStyle
                                  spellCheck
                                  style={{
                                    direction: 'rtl',
                                    minHeight: '220px',
                                    maxHeight: '300px',
                                    overflow: 'auto',
                                    border: '1px solid #eaeaea',
                                    borderRadius: '10px',
                                    margin: '10px 0',
                                    padding: '10px',
                                  }}
                                  dangerouslySetInnerHTML={sanitizeHTML(
                                    cardData?.listing?.rich_text_description_ar,
                                  )}
                                />
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </CardBody>
                      </Card>
                    </Tab>
                    <Tab key="photos" title="Photos" className={tabClassNames}>
                      <Card className={cardClassNames}>
                        <CardBody>
                          <SectionSubtitle title="Photos" />
                          <div
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr 1fr 1fr ',
                              outline: 'none',
                              maxHeight: '400px',
                              borderRadius: '10px',
                              margin: '0 auto',
                            }}
                          >
                            {cardData.listing?.images.map((image, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex !important',
                                  justifyContent: 'center',
                                }}
                              >
                                <ShowImage
                                  image={image}
                                  onClick={() => {
                                    handleClick(index);
                                  }}
                                  style={{
                                    outline: 'none',
                                    objectFit: 'scale-down',
                                    width: '300px',
                                    border:
                                      image.id ===
                                      cardData.listing.cover_image_id
                                        ? `3px solid green`
                                        : `1px dashed green`,
                                    borderRadius: '10px',
                                    height: '300px',
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </CardBody>{' '}
                      </Card>
                    </Tab>{' '}
                    <Tab
                      key="specifications"
                      title="Specifications"
                      className={tabClassNames}
                    >
                      <Card className={cardClassNames}>
                        <CardBody>
                          <div className="flex flex-row justify-between">
                            <SectionSubtitle title="Attributes" />
                            <ShowImage
                              imageSrc={
                                cardData?.listing?.images.find(
                                  (img) =>
                                    img.id ===
                                    cardData?.listing?.cover_image_id,
                                )?.public_s3_url
                              }
                              onClick={() => {
                                handleClick(
                                  cardData.listing?.images.findIndex(
                                    (img) =>
                                      img.id ===
                                      cardData.listing?.cover_image_id,
                                  ),
                                );
                              }}
                              style={{
                                width: '50px',
                                height: '50px',
                                borderRadius: '10px',
                              }}
                            />
                          </div>
                          <WrapperViewDetail>
                            <div className="container">
                              <motion.div className="left-side">
                                <div className="grid-row-three m-0 p-0">
                                  <div
                                    className="grid-column mt-0"
                                    style={{
                                      maxHeight: '540px',
                                    }}
                                  >
                                    {category && (
                                      <CategoryParentsBreadcrumbs
                                        category={category}
                                      />
                                    )}
                                    <AnimatePresence initial={false}>
                                      <motion.div
                                        className="dataPanel"
                                        variants={{
                                          open: {
                                            opacity: 1,
                                            height: 'auto',
                                          },
                                          closed: {
                                            opacity: 0,
                                            height: 0,
                                          },
                                        }}
                                        transition={{
                                          duration: 0.3,
                                          ease: [0.4, 0.62, 0.23, 0.4],
                                        }}
                                      >
                                        <RequestAttributeSection
                                          cardData={cardData}
                                        />
                                      </motion.div>
                                    </AnimatePresence>
                                  </div>
                                </div>
                              </motion.div>
                            </div>
                          </WrapperViewDetail>
                        </CardBody>
                      </Card>
                    </Tab>
                    <Tab
                      key="combs"
                      title="Combinations"
                      className={tabClassNames}
                    >
                      <Card className={cardClassNames}>
                        <CardBody>
                          <SectionSubtitle title="Combinations" />
                          <Table
                            key={cardData?.listing?.request?.id}
                            bottomContent={
                              <div className="flex w-full justify-center">
                                <Pagination
                                  showControls
                                  variant="bordered"
                                  page={page}
                                  total={Math.ceil(
                                    (cardData?.listing?.items?.length ?? 0) /
                                      rowsPerPage,
                                  )}
                                  onChange={(page) => setPage(page)}
                                />
                              </div>
                            }
                            classNames={{
                              wrapper: 'min-h-[470px] max-h-[540px]',
                            }}
                          >
                            <TableHeader>
                              <TableColumn key={'id'}>Item ID</TableColumn>
                              <TableColumn key={'attrs'}>
                                Item Attributes
                              </TableColumn>
                              <TableColumn key={'price'}>
                                Item Price
                              </TableColumn>
                              <TableColumn key={'imagesss'}>
                                Variation Image
                              </TableColumn>
                            </TableHeader>
                            <TableBody
                              key={cardData?.listing?.id}
                              items={items}
                            >
                              {(item) => (
                                <TableRow key={item.id}>
                                  {(columnKey) => {
                                    if (columnKey === 'imagesss') {
                                      return (
                                        <TableCell>
                                          <ShowImage
                                            image={item.image}
                                            style={{
                                              width: '100px',
                                              height: '100px',
                                              borderRadius: '10px',
                                            }}
                                            onClick={
                                              item.image
                                                ? () => {
                                                    handleClick(
                                                      cardData.listing?.images.findIndex(
                                                        (img) =>
                                                          img.id ===
                                                          item.image.id,
                                                      ),
                                                    );
                                                  }
                                                : undefined
                                            }
                                          />
                                        </TableCell>
                                      );
                                    } else if (columnKey === 'attrs') {
                                      return (
                                        <TableCell>
                                          {item.attributes
                                            .map((attr) =>
                                              formatAttributeValues(
                                                attr.attr_value_en?.toString(),
                                                attr.attr_value_ar?.toString(),
                                                attr.attribute_type,
                                              ),
                                            )
                                            .join(' - ')}
                                        </TableCell>
                                      );
                                    } else if (columnKey === 'price') {
                                      return (
                                        <TableCell>
                                          {formatPrice(
                                            getKeyValue(item, columnKey),
                                          )}
                                        </TableCell>
                                      );
                                    } else {
                                      return (
                                        <TableCell>
                                          {getKeyValue(item, columnKey)}
                                        </TableCell>
                                      );
                                    }
                                  }}
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </CardBody>{' '}
                      </Card>
                    </Tab>
                  </Tabs>
                </div>
                <div className="last-column w-[20%]">{LastColumn()}</div>
              </ModalBody>
              {Footer()}
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
