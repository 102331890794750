import CreateNewPasswordScreen from 'pages/auth/screens/create_new_password/create_new_password_screen';
import LoginScreen from 'pages/auth/screens/login/login_screen';
import OtpScreen from 'pages/auth/screens/otp/otp_screen';
import ResetPasswordScreen from 'pages/auth/screens/reset_password/reset_password_screen';
import SplashScreen from 'pages/auth/screens/splash/splash_screen';
import { Outlet, Route, Routes } from 'react-router-dom';
import { AuhtneticationPagesHandler } from 'routes/handlers/authentication_pages_handler';
import { NotFoundPage, Page404 } from 'pages/404/404_page';

const UnauthenticatedViewRouter = () => {
  return (
    <AuhtneticationPagesHandler>
      <Routes>
        <Route
          path="/"
          element={
            <div
              style={{
                width: '100%',
                height: '100vh',
              }}
            >
              <Outlet />
            </div>
          }
        >
          <Route index element={<LoginScreen />} />
          <Route path="phone-otp-verification" element={<OtpScreen />} />
          <Route path="resetpassword" element={<ResetPasswordScreen />} />
          <Route
            path="createnewpassword"
            element={<CreateNewPasswordScreen />}
          />
          <Route path="splash" element={<SplashScreen />} />
        </Route>
        {/* 404 */}
        <Route path="/404" element={<Page404 path="/" time={5} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuhtneticationPagesHandler>
  );
};

export default UnauthenticatedViewRouter;
