import { Icon } from '@iconify/react';

import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ApplicationChecklist from '../components/application_checklist';
import { missingInfoStyle } from '../components/request_base_view.styled';
import WrapperViewDetail from './business_request_view.styled';
import {
  StoreDetails,
  businessTaxTypeMap,
} from 'silal_app_base_react/src/data/types/stores';
import { formatStoreBankDetails } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { Address } from 'silal_app_base_react/src/data/types/address';
import { BusinessStartingRequest } from 'data/types/requests';

type BusinessRequestViewProps = {
  cardData: BusinessStartingRequest;
  isChecked: boolean;
  setIsChecked: () => void;
};

export default function BusinessRequestView({
  cardData,
  isChecked,
  setIsChecked,
}: BusinessRequestViewProps) {
  const [customerPanel, setCustomerPanel] = useState(false);
  const [driverPanel, setDriverPanel] = useState(false);
  const [orderListPanel, setOrderListPanel] = useState(false);
  const [lng, setLng] = useState('');
  const [lat, setLat] = useState('');

  const [fieldsNullFlags, setFieldsNullFlags] = useState({
    has_name: false,
    has_business_owner_full_name: false,
    has_owner_id: false,
    has_business_phone: false,
    has_tax_id: false,
    has_slogan: false,
    has_region: false,
    has_address: false,
    has_city: false,
    has_town: false,
    has_address1: false,
    has_address2: false,
    has_lng: false,
    has_lat: false,
    has_email: false,
    has_website: false,
    has_social_media_link: false,
    has_category: false,
    has_working_hours: false,
    has_accepts_cash_payments: false,
    has_accepts_credit_card_payments: false,
    has_accepts_cheques_payments: false,
    has_accepts_mobile_payments: false,
  });
  useEffect(() => {
    setLng(cardData?.store?.address[0]?.lng);
    setLat(cardData?.store?.address[0]?.lat);
    setFieldsNullFlags({
      has_name:
        cardData?.store?.name_en && cardData?.store?.name_ar ? true : false,
      has_business_owner_full_name: cardData?.store?.business_owner_full_name
        ? true
        : false,
      has_owner_id: cardData?.store?.owner_id ? true : false,
      has_business_phone: cardData?.store?.business_phone ? true : false,
      has_tax_id: cardData?.store?.tax_id ? true : false,
      has_slogan: cardData?.store?.slogan ? true : false,
      has_region: cardData?.store?.region ? true : false,
      has_address: cardData?.store?.address?.length > 0 ? true : false,
      has_city: cardData?.store?.address?.map(
        (ele: Address) => ele.city.name_ar && ele.city.name_en,
      )
        ? true
        : false,
      has_town: cardData?.store?.address?.map(
        (ele: Address) => ele.town.name_ar && ele.town.name_en,
      )
        ? true
        : false,
      has_address1: cardData?.store?.address?.map(
        (ele: Address) => ele.address_line_1,
      )
        ? true
        : false,
      has_address2: cardData?.store?.address?.map(
        (ele: Address) => ele.address_line_2,
      )
        ? true
        : false,
      has_lng: cardData?.store?.address?.map((ele: Address) => ele.lng)
        ? true
        : false,
      has_lat: cardData?.store?.address?.map((ele: Address) => ele.lat)
        ? true
        : false,
      has_email: cardData?.store?.business_email ? true : false,
      has_website: cardData?.store?.business_website_url ? true : false,
      has_social_media_link: cardData?.store?.business_social_media_url
        ? true
        : false,
      has_category:
        cardData?.store?.main_category_name_ar &&
        cardData?.store?.main_category_name_en
          ? true
          : false,
      has_working_hours: true,
      has_accepts_cash_payments: cardData?.store?.accepts_cash_payments,
      has_accepts_credit_card_payments:
        cardData?.store?.accepts_credit_card_payments,
      has_accepts_cheques_payments: cardData?.store?.accepts_cheques_payments,
      has_accepts_mobile_payments: cardData?.store?.accepts_mobile_payments,
    });
  }, [cardData]);

  useEffect(() => {
    setTimeout(() => {
      setCustomerPanel(true);
      setDriverPanel(true);
      setOrderListPanel(true);
    }, 100);
  }, []);

  function getAppropriateWorkingHours(start_time: string, end_time: string) {
    if (start_time === end_time) return 'Closed';
    const start_time_am_pm =
      parseInt(start_time.substring(0, 3)) >= 12 ? 'PM' : 'AM';
    const end_time_am_pm =
      parseInt(end_time.substring(0, 3)) >= 12 ? 'PM' : 'AM';
    return (
      start_time +
      ' ' +
      start_time_am_pm +
      ' - ' +
      end_time +
      ' ' +
      end_time_am_pm
    );
  }

  function getAcceptedPayments(store: StoreDetails) {
    const accepted_payments: string[] = [];
    if (store?.accepts_cash_payments) accepted_payments.push('Cash');
    if (store?.accepts_credit_card_payments) accepted_payments.push('Card');
    if (store?.accepts_cheques_payments) accepted_payments.push('Cheques');
    if (store?.accepts_mobile_payments) accepted_payments.push('Mobile');

    return accepted_payments.length === 1
      ? accepted_payments[0]
      : accepted_payments.length === 0
        ? 'No payment methods were provided'
        : accepted_payments.join(', ');
  }
  return (
    <WrapperViewDetail>
      <div className="container">
        <motion.div className="left-side">
          <div className="grid-row-three">
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setCustomerPanel(!customerPanel)}
              >
                basic information
                <span>
                  {customerPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {customerPanel && (
                  <motion.div
                    className="dataPanel"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info lato-font">
                      <div className="row">
                        <p className="left-column">Business name</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_name ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_name
                            ? cardData?.store?.name_en +
                              ' / ' +
                              cardData?.store?.name_ar
                            : 'No business name was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business owner</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_business_owner_full_name
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_business_owner_full_name
                            ? cardData?.store?.business_owner_full_name
                            : 'No business owner was provided'}
                        </p>
                      </div>

                      <div className="row">
                        <p className="left-column">Owner ID</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_owner_id ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_owner_id
                            ? cardData?.store?.owner_id
                            : 'No owner ID was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Owner phone</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_business_phone
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_business_phone
                            ? cardData?.store?.business_phone
                            : 'No owner phone was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business Tax ID</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_tax_id ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_tax_id
                            ? cardData?.store?.tax_id
                            : 'No business tax ID was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business Tax Type</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_tax_id ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_tax_id
                            ? businessTaxTypeMap[cardData?.store?.tax_type]
                            : 'No business tax type was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Bank (Acc/Branch/Bank)</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_tax_id ? {} : missingInfoStyle
                          }
                        >
                          {formatStoreBankDetails(cardData?.store)}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Store slogan</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_slogan ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_slogan
                            ? cardData?.store?.slogan
                            : 'No store slogan was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Region, City, Town</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_region &&
                            fieldsNullFlags.has_city &&
                            fieldsNullFlags.has_town
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_region
                            ? cardData?.store?.region
                            : 'NA'}
                          ,
                          {fieldsNullFlags.has_city
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele?.city.name_ar,
                              )
                            : 'NA'}
                          {', '}
                          {fieldsNullFlags.has_town
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele?.town.name_ar,
                              )
                            : 'NA'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business address 1</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_address1 ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_address1
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele.address_line_1,
                              )
                            : 'No business address1 was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business address 2</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_address2 ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_address2
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele.address_line_2,
                              )
                            : 'No business address2 was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Latitude/Longitude</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_lat && fieldsNullFlags.has_lng
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_lat
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele.lat,
                              )
                            : 'NA'}
                          {' / '}
                          {fieldsNullFlags.has_lng
                            ? cardData?.store?.address?.map(
                                (ele: Address) => ele.lng,
                              )
                            : 'NA'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Map location</p>
                        <p className="right-column">
                          <a
                            href={` https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Icon icon="bi-geo-alt" />
                          </a>
                        </p>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setDriverPanel(!driverPanel)}
              >
                Contacts
                <span>
                  {driverPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {driverPanel && (
                  <motion.div
                    className="dataPanel lato-font"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info lato-font">
                      <div className="row">
                        <p className="left-column">Account phone</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_business_phone
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_business_phone
                            ? cardData?.store?.business_phone
                            : 'No phone was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Business email</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_email ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_email
                            ? cardData.store?.business_email
                            : 'No email was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Social media</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_social_media_link
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_social_media_link
                            ? cardData?.store?.business_social_media_url
                            : 'No social media was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Web-site</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_website ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_website
                            ? cardData?.store?.business_website_url
                            : 'No website was provided'}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setOrderListPanel(!orderListPanel)}
              >
                details
                <span>
                  {orderListPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {orderListPanel && (
                  <motion.div
                    className="dataPanel"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info lato-font">
                      <div className="row">
                        <p className="left-column">Business type</p>
                        <p className="right-column">
                          {cardData?.store?.is_home_business
                            ? 'Home Business'
                            : 'Store'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Category</p>
                        <p
                          className="right-column"
                          style={
                            fieldsNullFlags.has_category ? {} : missingInfoStyle
                          }
                        >
                          {fieldsNullFlags.has_category
                            ? cardData?.store?.main_category_name_en +
                              ' / ' +
                              cardData?.store?.main_category_name_ar
                            : 'No category was provided'}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Payment methods</p>
                        <p
                          className="right-column"
                          style={
                            getAcceptedPayments(cardData?.store).length > 0
                              ? {}
                              : missingInfoStyle
                          }
                        >
                          {getAcceptedPayments(cardData?.store)}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Description</p>
                        <p
                          className="right-column"
                          style={{
                            marginBottom: '4px',
                          }}
                        >
                          {cardData?.store?.bio || 'No notes wre given'}
                        </p>
                      </div>
                      <div className="row">
                        <div className="left-column">Working hours</div>
                        <div className="right-columns">
                          <p>Sunday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_sun_start,
                              cardData?.store?.working_time_sun_end,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <p>Monday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_mon_start,
                              cardData?.store?.working_time_mon_end,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <div>Tuesday</div>
                          <div
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_tue_start,
                              cardData?.store?.working_time_tue_end,
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <p>Wednesday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_wed_start,
                              cardData?.store?.working_time_wed_end,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <p>Thursday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_thu_start,
                              cardData?.store?.working_time_thu_end,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <p>Friday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_fri_start,
                              cardData?.store?.working_time_fri_end,
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="left-column" />
                        <div className="right-columns">
                          <p>Saturday</p>
                          <p
                            style={{
                              marginLeft: '10px',
                            }}
                          >
                            {getAppropriateWorkingHours(
                              cardData?.store?.working_time_sat_start,
                              cardData?.store?.working_time_sat_end,
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        {!(cardData?.status_name === 'pending') ? null : (
          <ApplicationChecklist
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            requestType={cardData?.request_type}
          />
        )}
      </div>
    </WrapperViewDetail>
  );
}
