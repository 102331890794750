import { Icon } from '@iconify/react';

import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ApplicationChecklist from '../components/application_checklist';
import WrapperViewDetail from './driver_request_view.styled';
import { DriverRegisterationRequest } from 'data/types/requests';
import { formatBankDetails } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { businessTaxTypeMap } from 'silal_app_base_react/src/data/types/stores';
import ShowImage from 'silal_app_base_react/src/components/show_image';

type DriverRequestViewProps = {
  cardData: DriverRegisterationRequest;
  isChecked: boolean;
  setIsChecked: () => void;
};

export default function DriverRequestView({
  cardData,
  isChecked,
  setIsChecked,
}: DriverRequestViewProps) {
  const [customerPanel, setCustomerPanel] = useState(false);
  const [driverPanel, setDriverPanel] = useState(false);
  const [orderListPanel, setOrderListPanel] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCustomerPanel(true);
      setDriverPanel(true);
      setOrderListPanel(true);
    }, 100);
  }, []);

  return (
    <WrapperViewDetail>
      <div className="container">
        <motion.div className="left-side">
          <div className="grid-row-three">
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setCustomerPanel(!customerPanel)}
              >
                basic information
                <span>
                  {customerPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {customerPanel && (
                  <motion.div
                    className="dataPanel"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info lato-font">
                      <div className="row">
                        <p className="left-column">Captain name</p>
                        <p className="right-column">{cardData?.driver_name}</p>
                      </div>
                      <div className="row">
                        <p className="left-column">Captain Phone</p>
                        <p className="right-column">
                          {cardData?.driver_phone}
                          {cardData?.delivery_user.verified_phone
                            ? ' (Verified)'
                            : ''}
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Captain City ID</p>
                        <p className="right-column">{cardData?.city_id}</p>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setDriverPanel(!driverPanel)}
              >
                Payment & Tax Info
                <span>
                  {driverPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {driverPanel && (
                  <motion.div
                    className="dataPanel lato-font"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info">
                      <div className="row">
                        <p className="left-column">Bank (Acc/Brn/Ban)</p>
                        <p className="right-column">
                          {formatBankDetails(
                            cardData?.delivery_user?.bank_account_number || 0,
                            cardData?.delivery_user.bank_branch_number || 0,
                            cardData?.delivery_user.bank_number || 0,
                          )}
                        </p>
                      </div>{' '}
                      <div className="row">
                        <p className="left-column">Tax Type</p>
                        <p className="right-column">
                          {
                            businessTaxTypeMap[
                              cardData?.delivery_user?.tax_type
                            ]
                          }
                        </p>
                      </div>
                      <div className="row">
                        <p className="left-column">Tax ID</p>
                        <p className="right-column">
                          {cardData?.delivery_user?.tax_id}
                        </p>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <div className="grid-column">
              <button
                className="lato-font"
                onClick={() => setOrderListPanel(!orderListPanel)}
              >
                Documents
                <span>
                  {orderListPanel ? (
                    <Icon icon="akar-icons:chevron-up" />
                  ) : (
                    <Icon icon="akar-icons:chevron-down" />
                  )}
                </span>
              </button>
              <AnimatePresence initial={false}>
                {orderListPanel && (
                  <motion.div
                    className="dataPanel"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={{
                      open: {
                        opacity: 1,
                        height: 'auto',
                      },
                      closed: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    transition={{
                      duration: 0.3,
                      ease: [0.4, 0.62, 0.23, 0.4],
                    }}
                  >
                    <div className="customer-info lato-font">
                      <div className="row">
                        <p className="left-column">License</p>
                        <p className="right-column">
                          <a
                            href={
                              cardData?.delivery_user?.license_image
                                ?.get_object_presigned_url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ShowImage
                              image={cardData?.delivery_user?.license_image}
                            />
                          </a>
                        </p>
                      </div>
                      <br />
                      <div className="row">
                        <p className="left-column">Bank Ownership</p>
                        <p className="right-column">
                          <a
                            href={
                              cardData?.delivery_user?.business_bank_statement
                                ?.get_object_presigned_url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ShowImage
                              image={
                                cardData?.delivery_user?.business_bank_statement
                              }
                            />
                          </a>
                        </p>
                      </div>
                      <br />
                      <div className="row">
                        <p className="left-column">Tax Certificate</p>
                        <p className="right-column">
                          <a
                            href={
                              cardData?.delivery_user?.business_tax_certificate
                                ?.get_object_presigned_url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ShowImage
                              image={
                                cardData?.delivery_user
                                  ?.business_tax_certificate
                              }
                            />
                          </a>
                        </p>
                      </div>
                      <br />
                      <div className="row">
                        <p className="left-column">DAS Certificate</p>
                        <p className="right-column">
                          <a
                            href={
                              cardData?.delivery_user?.business_das_certificate
                                ?.get_object_presigned_url
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ShowImage
                              image={
                                cardData?.delivery_user
                                  ?.business_das_certificate
                              }
                            />
                          </a>
                        </p>
                        b
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        {!(cardData?.status_name === 'pending') ? null : (
          <ApplicationChecklist
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            requestType={cardData?.request_type}
          />
        )}
      </div>
    </WrapperViewDetail>
  );
}
