import { call, put, takeEvery } from 'redux-saga/effects';
import {
  GET_ORDERS,
  actionSetCurrentOrders,
  actionSetLastFetchedOrders,
} from './orders_actions';

import OrdersRepository from 'data/repositories/orders_repository';
import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';

export function* sagaGetAllOrders() {
  function* getOrdersOfStatuses(action: { type: string; statuses?: number[] }) {
    const res: OrderBrief[] = yield call(OrdersRepository.getOrders, {
      statuses: action.statuses,
    });
    if (!res) return false;
    yield put(actionSetCurrentOrders(res));
    yield put(actionSetLastFetchedOrders(new Date().getTime()));
  }
  yield takeEvery(GET_ORDERS, getOrdersOfStatuses);
}
