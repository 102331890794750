import { ISRAEL_TIMEZONE } from 'silal_app_base_react/src/config/constants';
import moment from 'moment-timezone';
import { Col, Row } from 'react-bootstrap';
import { Link, useOutletContext } from 'react-router-dom';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Colors } from 'silal_app_base_react/src/config/theme';
import {
  OrderPackageQualities,
  OrderPaymentMethodTag,
  OrderTimeline,
} from 'silal_app_base_react/src/pages/orders_archive/components/shared_components';
import { ScreenWrapper } from '../../screens_wrapper';
import { AbstractUserInfo } from 'silal_app_base_react/src/data/types/users';
import UsersRepository from 'data/repositories/users_repository';
import { RiVerifiedBadgeLine } from 'react-icons/ri';
import { IoStorefrontOutline } from 'react-icons/io5';
import { Card, CardBody, CardHeader, Spacer } from '@nextui-org/react';
import {
  FaBox,
  FaBoxOpen,
  FaCreditCard,
  FaHouse,
  FaMotorcycle,
  FaNoteSticky,
  FaUser,
} from 'react-icons/fa6';
import { OrderItemsTable } from '../../components/order_items_table';
import {
  homeMarker_path,
  storeMarker_path,
} from 'silal_app_base_react/src/assets';

function OrderOverviewTab() {
  const { orderDetails } = useOutletContext<{
    orderDetails: OrderDetails;
    showManagementNote: boolean;
    setShowManagementNote: (value: boolean) => void;
  }>();
  const srcLat = parseFloat(orderDetails.source_address.lat);
  const srcLng = parseFloat(orderDetails.source_address.lng);
  const dstLat = parseFloat(orderDetails.destination_address.lat);
  const dstLng = parseFloat(orderDetails.destination_address.lng);
  const [customer, setCustomer] = useState<AbstractUserInfo>();

  useEffect(() => {
    const fetchCustomer = async () => {
      await UsersRepository.getUserDetails(orderDetails.customer_id).then(
        (res) => {
          if (!res) return;
          setCustomer(res);
        },
      );
    };
    fetchCustomer();
  }, [orderDetails.customer_id]);

  const defaultProps = {
    center: {
      lat: dstLat,
      lng: dstLng,
    },
    zoom: 14,
  };

  const MarkerComponent = ({
    markerChild,
    lat,
    lng,
  }: {
    markerChild: React.ReactNode;
    lat: number;
    lng: number;
  }) => (
    <>
      <div
        style={{
          color: Colors.greenMain,
          background: 'transparent',
          display: 'inline-flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)',
          animation: 'jump 2s infinite',
        }}
        onClick={() => {
          // open in google maps
          window.open(`https://www.google.com/maps/search/${lat}+${lng}`);
        }}
      >
        {markerChild}
      </div>
      <style>
        {`
      @keyframes jump {
        0%, 100% {
        transform: translateY(0);
        }
        50% {
        transform: translateY(-10px);
        }
      }
      `}
      </style>
    </>
  );

  const cardStyle = {
    border: `1px solid ${Colors.grayLight}`,
    borderRadius: '10px',
    marginBottom: '10px',
  };

  const cardRowStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  const cardIconStyle = {
    color: Colors.blueAlternate,
    marginRight: '10px',
  };

  return (
    <ScreenWrapper>
      <Row className="mb-4">
        <Col xs={9}>
          Order Items
          <OrderItemsTable
            refunding={false}
            itemsOnly
            setRefunding={() => {}}
            orderDetails={orderDetails}
          />
          <div
            style={{
              height: '20px',
            }}
          />
          Packages & Map
          <div
            style={{
              height: '50vh',
              borderRadius: '10px',
            }}
            className="flex gap-4"
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyAAVVck4wb4sGAmMsfEBCsfVH5ofSzkJqE',
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <MarkerComponent
                lat={srcLat}
                lng={srcLng}
                markerChild={
                  <img src={storeMarker_path} width={30} height={30} />
                }
              />
              <MarkerComponent
                lat={dstLat}
                lng={dstLng}
                markerChild={
                  <img src={homeMarker_path} width={30} height={30} />
                }
              />
            </GoogleMapReact>
            <div
              style={{
                width: '50%',
              }}
            >
              {OrderPackageQualities(orderDetails)}
            </div>
          </div>
        </Col>
        <Col className="flex flex-col justify-between">
          <Card shadow="sm" style={cardStyle}>
            <CardHeader className="mb-0 pb-0">
              Customer Details{' '}
              <Link to={`/databases/users`}>#{orderDetails.customer_id} </Link>
            </CardHeader>
            <CardBody>
              <div style={cardRowStyle}>
                <FaUser style={cardIconStyle} />
                <p>{customer?.first_name + ' ' + customer?.last_name}</p>
              </div>

              <div style={cardRowStyle}>
                <RiVerifiedBadgeLine style={cardIconStyle} />
                <p>{customer?.phone}</p>
              </div>
              <div style={cardRowStyle}>
                <RiVerifiedBadgeLine style={cardIconStyle} />
                <p>{customer?.email ?? 'N/A'}</p>
              </div>
              <div style={cardRowStyle}>
                <FaNoteSticky style={cardIconStyle} />
                <p>
                  {orderDetails.destination_address.notes ??
                    'No delivery notes'}
                </p>
              </div>
              <div style={cardRowStyle}>
                <FaCreditCard style={cardIconStyle} />
                {OrderPaymentMethodTag(orderDetails.payment_type)}
              </div>
            </CardBody>
          </Card>
          <Card shadow="sm" style={cardStyle}>
            <CardHeader className="mb-0 pb-0">
              Store Details
              <Link
                to={`/databases/stores/${orderDetails.store_id}/current-orders`}
              >
                #{orderDetails.store_id}{' '}
              </Link>
            </CardHeader>
            <CardBody>
              <div style={cardRowStyle}>
                <IoStorefrontOutline style={cardIconStyle} />
                <p>{orderDetails.store_name_en}</p>
              </div>{' '}
              <div style={cardRowStyle}>
                <FaBox style={cardIconStyle} />
                <p>
                  {orderDetails.source_address.address_line_1},{' '}
                  {orderDetails.source_address.address_line_2},{' '}
                  {orderDetails.source_address.town.name_en},{' '}
                  {orderDetails.source_address.city.name_en}
                </p>
              </div>
            </CardBody>
          </Card>
          <Card
            shadow="sm"
            style={{
              ...cardStyle,
              marginBottom: 0,
            }}
          >
            <CardHeader className="mb-0 pb-0">Shipping Details </CardHeader>
            <CardBody>
              <div style={cardRowStyle}>
                <FaHouse style={cardIconStyle} />
                <p>
                  {orderDetails.destination_address.address_line_1},{' '}
                  {orderDetails.destination_address.address_line_2},{' '}
                  {orderDetails.destination_address.town.name_en},{' '}
                  {orderDetails.destination_address.city.name_en}
                </p>
              </div>
              <div style={cardRowStyle}>
                <FaBoxOpen style={cardIconStyle} />
                <p>{'Delivery'}</p>
              </div>
              <div style={cardRowStyle}>
                <FaMotorcycle style={cardIconStyle} />
                <p>{'Last mile'}</p>{' '}
                <Link
                  to={`/logistics/shifts/${orderDetails.last_mile_shift_id}`}
                >
                  #{orderDetails.last_mile_shift_id}
                </Link>
                <p>
                  {'  ' +
                    moment
                      .utc(
                        orderDetails.last_mile_shift.start_datetime,
                        'YYYY-MM-DD HH:mm:ss',
                      )
                      .tz(ISRAEL_TIMEZONE)
                      .format('HH:mm')}{' '}
                  -{' '}
                  {moment
                    .utc(
                      orderDetails.last_mile_shift.end_datetime,
                      'YYYY-MM-DD HH:mm:ss',
                    )
                    .tz(ISRAEL_TIMEZONE)
                    .format('HH:mm DD/MM')}
                </p>
              </div>
              <Spacer y={2} />
              <OrderTimeline
                orderActions={orderDetails.order_actions}
                headerStyle={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </ScreenWrapper>
  );
}

export default OrderOverviewTab;
