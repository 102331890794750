import { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
} from '@nextui-org/react';
import { ChevronDown } from 'akar-icons';
import {
  ActionCategory,
  ActionCategoryColorMap,
  ManagementOrderActions,
  ManagementOrderActionsData,
  ManagementOrderActionsTypes,
  getDisabledKeys,
} from '../functions/orders_functions';

export function OrderManagementButtonGroupWithDropdownMenu({
  orderStatus,
  selectedOption,
  setSelectedOption,
  handleButtonClick,
}: {
  orderStatus: number;
  selectedOption: ManagementOrderActionsData;
  setSelectedOption: (option: ManagementOrderActionsData) => void;
  handleButtonClick: () => void;
}) {
  const [color, setColor] = useState<
    'default' | 'success' | 'danger' | 'warning' | 'primary' | 'secondary'
  >('success');

  useEffect(() => {
    if (selectedOption.actionCategory === ActionCategory.dangerZone) {
      setColor('danger');
    } else if (selectedOption.actionCategory === ActionCategory.return) {
      setColor('warning');
    } else if (selectedOption.actionCategory === ActionCategory.reschedule) {
      setColor('secondary');
    } else {
      setColor('success');
    }
  }, [selectedOption]);

  const allDropdownMenuInfo = Object.keys(ManagementOrderActions)
    .map(
      (key) =>
        ManagementOrderActions[
          key as unknown as keyof typeof ManagementOrderActions
        ],
    )
    .filter((action) => {
      if (
        action.action === ManagementOrderActionsTypes.add_management_note ||
        action.action === ManagementOrderActionsTypes.refundOrder
      ) {
        return true;
      }
      return action.allowedToBeCalledWhenOrder.has(orderStatus);
    });

  const disabledKeys = getDisabledKeys(orderStatus);
  const generalActions = allDropdownMenuInfo.filter(
    (action) => action.actionCategory === ActionCategory.general,
  );

  const dangerZoneDropdownMenuInfo = allDropdownMenuInfo.filter(
    (action) => action.actionCategory === ActionCategory.dangerZone,
  );

  const shippingDropdownMenuInfo = allDropdownMenuInfo.filter(
    (action) => action.actionCategory === ActionCategory.shipping,
  );

  const returnDropdownMenuInfo = allDropdownMenuInfo.filter(
    (action) => action.actionCategory === ActionCategory.return,
  );

  const rescheduleDropdownMenuInfo = allDropdownMenuInfo.filter(
    (action) => action.actionCategory === ActionCategory.reschedule,
  );

  return (
    <ButtonGroup
      variant="flat"
      style={{
        border: 'none',
        marginLeft: 'auto',
        float: 'right',
      }}
    >
      <Button
        onClick={() => {
          handleButtonClick();
        }}
        color={color}
      >
        {selectedOption.label}
      </Button>
      <Dropdown
        showArrow
        shouldBlockScroll={false}
        classNames={{
          base: 'before:bg-success-200', // change arrow background
          content:
            'py-1 px-1 border border-default-200 bg-gradient-to-br from-white to-default-200 dark:from-default-50 dark:to-black',
        }}
      >
        <DropdownTrigger>
          <Button isIconOnly variant="flat" color={color}>
            <ChevronDown />
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          variant="faded"
          aria-label="Dropdown menu with description"
          // Allowed keys are stored selectedOption.allowedToBeCalledWhenOrder, all values are stored in ORDER_STATUS.getAllValues()
          disabledKeys={disabledKeys}
          onAction={(key) => {
            setSelectedOption(
              ManagementOrderActions[
                key as unknown as keyof typeof ManagementOrderActions
              ],
            );
          }}
        >
          {ShowDropDownMenuSection(ActionCategory.general, generalActions)}
          {ShowDropDownMenuSection(
            ActionCategory.shipping,
            shippingDropdownMenuInfo,
          )}
          {ShowDropDownMenuSection(
            ActionCategory.reschedule,
            rescheduleDropdownMenuInfo,
          )}
          {ShowDropDownMenuSection(
            ActionCategory.return,
            returnDropdownMenuInfo,
          )}
          {ShowDropDownMenuSection(
            ActionCategory.dangerZone,
            dangerZoneDropdownMenuInfo,
          )}
        </DropdownMenu>
      </Dropdown>
    </ButtonGroup>
  );
}

function ShowDropDownMenuSection(
  title: string,
  dropdownMenuInfo: ManagementOrderActionsData[],
) {
  return (
    <DropdownSection
      title={dropdownMenuInfo.length > 0 ? title : ''}
      className={`${dropdownMenuInfo.length > 0 ? '' : 'collapse'}`}
    >
      {dropdownMenuInfo.map((option) => (
        <DropdownItem
          key={option.action}
          description={option.description}
          startContent={option.icon}
          color={ActionCategoryColorMap[option.actionCategory]} // Use color or default to prevent undefined
          className={ActionCategoryColorMap[option.actionCategory]}
        >
          {option.label}
        </DropdownItem>
      ))}
    </DropdownSection>
  );
}
