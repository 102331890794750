import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  input {
    font-family: ${Fonts.secondary}, sans-serif;
    width: 100%;
    border: none;
    background: var(--color-light);
    border: 1px solid rgba(204, 212, 214, 0.5);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 10px 15px 50px;
    transition: 0.3s ease;
    ::placeholder {
      color: #b3bec2;
    }
    :focus {
      outline: none;
      border: 1px solid var(--app-color-dark);
    }
    :hover {
      border: 1px solid var(--app-color-dark);
      box-shadow: -1px 0px 8px 3px rgba(90, 179, 168, 0.62);
    }
  }
  label {
    position: absolute;
    left: 25px;
    top: 55%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #b3bec2;
  }
`;

export default Wrapper;
