import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { Request, RequestStatus, RequestType } from 'data/types/requests';
import { AxiosInstance } from 'axios';
import { REQUESTS_MESSAGES } from 'data/constants/messages';
class RequestsApiRepository extends AuthenticatedApiRepository {
  private static instance: RequestsApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): RequestsApiRepository {
    if (!RequestsApiRepository.instance) {
      RequestsApiRepository.instance = new RequestsApiRepository(axiosInstance);
    }
    return RequestsApiRepository.instance;
  }

  getRequests = async ({
    page = 1,
    statuses = [
      RequestStatus.pending,
      RequestStatus.accepted,
      RequestStatus.rejected,
    ],
    per_page = 100000,
    request_type = [
      RequestType.seller_management_add_store,
      RequestType.seller_management_add_listing,
      RequestType.seller_management_edit_listing,
      RequestType.register_driver_as_carrier,
    ],
  }: {
    page?: number;
    statuses?: RequestStatus[];
    per_page?: number;
    request_type?: RequestType[];
  }) => {
    try {
      const query = `page=${page}&per_page=${per_page}&archived=&${statuses.map(
        (status) => `status=${status}`,
      )}&${request_type.map((type) => `request_type=${type}`)}`;
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/requests/?${query}`),
        {
          noSuccessMsg: true,
          customPendingMsg: 'Loading requests...',
        },
      );
      return res.data.result as Request[];
    } catch (e: any) {
      return false;
    }
  };

  getFullRequestDetails = async (req_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/requests/${req_id}/`),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
        },
      );
      return res.data.approval_request as Request;
    } catch (e: any) {
      return false;
    }
  };

  respondToRequest = async (
    request_id: number,
    accepted: boolean,
    msg: string,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/requests/${request_id}/respond/`, {
          baseApiUrl: API_BASE,
          data: {
            accepted,
            msg,
          },
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: accepted
            ? REQUESTS_MESSAGES.RespondToRequestSuccess
            : REQUESTS_MESSAGES.RespondToRequestFailure,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };
}

const RequestsRepository = RequestsApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default RequestsRepository;
