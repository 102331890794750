import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  actionCreateShiftInCity,
  actionDeleteShiftInCity,
  actionUnregisterCarrierFromShift,
  actionUpdateShiftInCity,
} from 'store/shifts/shifts_actions';
import {
  changeShiftTimeZone,
  ceilToNearestTenMinutes,
} from 'silal_app_base_react/src/utils/functions/time_date_functions';
import {
  ISRAEL_TIMEZONE,
  UTC,
} from 'silal_app_base_react/src/config/constants';
import { Dayjs } from 'dayjs';
import { Checkbox, TimePicker } from 'antd';
import dayjs from 'dayjs';
import {
  Registeredcarrier,
  Shift,
} from 'silal_app_base_react/src/data/types/shifts';
import { toast } from 'react-toastify';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Chip,
  Button,
  Input,
} from '@nextui-org/react';
import { FaCircle } from 'react-icons/fa6';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ChevronLeftSmall } from 'akar-icons';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { Link, useNavigate } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
type SingleShiftProps = {
  shiftData: Shift;
  date: string;
  closeModal: () => void;
  selectedShiftId: string;
  selectShift?: (shiftId: string) => void;
};

export function ShiftCard({
  shiftData,
  date,
  selectedShiftId,
  selectShift,
}: SingleShiftProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [shift, setShift] = useState(shiftData);

  const [start_time, setStartTime] = useState<Dayjs | undefined>(
    ceilToNearestTenMinutes(dayjs(shiftData.start_datetime)) as Dayjs,
  );
  const [end_time, setEndTime] = useState<Dayjs | undefined>(
    ceilToNearestTenMinutes(
      dayjs(shiftData.end_datetime).add(3, 'hour'),
    ) as Dayjs,
  );

  const [selected, setSelected] = useState(
    selectedShiftId === shift.id.toString(),
  );
  const [confirmActionPopupOpen, setConfirmActionPopupOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const [editing, setEditing] = useState(false);
  const isNewShift = shift.id <= 0;

  useEffect(() => {
    if (shift.id) {
      setEndTime(dayjs(shiftData.end_datetime));
      setStartTime(dayjs(shiftData.start_datetime));
    }
  }, [shiftData, shift.id]);

  const setMaxOrdersForShift = (desired_carriers: number) => {
    setShift({
      ...shift,
      desired_carriers: desired_carriers,
    });
  };

  useEffect(() => {
    if (start_time && end_time) {
      const d = moment(date);
      const starting_date = d
        .set('hour', parseInt(start_time.format('HH')))
        .set('minutes', parseInt(start_time.format('mm')))
        .format('YYYY-MM-DD HH:mm:ss');
      const ending_date = d
        .set('hour', parseInt(end_time.format('HH')))
        .set('minutes', parseInt(end_time.format('mm')))
        .format('YYYY-MM-DD HH:mm:ss');

      setShift({
        ...shift,
        start_datetime: starting_date,
        end_datetime: ending_date,
      });
    }
  }, [start_time, end_time]);

  useEffect(() => {
    setSelected(shift.id.toString() === selectedShiftId);
  }, [selectedShiftId, shift.id]);

  const save = () => {
    const editedShift = changeShiftTimeZone(shift, ISRAEL_TIMEZONE, UTC);
    if (shift.id) {
      const payload = {
        data: editedShift,
        onSuccess: () => {
          setEditing(false);
          // closeModal();
        },
      };
      dispatch(actionUpdateShiftInCity(payload));
    } else createShift(editedShift);
  };

  const deleteShift = (shiftId: number) => {
    const payload = {
      shiftId: shiftId,
    };
    if (shiftId) dispatch(actionDeleteShiftInCity(payload));
  };

  const createShift = (shift: Shift) => {
    if (
      shift.start_datetime === shift.end_datetime ||
      moment(shift.start_datetime) > moment(shift.end_datetime)
    ) {
      toast.warn("Shift's end time must be greater than start time");
      return;
    }
    if (shift.desired_carriers < 1) {
      toast.warn('Desired carriers must be at least 1');
      return;
    }
    const updatedShift = changeShiftTimeZone(shift, ISRAEL_TIMEZONE, UTC);
    const payload = {
      data: updatedShift,
      onSuccess: () => {
        setEditing(false);
        // closeModal();
      },
    };
    dispatch(actionCreateShiftInCity(payload));
  };

  const onChangeStartTime = (
    value: Dayjs | null,
    timeString: string | string[],
  ) => {
    if (typeof timeString === 'string' && value) setStartTime(value!);
    else setStartTime(undefined);
  };

  const onChangeEndTime = (
    value: Dayjs | null,
    timeString: string | string[],
  ) => {
    if (typeof timeString === 'string' && value) setEndTime(value!);
    else setEndTime(undefined);
  };

  return (
    <>
      <PopupWithConfirmation
        open={confirmActionPopupOpen}
        onButtonOneClick={() => setConfirmActionPopupOpen(false)}
        onButtonTwoClick={() => {
          if (shift.id > 0) {
            if (!editing) setEditing(!editing);
            if (editing) save();
            return;
          } else {
            createShift(shift!);
          }
        }}
        headerTitle={
          !isNewShift
            ? 'Are you sure you want to update shift?'
            : 'Are you sure you want to create new shift?'
        }
        bodyText={
          !isNewShift
            ? 'This will move all shipments to the newly set timeframe.'
            : 'Please double check the time input.'
        }
        buttonTwoText={!isNewShift ? 'Update Shift' : 'Create Shift'}
        withTimerOfNSeconds={null}
      />{' '}
      <PopupWithConfirmation
        open={confirmDelete}
        onButtonOneClick={() => setConfirmDelete(false)}
        onButtonTwoClick={() => {
          deleteShift(shift.id);
        }}
        headerTitle={'Are you sure you want to delete this shift?'}
        bodyText={
          "A shift with shipments won't be deleted. This action cannot be undone."
        }
        buttonTwoText={'Delete'}
        withTimerOfNSeconds={2}
      />
      <Card
        className="w-64 h-96 m-2"
        shadow="sm"
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: '0 0 auto',
        }}
      >
        <CardHeader className="flex gap-3">
          <div>
            <div className="flex flex-row justify-between mb-1">
              <div className="flex flex-row">
                <p
                  className="text-md"
                  onClick={() => {
                    if (!isNewShift) {
                      navigate(`/logistics/shifts/${shift.id}`);
                    }
                  }}
                >
                  {isNewShift ? (
                    <>{shift.id === 0 ? 'Add New Shift' : 'Suggested Shift'}</>
                  ) : (
                    <Link
                      to={`/logistics/shifts/${shift.id}`}
                      style={{ color: 'inherit' }}
                    >
                      Shift{' '}
                      <span
                        style={{
                          color: Colors.greenMain,
                        }}
                      >
                        #{shift?.id}
                      </span>
                    </Link>
                  )}
                </p>
                <FaCircle
                  className={`${!isNewShift ? (shift?.is_active ? 'text-emerald-500' : 'text-amber-500') : 'text-blue-200'} mt-1 ml-2`}
                />
              </div>
              {selectShift !== undefined && (
                <Checkbox
                  value={shift.id.toString()}
                  checked={selected}
                  onChange={(event) => {
                    if (event.target.checked) {
                      selectShift(shift.id.toString());
                    } else {
                      selectShift('');
                    }
                  }}
                />
              )}
            </div>
            {!isNewShift && (
              <>
                <Chip
                  variant="flat"
                  color="success"
                  onClick={() => navigate(`/logistics/shifts/${shift.id}`)}
                >
                  {shift?.shipments_count} shipments
                </Chip>{' '}
                <Chip variant="flat" color="success">
                  {shift?.registered_carriers.length}/{shift?.desired_carriers}{' '}
                  carriers
                </Chip>
              </>
            )}
          </div>
        </CardHeader>
        <Divider />
        <CardBody>
          <TimePicker
            onChange={onChangeStartTime}
            value={start_time}
            format="HH:mm"
            disabled={shift.id > 0 && !editing}
            minuteStep={10}
          />
          <TimePicker
            className="my-2"
            onChange={onChangeEndTime}
            value={end_time}
            format="HH:mm"
            disabled={shift.id > 0 && !editing}
            status={
              end_time && start_time && end_time <= start_time ? 'error' : ''
            }
            minuteStep={10}
          />
          <Input
            size="sm"
            className="text-tiny"
            type="number"
            variant="bordered"
            label="Captains Required"
            fullWidth
            defaultValue={shift.desired_carriers.toString()}
            isDisabled={shift.id > 0 && !editing}
            onChange={(e) => {
              setMaxOrdersForShift(parseInt(e.target.value));
            }}
          />
          <Checkbox
            className="my-1 mx-1"
            defaultChecked={
              shift.id <= 0 ? true : shift.is_recieving_new_orders
            }
            checked={shift.id <= 0 ? true : shift.is_recieving_new_orders}
            onChange={(e) => {
              setShift({
                ...shift,
                is_recieving_new_orders: e.target.checked,
              });
            }}
            disabled={shift.id > 0 && !editing}
          >
            Recieving New Orders
          </Checkbox>

          <RegisteredCarriersChips
            shift={shift}
            chipDisabled={shift.id > 0 && !editing}
            onDeleteCallback={() => setEditing(true)}
          />
        </CardBody>
        <Divider />
        <CardFooter className="flex">
          {editing && (
            <>
              <Button
                isIconOnly
                variant="ghost"
                color="danger"
                className="mr-2"
                onClick={() => setConfirmDelete(true)}
              >
                <RiDeleteBin6Line />
              </Button>{' '}
              <Button
                isIconOnly
                variant="ghost"
                color="warning"
                className={`mr-2`}
                onClick={() => setEditing(false)}
              >
                <ChevronLeftSmall />
              </Button>
            </>
          )}
          <Button
            className={`flex-1 ${editing ? '' : 'text-white'}`}
            isIconOnly
            variant={editing ? 'flat' : 'solid'}
            color={!isNewShift ? 'warning' : 'success'}
            onClick={() => {
              !isNewShift
                ? editing
                  ? setConfirmActionPopupOpen(true)
                  : setEditing(true)
                : setConfirmActionPopupOpen(true);
            }}
          >
            {!isNewShift
              ? editing
                ? 'Update Shift'
                : 'Edit Shift Info'
              : 'Create New Shift'}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}

export function RegisteredCarriersChips({
  shift,
  chipDisabled,
  onDeleteCallback,
}: {
  shift: Shift;
  chipDisabled: boolean;
  onDeleteCallback?: () => void;
}) {
  const dispatch = useDispatch();
  const deleteCourier = (shiftId: number, driver: Registeredcarrier) => {
    dispatch(
      actionUnregisterCarrierFromShift({
        shiftId: shiftId,
        carrierId: driver.id,
      }),
    );
    onDeleteCallback && onDeleteCallback();
  };
  return (
    <>
      <div className="flex flex-row">
        <p className="text-tiny mb-1">Registered Carriers </p>
      </div>
      {shift?.registered_carriers.length === 0 ? (
        <p className="text-tiny">No carriers registered</p>
      ) : (
        shift?.registered_carriers.map((carrier, index) => (
          <Chip
            key={index}
            isCloseable={false}
            isDisabled={chipDisabled}
            onClose={() => deleteCourier(shift.id, carrier)}
            variant="flat"
          >
            {carrier.delivery_user.first_name +
              ' ' +
              carrier.delivery_user.last_name}
          </Chip>
        ))
      )}
    </>
  );
}
