import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { AxiosInstance } from 'axios';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import {
  StoreTransaction,
  StorePayout,
  DriverTransaction,
  DriverPayout,
  StorePaymentReportTransaction,
  DriverPaymentReportTransaction,
} from 'silal_app_base_react/src/data/types/transactions';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import Papa from 'papaparse';

class TransactionsApiRepository extends AuthenticatedApiRepository {
  private static instance: TransactionsApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): TransactionsApiRepository {
    if (!TransactionsApiRepository.instance) {
      TransactionsApiRepository.instance = new TransactionsApiRepository(
        axiosInstance,
      );
    }
    return TransactionsApiRepository.instance;
  }

  // ? Ledgers
  getStoreLedger = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/transactions/ledgers/stores/${store_id}/json/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.json_ledger as StoreTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  getDriverLedger = async (driver_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/transactions/ledgers/drivers/${driver_id}/json/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.json_ledger as DriverTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  // ? Reports
  getStoresPaymentReportsJSON = async (store_ids: number[]) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/stores/payment_report/json/`, {
          baseApiUrl: API_BASE,
          data: {
            ids: store_ids,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.balances as StorePaymentReportTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  getDriversPaymentReportsJSON = async (driver_ids: number[]) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/drivers/payment_report/json/`, {
          baseApiUrl: API_BASE,
          data: {
            ids: driver_ids,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.report as DriverPaymentReportTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  getStoresPaymentReportsCSV = async (store_ids: number[]) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/stores/payment_report/csv/`, {
          baseApiUrl: API_BASE,
          data: {
            ids: store_ids,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      // Add 'paid_amount',   'transaction_id', 'transaction_date' Headers
      const parsedCSV = Papa.parse(res.data, { header: true });

      const newHeaders = ['paid_amount', 'transaction_id', 'transaction_date'];
      newHeaders.forEach((header) => {
        if (!parsedCSV.meta.fields.includes(header)) {
          parsedCSV.meta.fields.push(header);
          // Initialize the new header in each row
          parsedCSV.data.forEach((row: any) => {
            row[header] = '';
          });
        }
      });

      // Convert the updated data back to CSV format
      const updatedCSV = Papa.unparse(parsedCSV);

      // Update res.data with the new CSV data
      res.data = updatedCSV;

      // Create a Blob object from the CSV data
      const blob = new Blob([res.data], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute with the desired filename
      link.download = `stores_payment_report__${formateServerDateToLocaleDate(
        new Date().toISOString(),
      )}.csv`;

      // Create a URL for the Blob and set it as the href attribute of the link
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
      return true;
    } catch (e: any) {
      return false;
    }
  };

  getDriversPaymentReportsCSV = async (driver_ids: number[]) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/drivers/payment_report/csv/`, {
          baseApiUrl: API_BASE,
          data: {
            ids: driver_ids,
          },
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );

      // Parse the CSV data with headers
      const parsedData = Papa.parse(res.data, { header: true });

      // Headers to add
      const headersToAdd = [
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ];

      // Add headers and initialize their values
      headersToAdd.forEach((header) => {
        if (!parsedData.meta.fields.includes(header)) {
          parsedData.meta.fields.push(header);
          parsedData.data.forEach((row: any) => {
            row[header] = '';
          });
        }
      });

      // Convert data back to CSV format
      const updatedCSV = Papa.unparse(parsedData);

      // Update the response data with the new CSV
      res.data = updatedCSV;
      // Create a Blob object from the CSV data
      const blob = new Blob([res.data], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute with the desired filename
      link.download = `drivers_payment_report__${formateServerDateToLocaleDate(
        new Date().toISOString(),
      )}.csv`;

      // Create a URL for the Blob and set it as the href attribute of the link
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
      return true;
    } catch (e: any) {
      return false;
    }
  };

  // ? Payouts
  setStoresPayoutsJSON = async (store_payouts: StorePayout[]) => {
    //TODO printing
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/payout/stores/json/`, {
          baseApiUrl: API_BASE,
          data: {
            stores: store_payouts,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data.transactions as StoreTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  setDriversPayoutsJSON = async (drivers_payouts: DriverPayout[]) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/payout/drivers/json/`, {
          baseApiUrl: API_BASE,
          data: {
            drivers: drivers_payouts,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      return res.data.transactions as DriverTransaction[];
    } catch (e: any) {
      return false;
    }
  };

  uploadStorePaymentsCSV = async (file: FormData) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/payout/stores/csv/`, {
          baseApiUrl: API_BASE,
          data: file,
          mustEndWithSlash: false,
          additionalHeaders: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  uploadDriverPaymentsCSV = async (file: FormData) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/payout/drivers/csv/`, {
          baseApiUrl: API_BASE,
          data: file,
          additionalHeaders: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  // ? Deduction-at-source
  setStoreDAS = async (store_id: number, das_percentage: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/das/stores/json/`, {
          baseApiUrl: API_BASE,
          data: {
            stores: [
              {
                store_id,
                das_percentage,
              },
            ],
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  setDriverDAS = async (driver_id: number, das_percentage: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/das/drivers/json/`, {
          baseApiUrl: API_BASE,
          data: {
            drivers: [
              {
                driver_id,
                das_percentage,
              },
            ],
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  uploadStoreDasCSV = async (file: FormData) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/das/stores/csv/`, {
          baseApiUrl: API_BASE,
          data: file,
          mustEndWithSlash: false,
          additionalHeaders: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  uploadDriverDasCSV = async (file: FormData) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/das/drivers/csv/`, {
          baseApiUrl: API_BASE,
          data: file,
          additionalHeaders: {
            'Content-Type': 'multipart/form-data',
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      return res.data.message;
    } catch (e: any) {
      return false;
    }
  };

  // ? Cash Recievals
  recieveCashFromDriver = async (
    driver_id: string,
    amount: number,
    additional_context?: any,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/transactions/drivers_cash/${driver_id}/`, {
          baseApiUrl: API_BASE,
          data: {
            amount,
            additional_context,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      return res.status === 200;
    } catch (e: any) {
      return false;
    }
  };

  getOrderDocumentUrl = async (
    document_id: string,
    document_type_name: string,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/receipts/get_url/${document_id}/?document_type=${document_type_name}`,
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.request?.responseURL;
    } catch (e: any) {
      return false;
    }
  };

  getStoreOrdersReportCSV = async ({
    status1,
    status2,
    status3,
    customer_id,
    shift_id,
    store_id,
    cash_only,
    columns, // selected columns from the UI
  }: {
    status1?: string;
    status2?: string;
    status3?: string;
    customer_id?: string;
    shift_id?: string;
    store_id?: number;
    cash_only?: boolean;
    columns?: string[];
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/orders/csv?${status1 ? `status=${status1}&` : ''}${
            status2 ? `status=${status2}&` : ''
          }${status3 ? `status=${status3}&` : ''}${
            customer_id ? `customer_id=${customer_id}&` : ''
          }${shift_id ? `shift_id=${shift_id}&` : ''}${
            store_id ? `store_id=${store_id}&` : ''
          }${cash_only ? `cash_only=${cash_only}` : ''}`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      // Parse the CSV data
      const parsedCSV = Papa.parse(res.data, { header: true });
      const filteredData = parsedCSV.data.map((row: any) => {
        // Keep only selected columns
        const filteredRow: any = {};
        if (!columns) return row;
        columns?.forEach((column) => {
          if (row[column] !== undefined) {
            filteredRow[column] = row[column];
          }
        });
        return filteredRow;
      });

      // Convert filtered data back to CSV format
      const filteredCSV = Papa.unparse(filteredData);

      // Create a Blob object from the filtered CSV data
      const blob = new Blob([filteredCSV], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');
      link.download = `orders_report__${formateServerDateToLocaleDate(
        new Date().toISOString(),
      )}.csv`;

      // Create a URL for the Blob and set it as the href attribute of the link
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      return true;
    } catch (e: any) {
      return false;
    }
  };
}

const TransactionsRepository = TransactionsApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default TransactionsRepository;
