import { AxiosInstance } from 'axios';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { City, Town } from 'silal_app_base_react/src/data/types/address';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { LOCATIONS_MESSAGES } from '../constants/messages';
class LocationsApiRepository extends AuthenticatedApiRepository {
  private static instance: LocationsApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): LocationsApiRepository {
    if (!LocationsApiRepository.instance) {
      LocationsApiRepository.instance = new LocationsApiRepository(
        axiosInstance,
      );
    }
    return LocationsApiRepository.instance;
  }

  getCities = async (regionId: number | string) => {
    //TODO why i cant add  / ???
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/region/${regionId}/cities?page=1&per_page=2000`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
        },
      );
      return res.data.result as City[];
    } catch (e: any) {
      return false;
    }
  };
  getTowns = async (city_id: number | string) => {
    //TODO this doesnt work!
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/city/${city_id}/towns?page=1&per_page=50`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
        },
      );

      return res.data.result as Town[];
    } catch (e: any) {
      return false;
    }
  };

  addCity = async (payload: City) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/cities/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.CityAdded,
        },
      );
      return res.status === 201;
    } catch (e: any) {
      return false;
    }
  };
  addTown = async (payload: Town) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/towns/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.TownAdded,
        },
      );
      return res.status === 201;
    } catch (e: any) {
      return false;
    }
  };

  updateCity = async (payload: City) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/cities/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.CityUpdated,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };
  updateTown = async (payload: Town) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/towns/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.TownUpdated,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };

  deleteCity = async (payload: City) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/cities/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.CityDeleted,
        },
      );

      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };
  deleteTown = async (payload: Town) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/towns/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: LOCATIONS_MESSAGES.TownDeleted,
        },
      );
      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };
}

const LocationsRepository = LocationsApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default LocationsRepository;
