import React from 'react';
import { useState } from 'react';
import {
  MANAGEMENT_NOTES_RETURN_FLAG,
  SEPERATOR_BETWEEN_MESSAGES,
  SEPERATOR_BETWEEN_MESSAGE_FIELDS,
} from 'src/data/constants/messages';
import { Collapse, Drawer } from 'antd';
import { FaNoteSticky } from 'react-icons/fa6';
import { Button } from '@nextui-org/react';
interface ManagementNoteProps {
  message: string;
}

const ManagementNotes: React.FC<ManagementNoteProps> = ({ message }) => {
  const [showManagementNote, setShowManagementNote] = useState(false);

  const all_messages: Record<string, string> = {};
  const drawers: Record<string, boolean> = {};
  const users: Record<string, string> = {};
  message.split(SEPERATOR_BETWEEN_MESSAGES).forEach((i) => {
    const current: Array<string> = i.split(SEPERATOR_BETWEEN_MESSAGE_FIELDS);
    if (current.length === 3) {
      all_messages[current[0]] = current[2];
      drawers[current[0]] =
        Object.keys(all_messages).length <= 2 ? true : false;
      users[current[0]] = current[1];
    }
  });

  const isMarkedAsReturn = message.includes(MANAGEMENT_NOTES_RETURN_FLAG);

  return (
    <>
      <Button
        color="warning"
        variant="flat"
        onClick={() => setShowManagementNote(!showManagementNote)}
        className="mr-2"
        endContent={<FaNoteSticky />}
      >
        {showManagementNote ? 'Hide' : 'Show'} Notes{' '}
        {isMarkedAsReturn ? '!!! MARKED RETURN !!!' : ''}
      </Button>
      <Drawer
        title={
          <>
            Management Notes{' '}
            <p
              style={{
                color: 'red',
                fontSize: '12px',
                fontWeight: 'bold',
              }}
            >
              {isMarkedAsReturn ? '!!! RETURN !!!' : ''}
            </p>
          </>
        }
        extra={<></>}
        width={'40%'}
        closable={true}
        onClose={() => setShowManagementNote(false)}
        open={showManagementNote}
      >
        <Collapse
          defaultActiveKey={Object.keys(all_messages).map((_, i) =>
            i.toString(),
          )}
          items={Object.keys(all_messages).map((key, i) => {
            return {
              key: i.toString(),
              label: (
                <>
                  {key} By {users[key]}
                </>
              ),
              children: (
                <div>
                  {all_messages[key].replace(
                    MANAGEMENT_NOTES_RETURN_FLAG,
                    'Marked as Return',
                  )}
                </div>
              ),
            };
          })}
        />
      </Drawer>
    </>
  );
};

export default ManagementNotes;
