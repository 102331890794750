import { Listing } from 'silal_app_base_react/src/data/types/listings';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { CaptainUserInfo } from 'silal_app_base_react/src/data/types/users';

export enum RequestStatus {
  pending = 1,
  rejected = 2,
  accepted = 3,
}

export const RequestStatusMap: { [key: number]: RequestStatus } = {
  1: RequestStatus.pending,
  2: RequestStatus.rejected,
  3: RequestStatus.accepted,
};

export const RequestStatusNames: { [key: number]: string } = {
  1: 'Pending',
  2: 'Rejected',
  3: 'Accepted',
};

export enum RequestType {
  request = 1,
  seller_management = 2,
  buyer_management = 3,
  seller_management_add_store = 4,
  seller_management_add_listing = 5,
  seller_management_edit_listing = 6,
  register_driver_as_carrier = 7,
}

export const RequestTypeMap: { [key: number]: RequestType } = {
  4: RequestType.seller_management_add_store,
  5: RequestType.seller_management_add_listing,
  6: RequestType.seller_management_edit_listing,
  7: RequestType.register_driver_as_carrier,
};

export const RequestTypeNameMap: { [key: number]: string } = {
  4: 'businesses',
  5: 'new-listings',
  6: 'update-listings',
  7: 'drivers',
};

export interface Request {
  id: number;
  request_date: string;
  archived: boolean;
  status: number;
  status_name: string;
  request_type: number;
  request_type_name: string;
  response?: Response;
}

export interface BusinessStartingRequestBrief extends Request {
  store_id: number;
  store_name: string;
  seller_id: number;
  store: StoreDetails;
}

export interface BusinessAddListingRequestBrief extends Request {
  store_id: number;
  store_name_en: string;
  store_name_ar: string;
  seller_id: number;
  listing_id: number;
  listing_name_en: string;
  listing_name_ar: string;
}

export interface BusinessEditListingRequestBrief extends Request {
  store_id: number;
  store_name_en: string;
  store_name_ar: string;
  seller_id: number;
  parent_listing_id: number;
  new_listing_id: number;
  new_listing_name_en: string;
  new_listing_name_ar: string;
}

export interface DriverRegisterationRequestBrief extends Request {
  delivery_user_id: number;
  city_id: number;
  driver_phone: string;
  driver_name: string;
}

export interface BusinessStartingRequest extends Request {
  store_id: number;
  store_name: string;
  seller_id: number;
  store: StoreDetails;
}
export interface BusinessAddListingRequest extends Request {
  id: number;
  request_date: string;
  archived: boolean;
  status: number;
  status_name: string;
  request_type: number;
  request_type_name: string;
  response: Response;
  store_id: number;
  store_name_en: string;
  store_name_ar: string;
  seller_id: number;
  listing_id: number;
  listing: Listing;
}

export interface BusinessEditListingRequest extends Request {
  store_id: number;
  store_name: string;
  seller_id: number;
  parent_listing_id: number;
  new_listing_id: number;
  new_listing_name: string;
}
export interface DriverRegisterationRequest extends Request {
  id: number;
  request_date: string;
  archived: boolean;
  status: number;
  status_name: string;
  request_type: number;
  request_type_name: string;
  response?: Response;
  delivery_user_id: number;
  city_id: number;
  driver_phone: string;
  driver_name: string;
  delivery_user: CaptainUserInfo;
}

interface Response {
  request_id: number;
  manager_id: number;
  response_date: string;
  msg: string;
  read: boolean;
}
