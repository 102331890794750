export const SEPERATOR_BETWEEN_MESSAGES = '　　';
export const SEPERATOR_BETWEEN_MESSAGE_FIELDS = '　';
export const MANAGEMENT_NOTES_RETURN_FLAG = '@&@';
const CREATE_MESSAGE = 'Added Successfully!';
const UPDATE_MESSAGE = 'Data Updated Successfully!';
const DELETE_MESSAGE = 'Data Deleted Successfully!';
const SELECT_CITY_TOWN_MESSAGE = 'Please Select Town or City!';

export const LISTINGS_CHECKLIST = {
  checkbox1: 'All the required fields are filled in',
  checkbox2: 'The listing is not a duplicate',
  checkbox3: 'The listing is not a duplicate',
  checkbox4: 'The listing is not a duplicate',
  checkbox5: 'The listing is not a duplicate',
  checkbox6: 'The listing is not a duplicate',
  checkbox7: 'The listing is not a duplicate',
  checkbox8: 'The listing is not a duplicate',
  checkbox9: 'The listing is not a duplicate',
  checkbox10: 'The listing is not a duplicate',
};

export const DRIVERS_CHECKLIST = {
  checkbox1: 'All the required fields are filled in',
  checkbox2: 'The listing is not a duplicate',
  checkbox3: 'The listing is not a duplicate',
  checkbox4: 'The listing is not a duplicate',
  checkbox5: 'The listing is not a duplicate',
  checkbox6: 'The listing is not a duplicate',
  checkbox7: 'The listing is not a duplicate',
  checkbox8: 'The listing is not a duplicate',
  checkbox9: 'The listing is not a duplicate',
  checkbox10: 'The listing is not a duplicate',
};

export const BUSINESS_CHECKLIST = {
  checkbox1: 'All the required fields are filled in',
  checkbox2: 'The listing is not a duplicate',
  checkbox3: 'The listing is not a duplicate',
  checkbox4: 'The listing is not a duplicate',
  checkbox5: 'The listing is not a duplicate',
  checkbox6: 'The listing is not a duplicate',
  checkbox7: 'The listing is not a duplicate',
  checkbox8: 'The listing is not a duplicate',
  checkbox9: 'The listing is not a duplicate',
  checkbox10: 'The listing is not a duplicate',
};
export const REQUESTS_MESSAGES = {
  RespondToRequestSuccess: 'Request Approved Successfully!',
  RespondToRequestFailure: 'Request Rejected Successfully!',
};
export const CATEGORIES_MESSAGES = {
  CategoryHidden: 'Category Hidden Successfully!',
  CategoryUnhidden: 'Category Unhidden Successfully!',
  CategoryAdded: 'Category Added Successfully!',
  CategoryUpdated: 'Category Updated Successfully!',
  CategoryDeleted: 'Category Deleted Successfully!',
  AddedNewAttribute: 'Attribute Added Successfully!',
  AttributeDeleted: 'Attribute Deleted Successfully!',
  AttributeUpdated: 'Attribute Updated Successfully!',
  CategoryLinkDeleted: 'Category Link Deleted Successfully!',
  CategoryLinked: 'Category Link Added Successfully!',
  AttributesBulkEdited: 'Attributes Bulk Edited Successfully!',
  AttributeValuesAppended: 'Attributes Appended Successfully!',
  AttributeValuesReplaced: 'Attributes Replaced Successfully!',
};
export const SHIFTS_MESSAGES = {
  ShiftAdded: 'Shift Added Successfully!',
  ShiftUpdated: 'Shift Updated Successfully!',
  ShiftDeleted: 'Shift Deleted Successfully!',
  CarrierUnregistered: 'Carrier Unregistered Successfully!',
  CarrierRegisgtered: 'Carrier Registered Successfully!',
};
export const LOCATIONS_MESSAGES = {
  CityAdded: 'City Added Successfully!',
  CityUpdated: 'City Updated Successfully!',
  CityDeleted: 'City Deleted Successfully!',
  TownAdded: 'Town Added Successfully!',
  TownUpdated: 'Town Updated Successfully!',
  TownDeleted: 'Town Deleted Successfully!',
};
export const FILES_MESSAGES = {
  FileUploaded: 'File Uploaded Successfully!',
  FileDeleted: 'File Deleted Successfully!',
  AltUpdated: 'Alt Updated Successfully!',
};
export const LISTINGS_MESSAGES = {
  ListingFreezed: 'Listing Freezed Successfully!',
  ListingUnfreezed: 'Listing Unfreezed Successfully!',
};
export const TEMPLATES_MESSAGES = {
  TemplateCreated: 'Template Created Successfully!',
  TemplateUpdated: 'Template Updated Successfully!',
  TemplateDeleted: 'Template Deleted Successfully! Refresh to see effect.',
};
export const AUTHENTICATION_MESSAGES = {
  OTPVerified: 'OTP Verified Successfully!',
  OTPSent: 'OTP Sent Successfully!',
  LoginSuccess: 'Login Success!',
  MFAEnabled:
    'This account is protected via 2FA, You need to confirm your identity',
};

export {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  DELETE_MESSAGE,
  SELECT_CITY_TOWN_MESSAGE,
};
