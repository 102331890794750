import {
  RequestType,
  Request,
  BusinessStartingRequestBrief,
  BusinessAddListingRequestBrief,
  BusinessEditListingRequestBrief,
  DriverRegisterationRequestBrief,
  RequestStatus,
  RequestStatusNames,
} from 'data/types/requests';
import Wrapper from './card.styled';
import moment from 'moment';
import { Chip } from '@nextui-org/react';

interface CardProps {
  ind: number;
  ele: Request;
  request?: any;
  requestType: RequestType;
}

export default function Card({ ele, requestType }: CardProps) {
  function getCard(ele: Request) {
    switch (requestType) {
      case RequestType.seller_management_add_store:
        return BusinessCard(ele as BusinessStartingRequestBrief);
      case RequestType.seller_management_add_listing:
        return NewListingCard(ele as BusinessAddListingRequestBrief);
      case RequestType.seller_management_edit_listing:
        return UpdateListingCard(ele as BusinessEditListingRequestBrief);
      case RequestType.register_driver_as_carrier:
        return CaptainCard(ele as DriverRegisterationRequestBrief);
      default:
        return RequestIdOnlyCard();
    }
  }

  function BusinessCard(info: BusinessStartingRequestBrief) {
    return (
      <div className="card">
        <div
          className="heads"
          style={{
            padding: '10px 10px 10px 10px',
          }}
        >
          <h3 style={{ paddingBottom: '10px' }}>
            request ID <strong>#{info?.id}</strong>
          </h3>

          <span
            className="label restaurant"
            style={{ padding: '8px 4px 4px 4px' }}
          >
            {info?.store?.type || 'Other'}
          </span>
        </div>
        <div className="body" style={{ padding: '8px 4px 4px 4px' }}>
          <table>
            <tbody>
              <tr>
                <th>Store</th>
                <td
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {info.store?.name_en}
                </td>
              </tr>
              <tr>
                <th>Owner</th>
                <td>{info.store?.owner_name}</td>
              </tr>
              <tr>
                <th>Phone</th>
                <td>{info.store?.business_phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function NewListingCard(info: BusinessAddListingRequestBrief) {
    return (
      <div className="card-small">
        <div className="heads-small">
          <h3 style={{ paddingBottom: '10px' }}>
            request <strong>#{info?.id}</strong>
          </h3>
        </div>
        <div className="body-small">
          <table>
            <tbody>
              <tr>
                <th>Listing </th>
                <td>#{info?.listing_id}</td>
              </tr>
              <tr>
                <th>Item</th>
                <td>{info?.listing_name_ar}</td>
              </tr>
              <tr>
                <th>Store</th>
                <td>{info?.store_name_ar}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  function UpdateListingCard(info: BusinessEditListingRequestBrief) {
    return (
      <>
        <div className="card-small">
          <div className="heads-small">
            <h3 style={{ paddingBottom: '10px' }}>
              request <strong>#{info?.id}</strong>
            </h3>
          </div>
          <div className="body-small">
            <table>
              <tbody>
                <tr>
                  <th>New </th>
                  <td>#{info?.new_listing_id}</td>
                </tr>
                <tr>
                  <th>Parent </th>
                  <td>#{info?.parent_listing_id}</td>
                </tr>
                <tr>
                  <th>Item</th>
                  <td>{info?.new_listing_name_ar}</td>
                </tr>
                <tr>
                  <th>Store</th>
                  <td>{info?.store_name_ar}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function CaptainCard(info: DriverRegisterationRequestBrief) {
    return (
      <>
        <div className="card-small">
          <div className="heads-small">
            <h3 style={{ paddingBottom: '10px' }}>
              request ID <strong>#{info?.id}</strong>
            </h3>
          </div>
          <div className="body-small">
            <table>
              <tbody>
                <tr>
                  <th>Captain</th>
                  <td>{info.driver_name}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{info.driver_phone}</td>
                </tr>
                <tr>
                  <th>City ID</th>
                  <td>{info.city_id}</td>
                </tr>
                <tr>
                  <th>Date</th>
                  <td>{moment(ele.request_date).format('DD/MM/YYYY')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function RequestIdOnlyCard() {
    return (
      <div
        className="card-small"
        style={{ height: '50px', borderRadius: '5px' }}
      >
        <div className="heads-small">
          <h3>
            request ID <strong>#{ele?.id}</strong>
          </h3>
        </div>
      </div>
    );
  }

  const color = '#8E3BF6';
  return <Wrapper color={color}>{getCard(ele)}</Wrapper>;
}

export const RequestStatusTag = ({ status }: { status: RequestStatus }) => {
  return (
    <Chip
      color={
        status === RequestStatus.pending
          ? 'warning'
          : status === RequestStatus.rejected
            ? 'danger'
            : 'success'
      }
      variant="flat"
      className="ml-3 mt-1 px-2 py-1"
    >
      {RequestStatusNames[status]}
    </Chip>
  );
};
