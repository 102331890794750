import { useState } from 'react';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Wrapper from './login_screen.styled';
import { silal_cover_big_image_placeholder } from 'assets/index';
import Spinner from 'silal_app_base_react/src/components/spinner';
import AuthenticationRepository from 'data/repositories/authentication_repository';
import { toast } from 'react-toastify';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import FormControl2 from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { Button } from '@nextui-org/react';

interface Data {
  email: string;
  password: string;
}

const enum LoginStep {
  EmailPass,
  SendOTP,
}

function LoginScreen() {
  const navigate = useNavigate();

  const [loginStep, setLoginStep] = useState(LoginStep.EmailPass);

  const [sendingRequest, setSendingRequest] = useState<boolean>(false);
  const [showPass, setShowPass] = useState<boolean>(false);

  const [selectedVerificationMethod, setSelectedVerificationMethod] =
    useState('sms_otp');
  const [form1Data, setFrom1Data] = useState<Data>({
    email: '',
    password: '',
  });
  const [from2Data, setFrom2Data] = useState<{
    sms_otp: string;
    email_otp: string;
    temporar_token: string;
  }>({
    sms_otp: '+97254***1003',
    email_otp: 'ceo@silal.app',
    temporar_token: '',
  });

  const handleSubmitEmailPass = async () => {
    setSendingRequest(true);
    await AuthenticationRepository.loginEmailVerifyPassword(
      form1Data.email,
      form1Data.password,
    )
      .then((res) => {
        setSendingRequest(false);
        if (!res) {
          return;
        }
        setSendingRequest(false);
        setLoginStep(LoginStep.SendOTP);
        setFrom2Data(res as any);
      })
      .catch((err) => {
        setSendingRequest(false);
        toast.error(err);
      });
  };

  const sendLoginOtp = async () => {
    if (selectedVerificationMethod === 'sms_otp') {
      await AuthenticationRepository.loginSendMFAPhoneOTP(
        from2Data.temporar_token,
      )
        .then((res) => {
          setSendingRequest(false);
          if (!res) {
            return;
          }
          toast.success(res.msg);
          navigate('/phone-otp-verification', {
            state: {
              isPhone: true,
              info: from2Data.sms_otp,
              temporar_token: from2Data.temporar_token,
              previousPage: 'login',
              expiration_date: res.expiration_date,
            },
          });
        })
        .catch(() => {
          setSendingRequest(false);
        });
    } else {
      await AuthenticationRepository.loginSendMFAEmailOTP(
        from2Data.temporar_token,
      )
        .then((res) => {
          setSendingRequest(false);
          if (!res) return;
          toast.success(res.msg);
          navigate('/phone-otp-verification', {
            state: {
              isPhone: false,
              info: from2Data.email_otp,
              temporar_token: from2Data.temporar_token,
              previousPage: 'login',
              expiration_date: res.expiration_date,
            },
          });
        })
        .catch(() => {
          setSendingRequest(false);
        });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <img
        src={silal_cover_big_image_placeholder}
        style={{
          objectFit: 'cover',
          height: '100%',
          width: '50%',
        }}
      />
      <Wrapper>
        <div className="top-row">
          <div className="title">
            <h1>Silal Management Dashboard</h1>
            <h3>Log In</h3>
          </div>
          {loginStep === LoginStep.EmailPass ? (
            <>
              <Button color="success" variant="flat" className="w-full">
                Email
              </Button>
              <div className="form">
                <div className="form-control-email">
                  <FormControl
                    labelValue="Email"
                    htmlFor="email"
                    input={true}
                    value={form1Data.email}
                    type="text"
                    onChange={(
                      e: any, // TODO fix any
                    ) =>
                      setFrom1Data({
                        ...form1Data,
                        email: e.target.value,
                      })
                    }
                    placeholder="email.example@gmail.com"
                  />
                  <div className="password">
                    <FormControl
                      labelValue="Password"
                      htmlFor="password"
                      input={true}
                      value={form1Data.password}
                      type={showPass ? 'text' : 'password'}
                      placeholder="Enter password"
                      passwordType={true}
                      onChange={(
                        e: any, // TODO fix any
                      ) =>
                        setFrom1Data({
                          ...form1Data,
                          password: e.target.value,
                        })
                      }
                      onClick={() => setShowPass(!showPass)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <FormControl2>
              <FormLabel id="demo-radio-buttons-group-label">
                Verification Method
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="sms_otp"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="sms_otp"
                  control={<Radio color="success" />}
                  label={`SMS code to ${from2Data.sms_otp}`}
                  onChange={(e: any) =>
                    setSelectedVerificationMethod(e.target.value)
                  }
                />
                <FormControlLabel
                  value="email_otp"
                  control={<Radio color="success" />}
                  label={`Email code to ${from2Data.email_otp}`}
                  onChange={(e: any) =>
                    setSelectedVerificationMethod(e.target.value)
                  }
                />
              </RadioGroup>
            </FormControl2>
          )}
        </div>
        <div className="bottom-row">
          <div className="form-submit">
            {sendingRequest ? (
              <Spinner />
            ) : (
              <Button
                onClick={() =>
                  loginStep === LoginStep.SendOTP
                    ? sendLoginOtp()
                    : handleSubmitEmailPass()
                }
                color="success"
                className="w-full"
              >
                {loginStep === LoginStep.SendOTP ? 'Send Code' : 'Log in'}
              </Button>
            )}
          </div>
        </div>
      </Wrapper>
    </div>
  );
}

export default LoginScreen;
