import Wrapper from './back_button.styled';
import { useNavigate } from 'react-router-dom';
// import icons
import { Icon } from '@iconify/react';

type BackButtonProps = {
  primaryIcon: boolean;
};

function BackButton({ primaryIcon }: BackButtonProps) {
  const Navigate = useNavigate();
  function handleClick() {
    Navigate(-1);
  }

  return (
    <Wrapper onClick={handleClick}>
      {primaryIcon ? (
        <Icon icon="akar-icons:chevron-left" />
      ) : (
        <Icon icon="bi:arrow-left" />
      )}
    </Wrapper>
  );
}

export default BackButton;
