import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';

export const Wrapper = styled.div`
  p {
    font-family: ${Fonts.primary} !important;
    font-weight: 600 !important;
    font-size: 19px !important;
    color: var(--color-dark) !important;
  }
`;
