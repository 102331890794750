import React, { useEffect, useState } from 'react';
import {
  Button,
  Table,
  Input,
  Modal,
  Form,
  Select,
  Space,
  Popconfirm,
  notification,
} from 'antd';
import {
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import {
  Manager,
  PermissionTypes,
  PermissionTypesIndexToEnum,
} from 'silal_app_base_react/src/data/types/users';
import UsersRepository from 'data/repositories/users_repository';
import { Heading } from 'silal_app_base_react/src/styles/style';

type NewUserModalProps = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password: string;
  access_level: string;
};

export const WorkforceDatabase: React.FC = () => {
  const [users, setUsers] = useState<Manager[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<Manager[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newUserCreatedDataModal, setNewUserCreatedDataModal] = useState(false);
  const [userDetailsModal, setUserDetailsModal] = useState<NewUserModalProps>();
  const [editingUser, setEditingUser] = useState<Manager | null>(null);

  const [form] = Form.useForm();

  useEffect(() => {
    function fetchUsers() {
      UsersRepository.getAllManagers().then((data) => {
        setUsers(data);
        setFilteredUsers(data);
      });
    }
    fetchUsers();
  }, []);

  const onSearch = (value: string) => {
    const lowerValue = value.toLowerCase();
    const filtered = users.filter(
      (user) =>
        user.first_name.toLowerCase().includes(lowerValue) ||
        user.last_name.toLowerCase().includes(lowerValue) ||
        (user.phone && user.phone.includes(lowerValue)) ||
        (user.email && user.email.toLowerCase().includes(lowerValue)),
    );
    setFilteredUsers(filtered);
  };

  const onFilterChange = (value: number) => {
    const filtered = users.filter((user) => user.level_enum === value);
    setFilteredUsers(filtered);
  };

  const addUser = () => {
    setIsModalVisible(true);
    setEditingUser(null);
    form.resetFields();
  };

  const editUser = (user: Manager) => {
    setIsModalVisible(true);
    setEditingUser(user);
    form.setFieldsValue(user);
  };

  const deleteUser = async (id: number) => {
    await UsersRepository.deleteUserById(id).then((data) => {
      if (!data) return;
      const updatedUsers = users.filter((user) => user.id !== id);
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
    });
  };

  const invalidatePassword = async (id: number) => {
    const values = users.find((user) => user.id === id);
    if (!values) {
      return;
    }
    const newPassword = generateRandomPassword();
    await UsersRepository.editManagerAccount({
      id: id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email || 'test@silal.app',
      phone: values.phone || '+972-051-234-5678',
      access_level:
        PermissionTypesIndexToEnum[
          values.level_enum as keyof typeof PermissionTypesIndexToEnum
        ],
      password: newPassword,
    }).then((data) => {
      if (data) {
        const updatedUsers = users.map((user) =>
          user.id === id ? { ...user, password: newPassword } : user,
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        setIsModalVisible(false);
        showUserDetailsModal({
          id: id,
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email!,
          phone: values.phone!,
          password: newPassword,
          access_level:
            PermissionTypesIndexToEnum[
              values.level_enum as keyof typeof PermissionTypesIndexToEnum
            ],
        });
      }
    });
  };

  const generateRandomPassword = () => {
    var length = 16,
      charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
      retVal = '';
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  const handleOk = async (values: any) => {
    const newPassword = generateRandomPassword();
    if (editingUser) {
      await UsersRepository.editManagerAccount({
        id: editingUser.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        access_level:
          PermissionTypesIndexToEnum[
            values.level_enum as keyof typeof PermissionTypesIndexToEnum
          ],
        password: newPassword,
      }).then((data) => {
        if (!data) return;
        const updatedUsers = users.map((user) =>
          user.id === editingUser.id ? { ...values, id: editingUser.id } : user,
        );
        setUsers(updatedUsers);
        setFilteredUsers(updatedUsers);
        setIsModalVisible(false);
        showUserDetailsModal({
          ...values,
          password: newPassword,
        });
      });
    } else {
      var phone =
        '+972-' +
        values.phone.slice(0, 3) +
        '-' +
        values.phone.slice(3, 6) +
        '-' +
        values.phone.slice(6, 10);
      values.phone = phone;
      await UsersRepository.addManagerAccount({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        access_level:
          PermissionTypesIndexToEnum[
            values.level_enum as keyof typeof PermissionTypesIndexToEnum
          ],
        password: newPassword,
      }).then((data) => {
        if (!data) return;
        const updatedUser = {
          ...values,
          password: newPassword,
          id: Date.now(),
        };
        setUsers([...users, updatedUser]);
        setFilteredUsers([...users, updatedUser]);
        setIsModalVisible(false);
        showUserDetailsModal(updatedUser);
      });
    }
  };

  const showUserDetailsModal = (user: NewUserModalProps) => {
    setUserDetailsModal(user);
    setNewUserCreatedDataModal(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleUserDetailsModalClose = () => {
    setNewUserCreatedDataModal(false);
    setUserDetailsModal(undefined);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'Copied to clipboard',
    });
  };

  return (
    <div>
      <Heading>Workforce Management </Heading>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Input
          style={{
            flex: 7,
          }}
          placeholder="Search by name, phone, email"
          onChange={(e) => onSearch(e.target.value)}
        />
        <Select
          placeholder="Filter by Permission Level"
          onChange={onFilterChange}
          style={{
            flex: 1,
            marginLeft: 16,
          }}
        >
          {Object.entries(PermissionTypes)
            .filter(([key, _]) => isNaN(Number(key)))
            .map(([key, value]) => (
              <Select.Option key={value} value={value}>
                {key}
              </Select.Option>
            ))}
        </Select>
        <Button
          style={{
            flex: 1,
            marginLeft: 16,
          }}
          type="primary"
          icon={<UserAddOutlined />}
          onClick={addUser}
        >
          Add Manager
        </Button>
      </div>

      <Table
        dataSource={filteredUsers}
        rowKey="id"
        pagination={{ pageSize: 100 }}
      >
        <Table.Column title="ID" dataIndex="id" key="id" />
        <Table.Column
          title="First Name"
          dataIndex="first_name"
          key="first_name"
        />
        <Table.Column title="Last Name" dataIndex="last_name" key="last_name" />
        <Table.Column title="Email" dataIndex="email" key="email" />
        <Table.Column title="Phone" dataIndex="phone" key="phone" />
        <Table.Column title="Level" dataIndex="level_name" key="level_name" />
        <Table.Column
          title="Actions"
          key="actions"
          render={(_, record: Manager) => (
            <Space>
              <Button icon={<EditOutlined />} onClick={() => editUser(record)}>
                Edit
              </Button>
              <Button
                icon={<LockOutlined />}
                style={{
                  color: record.is_disabled ? 'blue' : '',
                }}
                onClick={async () =>
                  record.is_disabled
                    ? await UsersRepository.enableManagerAccount(record.id)
                    : await UsersRepository.disableManagerAccount(record.id)
                }
              >
                {record.is_disabled ? 'Enable' : 'Disable'} Account
              </Button>
              <Button
                icon={<LockOutlined />}
                onClick={() => invalidatePassword(record.id)}
              >
                Invalidate Password
              </Button>
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => deleteUser(record.id)}
              >
                <Button icon={<DeleteOutlined />} danger>
                  Delete
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>

      <Modal
        title={editingUser ? 'Edit Manager' : 'Add Manager'}
        open={isModalVisible}
        onCancel={handleCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              handleOk(values as any);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[{ required: true, message: 'Please input first name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: 'Please input last name!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone (format: 0512345678)">
            <Input />
          </Form.Item>
          <Form.Item
            name="level_enum"
            label="Permission Level"
            rules={[
              { required: true, message: 'Please select permission level!' },
            ]}
          >
            <Select>
              {Object.entries(PermissionTypes)
                .filter(([key, _]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <Select.Option key={value} value={value}>
                    {key}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      {userDetailsModal && (
        <Modal
          title="User Credentials"
          open={newUserCreatedDataModal}
          onCancel={handleUserDetailsModalClose}
          footer={[
            <Button
              key="copy"
              icon={<CopyOutlined />}
              onClick={() =>
                copyToClipboard(
                  `Hello ${userDetailsModal.first_name},

Your agent has been successfully added to Silal. Please use the following credentials to log into your account:

Email: ${userDetailsModal.email}
Password: ${userDetailsModal.password}

Two-Factor Authentication:
1. Phone: ${userDetailsModal.phone}
2. Email: ${userDetailsModal.email}

Thanks for your collaboration, and best of luck!
Silal Team`,
                )
              }
            >
              Copy to Clipboard
            </Button>,
            <Button
              key="ok"
              type="primary"
              onClick={handleUserDetailsModalClose}
            >
              OK
            </Button>,
          ]}
        >
          <p>Hello {userDetailsModal.first_name},</p>
          <p>
            Your agent has been successfully added to Silal. Please use the
            following credentials to log into your account:
          </p>
          <p>
            <b>Email:</b> {userDetailsModal.email}
            <br />
            <b>Password:</b> {userDetailsModal.password}
          </p>
          <p>Two-Factor Authentication:</p>
          <p>
            <b>1. Phone:</b> {userDetailsModal.phone}
            <br />
            <b>2. Email:</b> {userDetailsModal.email}
          </p>
          <p>Thanks for your collaboration, and best of luck!</p>
          <p>Silal Team</p>
        </Modal>
      )}
    </div>
  );
};
