import { motion } from 'framer-motion';
import moment from 'moment';
import Cdactivatemodal from 'components/popup_with_response';
import WrapperView from './rejection_box.styled';
import React, { useEffect } from 'react';
import UsersRepository from 'data/repositories/users_repository';

interface RejectionBoxProps {
  cardData: any;
}

const RejectionBox: React.FC<RejectionBoxProps> = ({ cardData }) => {
  const [userName, setUserName] = React.useState('');
  useEffect(() => {
    const fetchUserName = async () => {
      await UsersRepository.getUserDetails(cardData?.response?.manager_id).then(
        (data) => {
          if (!data) return;
          setUserName(data?.first_name + ' ' + data?.last_name);
        },
      );
    };
    if (!cardData?.response?.manager_id) return;
    fetchUserName();
  }, []);
  return (
    <WrapperView>
      <motion.div
        className="grid-row"
        style={{
          marginLeft: '30px',
          marginRight: '20px',
          marginBottom: '20px',
          marginTop: '10px',
          maxWidth: '75%',
        }}
      >
        <div className="profile-container">
          <div className="time-details">
            <h5>Rejection information</h5>
          </div>
          <div className="profile-container2">
            <div>
              <p>Rejection notes:</p>
              <p>Rejected by: {userName}</p>
              <p>
                Rejected on{' '}
                {moment(cardData?.response?.response_date).format('YYYY-MM-D')}
              </p>
            </div>

            <div // <Icon
              style={{
                borderRadius: '5px',
                padding: '10px 15px',
                background: 'rgb(242, 244, 245)',
              }}
            >
              {cardData?.response?.msg
                .split('\n')
                .map((item: string, index: number) => (
                  <div key={index}>{item}</div>
                ))}
            </div>
          </div>
        </div>
      </motion.div>
    </WrapperView>
  );
};

export default RejectionBox;
