import { ImageType } from 'silal_app_base_react/src/data/types/files';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import {
  ServerException,
  ServerResponse,
} from 'silal_app_base_react/src/config/errors/server_exceptions';
import { AxiosInstance } from 'axios';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { FILES_MESSAGES } from 'data/constants/messages';
export type ManagementFilesResponse = {
  files: ImageType[];
  total: number;
  page: number;
  pages: number;
  per_page: number;
  http_status: number;
};
class ManagementFilesApiRepository extends AuthenticatedApiRepository {
  private static instance: ManagementFilesApiRepository | null = null;
  private constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): ManagementFilesApiRepository {
    if (!ManagementFilesApiRepository.instance) {
      ManagementFilesApiRepository.instance = new ManagementFilesApiRepository(
        axiosInstance,
      );
    }
    return ManagementFilesApiRepository.instance;
  }

  getImages = async (page: number, limit: number) => {
    //TODO / ??
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/files/library/paginate/?page=${page}&per_page=${limit}`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (e: any) {
      return false;
    }
  };

  removeStoreImages = async ({
    images,
    store_id,
  }: {
    images: ImageType[];
    store_id: number;
  }) => {
    const requests = images?.map(async (image) =>
      this.removeSingleImage({
        image: image,
        store_id: store_id,
      }),
    );
    return Promise.all(requests);
  };

  addAltsToStoreImages = async ({
    images,
  }: {
    images: ImageType[];
    store_id: number;
  }) => {
    //TODO try catch
    const requests = images?.map((image) => {
      return this.updateImage({ image: image });
    });
    return await Promise.all(requests);
  };

  private updateImage = async ({ image }: { image: ImageType }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/files/${image.id}/finished_uploading/`, {
          baseApiUrl: API_BASE,
          data: {
            alt: image.alt,
          },
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: FILES_MESSAGES.AltUpdated,
        },
      );

      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };

  //TODO ask saif.
  private removeSingleImage = async ({
    image,
  }: {
    image: ImageType;
    store_id: number;
  }) => {
    try {
      const res = await this.sendRequest('DELETE', `/files/${image.id}/`);
      if (res.status !== 204) {
        throw new ServerException(res.data.response.data as ServerResponse);
      }
      return { deleted: true, id: image.id };
    } catch (e: any) {
      const baseError = e?.response?.data?.error || '';
      let fullError = baseError;
      if (e?.response?.data?.listings_associated) {
        fullError += ': ';
        fullError += e.response.data.listings_associated.join(', ');
      }

      if (e?.response?.data?.categories_using_image) {
        fullError += ': ';
        fullError += e.response.data.categories_using_image.map(
          (e: any) => e.id,
        );
      }
      return {
        deleted: false,
        id: image.id,
        error: fullError === '' ? 'Something went wrong' : fullError,
      };
    }
  };
}

const ManagementFilesRepository = ManagementFilesApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export { ManagementFilesRepository };
