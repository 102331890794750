import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';

type WrapperProps = {
  bold?: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  p {
    font-family: ${Fonts.secondary} !important;
    font-weight: 400;
    font-size: 15px !important;
    color: var(--color-text-gray) !important;
    font-weight: ${(props: WrapperProps) => (props.bold ? 'bold' : '')};
  }
`;
