import { state as init } from '../global_state';
import * as TemplatesActions from './templates_actions';

const reducer = (state = init, action: any) => {
  switch (action.type) {
    case TemplatesActions.GET_TEMPLATES: {
      return { ...state, loading: true, error: '' };
    }
    case TemplatesActions.CREATE_TEMPLATES: {
      return { ...state, loading: true, error: '' };
    }
    case TemplatesActions.TEMPLATES_FAILURE: {
      return { ...state, loading: false, error: action.payload.error };
    }
    case TemplatesActions.TEMPLATES_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        ...payload,
      };
    }
    case TemplatesActions.GET_CATEGORIES_FAILURE: {
      return { ...state, loading: false, error: action.payload };
    }
    case TemplatesActions.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: '',
        categories: action.payload?.categories,
      };
    }

    case TemplatesActions.SET_LAST_FETCHED_TEMPLATES:
      return {
        ...state,
        lastFetched: action.timestamp,
      };

    case TemplatesActions.LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
