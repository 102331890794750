import { AxiosInstance } from 'axios';
import {
  AbstractUserInfo,
  UserTypes,
  CaptainUserInfo,
  Manager,
  MerchantUserInfo,
  CustomerUserInfo,
} from 'silal_app_base_react/src/data/types/users';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { toast } from 'react-toastify';

class UsersApiRepository extends AuthenticatedApiRepository {
  private static instance: UsersApiRepository | null = null;
  private constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: AxiosInstance): UsersApiRepository {
    if (!UsersApiRepository.instance) {
      UsersApiRepository.instance = new UsersApiRepository(axiosInstance);
    }
    return UsersApiRepository.instance;
  }

  getUsersOfType = async ({
    userType,
    per_page = 10000,
  }: {
    userType: UserTypes;
    per_page?: number | undefined;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/users/?user_type=${userType.toString()}&per_page=${per_page}`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data ? (res.data.result as AbstractUserInfo[]) : [];
    } catch (error: any) {
      return false;
    }
  };

  getMyProfileDetails = async () => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/profile/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          noPendingMsg: true,
        },
      );

      return res.data.data;
    } catch (error: any) {
      return false;
    }
  };

  getUserDetails = async (user_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/users/${user_id}/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          noPendingMsg: true,
        },
      );

      return res.data.result as AbstractUserInfo;
    } catch (error: any) {
      return false;
    }
  };

  getDriverDetails = async (user_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/users/delivery/${user_id}/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          noPendingMsg: true,
        },
      );

      return res.data.result as CaptainUserInfo;
    } catch (error: any) {
      return false;
    }
  };

  getAllUsers = async ({
    per_page = 10000,
  }: {
    per_page?: number | undefined;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/users${per_page ? `/?per_page=${per_page}` : ''}`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
          },
        ),
        {
          noSuccessMsg: true,
        },
      );

      return res.data ? (res.data.result as AbstractUserInfo[]) : [];
    } catch (error: any) {
      return false;
    }
  };

  getAllCustomers = async () => {
    try {
      return (await this.getUsersOfType({
        userType: UserTypes.CUSTOMER,
      })) as CustomerUserInfo[];
    } catch (error: any) {
      return false;
    }
  };

  getAllSellers = async () => {
    try {
      return (await this.getUsersOfType({
        userType: UserTypes.SELLER,
      })) as MerchantUserInfo[];
    } catch (error: any) {
      return false;
    }
  };

  getAllCaptains = async () => {
    try {
      return (await this.getUsersOfType({
        userType: UserTypes.CAPTAIN,
      })) as CaptainUserInfo[];
    } catch (error: any) {
      return false; // Add this line to return undefined in case of an error
    }
  };

  getAllManagers = async () => {
    try {
      return (await this.getUsersOfType({
        userType: UserTypes.MANAGER,
      })) as Manager[];
    } catch (error: any) {
      return []; // Add this line to return undefined in case of an error
    }
  };

  getAllCarriers = async () => {
    try {
      const drivers = await this.getAllCaptains();
      if (!drivers) {
        return false;
      }
      let captains: (CaptainUserInfo | false)[] = [];
      for (let i = 0; i < drivers.length; i++) {
        if (!drivers[i]) {
          continue;
        }
        captains.push(await this.getDriverDetails(drivers[i].id));
      }
      return captains as CaptainUserInfo[];
    } catch (error: any) {
      return false; // Add this line to return undefined in case of an error
    }
  };

  addManagerAccount = async ({
    first_name,
    last_name,
    email,
    phone,
    access_level,
    password,
  }: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    access_level: string;
    password: string;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/users/managers/add_account/`, {
          baseApiUrl: API_BASE,
          data: {
            first_name,
            last_name,
            email,
            phone,
            access_level,
            password,
          },
          mustEndWithSlash: true,
        }),
        {
          customSuccessMsg: 'Manager account added successfully',
        },
      );

      return true;
    } catch (error: any) {
      return false;
    }
  };

  editManagerAccount = async ({
    id,
    first_name,
    last_name,
    email,
    phone,
    access_level,
    password,
  }: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    access_level: string;
    password: string;
  }) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('PUT', `/users/managers/edit_account/${id}/`, {
          baseApiUrl: API_BASE,
          data: {
            first_name,
            last_name,
            email,
            phone,
            access_level,
            password,
          },
          mustEndWithSlash: false,
        }),
        {
          customSuccessMsg: 'Manager account edited successfully',
        },
      );

      return true;
    } catch (error: any) {
      return false;
    }
  };

  deleteUserById = async (id: number) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('DELETE', `/users/${id}/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          customSuccessMsg: 'Manager account deleted successfully',
        },
      );

      return true;
    } catch (error: any) {
      return false;
    }
  };

  disableManagerAccount = async (id: number) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('PUT', `/users/${id}/disable/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          customSuccessMsg: 'Manager account disabled successfully',
        },
      );

      return true;
    } catch (error: any) {
      return false;
    }
  };

  enableManagerAccount = async (id: number) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('PUT', `/users/${id}/enable/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: false,
        }),
        {
          customSuccessMsg: 'Manager account enabled successfully',
        },
      );

      return true;
    } catch (error: any) {
      return false;
    }
  };
}

const UsersRepository = UsersApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default UsersRepository;
