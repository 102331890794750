import styled from 'styled-components';

const Wrapper = styled.button`
  border: none;
  background: transparent;
  font-size: 30px;
  margin-top: 5px;
  cursor: pointer;
`;

export default Wrapper;
