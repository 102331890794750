import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import CategoriesRepository from 'data/repositories/categories_repository';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import {
  GET_CATEGORIES,
  GET_SINGLE_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  DELETE_CATEGORY,
  HIDE_CATEGORY,
  UNHIDE_CATEGORY,
  ADD_NEW_ATTRIBUTE,
  EDIT_ATTRIBUTE,
  REMOVE_ATTRIBUTE,
  actionGetCategories,
  actionGetCategoriesSuccess,
  actionSetLastFetchedCategories,
} from './categories_actions';
import { actionSetLastFetchedCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { shouldRefreshCache } from 'core/utils/caching_functions';

export function* sagaGetCategories() {
  function* getCategories() {
    var payload = {
      categories: [],
    };
    // Get the current state of categories from the Redux store
    // const state = yield select((state) => state.Categories);
    // const { categories, lastFetchedCategories } = state;
    // if (
    //   categories?.length > 0 &&
    //   lastFetchedCategories &&
    //   !shouldRefreshCache(GET_CATEGORIES, lastFetchedCategories)
    // ) {
    //   // If the cache is valid, return the cached categories
    //   yield put(
    //     actionSetLastFetchedCategories({
    //       categories: categories,
    //     }),
    //   );
    //   return;
    // }
    const res = yield call(SharedRepository.getCategoryBrief, 0, true);
    if (!res) return false;
    payload.categories = res.sub_categories;
    yield put(actionGetCategoriesSuccess(payload));
  }

  yield takeLatest(GET_CATEGORIES, getCategories);
}

export function* sagaGetSingleCategory() {
  function* getSingleCategory(action) {
    const res = yield call(
      SharedRepository.getCategoryDetails,
      action.payload.data?.category_id,
    );
    if (!res) return false;
    action.payload.success(res);
  }
  yield takeLatest(GET_SINGLE_CATEGORY, getSingleCategory);
}

export function* sagaAddCategory() {
  function* addCategory(action) {
    const res = yield call(
      CategoriesRepository.addCategory,
      action.payload.data,
    );
    if (!res) return false;
    yield put(actionGetCategories());
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
    action.payload.success();
  }
  yield takeLatest(ADD_CATEGORY, addCategory);
}

export function* sagaEditCategory() {
  function* editCategory(action) {
    const payload = action.payload.data;
    const res = yield call(CategoriesRepository.editCategory, payload);
    if (!res) return false;
    action.payload.success();
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
    yield put(actionGetCategories());
  }
  yield takeEvery(EDIT_CATEGORY, editCategory);
}

export function* sagaDeleteCategory() {
  function* deleteCategory(action) {
    const res = yield call(
      CategoriesRepository.deleteCategory,
      action.payload.data,
    );
    if (!res) return false;
    action.payload.success();
    yield put(actionGetCategories());
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(DELETE_CATEGORY, deleteCategory);
}

export function* sagaHideCategory() {
  function* actionHideCategory(action) {
    const res = yield call(
      CategoriesRepository.hideCategory,
      action.payload.data,
    );
    if (!res) return false;
    yield put(actionGetCategories());
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(HIDE_CATEGORY, actionHideCategory);
}

export function* sagaUnhideCategory() {
  function* unhideCategory(action) {
    const res = yield call(
      CategoriesRepository.unhideCategory,
      action.payload.data,
    );
    if (!res) return false;
    yield put(actionGetCategories());
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(UNHIDE_CATEGORY, unhideCategory);
}

export function* sagaAddNewAttribute() {
  function* addNewAttribute(action) {
    const { data } = action.payload;
    let res;
    if (data.type === 3) {
      res = yield call(CategoriesRepository.addNewAttributeBool, data);
    } else if (data.type === 1) {
      res = yield call(CategoriesRepository.addNewAttributeNumeric, data);
    } else if (data.type === 2) {
      res = yield call(CategoriesRepository.addNewAttributeSelect, data);
    }
    if (!res) return false;
    action.payload.success(res);
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(ADD_NEW_ATTRIBUTE, addNewAttribute);
}

export function* sagaEditAttribute() {
  function* editAttribute(action) {
    const { data } = action.payload;
    const res = yield call(CategoriesRepository.editAttribute, data);
    if (!res) return;
    if (data.type === AttributeType.SELECT) {
      const res = yield call(
        CategoriesRepository.replaceSelectAttributeValues,
        {
          id: data.id,
          new_values_en: data.values_en,
          new_values_ar: data.values_ar,
        },
      );
      if (!res) return false;
    }
    action.payload.success(res);
    yield put(actionGetCategories());
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(EDIT_ATTRIBUTE, editAttribute);
}

export function* sagaRemoveAttribute() {
  function* removeAttribute(action) {
    const { data } = action.payload;
    const res = yield call(CategoriesRepository.removeAttribute, data);
    if (!res) return false;
    action.payload.success();
    yield put(actionSetLastFetchedCategoriesMinimalList(0));
  }
  yield takeLatest(REMOVE_ATTRIBUTE, removeAttribute);
}
