import { useState } from 'react';
import Wrapper from './reset_password_screen.styled';
import Linktag from 'silal_app_base_react/src/components/linktag';
import FormControl from 'silal_app_base_react/src/components/form_control';
import BackButton from 'components/back_button';

function Resetpass() {
  const [formValue, setFormValue] = useState('');
  return (
    <Wrapper>
      <div className="go-back-button">
        <BackButton primaryIcon={true} />
      </div>
      <div className="top-row">
        <div className="title">Reset password</div>
        <div>
          <h2>Enter your email</h2>
          <p>We will email your a code to reset password</p>
        </div>
        <div className="form">
          <FormControl
            htmlFor="email"
            labelValue="Email"
            input={true}
            type="email"
            placeholder="email.example@gmail.com"
            value={formValue}
            onChange={(e: any) => setFormValue(e.target.value)}
          />
        </div>
      </div>
      <div className="bottom-row">
        <div className="form-submit">
          <Linktag text="Send Code" blockitem={true} to="/emailverifycode" />
        </div>
      </div>
    </Wrapper>
  );
}

export default Resetpass;
