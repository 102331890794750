import { Button, useDisclosure } from '@nextui-org/react';
import RequestsRepository from 'data/repositories/requests_repository';
import { useDispatch } from 'react-redux';
import { PopupWithDropdownMenuAndTextArea } from 'silal_app_base_react/src/components/popups';

export const RejectionReasons = [
  'Low quality images',
  'Images not padded',
  'Main image has a background',
  'Name does not describe the product sufficiently',
  'Missing or incomplete description',
  'All/some Images do not belong to this product',
  'Images contain water marks',
  'Main image is not square',
  'Incorrect specifications',
  'Incorrect Category',
  'Duplicate product in the store',
  'Contains spelling issues',
  'Needs more images',
  'Variants are not consistent',
  'Missing variantions',
  'Incorrect/Missing translations',
  'Description needs formatting/rewrite',
];

export const RejectApplicationModal = ({
  requestID,
  onRejectCallback,
}: {
  requestID: number;
  onRejectCallback?: () => void;
}) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };
  return (
    <>
      <div className="flex flex-wrap gap-3">
        <Button color="danger" variant="bordered" onPress={() => handleOpen()}>
          Reject Application
        </Button>
      </div>
      <PopupWithDropdownMenuAndTextArea
        open={isOpen}
        headerTitle="Reject Application"
        dropdownMenuButtonText="Reason(s) for rejection"
        buttonTwoText="Reject"
        buttonTwoColor="danger"
        multipleInputs={true}
        onButtonOneClick={() => {
          onClose();
          return;
        }}
        onButtonTwoClick={async (rejectionMsg: string) => {
          if (!rejectionMsg) return;
          await RequestsRepository.respondToRequest(
            requestID,
            false,
            rejectionMsg,
          ).then((res) => {
            if (!res) return;
            onClose();
            onRejectCallback?.();
          });
        }}
        items={RejectionReasons.map((reason, i) => ({
          key: i.toString(),
          label: reason,
        }))}
      />
    </>
  );
};
