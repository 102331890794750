import * as LocationsActions from './locations_actions';

const init = {
  loading: false,
  error: '',
  towns: [],
  cities: [],
  city: '',
  region: '',
  success: '',
};

const reducer = (state = init, action: any) => {
  switch (action.type) {
    case LocationsActions.SET_CITY: {
      return { ...state, city: action.payload };
    }

    case LocationsActions.SET_REGION: {
      return { ...state, region: action.payload };
    }

    case LocationsActions.GET_CITIES: {
      return { ...state, loading: true };
    }

    case LocationsActions.GET_TOWNS: {
      return { ...state, loading: true };
    }

    case LocationsActions.SET_CITIES: {
      return { ...state, cities: action.payload, loading: false };
    }

    case LocationsActions.SET_TOWNS: {
      return { ...state, towns: action.payload, loading: false };
    }

    case LocationsActions.LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
