import styled from 'styled-components';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';

const Wrapper = styled.div`
  position: relative;
  .logo {
    padding: 20px 0;
    h3 {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.5px;
      padding-left: 30px;
      text-align: left;
      color: var(--app-color-dark);
    }
    .p-left {
      padding-left: 0;
      text-align: center;
    }
  }

  .logout {
    position: absolute;
    bottom: 50px;
    width: 100%;
  }

  .logout-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-dark);
    padding: 13px 30px;
    font-size: 13px;
    font-weight: 500;
    font-family: ${Fonts.primary}, sans-serif;
    letter-spacing: 0.5px;
  }

  .logout-link:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  .logout-link img {
    margin-right: 5px;
  }
`;

export default Wrapper;
