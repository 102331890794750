import { Shift } from 'silal_app_base_react/src/data/types/shifts';

export const GET_SHIFTS_IN_CITY = 'GET_SHIFTS_IN_CITY';
export const SET_SHIFTS_IN_CITY = 'SET_SHIFTS_IN_CITY';
export const CREATE_SHIFTS_IN_CITY = 'CREATE_SHIFTS_IN_CITY';
export const UPDATE_SHIFTS_IN_CITY = 'UPDATE_SHIFTS_IN_CITY';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const UNREGISTER_SHIFT = 'UNREGISTER_SHIFT';

export const LOGOUT = 'LOGOUT';

export const actionGetShiftsInCity = () => ({
  type: GET_SHIFTS_IN_CITY,
});

export const actionSetShiftsInCity = (payload: Shift[]) => ({
  type: SET_SHIFTS_IN_CITY,
  payload,
});

export const actionCreateShiftInCity = (payload: { data: Shift }) => ({
  type: CREATE_SHIFTS_IN_CITY,
  payload,
});

export const actionUpdateShiftInCity = (payload: { data: Shift }) => ({
  type: UPDATE_SHIFTS_IN_CITY,
  payload,
});

export const actionDeleteShiftInCity = (payload: { shiftId: number }) => ({
  type: DELETE_SHIFT,
  payload,
});

export const actionUnregisterCarrierFromShift = (payload: any) => ({
  type: UNREGISTER_SHIFT,
  payload,
});

export const actionLogoutShifts = () => ({
  type: LOGOUT,
});
