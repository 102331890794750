import {
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Modal,
} from '@nextui-org/react';
import { useState } from 'react';
import DefaultStartingView from '../components/request_base_view';
import { FaLeftLong, FaRightLong } from 'react-icons/fa6';
import { RejectApplicationModal } from './reject_application_modal';
import { AcceptApplicationModal } from './accept_application_modal';
import { BsFullscreen, BsFullscreenExit } from 'react-icons/bs';

export const RequestsViewerModal = ({
  bulkRequests,
}: {
  bulkRequests: number[];
}) => {
  const [index, setIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fullScreen, setFullScreen] = useState(true);

  const handleOpen = () => {
    onOpen();
  };
  return (
    <>
      <div className="flex flex-wrap gap-3">
        <Button color="warning" variant="flat" onPress={() => handleOpen()}>
          Open Requests Viewer
        </Button>
      </div>
      <Modal
        isKeyboardDismissDisabled={true}
        isDismissable={false}
        size={fullScreen ? 'full' : undefined}
        style={
          fullScreen
            ? {
                width: '100%',
                maxWidth: '100%',
                maxHeight: '100vh',
              }
            : {
                width: '90%',
                maxWidth: '90%',
                maxHeight: '90vh',
              }
        }
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody>
                <DefaultStartingView
                  requestID={bulkRequests[index]?.toString()}
                />
              </ModalBody>
              <ModalFooter>
                <RejectApplicationModal
                  requestID={bulkRequests[index]}
                  onRejectCallback={() => {
                    if (index === bulkRequests.length - 1) {
                      onClose();
                    } else {
                      setIndex(index + 1);
                    }
                  }}
                />
                <div
                  style={{
                    width: '100%',
                  }}
                ></div>
                <Button
                  isDisabled={index === 0}
                  isIconOnly
                  onClick={() => setIndex(index - 1)}
                >
                  <FaLeftLong />
                </Button>
                <Button
                  isDisabled={index === bulkRequests.length - 1}
                  isIconOnly
                  onClick={() => setIndex(index + 1)}
                >
                  <FaRightLong />
                </Button>
                <Button
                  isIconOnly
                  onClick={() => setFullScreen(!fullScreen)}
                  variant="light"
                >
                  {fullScreen ? <BsFullscreenExit /> : <BsFullscreen />}
                </Button>
                <AcceptApplicationModal
                  requestID={bulkRequests[index]}
                  onAcceptCallback={() => {
                    if (index === bulkRequests.length - 1) {
                      onClose();
                    } else {
                      setIndex(index + 1);
                    }
                  }}
                />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
