import { combineReducers } from 'redux';
import Auth from './authentication/authentication_reducer';
import Banners from './banners/banners_reducer';
import Categories from './categories/categories_reducer';
import CategoriesBase from 'silal_app_base_react/src/store/categories/categories_reducer';
import Orders from './orders/orders_reducer';
import { Images } from 'silal_app_base_react/src/store/images/image_library_reducer';
import Requests from './requests/requests_reducer';
import Locations from './locations/locations_reducer';
import Shifts from './shifts/shifts_reducer';
import Templates from './templates/templates_reducer';
const RootReducer = combineReducers({
  Auth,
  Categories,
  CategoriesBase,
  Images,
  Orders,
  Requests,
  Templates,
  Banners,
  Shifts,
  Locations,
});

export default RootReducer;
