import styled from 'styled-components';
import { Colors } from 'silal_app_base_react/src/config/theme';

export const missingInfoStyle = {
  color: Colors.red,
  fontWeight: 'bold',
  fontSize: '12px',
};

const Wrapper = styled.div`
  font-family: 'Poppins';
  margin-top: 20px;
  .header {
    h4 {
      display: flex;
      align-items: start;
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: var(--color-dark);
      margin: 0px 20px 0px 20px;
      a {
        margin-top: 10px;
        svg {
          font-size: 20px;
          color: var(--color-dark);
          margin-right: 20px;
        }
      }
    }

    p {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 15px;
      text-transform: uppercase;
      color: rgba(76, 104, 112, 0.5);
      margin-left: 60px;
    }
  }

  .body {
    .head {
      width: 100%;
      padding: 10px 0px 0px 0px;
      display: flex;
      font-family: 'Poppins', sans-serif;
      .tabs {
        width: 100%;
        float: left;
        button {
          margin-right: 20px;
        }
        /* .bgGreen {
          background-color: #05ae48;
        }
        .bgGrey {
          background-color: #4c6870;
        } */
      }
      .tabs_btn {
        margin-left: 20px;
        display: grid;
        grid-template-columns: 35% 35%;
        width: 75%;
      }
      .create {
        float: left;
        width: 25%;
        font-family: 'Poppins', sans-serif;
        button {
          width: 100%;
          margin-right: 20px;
        }
      }
    }
    .searchContainer {
      margin-top: 23px;
      margin-bottom: 13px;
      display: grid;
      column-gap: 20px;
      button {
        border: none;
        background: var(--color-light);
        font-size: 15px;
        font-weight: 500;
        border: 1px solid rgba(232, 232, 232, 0.5);
        border-radius: 5px;
        color: #4c6870;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;
        :hover {
          background: var(--app-color-dark);
          color: var(--color-light);
        }
        svg {
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .filter-options {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      > div {
        :not(:last-child) {
          /* margin-right: 10px; */
        }
      }
      .select-container {
        p {
          margin-bottom: 5px;
        }
        .select {
          max-width: 149px;
          width: 100%;
          .value-container {
            font-size: 15px;
            font-weight: 400;
            color: #ccd4d6;
            padding: 5px 10px;
            padding-top: 7px;
          }
          .dropdown {
            z-index: 99;
          }
        }

        .dropdown_header {
          /* border: 1px solid #000; */
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          button {
            border: none;
            background: transparent;
            color: #05ae48;
            font-size: 10px;
            font-weight: 400;
            text-align: left;
            padding: 10px;
            padding: 0;
            margin: 0;
            font-family: 'Lato', sans-serif;
          }
        }
      }

      .button-groups {
        display: flex;
        align-items: center;
        button {
          font-size: 20px;
          font-weight: bold;
          outline: none;
          cursor: pointer;
          border: none;
          border-radius: 5px;
        }
        .sort-button {
          padding: 10px 12px;
          background: #bbc6c9;
          color: #4c6870;
          margin-right: 10px;
          display: flex;
          justify-content: center;
        }
        .apply-changes {
          font-size: 13px;
          font-weight: 500;
          padding: 12px 33px;
          background: #4c6870;
          color: var(--color-light);
        }
      }
    }
    /* .searchContainer {
      margin: 10px 20px;
      margin-bottom: 13px;
      display: grid;
      grid-template-columns: 4fr 1fr;
      column-gap: 20px;
      button {
        border: none;
        background: var(--color-light);
        font-size: 15px;
        font-weight: 500;
        border: 1px solid rgba(232, 232, 232, 0.5);
        border-radius: 5px;
        color: #4c6870;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 0.3s ease;
        :hover {
          background: var(--app-color-dark);
          color: var(--color-light);
        }
        svg {
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }
      }
      .search {
        background: var(--app-color-dark);
        color: var(--color-light);
        font-size: 15px;
        font-weight: 600;
        :hover {
          color: var(--app-color-dark);
          background: var(--color-light);
          border: 1px solid var(--app-color-dark);
        }
      }
    } */
    .add-searchbutton {
      grid-template-columns: 4fr 1fr 1fr;
    }
    .filter-options {
      /* display: grid; */
      grid-template-columns: repeat(5, 1fr);
      > div {
        :not(:last-child) {
          margin-right: 20px;
        }
      }
      .select-container {
        p {
          margin-bottom: 5px;
        }
        .select {
          .value-container {
            font-family: 'Lato', sans-serif;
            font-size: 15px;
            font-weight: 400;
            color: #ccd4d6;
            padding: 5px 10px;
            padding-top: 7px;
          }
          .dropdown {
            z-index: 99;
            padding: 0;
            button {
              font-size: 15px;
              font-weight: 400;
              text-align: left;
              padding: 10px;
              font-family: 'Lato', sans-serif;
            }
            .selected {
              font-weight: 700;
            }
          }
        }
      }
      .button-groups {
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        button {
          font-size: 20px;
          font-weight: bold;
          outline: none;
          cursor: pointer;
          border: none;
          border-radius: 5px;
          :hover {
            color: var(--color-light);
            background: var(--color-dark);
          }
        }
        .sort-button {
          padding: 10px 12px;
          background: #bbc6c9;
          color: #4c6870;
          margin-right: 10px;
          display: flex;
          justify-content: center;
        }
        .apply-changes {
          width: 129px;
          font-size: 13px;
          font-weight: 500;
          padding: 12px 33px;
          background: #4c6870;
          color: var(--color-light);
          border: 1px solid transparent;
          transition: 0.3s ease;
          :hover {
            color: var(--color-dark);
            background: transparent;
            border: 1px solid var(--color-dark);
          }
        }
      }
    }
    .buttongroups {
      display: flex;
      align-items: center;
      margin-left: 10px;
      position: relative;
      ::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        border: 5px solid #bbc6c9;
        width: 100%;
        border-radius: 10px;
      }
      button {
        border: none;
        background: none;
        font-size: 15px;
        font-weight: 600;
        padding: 20px 5px;
        padding-bottom: 30px;
        color: #ccd4d6;
        position: relative;
        :hover {
          color: var(--color-dark);
          cursor: pointer;
        }
        :not(:first-child) {
          margin-left: 50px;
        }
        .underline {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          border: 5px solid var(--app-color-dark);
          z-index: 9;
          border-radius: 10px;
        }
      }
      .active {
        color: var(--color-dark);
      }
    }
    .cards_div {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
      grid-auto-rows: 1fr;
    }
  }
`;

export const WrapperViewDetail = styled.div`
  .top-row {
    display: flex;
    align-items: flex-start;
    > button {
      margin-top: 0;
    }
    .customer-name-id {
      margin-left: 20px;
      h2 {
        font-size: 25px;
        font-weight: 700;
        color: var(--color-dark);
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
        }
        .type {
          padding: 5px 8px;
          background: #2684ff;
          border-radius: 5px;
          color: var(--color-light);
          font-size: 11px;
          font-weight: 500;
          margin-left: 15px;
        }
      }
      p {
        margin-top: 7px;
        color: #ccd4d6;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  .container {
    display: flex;
    max-width: 100%;
    margin-top: 20px;
    gap: 20px;
    .left-side {
      flex: 3;
      overflow-y: auto;
      .grid-row-one {
        grid-gap: 30px;
        margin: 20px 0;
        a {
          border: 1px solid transparent;
          font-family: 'Poppins';
        }
        .first-button {
          border-radius: 5px;
          a {
            background: #ccd4d6;
            color: var(--color-dark) 80;
            :hover {
              background: transparent;
              border: 1px solid var(--color-dark) 80;
            }
          }
        }
        .second-button {
          a {
            :hover {
              background: transparent;
              border: 1px solid var(--app-color-dark);
            }
          }
        }
      }
      .grid-row-two {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .edit-order {
          background: var(--color-light-bg);
          color: var(--app-color-dark);
          background-color: var(--app-color-dark);
          border-radius: 5px;
          color: white;
          :hover {
            background: var(--color-light-bg);
            border: none;
            color: var(--app-color-dark);
          }
        }
        .contacts {
          background-color: var(--color-light-bg);
          color: var(--app-color-dark);
        }
        .delete-order {
          background: #ccd4d6;
          color: var(--color-text-dark);
        }
        button {
          font-family: 'Poppins';
          border-radius: 5px;
          font-size: 13px;
          font-weight: 600;
          padding: 9px;
          cursor: pointer;
          transition: 0.3s ease;
          :hover {
            background: var(--app-color-dark);
            color: var(--color-light);
          }
        }
      }
      .grid-row-three {
        padding: 20px;
        background: var(--color-light);
        border-radius: 5px;
        box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
        .grid-column {
          margin-top: 15px;
          > button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            /* padding: 10px; */
            text-transform: uppercase;
            background: #f2f4f5;
            border: none;
            outline: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            color: var(--color-dark);
            margin-bottom: 15px;
          }
          .dataPanel {
            overflow: hidden;
            margin: 10px;
            .center-text {
              text-align: center;
            }
            span {
              font-family: 'Lato';
              font-weight: 600;
              font-size: 12px;
              color: var(--color-text-dark);

              .green {
                color: var(--app-color-dark);
              }
            }

            .item-info {
              .row-3 {
                background: #fff;
                table {
                  width: 100%;
                  background: #fff;
                  border-radius: 5px;
                  border-collapse: collapse;
                  display: block;
                  thead {
                    display: block;
                    padding: 15px 20px;
                    background: #f2f4f5;
                    box-shadow: 0px 1px 1px rgba(0, 39, 51, 0.25);

                    tr {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      th {
                        font-size: 13px;
                        font-weight: 700;
                        color: var(--color-dark);
                        text-align: left;
                        :last-child {
                          text-align: right;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    tr {
                      cursor: pointer;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 15px 20px;
                      :hover {
                        background: rgba(90, 179, 168, 0.15);
                      }
                      :not(:last-child) {
                        border-bottom: 1px solid rgba(0, 39, 51, 0.08);
                      }
                      td {
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--color-dark);
                        text-align: left;
                        :last-child {
                          text-align: right;
                        }
                        img {
                          border-radius: 5px;
                          width: 44px;
                          height: 44px;
                        }
                      }
                      .more {
                        position: relative;
                        > button {
                          font-size: 18px;
                          color: #4c6870;
                          background: transparent;
                          border: none;
                          border-radius: 50%;
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          cursor: pointer;
                          padding: 10px;
                        }
                      }
                      .dropdown {
                        position: absolute;
                        min-width: 200px;
                        right: 0;
                        background: var(--color-light);
                        border-radius: 5px;
                        padding: 5px;
                        z-index: 1;
                        border: 1px solid rgba(204, 212, 214, 0.5);
                        box-shadow: 0px 8px 12px rgba(0, 39, 51, 0.12);
                        button {
                          width: 100%;
                          border: none;
                          padding: 8px 10px;
                          outline: none;
                          font-size: 15px;
                          font-weight: 400;
                          color: var(--color-dark);
                          background: transparent;
                          text-align: left;
                          border-radius: 5px;
                          :hover {
                            cursor: pointer;
                            background: #f2f4f5;
                          }
                        }
                      }
                    }
                    .active-row {
                      background: rgba(90, 179, 168, 0.15);
                    }
                  }
                }
              }
              .form-check-input:checked {
                background-color: #05ae4b;
              }
              table {
                border-radius: 5px;
              }
              thead {
                background: #f2f4f5;
                box-shadow: 0px 1px 1px rgba(0, 39, 51, 0.25);
                font-weight: 700;
                font-size: 13px;
                border-radius: 12px 12px 0px 0px;
                color: #002733;
              }
              tbody {
                background: rgba(220, 238, 221, 0.5);
                font-size: 15px;
                color: #002733;
                border-radius: 0px 0px 12px 12px;
              }
              .row {
                display: grid;
                grid-template-columns: 2fr 4fr 4fr 2fr;
                grid-gap: 5px 10px;
                font-size: 13px;
                font-weight: 500;
                line-height: 1.8;
                padding: 4px;
                .left-column {
                  color: #ccd4d6;
                  font-size: 14px;
                }
                .right-column {
                  color: var(--color-dark);
                  font-size: 14px;
                }
                .third-column {
                  color: var(--color-dark);
                }
                .left-column.header {
                  font-weight: bold;
                  text-align: center;
                }
                .right-column.header {
                  font-weight: bold;
                  text-align: center;
                }
                .third-column.header {
                  font-weight: bold;
                  text-align: center;
                }
                .left-column.tab {
                  background-color: #fafafa;
                  padding: 10px;
                  border-radius: 5px;
                  color: ${Colors.blueMain};
                }
                .right-column.tab {
                  padding: 10px;
                }
                .third-column.tab {
                  padding: 10px;
                }
                .forth-column.tab {
                  padding: 10px;
                  font-weight: bold;
                }
                .images {
                  display: flex;
                  justify-content: center;
                  img {
                    margin-right: 10px;
                    width: 100px !important;
                    height: 100px !important;
                    max-width: 100px;
                    max-height: 100px;
                    padding: 0px;
                    border: 1px solid #ccd4d6 !important;
                    border-radius: 5px;
                    object-fit: contain !important;
                  }
                }
                .right-columns {
                  display: grid;
                  grid-template-columns: 15% 50%;
                  /* border: 1px solid #000; */
                }
              }
            }
            .item-info.row-2 {
              .row {
                display: grid;
                grid-template-columns: 3fr 9fr;
              }
            }
            .time-details {
              background: rgba(90, 179, 168, 0.15);
              border-radius: 5px;
              margin-top: 20px;
              padding: 15px;
              h5 {
                font-weight: 600;
                font-size: 12px;
                line-height: 120%;
                color: #4c687080;
              }
            }
            .order-list {
              .row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                :not(:last-child) {
                  margin-bottom: 10px;
                }
                span {
                  color: var(--color-dark);
                  font-size: 17px;
                  font-weight: 400;
                }
                button {
                  border: none;
                  background: transparent;
                  cursor: pointer;
                  font-size: 15px;
                  color: #ccd4d6;
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }
      .grid-row-four {
        margin-top: 20px;
        .grid-column {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          p {
            line-height: 1.8;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            color: #4c6870;
            :last-child {
              min-width: 100px;
              text-align: right;
              color: var(--color-dark);
            }
          }
        }
        .last-column {
          color: var(--color-dark);
          p {
            :last-child {
              color: var(--color-dark);
            }
          }
        }
      }
    }
    .right-side {
      flex: 1;
      padding: 30px;
      border-radius: 5px;
      background-color: white;
      max-height: fit-content;
      height: fit-content;

      h3 {
        font-size: 19px;
        font-weight: 600;
        color: #002733;
        text-transform: capitalize;
        padding: 5px 0px;
        /* background-color: var(--color-light-gray-low); */
        border-radius: 5px;
      }
      p {
        color: #809399;
      }
      .order-timeline {
        margin-top: 30px;
        .row {
          font-family: 'Lato';
          /* padding-left: 40px; */
          position: relative;
          .card.canceled {
            color: var(--color-dark);
          }
          .card {
            padding: 8px;
            color: #ccd4d6;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 10px;
            h4 {
              font-size: 13px;
              line-height: 2;
            }
            p {
              font-size: 11px;
            }
          }
          .completed {
            color: var(--color-dark);
            position: relative;
          }
        }
      }
    }
  }
  .images {
    display: flex;
    justify-content: start;
    img {
      margin-right: 10px;
      width: 50px !important;
      height: 50px !important;
      border-radius: 5px;
      object-fit: contain !important;
    }
  }

  .images_grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    justify-content: start;
    img {
      width: 400px !important;
      height: 400px !important;
      padding: 0px;
      border: 1px solid #ccd4d6;
      margin-right: 10px;
      border-radius: 5px;
      object-fit: contain !important;
    }
  }
`;
export default Wrapper;
