import { AxiosInstance } from 'axios';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { TEMPLATES_MESSAGES } from 'data/constants/messages';
import { TemplateBrief } from 'silal_app_base_react/src/data/types/listings';
class TemplatesApiRepository extends AuthenticatedApiRepository {
  private static instance: TemplatesApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): TemplatesApiRepository {
    if (!TemplatesApiRepository.instance) {
      TemplatesApiRepository.instance = new TemplatesApiRepository(
        axiosInstance,
      );
    }
    return TemplatesApiRepository.instance;
  }

  getTemplateListings = async ({
    per_page = 100000,
    detailed = false,
  }: {
    per_page?: number;
    detailed?: boolean;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/paginate_templates/?${
            detailed ? 'detailed=true&' : ''
          }per_page=${per_page}`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.templates as TemplateBrief[];
    } catch (e: any) {
      return false;
    }
  };

  createListingTemplates = async (payload: any) => {
    //TODO whats dis
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/templates/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: TEMPLATES_MESSAGES.TemplateCreated,
        },
      );
      return res;
    } catch (e: any) {
      return false;
    }
  };

  updateListingTemplates = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'POST',

          `/templates/`,
          {
            baseApiUrl: API_BASE,
            data: payload.id,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: TEMPLATES_MESSAGES.TemplateUpdated,
        },
      );
      return res.data;
    } catch (e: any) {
      return false;
    }
  };

  deleteTemplate = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/templates/${payload}`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: TEMPLATES_MESSAGES.TemplateDeleted,
        },
      );
      return true;
    } catch (e: any) {
      return false;
    }
  };
}
const TemplatesRepository = TemplatesApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default TemplatesRepository;
