import moment from 'moment';
import { useEffect, useState } from 'react';
import { ShiftCard } from '../../components/shift_card';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import {
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
  Chip,
} from '@nextui-org/react';
import { DatePicker } from 'antd';
import { toast } from 'react-toastify';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { Link } from 'react-router-dom';
import { Colors } from 'silal_app_base_react/src/config/theme';
import dayjs from 'dayjs';

type ShiftsModalProps = {
  date: string;
  setDate: (date: string) => void;
  modal: boolean;
  closeModal: () => void;
  getFilteredShifts: (date: string) => Shift[];
  allShifts: Shift[];
  selectShift?: (shiftId: string) => void;
  selectShiftButtonText?: string;
  customTitle?: JSX.Element;
  customFirstButton?: (shiftId: string) => JSX.Element;
};

export function ShiftsAddOrChoosePopup({
  date,
  setDate,
  modal,
  closeModal,
  getFilteredShifts,
  allShifts,
  selectShift,
  selectShiftButtonText = 'Select Shift',
  customTitle,
  customFirstButton,
}: ShiftsModalProps) {
  const [todaysShifts, setShifts] = useState<Shift[]>([]);
  const [confirmActionPopupOpen, setConfirmActionPopupOpen] = useState(false);

  const [modalSelectedShift, setModalSelectedShift] = useState('');
  useEffect(() => {
    setShifts(getFilteredShifts(date));
  }, [date, allShifts, getFilteredShifts]);

  const { isOpen, onClose, onOpenChange } = useDisclosure({
    isOpen: modal,
    onClose: () => {
      setModalSelectedShift('');
      closeModal();
    },
  });

  const SuggestedShifts = () => {
    if (todaysShifts.length >= 3) {
      return <></>;
    }

    var suggestMorningShift = true;
    var suggestAfternoonShift = true;
    var suggestEveningShift = true;

    const morningShiftStartHour = 11;
    const afternoonShiftStartHour = 14;
    const eveningShiftStartHour = 17;

    const length = 2;
    const range = 2;

    for (const shift of todaysShifts) {
      // Set to false if a shift in range of 2 hours is found
      if (
        moment(shift.start_datetime).isBetween(
          moment(date).set('hour', morningShiftStartHour - range),
          moment(date).set('hour', morningShiftStartHour + range),
        )
      ) {
        suggestMorningShift = false;
      }
      if (
        moment(shift.start_datetime).isBetween(
          moment(date).set('hour', afternoonShiftStartHour - range),
          moment(date).set('hour', afternoonShiftStartHour + range),
        )
      ) {
        suggestAfternoonShift = false;
      }
      if (
        moment(shift.start_datetime).isBetween(
          moment(date).set('hour', eveningShiftStartHour - range),
          moment(date).set('hour', eveningShiftStartHour + range),
        )
      ) {
        suggestEveningShift = false;
      }
    }

    return (
      <>
        {suggestMorningShift && (
          <ShiftCard
            key={-2}
            date={date}
            selectedShiftId={modalSelectedShift}
            shiftData={{
              id: -2,
              finished: false,
              start_datetime: moment(date)
                .set('hour', morningShiftStartHour)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              end_datetime: moment(date)
                .set('hour', morningShiftStartHour + length)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              city_id: 0,
              registered_carriers: [],
              desired_carriers: 1,
              shipments_count: 0,
              is_recieving_new_orders: false,
              is_active: false,
            }}
            closeModal={closeModal}
          />
        )}
        {suggestAfternoonShift && (
          <ShiftCard
            key={-1}
            date={date}
            selectedShiftId={modalSelectedShift}
            shiftData={{
              id: -1,
              finished: false,
              start_datetime: moment(date)
                .set('hour', afternoonShiftStartHour)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              end_datetime: moment(date)
                .set('hour', afternoonShiftStartHour + length)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              city_id: 0,
              registered_carriers: [],
              desired_carriers: 1,
              shipments_count: 0,
              is_recieving_new_orders: false,
              is_active: false,
            }}
            closeModal={closeModal}
          />
        )}
        {suggestEveningShift && (
          <ShiftCard
            key={-3}
            date={date}
            selectedShiftId={modalSelectedShift}
            shiftData={{
              id: -3,
              finished: false,
              start_datetime: moment(date)
                .set('hour', eveningShiftStartHour)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              end_datetime: moment(date)
                .set('hour', eveningShiftStartHour + length)
                .set('minute', 0)
                .format('YYYY-MM-DD HH:mm:ss'),
              city_id: 0,
              registered_carriers: [],
              desired_carriers: 1,
              shipments_count: 0,
              is_recieving_new_orders: false,
              is_active: false,
            }}
            closeModal={closeModal}
          />
        )}
      </>
    );
  };

  return (
    <>
      <PopupWithConfirmation
        open={confirmActionPopupOpen}
        onButtonOneClick={() => setConfirmActionPopupOpen(false)}
        onButtonTwoClick={() => {
          selectShift && selectShift(modalSelectedShift);
          closeModal();
        }}
        headerTitle={`Are you sure you want to ${selectShiftButtonText.toLowerCase()}?`}
        bodyText={
          'This action will effect the order/shipment immediatly and cannot be undone.'
        }
        buttonTwoText={selectShiftButtonText}
        withTimerOfNSeconds={1}
      />
      <Modal
        backdrop="opaque"
        isOpen={isOpen}
        onClose={onClose}
        onOpenChange={onOpenChange}
        size="5xl"
        isDismissable={false}
        className="bg-white"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex-column">
                <div>
                  {customTitle ??
                    `Shifts for ${moment(date).format('ddd MMM Do YYYY')} or:`}
                  <DatePicker
                    style={{
                      marginLeft: '10px',
                    }}
                    onChange={(date) => {
                      if (!date) return;
                      setDate(date.toString());
                      return;
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: '1rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 'bold',
                      color: 'gray',
                    }}
                  >
                    Future Shifts:
                  </p>{' '}
                  {
                    // Chip mentioning all shifts start time & end time
                    allShifts.length > 0 ? (
                      allShifts
                        .filter((e) =>
                          moment(e.start_datetime).isAfter(moment(date)),
                        )

                        .map((shift) => (
                          <Chip
                            key={shift.id}
                            color="success"
                            variant="dot"
                            className="m-1"
                          >
                            <span
                              style={{
                                color: Colors.orange,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setDate(
                                  dayjs(shift.start_datetime).format(
                                    'YYYY-MM-DD',
                                  ),
                                );
                              }}
                            >
                              #{shift?.id}
                            </span>
                            {' - '}
                            {moment(shift.start_datetime).format(
                              'HH:mm',
                            )} - {moment(shift.end_datetime).format('HH:mm')} -{' '}
                            <span
                              onClick={() => {
                                setDate(
                                  dayjs(shift.start_datetime).format(
                                    'YYYY-MM-DD',
                                  ),
                                );
                              }}
                              style={{
                                color: Colors.orange,
                                cursor: 'pointer',
                              }}
                            >
                              {moment(shift.start_datetime).format('DD/MM')}{' '}
                            </span>
                          </Chip>
                        ))
                    ) : (
                      <Chip color="danger" variant="flat" className="mx-2">
                        No future shifts available, please create one.
                      </Chip>
                    )
                  }
                </div>
              </ModalHeader>
              <ModalBody className="flex-row items-center">
                <div
                  className="scrollable"
                  style={{
                    margin: 0,
                    padding: 0,
                    gap: 'var(--lg)', // Assuming you have a variable --lg defined
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    display: 'flex',
                    overflowX: 'auto',
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                  }}
                >
                  {SuggestedShifts()}
                  <ShiftCard
                    key={0}
                    date={date}
                    selectedShiftId={modalSelectedShift}
                    shiftData={{
                      id: 0,
                      finished: false,
                      start_datetime: moment(date).format(
                        'YYYY-MM-DD HH:mm:ss',
                      ),
                      end_datetime: moment(date).format('YYYY-MM-DD HH:mm:ss'),
                      city_id: 0,
                      registered_carriers: [],
                      desired_carriers: 1,
                      shipments_count: 0,
                      is_recieving_new_orders: false,
                      is_active: false,
                    }}
                    closeModal={closeModal}
                  />
                  {todaysShifts.map((shift) => (
                    <ShiftCard
                      key={shift.id}
                      date={date}
                      shiftData={shift}
                      closeModal={closeModal}
                      selectShift={selectShift && setModalSelectedShift}
                      selectedShiftId={modalSelectedShift}
                    />
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                {selectShift ? (
                  <>
                    {customFirstButton !== undefined ? (
                      customFirstButton(modalSelectedShift)
                    ) : (
                      <Button color="danger" variant="light" onPress={onClose}>
                        Cancel
                      </Button>
                    )}
                    <Button
                      color="success"
                      onPress={() => {
                        if (
                          modalSelectedShift === '' ||
                          isNaN(parseInt(modalSelectedShift))
                        ) {
                          toast.warn('Please select a shift before continuing');
                          return;
                        }
                        setConfirmActionPopupOpen(true);
                      }}
                    >
                      {selectShiftButtonText}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
