import { AxiosInstance } from 'axios';
import { Shift, ShiftCreate } from 'silal_app_base_react/src/data/types/shifts';
import {
  API_BASE,
  DEFAULT_CITY,
} from 'silal_app_base_react/src/config/constants';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { SHIFTS_MESSAGES } from 'data/constants/messages';
class ShiftsApiRepository extends AuthenticatedApiRepository {
  private static instance: ShiftsApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: AxiosInstance): ShiftsApiRepository {
    if (!ShiftsApiRepository.instance) {
      ShiftsApiRepository.instance = new ShiftsApiRepository(axiosInstance);
    }
    return ShiftsApiRepository.instance;
  }

  getShifts = async ({
    city_id = DEFAULT_CITY,
    futureOnly = false,
    noPendingMsg = false,
  }: {
    city_id?: number;
    futureOnly?: boolean;
    noPendingMsg?: boolean;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/delivery/${city_id}/last_mile_shifts/${futureOnly ? '?future_only=t' : ''}`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          noPendingMsg: noPendingMsg,
          customPendingMsg: 'Loading shifts...',
        },
      );
      return res.data.shifts as Shift[];
    } catch (e: any) {
      return false;
    }
  };

  getShiftsWhereStoreIsOpen = async (store_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/delivery/last_mile_shifts/store_is_open/${store_id}/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.shifts as Shift[];
    } catch (e: any) {
      return false;
    }
  };

  getShiftsAvailableForCheckoutFromStore = async (store_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/delivery/last_mile_shifts/available_for_checkout_from_store/${store_id}/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.shifts as Shift[];
    } catch (e: any) {
      return false;
    }
  };

  createShift = async (city_id: number, payload: ShiftCreate) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/delivery/${city_id}/last_mile_shifts/`, {
          baseApiUrl: API_BASE,
          data: {
            start_datetime: payload.start_datetime,
            end_datetime: payload.end_datetime,
            desired_carriers: payload.desired_carriers,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: SHIFTS_MESSAGES.ShiftAdded,
        },
      );
      return res.status === 201;
    } catch (e: any) {
      return false;
    }
  };

  updateShift = async (city_id: number, shift: Shift) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/delivery/${city_id}/last_mile_shifts/${shift.id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              start_datetime: shift.start_datetime,
              end_datetime: shift.end_datetime,
              desired_carriers: shift.desired_carriers,
              is_recieving_new_orders: shift.is_recieving_new_orders,
            },
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: SHIFTS_MESSAGES.ShiftUpdated,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };

  deleteShift = async (cityId: number, shiftId: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/delivery/${cityId}/last_mile_shifts/${shiftId}/`,
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: SHIFTS_MESSAGES.ShiftDeleted,
        },
      );
      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };

  unregisterCarrierFromShift = async (shiftId: any, carrierId: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/delivery/1/last_mile_shifts/${shiftId}/unregister_carrier/${carrierId}/`,
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: SHIFTS_MESSAGES.CarrierUnregistered,
        },
      );
      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };
}

const ShiftsRepository = ShiftsApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default ShiftsRepository;
