import { OrderBrief } from 'silal_app_base_react/src/data/types/orders';
import * as OrdersActions from './orders_actions';

const ordersInitialState = {
  orders: [] as OrderBrief[],
};

export default (state = ordersInitialState, action: any) => {
  switch (action.type) {
    case OrdersActions.GET_ORDERS:
      return {
        ...state,
      };
    case OrdersActions.SET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case OrdersActions.SET_LAST_FETCHED_ORDERS:
      return {
        ...state,
        lastFetched: action.timestamp,
      };
    case OrdersActions.LOGOUT:
      return {};

    default:
      return state;
  }
};
