import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import '../src/styles/index.css';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const domNode = document.getElementById('root') as Element;
const root = createRoot(domNode);
root.render(
  <BrowserRouter>
    <Suspense fallback={<Suspense />}>
      <App />
    </Suspense>
  </BrowserRouter>,
);
