import { state as init } from '../global_state';
import * as AuthenticationActions from './authentication_actions';

const reducer = (state = init, action: any) => {
  switch (action.type) {
    case AuthenticationActions.LOGIN: {
      return { ...state, loading: true, error: '', user: action.payload.user };
    }
    case AuthenticationActions.LOGIN_MFA_VERIFY_OTP: {
      return { ...state, loading: true, error: '', user: action.payload.user };
    }
    case AuthenticationActions.SET_STATE: {
      return {
        user: action.payload.user,
        userManagementAvailableRoutes:
          action.payload.userManagementAvailableRoutes,
      };
    }
    case AuthenticationActions.LOGOUT: {
      return {};
    }
    default:
      return state;
  }
};

export default reducer;
