import { put, call, takeLatest } from 'redux-saga/effects';
import { actionSetShiftsInCity, actionGetShiftsInCity } from './shifts_actions';

import {
  GET_SHIFTS_IN_CITY,
  CREATE_SHIFTS_IN_CITY,
  UPDATE_SHIFTS_IN_CITY,
  DELETE_SHIFT,
  UNREGISTER_SHIFT,
} from './shifts_actions';

import ShiftsRepository from 'data/repositories/shifts_repository';
import { Shift, ShiftCreate } from 'silal_app_base_react/src/data/types/shifts';
import { DEFAULT_CITY } from 'silal_app_base_react/src/config/constants';

export function* sagaGetShiftsInCity() {
  function* actionGetShiftsInCity() {
    const res: Shift[] = yield call(ShiftsRepository.getShifts, {
      city_id: DEFAULT_CITY,
    });
    if (!res) return false;
    yield put(actionSetShiftsInCity(res));
  }
  yield takeLatest(GET_SHIFTS_IN_CITY, actionGetShiftsInCity);
}

export function* sagaCreateShift() {
  function* createShift(action: {
    type: string;
    payload: { data: ShiftCreate; onSuccess: () => void };
  }) {
    const res: boolean = yield call(
      ShiftsRepository.createShift,
      1,
      action.payload.data,
    );
    if (!res) return false;
    action.payload.onSuccess();
    yield put(actionGetShiftsInCity());
  }
  yield takeLatest(CREATE_SHIFTS_IN_CITY, createShift);
}

export function* sagaUpdateShift() {
  function* updateShift(action: {
    type: string;
    payload: { data: Shift; onSuccess: () => void };
  }) {
    const res: boolean = yield call(
      ShiftsRepository.updateShift,
      1,
      action.payload.data,
    );
    if (!res) return false;
    if (action.payload.onSuccess) action.payload.onSuccess();
    yield put(actionGetShiftsInCity());
  }
  yield takeLatest(UPDATE_SHIFTS_IN_CITY, updateShift);
}

export function* sagaDeleteShift() {
  function* deletShift(action: { type: string; payload: { shiftId: number } }) {
    const res: boolean = yield call(
      ShiftsRepository.deleteShift,
      1,
      action.payload.shiftId,
    );
    if (!res) return false;
    yield put(actionGetShiftsInCity());
  }
  yield takeLatest(DELETE_SHIFT, deletShift);
}

export function* sagaUnregisterCarrierFromShift() {
  function* unregisterCarrierFromShift(action: {
    type: string;
    payload: { shiftId: string; carrierId: string };
  }) {
    const res: boolean = yield call(
      ShiftsRepository.unregisterCarrierFromShift,
      action.payload.shiftId,
      action.payload.carrierId,
    );
    if (!res) return false;
    yield put(actionGetShiftsInCity());
  }
  yield takeLatest(UNREGISTER_SHIFT, unregisterCarrierFromShift);
}
