import styled from 'styled-components';

const Wrapper = styled.div`
  .main_section {
    .body {
      display: flex;
      justify-content: space-between;
      .options {
        margin-top: 20px;
        width: 40%;
        .category-col {
          width: 100%;
          border-right: 1px solid rgba(0, 39, 51, 0.1);
          overflow-y: auto;
          background-color: var(--color-grey);
          color: var(--color-dark);
          margin-top: 10px;
          &:last-of-type {
            border-right: none;
          }
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-track {
            background: #0027330d;
          }
          ::-webkit-scrollbar-thumb {
            background: #00273326;
            border-radius: 5px;
          }
          .item {
            height: 45px;
            padding: 10px 20px;
            font-family: 'Lato';
            font-weight: 400;
            font-size: 15px;
            line-height: 104%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #002733;
            border-bottom: 1px solid rgba(0, 39, 51, 0.1);
            cursor: pointer;
            &:last-of-type {
              border-bottom: none;
            }
            &:hover,
            &.active {
              background: #daecea;
            }
            svg {
              path {
                fill: #0027331a;
              }
            }
          }
        }
      }
      .constants {
        padding: 20px;
        width: 60%;

        .bg-white {
          padding: 20px;
          background-color: var(--color-light);
          border-radius: 20px;
          label {
            font-family: 'Lato';
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
    }
  }
`;
export default Wrapper;
