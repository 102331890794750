import { Outlet, useParams } from 'react-router-dom';
import DriverProfileToprow from '../components/top_row';
import { useEffect, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';
import UsersRepository from 'data/repositories/users_repository';
import { CaptainUserInfo } from 'silal_app_base_react/src/data/types/users';

export default function DriverProfileParentRouteComponent() {
  const { driverId } = useParams<{ driverId: string }>();
  const [captain, setCaptain] = useState<CaptainUserInfo>();

  useEffect(() => {
    async function fetchDriver() {
      await UsersRepository.getDriverDetails(parseInt(driverId!)).then(
        (res) => {
          if (res) setCaptain(res as CaptainUserInfo);
        },
      );
    }
    fetchDriver();
  }, [driverId]);

  return (
    <div>
      {captain ? (
        <div>
          <DriverProfileToprow captain={captain} />
          <Outlet context={captain} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
