import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: 'Poppins';
  .header {
    h4 {
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: var(--color-dark);
      margin: 20px;
      a {
        svg {
          font-size: 20px;
          color: var(--color-dark);
          margin-right: 20px;
        }
      }
    }
  }

  .cards {
    margin: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    width: 100%;
    margin-left: 10px;
    .card {
      border: none;
      padding: 20px;
      margin-right: 10px;
      margin-top: 10px;
      min-width: 31%;
      min-height: 120px;
      background: var(--color-light);
      box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
      border-radius: 5px;

      h6 {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 18px;
        color: var(--color-dark);
        cursor: pointer;
        vertical-align: middle;
        line-height: 2;
      }
    }
  }
`;

export default Wrapper;
