import styled from 'styled-components';

const WrapperView = styled.div`
  .grid-row {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 20px;
    .profile-container {
      background: var(--color-light);
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
      .profile-container2 {
        display: grid;
        grid-template-columns: 0.6fr 2fr;
        /* gap: 20px; */
        p {
          margin-top: 13px;
        }
        h5 {
          text-transform: capitalize !important;
          color: #002733 !important;
        }
      }
      .time-details {
        background: #fb5c5c3a;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 15px;
        h5 {
          font-weight: 600;
          font-size: 12px;
          line-height: 120%;
          text-transform: uppercase;
          /* margin-bottom: 10px; */
          color: #002733;
        }
        p {
          margin-bottom: 3px;
        }
        h6 {
          margin: 0;
        }
      }
      h6 {
        font-weight: 500;
        font-size: 15px;
        line-height: 130%;
        letter-spacing: -0.408px;
        color: var(--color-dark);
        margin: 0 0 20px 0;
      }
      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        color: rgba(76, 104, 112, 0.8);
        margin-bottom: 6px;
      }
    }
  }
`;

export default WrapperView;
