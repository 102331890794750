export const LOGIN = 'LOGIN';
export const LOGIN_MFA_VERIFY_OTP = 'LOGIN_MFA_VERIFY_OTP';
export const LOGOUT = 'LOGOUT';
export const SET_STATE = 'SET_STATE';

export const actionLogin = (payload: any) => ({
  type: LOGIN,
  payload,
});

export const actionLoginMFAVerifyOTP = (payload: any) => ({
  type: LOGIN_MFA_VERIFY_OTP,
  payload,
});

export const actionSetUser = (payload: any) => ({
  type: SET_STATE,
  payload,
});

export const actionLogout = () => ({
  type: LOGOUT,
});
