import { put, call, takeLatest, select } from 'redux-saga/effects';
import { locations } from 'core/hooks/use_selector';
import * as LocationsActions from './locations_actions';
import {
  ADD_CITY,
  GET_CITIES,
  UPDATE_CITY,
  DELETE_CITY,
  ADD_TOWN,
  GET_TOWNS,
  UPDATE_TOWN,
  DELETE_TOWN,
} from './locations_actions';
import LocationsRepository from 'data/repositories/locations_repository';
import { City, Town } from 'silal_app_base_react/src/data/types/address';

export function* sagaGetCities() {
  function* getCities(action: { type: string; payload: { regionId: string } }) {
    const res: City[] = yield call(
      LocationsRepository.getCities,
      action.payload.regionId,
    );
    yield put(LocationsActions.actionSetCities(res || []));
    yield put(LocationsActions.actionSetTowns([]));
  }
  yield takeLatest(GET_CITIES, getCities);
}
export function* sagaGetTowns() {
  function* getTowns(action: { type: string; payload: { city_id: string } }) {
    const res: Town[] = yield call(
      LocationsRepository.getTowns,
      action.payload.city_id,
    );
    yield put(LocationsActions.actionSetTowns(res || []));
  }
  yield takeLatest(GET_TOWNS, getTowns);
}

export function* sagaAddCity() {
  function* addCity(action: { type: string; payload: City }) {
    const { region } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.addCity,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetCities({ regionId: region }));
  }

  yield takeLatest(ADD_CITY, addCity);
}
export function* sagaAddTown() {
  function* addTown(action: { type: string; payload: Town }) {
    const { city } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.addTown,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetTowns({ city_id: city }));
  }
  yield takeLatest(ADD_TOWN, addTown);
}

export function* sagaUpdateCity() {
  function* updateCity(action: { type: string; payload: City }) {
    const { region } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.updateCity,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetCities({ regionId: region }));
  }
  yield takeLatest(UPDATE_CITY, updateCity);
}
export function* sagaUpdateTown() {
  function* updateTown(action: { type: string; payload: Town }) {
    const { city } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.updateTown,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetTowns({ city_id: city }));
  }
  yield takeLatest(UPDATE_TOWN, updateTown);
}

export function* sagaDeleteCity() {
  function* deleteCity(action: { type: string; payload: City }) {
    const { region } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.deleteCity,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetCities({ regionId: region }));
  }
  yield takeLatest(DELETE_CITY, deleteCity);
}
export function* sagaDeleteTown() {
  function* deleteTown(action: { type: string; payload: Town }) {
    const { city } = yield select(locations);
    const res: boolean = yield call(
      LocationsRepository.deleteTown,
      action.payload,
    );
    if (!res) return false;
    yield put(LocationsActions.actionGetTowns({ city_id: city }));
  }
  yield takeLatest(DELETE_TOWN, deleteTown);
}
