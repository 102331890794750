import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import {
  Package,
  Shipment,
} from 'silal_app_base_react/src/data/types/shipping';
import { toast } from 'react-toastify';

class DeliveryApiRepository extends AuthenticatedApiRepository {
  private static instance: DeliveryApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): DeliveryApiRepository {
    if (!DeliveryApiRepository.instance) {
      DeliveryApiRepository.instance = new DeliveryApiRepository(axiosInstance);
    }
    return DeliveryApiRepository.instance;
  }

  getShipmentByTrackingCode = async (tracking_code: string) => {
    //TODO handle the case of zero returned shimpents!
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/delivery/get_shipments_by_tracking_code/${tracking_code}/`,
        ),
        {
          noFailureMsg: false,
          noSuccessMsg: true,
        },
      );
      return res.data;
    } catch (e: any) {
      return false;
    }
  };
  editShimpent = async (tracking_code: number, payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/delivery/edit_shipment/${tracking_code}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
        },
      );
      return res.data;
    } catch (e: any) {
      return false;
    }
  };

  generateNewCodes = async (count: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/delivery/generate_new_codes/`, {
          baseApiUrl: API_BASE,
          data: {
            count,
          },
        }),
        {
          noFailureMsg: false,
          noSuccessMsg: false,
          customSuccessMsg: `Successfully generated ${count} new tracking codes`,
          customErrorMsg: `Failed to generate ${count} new tracking codes`,
        },
      );
      // Create a csv file with the tracking codes
      const trackingCodes = res.data.tracking_codes as string[];

      // Create the CSV content as a string with the header
      const csvContent = `Tracking Codes\n${trackingCodes.join('\n')}`; // Add header and join codes

      const blob = new Blob([csvContent], { type: 'text/csv' });

      // Create a link element
      const link = document.createElement('a');

      // Set the download attribute with the desired filename
      link.download = `tracking_codes__${count}__${formateServerDateToLocaleDate(
        new Date().toISOString(),
      )}.csv`;

      // Create a URL for the Blob and set it as the href attribute of the link
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
      return res.data.tracking_codes as string[];
    } catch (e: any) {
      return false;
    }
  };

  collectShipmentToSilalAddress = async ({
    shipment_id,
    silal_address_id = 1,
  }: {
    shipment_id: number;
    silal_address_id?: number;
  }) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'POST',
          `/delivery/collect_shipment_to_silal_address/`,
          {
            baseApiUrl: API_BASE,
            data: {
              shipment_id,
              silal_address_id,
            },
          },
        ),
        {
          customErrorMsg: 'Failed to collect shipment',
          customSuccessMsg: 'Successfully collected shipment',
        },
      );
      if (res.status !== 202) {
        return false;
      }
      return res.data;
    } catch (e: any) {
      return false;
    }
  };

  // Mark Shipment As Failed Drop Off Endpoint
  markShipmentAsFailedDropOff = async (
    shipmentId: number,
    driver_id?: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/delivery/mark_shipment_as_failed_dropoff/${shipmentId}/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
            data: {
              driver_id,
            },
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Mark Shipment As Failed Pickup Endpoint
  markShipmentAsFailedPickup = async (
    shipmentId: number,
    driver_id?: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/delivery/mark_shipment_as_failed_pickup/${shipmentId}/`,

          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: false,
            data: { driver_id },
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  // Mark Shipment As Failed Pickup Endpoint
  editShipment = async (
    shipmentId: number,
    packages: Package[],
    shiftId: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/delivery/edit_shipment/${shipmentId}/`, {
          baseApiUrl: API_BASE,
          mustEndWithSlash: true,
          data: { packages: packages, shift_id: shiftId },
        }),
        {
          noPendingMsg: false,
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: 'Successfully updated shipment info',
        },
      );
      return res.status === 202;
    } catch (error: any) {
      return false;
    }
  };

  // List Shipments In Shift Endpoint
  listShipmentsInShift = async (shiftId: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/delivery/list_shipments_in_shift/${shiftId}/`,
          {
            baseApiUrl: API_BASE,
            mustEndWithSlash: true,
          },
        ),
        {
          noSuccessMsg: true,
        },
      );
      toast.success(res.data.msg);
      return res.data.shipments as Shipment[];
    } catch (error: any) {
      return false;
    }
  };
}
const DeliveryRepository = DeliveryApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default DeliveryRepository;
