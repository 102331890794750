import { ServerException } from 'silal_app_base_react/src/config/errors/server_exceptions';
import { ApiRepository } from 'silal_app_base_react/src/data/repositories/base_repository';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { toast } from 'react-toastify';
import {
  AUTH_API_BASE,
  LOCAL_STORAGE_TOKEN_KEY,
} from 'silal_app_base_react/src/config/constants';
import { User } from 'data/types/state';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
class AuthenticationApiRepository extends ApiRepository {
  private static instance: AuthenticationApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): AuthenticationApiRepository {
    if (!AuthenticationApiRepository.instance) {
      AuthenticationApiRepository.instance = new AuthenticationApiRepository(
        axiosInstance,
      );
    }
    return AuthenticationApiRepository.instance;
  }

  loginEmailVerifyPassword = async (email: string, password: string) => {
    try {
      const res = await this.sendRequest('POST', `/login/email_pass/`, {
        baseApiUrl: AUTH_API_BASE,
        data: {
          email,
          password,
        },
      });

      if (res.status === 300) {
        toast.info(
          `This account is protected via 2FA, you may choose between the following to confirm your identity:
            \n 
            ${res.data.choices.email_otp[1]} OR
            \n 
            ${res.data.choices.sms_otp[1]}`,
        );
        return {
          temporar_token: res.data.temp_bearer_token,
          email_otp: res.data.choices.email_otp[1],
          sms_otp: res.data.choices.sms_otp[1],
        };
      }

      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }
      return {
        bearer: res.data.bearer,
        current_user_id: res.data.current_user_id,
      } as User;
    } catch (e: any) {
      toast.error(
        (e?.message as string) ||
          e.msg ||
          'Unknown error occured in login via email and password',
      );
      return false;
    }
  };

  loginPhoneMFAVerifyOTP = async (temporar_token: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/mfa/phone/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            temporar_token: temporar_token,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return {
        bearer: res.data.bearer,
        current_user_id: res.data.current_user_id,
      } as User;
    } catch (e: any) {
      toast.error(e?.response?.data?.msg || 'Authentication failed');
      return false;
    }
  };

  loginEmailMFAVerifyOTP = async (temporar_token: string, otp: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', '/mfa/email/otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            temporar_token: temporar_token,
            otp: otp,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 201) {
        throw new ServerException(res.data);
      }

      return {
        bearer: res.data.bearer,
        current_user_id: res.data.current_user_id,
      } as User;
    } catch (e: any) {
      toast.error(e?.response?.data?.msg || 'Authentication failed');
      return false;
    }
  };

  loginSendMFAEmailOTP = async (temporar_token: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', '/mfa/email/send_otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            temporar_token,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 429) {
        throw new ServerException(res.data);
      }

      return res.data;
    } catch (e: any) {
      toast.error(e.res.error || (e.message as string));
      return false;
    }
  };

  loginSendMFAPhoneOTP = async (temporar_token: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', '/mfa/phone/send_otp/', {
          baseApiUrl: AUTH_API_BASE,
          data: {
            temporar_token,
          },
        }),
        {
          noSuccessMsg: true,
        },
      );
      if (res.status !== 200 && res.status !== 429) {
        throw new ServerException(res.data);
      }

      return res.data;
    } catch (e: any) {
      toast.error(e.res.error || (e.message as string));
      return false;
    }
  };

  logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    return true;
  };

  setUser = (user: User) => {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, user.bearer);
    return;
  };
}

const AuthenticationRepository = AuthenticationApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default AuthenticationRepository;
