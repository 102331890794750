import { call, put, takeLatest } from 'redux-saga/effects';
import {
  actionGetTemplates,
  actionCreateListingSuccess,
  GET_TEMPLATES,
  CREATE_TEMPLATES,
  DELETE_TEMPLATE,
  actionSetLastFetchedTemplates,
} from './templates_actions';
import TemplatesRepository from 'data/repositories/templates_repository';
import { AxiosResponse } from 'axios';
export function* sagaGetTemplates() {
  function* getTemplates() {
    const res: AxiosResponse<any, any> = yield call(
      TemplatesRepository.getTemplateListings,
      {},
    );
    if (!res) return false;
    const payload = {
      templates: res || [],
    };
    yield put(actionCreateListingSuccess(payload));
    yield put(actionSetLastFetchedTemplates(new Date().getTime()));
  }
  yield takeLatest(GET_TEMPLATES, getTemplates);
}

export function* sagaCreateTemplate() {
  function* createTemplate(action: any) {
    const payload = action.payload.data;
    const res: AxiosResponse<any, any> = yield call(
      TemplatesRepository.createListingTemplates,
      payload,
    );
    if (!res) {
      if (action.payload.error) action.payload.error();
      return false;
    }
    yield put(actionGetTemplates());
    action.payload.success(payload);
  }
  yield takeLatest(CREATE_TEMPLATES, createTemplate);
}

export function* sagaDeleteTemplate() {
  function* deleteTemplate(action: any) {
    const res: AxiosResponse<any, any> = yield call(
      TemplatesRepository.deleteTemplate,
      action.payload.template_id,
    );
    if (!res) return false;
    // yield put(actionGetTemplates());
  }
  yield takeLatest(DELETE_TEMPLATE, deleteTemplate);
}
