import { Layout } from 'antd';

type IntermediateViewsHandlerProps = {
  children: React.ReactNode;
};

export function AuthenticatedPagesHandler({
  children,
}: IntermediateViewsHandlerProps) {
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      {children}
    </Layout>
  );
}
