import React from 'react';
import Wrapper from './search_box.styled';
import { Icon } from '@iconify/react';

type SearchBoxProps = {
  type: React.HTMLInputTypeAttribute;
  placeholder: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  searchValue: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className: string;
};

function SearchBox({
  type = 'text',
  placeholder,
  onFocus,
  onBlur,
  searchValue = '',
  onChange,
  className,
}: SearchBoxProps) {
  return (
    <Wrapper>
      <label htmlFor="search">
        <Icon icon="fe:search" className="  mb-2" />
      </label>
      <input
        id="search"
        type={type}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        value={searchValue}
        onChange={onChange}
        className={className}
      />
    </Wrapper>
  );
}

export default SearchBox;
