import { AxiosInstance } from 'axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { Listing } from 'silal_app_base_react/src/data/types/listings';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { LISTINGS_MESSAGES } from 'data/constants/messages';
class ListingsApiRepository extends AuthenticatedApiRepository {
  private static instance: ListingsApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): ListingsApiRepository {
    if (!ListingsApiRepository.instance) {
      ListingsApiRepository.instance = new ListingsApiRepository(axiosInstance);
    }
    return ListingsApiRepository.instance;
  }

  getListingWithID = async (listing_id: number | string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/listings/${listing_id}/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.listing as Listing;
    } catch (e: any) {
      return false;
    }
  };

  getTemplateWithID = async (template_id: number | string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/templates/${template_id}/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.template as Listing;
    } catch (e: any) {
      return false;
    }
  };

  freezeListing = async (listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/listings/freeze/${listing_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingFreezed,
        },
      );
      return res.status === 200;
    } catch (e: any) {
      return false;
    }
  };

  unfreezeListing = async (listing_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/listings/unfreeze/${listing_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: LISTINGS_MESSAGES.ListingUnfreezed,
        },
      );
      return res.status === 200;
    } catch (e: any) {
      return false;
    }
  };
}

const ListingsRepository = ListingsApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default ListingsRepository;
