export const GET_ORDERS = 'GET_ORDERS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_LAST_FETCHED_ORDERS = 'SET_LAST_FETCHED_ORDERS';
export const LOGOUT = 'LOGOUT';

export const actionGetAllOrders = (statuses: number[]) => ({
  type: GET_ORDERS,
  statuses,
});

export const actionSetLastFetchedOrders = (timestamp: number) => ({
  type: SET_LAST_FETCHED_ORDERS,
  timestamp,
});

export const actionSetCurrentOrders = (payload: any) => ({
  type: SET_ORDERS,
  payload,
});

export const actionLogoutOrders = () => ({
  type: LOGOUT,
});
