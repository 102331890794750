import React from 'react';
import Wrapper from './otp_code.styled';
import { motion } from 'framer-motion';

type OtpcodeProps = {
  num:
    | {
        [key: string]: string;
      }
    | {
        [key: string]: string | undefined;
      };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function Otpcode({ num, onChange }: OtpcodeProps) {
  // input animation
  const inputAnimate = {
    y: [0, -50, -20],
    scale: [1, 1.1, 1],
  };
  return (
    <Wrapper>
      {Object.keys(num).map((item, index) => (
        <motion.input
          key={index}
          type="number"
          id={item}
          animate={inputAnimate}
          transition={{ delay: (index * 10) / 80 }}
          onChange={onChange}
          value={num[item]}
        />
      ))}
    </Wrapper>
  );
}

export default Otpcode;
