export const GET_CATEGORIES = 'GET_CATEGORIES';
export const SET_LAST_FETCHED_CATEGORIES = 'SET_LAST_FETCHED_CATEGORIES';
export const GET_SINGLE_CATEGORY = 'GET_SINGLE_CATEGORY';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const HIDE_CATEGORY = 'HIDE_CATEGORY';
export const UNHIDE_CATEGORY = 'UNHIDE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_NEW_ATTRIBUTE = 'ADD_NEW_ATTRIBUTE';
export const EDIT_ATTRIBUTE = 'EDIT_ATTRIBUTE';
export const REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE';

export const LOGOUT = 'LOGOUT';

export const actionGetCategories = () => ({
  type: GET_CATEGORIES,
});

export const actionSetLastFetchedCategories = (timestamp: number) => ({
  type: SET_LAST_FETCHED_CATEGORIES,
  timestamp,
});

export const actionGetSingleCategory = (payload: any) => ({
  type: GET_SINGLE_CATEGORY,
  payload,
});

export const actionGetCategoriesSuccess = (payload: any) => ({
  type: GET_CATEGORIES_SUCCESS,
  payload,
});

export const actionGetCategoriesFail = (payload: any) => ({
  type: GET_CATEGORIES_FAILURE,
  payload,
});

export const actionAddCategory = (payload: any) => ({
  type: ADD_CATEGORY,
  payload,
});

export const actionEditCategory = (payload: any) => ({
  type: EDIT_CATEGORY,
  payload,
});

export const actionHideCategory = (payload: any) => ({
  type: HIDE_CATEGORY,
  payload,
});

export const actionUnhideCategory = (payload: any) => ({
  type: UNHIDE_CATEGORY,
  payload,
});

export const actionDeleteCategory = (payload: any) => ({
  type: DELETE_CATEGORY,
  payload,
});

export const actionAddNewAttribute = (payload: any) => ({
  type: ADD_NEW_ATTRIBUTE,
  payload,
});

export const actionEditAttribute = (payload: any) => ({
  type: EDIT_ATTRIBUTE,
  payload,
});

export const actionRemoveAttribute = (payload: any) => ({
  type: REMOVE_ATTRIBUTE,
  payload,
});

export const actionLogoutCategories = () => ({
  type: LOGOUT,
});
