import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';

class StoresApiRepository extends AuthenticatedApiRepository {
  private static instance: StoresApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(axiosInstance: AxiosInstance): StoresApiRepository {
    if (!StoresApiRepository.instance) {
      StoresApiRepository.instance = new StoresApiRepository(axiosInstance);
    }
    return StoresApiRepository.instance;
  }

  getStoreDetails = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('GET', `/stores/${store_id}/details/`),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.store as StoreDetails;
    } catch (e: any) {
      return false;
    }
  };

  storeCredentials = async (store_id: string) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/stores/${store_id}/generate_access_account_creds/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return {
        email: res.data.email,
        password: res.data.password,
      };
    } catch (e: any) {
      return false;
    }
  };

  getStoreOrdersReport = async (
    store_id: number,
    {
      start_date,
      end_date,
      format = 'html', // default to HTML if not specified
      downloadedFilename,
    }: {
      start_date?: string;
      end_date?: string;
      format: 'html' | 'pdf';
      downloadedFilename?: string;
    },
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/documents/reports/get_store_report/${store_id}/${
            start_date && end_date
              ? `?start_date=${start_date}&end_date=${end_date}`
              : ``
          }`,
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );

      if (format === 'html') {
        // download HTML file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          downloadedFilename ?? `store_report_${store_id}.html`,
        );
        document.body.appendChild(link);
        link.click();
        // remove link
        document.body.removeChild(link);
      } else if (format === 'pdf') {
        const printWindow = window.open('', '_blank');
        if (printWindow) {
          // Write the HTML content to the new window
          printWindow.document.write(res.data);

          // Set the document title to the desired filename
          printWindow.document.title =
            downloadedFilename ?? `store_report_${store_id}`;

          printWindow.document.close(); // Necessary for some browsers

          // Wait for the content to load before printing
          setTimeout(() => {
            printWindow.print();
          }, 500);
        } else {
          console.error('Failed to open print window');
        }
      }

      return true;
    } catch (e: any) {
      return false;
    }
  };
}

const StoresRepository = StoresApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default StoresRepository;
