export enum RefundDataType {
  fault_of,
  return_method,
}

export enum FaultOf {
  silal,
  store,
}

export const FAULT_OF: {
  [key: string]: FaultOf;
} = {
  silal: FaultOf.silal,
  store: FaultOf.store,
};

export const FAULT_OF_NAME_MAP: {
  [key: string]: string;
} = {
  silal: 'Silal',
  store: 'Store',
};

export enum ReturnMethod {
  pickup,
  self_return,
}

export const RETURN_METHOD: {
  [key: string]: ReturnMethod;
} = {
  pickup: ReturnMethod.pickup,
  self_return: ReturnMethod.self_return,
};

export const RETURN_METHOD_NAME_MAP: {
  [key: string]: string;
} = {
  pickup: 'Pickup',
  self_return: 'Self Return',
};

export enum RefundType {
  balance_credit,
  credit_card,
}

export const REFUND_TYPE: {
  [key: string]: RefundType;
} = {
  balance_credit: RefundType.balance_credit,
  credit_card: RefundType.credit_card,
};

export const REFUND_TYPE_NAME_MAP: {
  [key: string]: string;
} = {
  balance_credit: 'Balance Credit',
  credit_card: 'Credit Card',
};

export enum RefundReason {
  replace_valid_item = 0,
  return_item_without_valid_reason = 1,
  recieved_wrong_or_broken_item_and_want_to_return = 2,
  recieved_wrong_or_broken_item_and_want_to_replace = 3,
  missing_item = 4,
}

export const REFUND_REASONS: {
  [key: string]: RefundReason;
} = {
  replace_valid_item: RefundReason.replace_valid_item,
  return_item_without_valid_reason:
    RefundReason.return_item_without_valid_reason,
  recieved_wrong_or_broken_item_and_want_to_return:
    RefundReason.recieved_wrong_or_broken_item_and_want_to_return,
  recieved_wrong_or_broken_item_and_want_to_replace:
    RefundReason.recieved_wrong_or_broken_item_and_want_to_replace,
  missing_item: RefundReason.missing_item,
};

export const REFUND_REASONS_NAME_MAP: {
  [key: string]: string;
} = {
  replace_valid_item: 'Replace valid item',
  return_item_without_valid_reason: 'Return item without valid reason',
  recieved_wrong_or_broken_item_and_want_to_return:
    'Recieved wrong or broken item and want to return',
  recieved_wrong_or_broken_item_and_want_to_replace:
    'Recieved wrong or broken item and want to replace',
  missing_item: 'Missing item',
};

export type RefundPayload = {
  amount_to_refund_to_customer: number;
  amount_to_deduct_from_store_debt: number;
  refund_as_balance_credit: RefundType;
};

export function getRefundAmounts({
  customerPaidForSelectedOrdersItems,
  sellerRecievesForSelectedOrdersItems,
  deliveryFee,
  returnPickupFee,
  faultOf,
  returnMethod,
  refundReason,
}: {
  customerPaidForSelectedOrdersItems: number;
  sellerRecievesForSelectedOrdersItems: number;
  deliveryFee: number;
  returnPickupFee: number;
  faultOf: FaultOf;
  returnMethod: ReturnMethod;
  refundReason: RefundReason;
}): RefundPayload {
  if (REFUND_REASONS[refundReason] === RefundReason.replace_valid_item) {
    if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
      return {
        amount_to_refund_to_customer:
          customerPaidForSelectedOrdersItems - returnPickupFee,
        amount_to_deduct_from_store_debt: sellerRecievesForSelectedOrdersItems,
        refund_as_balance_credit: RefundType.balance_credit,
      };
    } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
      return {
        amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
        amount_to_deduct_from_store_debt: sellerRecievesForSelectedOrdersItems,
        refund_as_balance_credit: RefundType.balance_credit,
      };
    }
  }
  if (
    REFUND_REASONS[refundReason] ===
    RefundReason.return_item_without_valid_reason
  ) {
    if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
      return {
        amount_to_refund_to_customer:
          customerPaidForSelectedOrdersItems - returnPickupFee,
        amount_to_deduct_from_store_debt: sellerRecievesForSelectedOrdersItems,
        refund_as_balance_credit: RefundType.balance_credit,
      };
    } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
      return {
        amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
        amount_to_deduct_from_store_debt: sellerRecievesForSelectedOrdersItems,
        refund_as_balance_credit: RefundType.balance_credit,
      };
    }
  }
  if (
    REFUND_REASONS[refundReason] ===
    RefundReason.recieved_wrong_or_broken_item_and_want_to_return
  ) {
    if (FAULT_OF[faultOf] === FaultOf.silal) {
      if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
        return {
          amount_to_refund_to_customer:
            customerPaidForSelectedOrdersItems + deliveryFee,
          amount_to_deduct_from_store_debt: 0,
          refund_as_balance_credit: RefundType.credit_card,
        };
      } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
        return {
          amount_to_refund_to_customer:
            customerPaidForSelectedOrdersItems + deliveryFee,
          amount_to_deduct_from_store_debt: 0,
          refund_as_balance_credit: RefundType.credit_card,
        };
      }
    } else if (FAULT_OF[faultOf] === FaultOf.store) {
      if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt:
            sellerRecievesForSelectedOrdersItems +
            deliveryFee +
            returnPickupFee,
          refund_as_balance_credit: RefundType.credit_card,
        };
      } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt:
            sellerRecievesForSelectedOrdersItems + deliveryFee,
          refund_as_balance_credit: RefundType.credit_card,
        };
      }
    }
  }
  if (
    REFUND_REASONS[refundReason] ===
    RefundReason.recieved_wrong_or_broken_item_and_want_to_replace
  ) {
    if (FAULT_OF[faultOf] === FaultOf.silal) {
      if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt: 0,
          refund_as_balance_credit: RefundType.balance_credit,
        };
      } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt: 0,
          refund_as_balance_credit: RefundType.balance_credit,
        };
      }
    } else if (FAULT_OF[faultOf] === FaultOf.store) {
      if (RETURN_METHOD[returnMethod] === ReturnMethod.pickup) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt:
            sellerRecievesForSelectedOrdersItems +
            deliveryFee +
            returnPickupFee,
          refund_as_balance_credit: RefundType.balance_credit,
        };
      } else if (RETURN_METHOD[returnMethod] === ReturnMethod.self_return) {
        return {
          amount_to_refund_to_customer: customerPaidForSelectedOrdersItems,
          amount_to_deduct_from_store_debt:
            sellerRecievesForSelectedOrdersItems + deliveryFee,
          refund_as_balance_credit: RefundType.balance_credit,
        };
      }
    }
  }
  if (REFUND_REASONS[refundReason] === RefundReason.missing_item) {
    return {
      amount_to_refund_to_customer:
        customerPaidForSelectedOrdersItems + deliveryFee,
      amount_to_deduct_from_store_debt:
        customerPaidForSelectedOrdersItems + deliveryFee,
      refund_as_balance_credit: RefundType.balance_credit,
    };
  }
  return {
    amount_to_refund_to_customer: 0,
    amount_to_deduct_from_store_debt: 0,
    refund_as_balance_credit: RefundType.credit_card,
  };
}

export function isDataRelevantToRefundType(
  refundReason: RefundReason,
  refundDataType: RefundDataType,
): boolean {
  switch (REFUND_REASONS[refundReason]) {
    case RefundReason.replace_valid_item:
      if (refundDataType === RefundDataType.return_method) {
        return true;
      }
      return false;
    case RefundReason.return_item_without_valid_reason:
      if (refundDataType === RefundDataType.return_method) {
        return true;
      }
      return false;
    case RefundReason.recieved_wrong_or_broken_item_and_want_to_return:
      if (
        refundDataType === RefundDataType.return_method ||
        refundDataType === RefundDataType.fault_of
      ) {
        return true;
      }
      return false;
    case RefundReason.recieved_wrong_or_broken_item_and_want_to_replace:
      if (
        refundDataType === RefundDataType.return_method ||
        refundDataType === RefundDataType.fault_of
      ) {
        return true;
      }
      return false;
    case RefundReason.missing_item:
      return false;
  }
}
