import { Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'data/types/state';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { actionLogout } from 'store/authentication/authentication_actions';
import { actionLogoutBanners } from 'store/banners/banners_actions';
import { actionLogoutCategories } from 'store/categories/categories_actions';
import { actionLogoutImages } from 'silal_app_base_react/src/store/images/image_library_actions';
import { actionLogoutLocations } from 'store/locations/locations_actions';
import { actionLogoutOrders } from 'store/orders/orders_actions';
import { actionLogoutRequests } from 'store/requests/requests_actions';
import { actionLogoutShifts } from 'store/shifts/shifts_actions';
import { actionLogoutTemplates } from 'store/templates/templates_actions';
import { LOCAL_STORAGE_TOKEN_KEY } from 'silal_app_base_react/src/config/constants';

const IsUserAuthenticated = () => {
  const token = useSelector((state: RootState) => state?.Auth?.user?.bearer);
  const localToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
  if (token && localToken) {
    if (token !== '') {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const fullLogout = (dispatch: Dispatch, navigate: NavigateFunction) => {
  dispatch(actionLogout());
  dispatch(actionLogoutBanners());
  dispatch(actionLogoutCategories());
  dispatch(actionLogoutImages());
  dispatch(actionLogoutLocations());
  dispatch(actionLogoutOrders());
  dispatch(actionLogoutRequests());
  dispatch(actionLogoutShifts());
  dispatch(actionLogoutTemplates());
  navigate('/');
};
export default IsUserAuthenticated;
