import styled from 'styled-components';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';

export const ScreenWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  padding: 20px 20px 0px 20px;
  font-family: ${Fonts.primary};
  font-size: 16px;
  a {
    color: ${Colors.greenMain};
    font-weight: 500;
    margin-left: 6px;
    margin-right: 6px;
  }
  p {
    font-size: 14px;
    font-family: ${Fonts.secondary};
    color: ${Colors.grey};
  }

  label {
    font-size: 12px;
    font-weight: 600;
  }

  .dimensions {
    font-size: 14px;
    padding-top: 2px;
    color: #999999;
  }

  .packageId {
    font-size: 16px;
    color: ${Colors.blueMain};
  }

  .positive-amount {
    color: ${Colors.greenMain}; /* Set the text color to red for negative amounts */
  }

  .negative-amount {
    color: ${Colors.tomatoRed}; /* Set the text color to green for positive amounts */
  }

  .pending-amount {
    color: ${Colors.grey}; /* Set the text color to green for positive amounts */
  }
`;
