import styled from 'styled-components';

const WrapperViewDetail = styled.div`
  .top-row {
    display: flex;
    align-items: flex-start;
    > button {
      margin-top: 0;
    }
    .customer-name-id {
      margin-left: 20px;
      h2 {
        font-size: 25px;
        font-weight: 700;
        color: var(--color-dark);
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
        }
        .type {
          padding: 5px 8px;
          background: #2684ff;
          border-radius: 5px;
          color: var(--color-light);
          font-size: 11px;
          font-weight: 500;
          margin-left: 15px;
        }
      }
      p {
        margin-top: 7px;
        color: #ccd4d6;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }
  .container {
    display: flex;
    max-width: 100%;
    margin-top: 20px;
    gap: 20px;
    .left-side {
      flex: 3;
      max-height: 82vh;
      overflow-y: auto;
      .grid-row-one {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
        margin: 20px 0;
        a {
          border: 1px solid transparent;
          font-family: 'Poppins';
        }
        .first-button {
          border-radius: 5px;
          a {
            background: #ccd4d6;
            color: var(--color-dark) 80;
            :hover {
              background: transparent;
              border: 1px solid var(--color-dark) 80;
            }
          }
        }
        .second-button {
          a {
            :hover {
              background: transparent;
              border: 1px solid var(--app-color-dark);
            }
          }
        }
      }
      .grid-row-two {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        .edit-order {
          background: var(--color-light-bg);
          color: var(--app-color-dark);
          background-color: var(--app-color-dark);
          border-radius: 5px;
          color: white;
          :hover {
            background: var(--color-light-bg);
            border: none;
            color: var(--app-color-dark);
          }
        }
        .contacts {
          background-color: var(--color-light-bg);
          color: var(--app-color-dark);
        }
        .delete-order {
          background: #ccd4d6;
          color: var(--color-text-dark);
        }
        button {
          font-family: 'Poppins';
          border-radius: 5px;
          font-size: 13px;
          font-weight: 600;
          padding: 9px;
          cursor: pointer;
          transition: 0.3s ease;
          :hover {
            background: var(--app-color-dark);
            color: var(--color-light);
          }
        }
      }
      .grid-row-three {
        padding: 20px;
        background: var(--color-light);
        border-radius: 5px;
        box-shadow: 0px 5px 10px -2px rgba(196, 214, 220, 0.25);
        margin-top: 15px;
        .grid-column {
          > button {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            text-transform: uppercase;
            background: #f2f4f5;
            border: none;
            outline: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            color: var(--color-dark);
            margin-bottom: 15px;
          }
          .dataPanel {
            overflow: hidden;
            margin: 10px;
            .customer-info {
              .row {
                display: grid;
                grid-template-columns: 1fr 3fr;
                grid-gap: 20px;
                .left-column {
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 1.8;
                  color: #ccd4d6;
                }
                .right-column {
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 1.8;
                  color: var(--color-dark);
                }
                .right-columns {
                  display: grid;
                  grid-template-columns: 15% 50%;
                  /* border: 1px solid #000; */
                }
              }
            }
            .time-details {
              background: rgba(90, 179, 168, 0.15);
              border-radius: 5px;
              margin-top: 20px;
              padding: 15px;
              h5 {
                font-weight: 600;
                font-size: 12px;
                line-height: 120%;
                color: #4c687080;
              }
            }
            .order-list {
              .row {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                :not(:last-child) {
                  margin-bottom: 10px;
                }
                span {
                  color: var(--color-dark);
                  font-size: 17px;
                  font-weight: 400;
                }
                button {
                  border: none;
                  background: transparent;
                  cursor: pointer;
                  font-size: 15px;
                  color: #ccd4d6;
                  margin: 0 10px;
                }
              }
            }
          }
        }
      }
      .grid-row-four {
        margin-top: 20px;
        .grid-column {
          display: flex;
          justify-content: flex-end;
          p {
            line-height: 1.8;
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 600;
            color: #4c6870;
            :last-child {
              min-width: 100px;
              text-align: right;
              color: var(--color-dark);
            }
          }
        }
        .last-column {
          color: var(--color-dark);
          p {
            :last-child {
              color: var(--color-dark);
            }
          }
        }
      }
    }
    .right-side {
      margin-top: 20px;
      flex: 1;
      padding: 30px;
      border-radius: 5px;
      background-color: white;
      max-height: fit-content;
      height: fit-content;
      h3 {
        font-size: 19px;
        font-weight: 600;
        color: #002733;
        text-transform: capitalize;
        padding: 5px 0px;
        /* background-color: var(--color-light-gray-low); */
        border-radius: 5px;
      }
      p {
        color: #809399;
      }
      .order-timeline {
        margin-top: 30px;
        .row {
          font-family: 'Lato';
          /* padding-left: 40px; */
          position: relative;
          .card.canceled {
            color: var(--color-dark);
          }
          .card {
            display: flex;
            color: #ccd4d6;
            font-weight: 400;
            text-transform: uppercase;
            position: relative;
            margin-bottom: 20px;
            h4 {
              font-size: 13px;
              line-height: 2;
            }
            p {
              font-size: 11px;
            }
          }
          .completed {
            color: var(--color-dark);
            position: relative;
          }
        }
      }
    }
  }
`;

export default WrapperViewDetail;
