import { State } from 'data/types/state';

export const state: State = {
  loading: false,
  error: '',
  token: '',
  success_msg: '',
  images: [],
  language: 'eng',
  theme: '',
  categories: [],
  requests: [],
  templates: [],
  singleReq: [],
  filters: {
    category: '',
    sub_category: '',
  },
  progress_monitor_bool: false,
  progress_monitor_reflected_indexes: 0,
  monitor_id: 0,
  monitor_current_progress: 0,
  monitor_max_progress: 0,
  userManagementAvailableRoutes: {
    dashboard: false,
    databases: false,
    payments: false,
    coupons: false,
    products: false,
    inquiries: false,
    categories: false,
    templates: false,
    image_library: false,
    banners: false,
    logistics: false,
    settings: false,
  },
};
// dashboard: boolean;
// databases: boolean;
// payments: boolean;
// coupons: boolean;
// products: boolean;
// inquiries: boolean;
// categories: boolean;
// templates: boolean;
// image_library: boolean;
// banners: boolean;
// logistics: boolean;
// settings: boolean;
