import styled from 'styled-components';

const Wrapper = styled.div`
  .row {
    .top-row {
      display: flex;
      align-items: flex-start;
      > button {
        margin-top: 0;
      }
      .customer-name-id {
        margin-left: 20px;
        h2 {
          font-size: 25px;
          font-weight: 700;
          color: var(--color-dark);
          display: flex;
          align-items: center;
          span {
            padding: 5px 8px;
            background: #36b37e;
            border-radius: 5px;
            color: var(--color-light);
            font-size: 12px;
            font-weight: 400;
            line-height: 1.4;
            margin-left: 15px;
          }
        }
        p {
          margin-top: 7px;
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 700;
          line-height: 1.8;
          color: #ccd4d6;
        }
      }
    }
    .top-row-buttons {
      padding: 15px;
      a {
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        padding: 8px 20px;
        color: #4c6870;
        border-radius: 5px;
        margin-right: 15px;
        transition: 0.3s ease;
        :hover {
          background: var(--app-color-dark);
          color: var(--color-light);
        }
      }
      .active {
        background: var(--app-color-dark);
        color: var(--color-light);
      }
    }
  }
`;

export default Wrapper;
