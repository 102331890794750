/* eslint-disable no-unused-vars */
import { ORDER_STATUS } from 'silal_app_base_react/src/data/types/orders';
import { IoCalendarOutline, IoClose } from 'react-icons/io5';
import { GoPackageDependents, GoPackageDependencies } from 'react-icons/go';
import {
  CiBookmarkCheck,
  CiBookmarkRemove,
  CiStickyNote,
} from 'react-icons/ci';
import { TbCreditCardRefund, TbTruckReturn } from 'react-icons/tb';
import { GrScheduleNew, GrSchedules } from 'react-icons/gr';
import { ImCheckboxChecked } from 'react-icons/im';
import { TiArrowBackOutline, TiCancel } from 'react-icons/ti';
import { RiCalendarScheduleLine } from 'react-icons/ri';
import { FaRegHandBackFist } from 'react-icons/fa6';
import ManagementOrdersRepository from 'data/repositories/management_orders_repository';
import DeliveryRepository from 'data/repositories/delivery_repository';
import { Package } from 'silal_app_base_react/src/data/types/shipping';

export enum ManagementOrderActionsTypes {
  add_management_note,

  collect_shipment_from_captain,
  mark_shipment_as_failed_dropoff,
  mark_shipment_as_failed_pickup,

  reschedule_return_to_store,
  reschedule_silal_return,
  update_order_dropoff_shift,
  update_pickup_dropoff_shift,

  return_undelivered_order,
  initiate_customer_return,
  collect_return_from_customer,
  mark_return_as_inspected,
  order_return_rejected_at_silal,

  markAsReturning,
  cancelOrder,
  refundOrder,
}

export enum ActionCategory {
  general = 'General Actions',
  shipping = 'Shipping Actions',
  reschedule = 'Rescheduling Actions',
  return = 'Returns Actions',
  dangerZone = 'Danger Zone',
}

export const ActionCategoryColorMap: {
  [key in ActionCategory]: 'success' | 'secondary' | 'warning' | 'danger';
} = {
  [ActionCategory.general]: 'success',
  [ActionCategory.shipping]: 'success',
  [ActionCategory.reschedule]: 'secondary',
  [ActionCategory.return]: 'warning',
  [ActionCategory.dangerZone]: 'danger',
};

export type ManagementOrderActionsData = {
  action: ManagementOrderActionsTypes;
  label: string;
  description: string;
  icon: React.ReactNode;
  actionCategory: ActionCategory;
  allowedToBeCalledWhenOrder: Set<number>;
  callback: (arg0: any) => Promise<any>;
};

const iconClasses =
  'text-2xl text-default-500 pointer-events-none flex-shrink-0';
const successClasses = iconClasses + ' text-success success';
const warningClasses = iconClasses + ' text-warning warning';
const secondaryClasses = iconClasses + ' text-purple-600';
const dangerIconClasses = iconClasses + ' text-danger danger';

export const ManagementOrderActions: {
  [key in ManagementOrderActionsTypes]: ManagementOrderActionsData;
} = {
  // General
  [ManagementOrderActionsTypes.add_management_note]: {
    action: ManagementOrderActionsTypes.add_management_note,
    label: 'Add Management Note',
    description: 'Add a note to the order for management purposes',
    icon: <CiStickyNote className={successClasses} />,
    actionCategory: ActionCategory.general,
    allowedToBeCalledWhenOrder: new Set<number>(
      Object.values(ORDER_STATUS).map((status) => status as number),
    ),
    callback: async ({
      order_id,
      note,
    }: {
      order_id: number;
      note: string;
    }) => {
      return await ManagementOrdersRepository.editOrderNotes(order_id, note);
    },
  },
  // Shipping
  [ManagementOrderActionsTypes.collect_shipment_from_captain]: {
    action: ManagementOrderActionsTypes.collect_shipment_from_captain,
    label: 'Collect Shipment From Captain',
    description: 'Collect shipment from the captain',
    icon: <GoPackageDependents className={successClasses} />,
    actionCategory: ActionCategory.shipping,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__in_transit__to_silal,
      ORDER_STATUS.ship__in_transit__failed_dropoff_to_customer,
      ORDER_STATUS.return__in_transit__to_silal,
      ORDER_STATUS.return__in_transit__failed_dropoff_to_store,
    ]),
    callback: async ({ shipment_id }: { shipment_id: number }) => {
      return DeliveryRepository.collectShipmentToSilalAddress({
        shipment_id,
      });
    },
  },
  [ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff]: {
    action: ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff,
    label: 'Mark Shipment As Failed Dropoff',
    description: 'Mark the shipment as failed dropoff',
    icon: <CiBookmarkCheck className={successClasses} />,
    actionCategory: ActionCategory.shipping,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__in_transit__to_customer,
      ORDER_STATUS.return__in_transit__to_store,
    ]),
    callback: async ({
      shipment_id,
      driver_id,
    }: {
      shipment_id: number;
      driver_id: number;
    }) => {
      return await DeliveryRepository.markShipmentAsFailedDropOff(
        shipment_id,
        driver_id,
      );
    },
  },
  [ManagementOrderActionsTypes.mark_shipment_as_failed_pickup]: {
    action: ManagementOrderActionsTypes.mark_shipment_as_failed_pickup,
    label: 'Mark Shipment As Failed Pickup',
    description: 'Mark the shipment as failed pickup',
    icon: <CiBookmarkRemove className={successClasses} />,
    actionCategory: ActionCategory.shipping,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__in_store__ready,
      ORDER_STATUS.return__with_customer__ready,
    ]),
    callback: async ({
      shipment_id,
      driver_id,
    }: {
      shipment_id: number;
      driver_id: number;
    }) => {
      return await DeliveryRepository.markShipmentAsFailedPickup(
        shipment_id,
        driver_id,
      );
    },
  },
  // Reschedule
  [ManagementOrderActionsTypes.reschedule_return_to_store]: {
    action: ManagementOrderActionsTypes.reschedule_return_to_store,
    label: 'Reschedule Return To Store',
    description: 'In case the return needs to be rescheduled to the store.',
    icon: <RiCalendarScheduleLine className={secondaryClasses} />,
    actionCategory: ActionCategory.reschedule,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.return__at_silal__waiting_schedule,
    ]),
    callback: async ({
      orderId,
      shift_id,
      packages,
    }: {
      orderId: number;
      shift_id: number;
      packages: Package[];
    }) => {
      return await ManagementOrdersRepository.scheduleReturnToStore({
        orderId,
        shift_id,
        packages,
      });
    },
  },
  [ManagementOrderActionsTypes.reschedule_silal_return]: {
    action: ManagementOrderActionsTypes.reschedule_silal_return,
    label: 'Reschedule Silal Return',
    description:
      "Reschedule the return to Silal in case the first return pickup didn't work.",
    icon: <IoCalendarOutline className={secondaryClasses} />,
    actionCategory: ActionCategory.reschedule,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.delivered,
      ORDER_STATUS.return__with_customer__waiting_self_dropoff,
    ]),
    callback: async ({
      order_id,
      shift_id,
      packages,
    }: {
      order_id: number;
      shift_id: number;
      packages: Package[];
    }) => {
      return await ManagementOrdersRepository.scheduleSilalReturn(
        order_id,
        shift_id,
        packages,
      );
    },
  },
  [ManagementOrderActionsTypes.update_order_dropoff_shift]: {
    action: ManagementOrderActionsTypes.update_order_dropoff_shift,
    label: 'Update Order Dropoff Shift',
    description:
      'Change the order dropoff shift after contacting the relevant parties.',
    icon: <GrScheduleNew className={secondaryClasses} />,
    actionCategory: ActionCategory.reschedule,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__at_silal__waiting_dropoff_reschedule,
    ]),
    callback: async ({
      order_id,
      new_dropoff_shift_id,
    }: {
      order_id: number;
      new_dropoff_shift_id: number;
    }) => {
      return await ManagementOrdersRepository.updateShipmentDropoffShift(
        order_id,
        new_dropoff_shift_id,
      );
    },
  },
  [ManagementOrderActionsTypes.update_pickup_dropoff_shift]: {
    action: ManagementOrderActionsTypes.update_pickup_dropoff_shift,
    label: 'Update Pickup / Dropoff Shift',
    description:
      'Change the pickup or dropoff shift according to the customer needs.',
    icon: <GrSchedules className={secondaryClasses} />,
    actionCategory: ActionCategory.reschedule,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__in_store__waiting_pickup_reschedule,
    ]),
    callback: async ({
      order_id,
      new_pickup_shift_id,
      new_dropoff_shift_id,
      packages,
    }: {
      order_id: number;
      new_pickup_shift_id: number;
      new_dropoff_shift_id?: number;
      packages?: Package[];
    }) => {
      return await ManagementOrdersRepository.updateOrderPickupAndDropoffShifts(
        order_id,
        new_pickup_shift_id,
        new_dropoff_shift_id,
        packages,
      );
    },
  },
  // Return
  [ManagementOrderActionsTypes.return_undelivered_order]: {
    action: ManagementOrderActionsTypes.return_undelivered_order,
    label: 'Return Undelivered Order',
    description: 'Return the undelivered order',
    icon: <TbTruckReturn className={warningClasses} />,
    actionCategory: ActionCategory.return,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.ship__at_silal__ready,
      ORDER_STATUS.ship__at_silal__waiting_dropoff_reschedule,
    ]),
    callback: async ({ order_id }: { order_id: number }) => {
      return await ManagementOrdersRepository.returnUndeliveredOrder(order_id);
    },
  },
  [ManagementOrderActionsTypes.initiate_customer_return]: {
    action: ManagementOrderActionsTypes.initiate_customer_return,
    label: 'Initiate Customer Return',
    description: 'Initiate the customer return',
    icon: <FaRegHandBackFist className={warningClasses} />,
    actionCategory: ActionCategory.return,
    allowedToBeCalledWhenOrder: new Set<number>([ORDER_STATUS.delivered]),
    callback: async ({ order_id }: { order_id: number }) => {
      return await ManagementOrdersRepository.initiateSelfReturn(order_id);
    },
  },
  [ManagementOrderActionsTypes.collect_return_from_customer]: {
    action: ManagementOrderActionsTypes.collect_return_from_customer,
    label: 'Collect Return From Customer',
    description: 'Collect the return from the customer',
    icon: <GoPackageDependencies className={warningClasses} />,
    actionCategory: ActionCategory.return,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.return__with_customer__waiting_self_dropoff,
    ]),
    callback: async ({ order_id }: { order_id: number }) => {
      return await ManagementOrdersRepository.collectReturnFromCustomer(
        order_id,
      );
    },
  },
  [ManagementOrderActionsTypes.mark_return_as_inspected]: {
    action: ManagementOrderActionsTypes.mark_return_as_inspected,
    label: 'Mark Return As Inspected',
    description: 'Mark the return as inspected',
    icon: <ImCheckboxChecked className={warningClasses} />,
    actionCategory: ActionCategory.return,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.return__at_silal__waiting_inspection,
    ]),
    callback: async ({ order_id }: { order_id: number }) => {
      return await ManagementOrdersRepository.markOrderAsInspected(order_id);
    },
  },
  [ManagementOrderActionsTypes.order_return_rejected_at_silal]: {
    action: ManagementOrderActionsTypes.order_return_rejected_at_silal,
    label: 'Reject Order Return At Silal',
    description: 'Reject the order return at silal',
    icon: <TiCancel className={warningClasses} />,
    actionCategory: ActionCategory.return,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.return__at_silal__waiting_inspection,
    ]),
    callback: async ({ order_id }: { order_id: number }) => {
      return await ManagementOrdersRepository.rejectOrderReturnAtSilal(
        order_id,
      );
    },
  },
  // Cancel && Refund
  [ManagementOrderActionsTypes.markAsReturning]: {
    action: ManagementOrderActionsTypes.markAsReturning,
    label: 'Mark As Returning',
    description:
      'Mark the order as on its way back to Silal in case customer cancels the order before delivery.',
    icon: <TiArrowBackOutline className={dangerIconClasses} />,
    actionCategory: ActionCategory.dangerZone,
    allowedToBeCalledWhenOrder: new Set<number>(
      Object.values(ORDER_STATUS).map((status) => status as number),
    ),
    callback: async () => {
      return await Promise.resolve();
    },
  },
  [ManagementOrderActionsTypes.cancelOrder]: {
    action: ManagementOrderActionsTypes.cancelOrder,
    label: 'Cancel Order',
    description: 'Cancel the order and notify the customer.',
    icon: <IoClose className={dangerIconClasses} />,

    actionCategory: ActionCategory.dangerZone,
    allowedToBeCalledWhenOrder: new Set<number>([
      ORDER_STATUS.requested,
      ORDER_STATUS.accepted,
      ORDER_STATUS.ship__in_store__ready,
      ORDER_STATUS.ship__in_store__waiting_pickup_reschedule,
    ]),
    callback: async ({ order_id }: { order_id: number }) => {
      return ManagementOrdersRepository.cancelOrder(order_id);
    },
  },
  [ManagementOrderActionsTypes.refundOrder]: {
    action: ManagementOrderActionsTypes.refundOrder,
    label: 'Refund Order',
    description:
      'Refund the order and choose how and where to return the money.',
    icon: <TbCreditCardRefund className={dangerIconClasses} />,
    actionCategory: ActionCategory.dangerZone,
    allowedToBeCalledWhenOrder: new Set<number>(
      Object.values(ORDER_STATUS).map((status) => status as number),
    ),
    callback: async () => {
      return;
    },
  },
};

export function CanManagementDoThisWhenOrderIsThat(
  action: string,
  orderStatus: number,
) {
  const that =
    ManagementOrderActions[action as unknown as ManagementOrderActionsTypes];
  if (
    that.action === ManagementOrderActionsTypes.add_management_note ||
    that.action === ManagementOrderActionsTypes.refundOrder
  ) {
    return true;
  }
  return that.allowedToBeCalledWhenOrder.has(orderStatus);
}

export function getDisabledKeys(orderStatus: number) {
  const disabledKeys: string[] = [];
  for (const action in ManagementOrderActions) {
    if (!CanManagementDoThisWhenOrderIsThat(action, orderStatus)) {
      disabledKeys.push(action);
    }
  }
  return disabledKeys;
}

export function getRelevantOrderActionsPopupsMessages({
  action,
  id,
}: {
  action: ManagementOrderActionsTypes;
  id: number;
}) {
  switch (action) {
    // General
    case ManagementOrderActionsTypes.add_management_note:
      return {
        headerTitle: `Add Note to Order #${id}`,
        bodyText: '',
        inputOneLabel: 'Note',
        inputOnePlaceholder: 'Type your note here...',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Add Note',
      };
    // Shipping
    case ManagementOrderActionsTypes.collect_shipment_from_captain:
      return {
        headerTitle: `Collect Shipment from Captain?`,
        bodyText: '',
        inputOneLabel: 'Shipment ID',
        inputOnePlaceholder: '#1234567890',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Collect Shipment',
      };
    case ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff:
      return {
        headerTitle: `Mark Shipment as Failed Dropoff?`,
        bodyText: '',
        inputOneLabel: 'Shipment ID',
        inputOnePlaceholder: '#1234567890',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Mark as Failed Dropoff',
      };
    case ManagementOrderActionsTypes.mark_shipment_as_failed_pickup:
      return {
        headerTitle: `Mark Shipment as Failed Pickup?`,
        bodyText: '',
        inputOneLabel: 'Shipment ID',
        inputOnePlaceholder: '#1234567890',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Mark as Failed Pickup',
      };
    // Reschedule
    case ManagementOrderActionsTypes.reschedule_return_to_store:
      return {
        headerTitle: `Schedule Return to Store for Order #${id}`,
      };
    case ManagementOrderActionsTypes.reschedule_silal_return:
      return {
        headerTitle: `Reschedule Silal Return Pickup for Order #${id}?`,
      };
    case ManagementOrderActionsTypes.update_order_dropoff_shift:
      return {
        headerTitle: `Update Order Dropoff Shift for Order #${id}?`,
      };
    case ManagementOrderActionsTypes.update_pickup_dropoff_shift:
      return {
        headerTitle: `Update Pickup and/or Dropoff Shift for Order #${id}?`,
      };
    // Return
    case ManagementOrderActionsTypes.return_undelivered_order:
      return {
        headerTitle: `Return Undelivered Order #${id}?`,
        bodyText:
          'Return order and mark it as undelivered, in case the customer cancells the order before delivery.',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Return Undelivered Order',
      };
    case ManagementOrderActionsTypes.initiate_customer_return:
      return {
        headerTitle: `Initiate Self-Return for Order #${id}?`,
        bodyText:
          'This will mark the order as waiting to be returned by the customer to Silal office.',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Initiate Self-Return',
      };
    case ManagementOrderActionsTypes.collect_return_from_customer:
      return {
        headerTitle: `Collect Return From Customer?`,
        bodyText:
          'Collect return from customer after validating all the packages in the order.',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Collect Order',
      };
    case ManagementOrderActionsTypes.mark_return_as_inspected:
      return {
        headerTitle: `Mark Items of Order #${id} as Inspected?`,
        bodyText:
          'This will confirm that all items returned by customer are in a good situation and can be returned to store.',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Mark as Inspected',
      };
    case ManagementOrderActionsTypes.order_return_rejected_at_silal:
      return {
        headerTitle: `Reject Return of Order #${id}?`,
        bodyText:
          'Reject the customer return request after inspecting the items or not getting an approval from the store. This action cannot be undone!',
        buttonOneText: 'Cancel',
        buttonTwoText: 'Reject Return',
      };
    // Cancel && Refund
    case ManagementOrderActionsTypes.markAsReturning:
      return {
        headerTitle: `Mark Order #${id} as Returning?`,
        bodyText:
          'Are you sure you want to mark this order as returning to Silal? this action cannot be undone!',
        buttonOneText: 'Back',
        buttonTwoText: 'Mark as Returning',
      };
    case ManagementOrderActionsTypes.cancelOrder:
      return {
        headerTitle: `Cancel Order #${id}?`,
        bodyText:
          'Are you sure you want to cancel this order? this action cannot be undone!',
        buttonOneText: 'Back',
        buttonTwoText: 'Cancel Order',
      };
    case ManagementOrderActionsTypes.refundOrder:
      return {
        headerTitle: `Refund Order #${id}?`,
        bodyText:
          'Are you sure you want to refund this order? this action cannot be undone!',
        buttonOneText: 'Back',
        buttonTwoText: 'Refund Order',
      };
  }
}

export function getOrderItemsTableNumbers(tableData: any) {
  let returnedItems = 0;
  let refundAmount = 0;
  let itemsInOrder = 0;

  tableData.forEach((record: any) => {
    itemsInOrder += record.quantity;
    returnedItems += record.return_quantity;
    refundAmount += record.return_quantity * parseFloat(record.unit_price);
  });
  return {
    itemsInOrder,
    returnedItems,
    refundAmount,
  };
}
