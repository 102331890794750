import { Attribute } from 'data/types/attributes';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';

export function buildAttributeKey(attribute: Attribute) {
  const type =
    attribute.type === AttributeType.BOOLEAN
      ? 'boolean'
      : attribute.type === AttributeType.NUMERIC
        ? 'numeric'
        : 'select';
  if (attribute.type === AttributeType.SELECT) {
    return `silal_attr__${type}__en__${attribute.id}`;
  }
  return `silal_attr__${type}__${attribute.id}`;
}
