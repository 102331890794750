import { call, put, takeLatest } from 'redux-saga/effects';
import {
  GET_BANNERS,
  CREATE_BANNERS,
  DELETE_BANNER,
  PAUSE_BANNER,
  UPDATE_BANNERS,
  actionBannerSuccess,
  actionGetBanners,
} from './banners_actions';
import BannersRepository from 'data/repositories/banners_repository';
import { Banner } from 'data/types/banners';

export function* sagaGetBanners() {
  function* getBanners() {
    const payload: {
      banners: Banner[];
    } = {
      banners: [],
    };
    const res: Banner[] = yield call(BannersRepository.getBanners);
    if (!res) return false;
    payload.banners = res;
    yield put(actionBannerSuccess(payload));
  }
  yield takeLatest(GET_BANNERS, getBanners);
}

export function* sagaCreateBanner() {
  function* createBanner(action: any) {
    const { data } = action.payload;
    const res: Banner = yield call(BannersRepository.createBanner, data);
    if (!res) return false;
    action.payload.success();
    yield put(actionGetBanners());
  }
  yield takeLatest(CREATE_BANNERS, createBanner);
}

export function* sagaUpdateBanner() {
  function* updateBanner(action: any) {
    const payload = action.payload.data;
    const res: Banner = yield call(BannersRepository.updateBanner, payload);
    if (!res) return false;
    action.payload.success(payload);
    yield put(actionGetBanners());
  }
  yield takeLatest(UPDATE_BANNERS, updateBanner);
}

export function* sagaDeleteBanner() {
  function* deleteBanner(action: any) {
    const res: Banner = yield call(
      BannersRepository.deleteBanner,
      action.payload.data.id,
    );
    if (!res) return false;
    action.payload.success();
    yield put(actionGetBanners());
  }
  yield takeLatest(DELETE_BANNER, deleteBanner);
}

export function* sagaPauseBanner() {
  function* pauseBanner(action: any) {
    const res: Banner = yield call(
      BannersRepository.pauseBanner,
      action.payload.data,
    );
    if (!res) return false;
    action.payload.success();
    yield put(actionGetBanners());
  }
  yield takeLatest(PAUSE_BANNER, pauseBanner);
}
