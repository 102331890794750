import { City, Town } from 'silal_app_base_react/src/data/types/address';

export const ADD_CITY = 'ADD_CITY';
export const GET_CITIES = 'GET_CITIES';
export const UPDATE_CITY = 'UPDATE_CITY';
export const DELETE_CITY = 'DELETE_CITY';
export const SET_CITIES = 'SET_CITIES';

export const ADD_TOWN = 'ADD_TOWN';
export const GET_TOWNS = 'GET_TOWNS';
export const UPDATE_TOWN = 'UPDATE_TOWN';
export const DELETE_TOWN = 'DELETE_TOWN';
export const SET_TOWNS = 'SET_TOWNS';

export const SET_REGION = 'SET_REGION';
export const SET_CITY = 'SET_CITY';

export const LOGOUT = 'LOGOUT';

export const actionSetRegion = (payload: any) => ({
  type: SET_REGION,
  payload,
});
export const actionSetCity = (payload: any) => ({
  type: SET_CITY,
  payload,
});
export const actionSetCities = (payload: City[]) => ({
  type: SET_CITIES,
  payload,
});
export const actionSetTowns = (payload: Town[]) => ({
  type: SET_TOWNS,
  payload,
});

export const actionGetCities = (payload: any) => ({
  type: GET_CITIES,
  payload,
});
export const actionGetTowns = (payload: any) => ({
  type: GET_TOWNS,
  payload,
});

export const actionUpdateCity = (payload: City) => ({
  type: UPDATE_CITY,
  payload,
});
export const actionUpdateTown = (payload: Town) => ({
  type: UPDATE_TOWN,
  payload,
});

export const actionAddCity = (payload: City) => ({
  type: ADD_CITY,
  payload,
});
export const actionAddTown = (payload: Town) => ({
  type: ADD_TOWN,
  payload,
});

export const actionDeleteCity = (payload: City) => ({
  type: DELETE_CITY,
  payload,
});
export const actionDeleteTown = (payload: Town) => ({
  type: DELETE_TOWN,
  payload,
});

export const actionLogoutLocations = () => ({
  type: LOGOUT,
});
