import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Heading from 'components/headings/main_heading';
import RoutingScheduleDayRow from './components/routing_schedule_day_row';
import { actionGetShiftsInCity } from 'store/shifts/shifts_actions';
import { useHorizontalScroll } from '../../../core/hooks/use_horizontal_scroll';
import Wrapper from './routing_schedule.styled';
import { ShiftsAddOrChoosePopup } from './modals/shifts_modal';
import { RootState } from 'data/types/state';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import {
  changeShiftsTimezone,
  getFilteredShifts,
} from 'silal_app_base_react/src/utils/functions/shifts_functions';
import { DatePicker } from 'antd';

const DAYS_TO_SHOW = 14;

function RoutingSchedule() {
  const dispatch = useDispatch();
  const {
    elRef,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
  }: {
    elRef: any;
    handleMouseDown: any;
    handleMouseUp: any;
    handleMouseMove: any;
  } = useHorizontalScroll();
  const { shifts = [] } = useSelector((state: RootState) => state.Shifts);

  const [popup_time, setPopupTime] = useState(0);
  const [modal, setModal] = useState(false);
  const [active_date, setActiveDate] = useState(new Date());
  const [days_count, setDaysCount] = useState(45);
  const [shift_date, setShiftDate] = useState(
    moment(new Date()).format('YYYY-M-d'),
  );
  const [timezonedShites, setTimezonedShites] = useState<Shift[]>([]);

  useEffect(() => {
    dispatch(actionGetShiftsInCity());
  }, []);

  useEffect(() => {
    setTimezonedShites(changeShiftsTimezone(shifts));
  }, [shifts]);

  useEffect(() => {
    getFilteredShifts(timezonedShites, moment(active_date).toDate());
  }, []);

  useEffect(() => {
    let interval: any = null;

    if (popup_time > 0) {
      interval = setInterval(() => {
        setPopupTime(popup_time - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [popup_time]);

  const handleScrollEnd = () => {
    if (elRef?.current?.scrollLeft !== undefined) {
      if (
        Math.abs(
          elRef?.current?.scrollLeft -
            (elRef?.current?.scrollWidth - elRef?.current?.clientWidth),
        ) < 20
      ) {
        setDaysCount(days_count + 13);
      }
    }
  };

  const show14Days = () => {
    const daysOfYear: Date[] = [];
    for (
      let d = new Date();
      d <= moment(active_date).add(days_count, 'days').toDate();
      d.setDate(d.getDate() + 1)
    ) {
      daysOfYear.push(new Date(d));
    }

    return daysOfYear.map((x, index) => {
      return (
        <div // <Icon
          key={index}
          className={`wrap center ${
            moment(active_date).isSame(moment(x), 'date') && 'active'
          }`}
          onClick={() => setActiveDate(x)}
        >
          <p className="day_name">{moment(x).format('ll').substring(0, 3)}</p>
          <p className="day_number">{moment(x).format('D')}</p>
        </div>
      );
    });
  };

  const weekDaysSchedule = (date: Date) => {
    const days: {
      full_date: string;
      name: string;
      number: string;
      shifts: Shift[];
    }[] = [];
    for (
      let d = moment(date).toDate();
      d <= moment(date).add(DAYS_TO_SHOW, 'days').toDate();
      d.setDate(d.getDate() + 1)
    ) {
      const day = moment(d).format('D.MM');
      days.push({
        full_date: moment(d).format(),
        name: moment(d).format('LLLL').split(',')[0] + ' ' + day,
        number: day,
        shifts: getFilteredShifts(timezonedShites, d),
      });
    }
    return days.map((x, index) => {
      return (
        <RoutingScheduleDayRow
          key={index}
          day={x}
          setModal={setModal}
          setShiftDate={setShiftDate}
        />
      );
    });
  };

  return (
    <Wrapper>
      <div className="schedule bg-white">
        <div className="d-flex justify-content-between">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Heading
              text={`${moment(active_date).format('MMMM')} ${moment(
                active_date,
              ).format(
                'D',
              )}th - ${moment(active_date).add(30, 'days').format('D')}th`}
            />
            <DatePicker
              style={{
                marginLeft: '10px',
              }}
              onChange={(date) => {
                if (!date) return;
                setActiveDate(date.toDate());
              }}
            />
          </div>
          <p className="caption">Showing {DAYS_TO_SHOW} days at a time</p>
        </div>
        <div // <Icon
          className="d-flex border-bottom days mt-2 scrollable"
          ref={elRef}
          onScroll={handleScrollEnd}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          {show14Days()}
        </div>
        <div className="d-flex-wrap justify-content-between">
          {weekDaysSchedule(active_date)}
        </div>
      </div>
      <ShiftsAddOrChoosePopup
        modal={modal}
        closeModal={() => setModal(false)}
        date={moment(shift_date).toISOString()}
        setDate={setShiftDate}
        getFilteredShifts={(day_number: string) =>
          getFilteredShifts(timezonedShites, new Date(day_number))
        }
        allShifts={timezonedShites}
      />
    </Wrapper>
  );
}

export default RoutingSchedule;
