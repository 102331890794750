import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import SellerProfileToprow from './components/top_row';
import { useEffect, useState } from 'react';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { StoreBrief } from 'silal_app_base_react/src/data/types/stores';
import Spinner from 'silal_app_base_react/src/components/spinner';

export default function StoreProfileParentRouteComponent() {
  const { storeId } = useParams<{ storeId: string }>();

  const [store, setStore] = useState<StoreBrief | null>(null);

  useEffect(() => {
    const fetchStoreData = async () => {
      const res = await SolrRepository.getStores();
      if (!res) return;
      const filteredStore = res?.find((store) => store.id === storeId);
      setStore(filteredStore || null);
    };

    fetchStoreData();
  }, [storeId]);

  return (
    <div>
      {store ? (
        <div>
          <SellerProfileToprow store={store} />
          <Outlet context={store} />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
}
