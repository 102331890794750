import { Wrapper } from './main_heading.styled';

type MainHeadingProps = {
  className?: string;
  style?: React.CSSProperties;
  text: string;
};

export default function MainHeading({
  className,
  style,
  text,
}: MainHeadingProps) {
  return (
    <Wrapper>
      <p className={className} style={style}>
        {text}
      </p>
    </Wrapper>
  );
}
