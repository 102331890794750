import { NavLink } from 'react-router-dom';
import Wrapper from './top_row.styled';
import { CaptainUserInfo } from 'silal_app_base_react/src/data/types/users';

function DriverProfileToprow({ captain }: { captain: CaptainUserInfo }) {
  const DriverProfileNavData = [
    {
      id: 'f5343',
      name: 'Profile',
      pathName: 'profile',
    },
    // {
    //   id: 'yh55',
    //   name: 'Orders',
    //   pathName: 'orders',
    // },
    {
      id: '8g8gd',
      name: 'Ledger',
      pathName: 'ledger',
    },
    // {
    //   id: '9gle3',
    //   name: 'Schedule',
    //   pathName: 'schedules',
    // },
  ];
  return (
    <Wrapper>
      <div>
        <div className="row">
          <div className="top-row">
            <div className="customer-name-id poppins-font">
              <h2>{captain.first_name + ' ' + captain.last_name}</h2>
            </div>
          </div>
          <div className="top-row-buttons poppins-font">
            {DriverProfileNavData.map((nav) => (
              <NavLink
                key={nav.id}
                to={nav.pathName}
                className={(info) => (info.isActive ? 'active' : '')}
                end
              >
                {nav.name}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default DriverProfileToprow;
