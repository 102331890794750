import { Banner } from 'data/types/banners';

export const GET_BANNERS = 'GET_BANNERS';
export const BANNERS_SUCCESS = 'BANNERS_SUCCESS';
export const CREATE_BANNERS = 'CREATE_BANNERS';
export const UPDATE_BANNERS = 'UPDATE_BANNERS';
export const DELETE_BANNER = 'DELETE_BANNER';
export const PAUSE_BANNER = 'PAUSE_BANNER';
export const LOGOUT = 'LOGOUT';

export const actionGetBanners = () => ({
  type: GET_BANNERS,
});

export const actionBannerSuccess = (payload: { banners: Banner[] }) => ({
  type: BANNERS_SUCCESS,
  payload,
});

export const actionCreateBanner = (payload: {
  data: any;
  success: () => void;
}) => ({
  type: CREATE_BANNERS,
  payload,
});

export const actionUpdateBanner = (payload: {
  data: any;
  success: () => void;
}) => ({
  type: UPDATE_BANNERS,
  payload,
});

export const actionDeleteBanner = (payload: {
  data: Banner;
  success: () => void;
}) => ({
  type: DELETE_BANNER,
  payload,
});

export const actionPauseBanner = (payload: {
  data: Banner;
  success: () => void;
}) => ({
  type: PAUSE_BANNER,
  payload,
});

export const actionLogoutBanners = () => ({
  type: LOGOUT,
});
