import Wrapper from './radio_button.styled';

type RadioInputProps = {
  name?: string;
  checked?: boolean;
  title?: string;
  onChange?: () => void;
  disabled?: boolean;
};

const RadioInput = ({
  name,
  title,
  checked,
  onChange,
  disabled,
}: RadioInputProps) => {
  return (
    <Wrapper className="container">
      <input
        disabled={disabled ?? false}
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="checkmark" />
      {title}
    </Wrapper>
  );
};

export default RadioInput;
