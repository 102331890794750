import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import SearchBox from 'components/search_box';
import Button from 'silal_app_base_react/src/components/buttons';
import Checkbox from 'silal_app_base_react/src/components/checkbox';
import Customselect, {
  Option,
  OptionWithObj,
} from 'silal_app_base_react/src/components/custom_select';
import Heading from 'components/headings/main_heading';
import SubHeading from 'components/headings/sub_heading';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import { Wrapper } from './review_attributes.styled';
import { ManagementCategory } from 'data/types/categories';
import { Attribute, SelectAttribute } from 'data/types/attributes';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import SharedRepository from 'silal_app_base_react/src/data/repositories/shared_repository';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import MainHeading from 'components/headings/main_heading';
import backarrow from 'assets/images/backarrow.svg';
import { buildAttributeKey } from '../functions/review_attributes_fucntions';
import { Select } from 'antd';
import CategoriesRepository from 'data/repositories/categories_repository';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';

type SolrResponseDoc = {
  id: string;
  listing_id: number;
  name_en: string;
  [key: string]: any;
};

function ReviewAttributes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  const [
    selectedCategoryAttributesOptions,
    setSelectedCategoryAttributesOptions,
  ] = useState<OptionWithObj[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<ManagementCategory>();
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>();
  const [attrKey, setAttrKey] = useState<string>('');

  const [listings, setListings] = useState<SolrResponseDoc[]>([]);
  const [listingsWithNull, setListingsWithNull] = useState<SolrResponseDoc[]>(
    [],
  );

  const [updateSelectedListings, setUpdateSelectedListings] = useState<
    SolrResponseDoc[]
  >([]);
  const [bulkSelectedListings, setBulkSelectedListings] = useState<
    SolrResponseDoc[]
  >([]);

  const [checkAllForUpdate, setCheckAllForUpdate] = useState<boolean>(false);
  const [checkAllForBulk, setCheckAllForBulk] = useState<boolean>(false);

  const [showNullOnly, setShowNullOnly] = useState<boolean>(false);

  const [bulkValue, setBulkValue] = useState<string>();

  useEffect(() => {
    dispatch(actionGetCategoriesMinimalList());
  }, []);

  useEffect(() => {
    async function fetchCatgeoryListings() {
      await SolrRepository.getListingsOfCategoryAndSubcategories(
        selectedCategory!.id.toString(),
      ).then((res) => {
        if (!res) return;
        setListings(res);
      });
    }
    if (selectedCategory) {
      fetchCatgeoryListings();
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedAttribute) {
      setAttrKey(buildAttributeKey(selectedAttribute));
      setListingsWithNull(listings.filter((i: any) => !i[attrKey]));
    }
  }, [selectedAttribute, listings]);

  const renderTypeFormat = (listing: any, bulkValueOnChange: any = null) => {
    // Select
    if (selectedAttribute?.type === AttributeType.SELECT) {
      return (
        <Select
          value={listing[attrKey]}
          options={
            (selectedAttribute as SelectAttribute)?.official_values_en?.map(
              (i: string, index: number) =>
                ({
                  label:
                    i +
                    ' - ' +
                    (selectedAttribute as SelectAttribute).official_values_ar[
                      index
                    ],
                  value: i,
                }) as OptionWithObj,
            ) || []
          }
          style={{
            width: '150px',
          }}
          onChange={(e: any) => {
            if (bulkValueOnChange) {
              bulkValueOnChange(e);
            } else {
              setListings(
                listings?.map((i: any) => {
                  if (listing.listing_id === i.listing_id) {
                    i[attrKey] = e;
                  }
                  return i;
                }),
              );
            }
          }}
        >
          {(selectedAttribute as SelectAttribute)?.official_values_en?.map(
            (val, index) => {
              return (
                <option key={index} value={val}>
                  {val}
                </option>
              );
            },
          )}
        </Select>
      );
    }
    // Boolean
    if (selectedAttribute?.type === AttributeType.BOOLEAN) {
      return (
        <Checkbox
          title=""
          name="rating-checkbox"
          mr="20px"
          checked={listing[attrKey]}
          onChange={(e: any) => {
            if (bulkValueOnChange) {
              bulkValueOnChange(e.target.checked);
            } else {
              setListings(
                listings?.map((i: any) => {
                  if (listing.listing_id === i.listing_id) {
                    i[attrKey] = e.target.checked;
                  }
                  return i;
                }),
              );
            }
          }}
        />
      );
    }
    // Numeric
    return (
      <input
        title="Value"
        type={'number'}
        value={listing[attrKey]}
        style={{
          height: '32px',
          width: '150px',
          margin: '0px',
          minWidth: '0px',
          maxWidth: '100px !important',
          border: '1px solid #D9D9D9',
        }}
        onChange={(e: any) => {
          if (bulkValueOnChange) {
            bulkValueOnChange(e.target.value);
          } else {
            setListings(
              listings?.map((i: any) => {
                if (listing.listing_id === i.listing_id) {
                  i[attrKey] = e.target.value;
                }
                return i;
              }),
            );
          }
        }}
      />
    );
  };

  const columns = [
    {
      name: (
        <Checkbox
          title="Update?"
          name="rating-checkbox"
          mr="20px"
          checked={checkAllForUpdate}
          onChange={(e: any) => {
            setCheckAllForUpdate(e.target.checked);
            if (e.target.checked) {
              setUpdateSelectedListings(listings);
            } else {
              setUpdateSelectedListings([]);
            }
          }}
        />
      ),
      width: '120px',
      selector: (row: SolrResponseDoc) => {
        return (
          <Checkbox
            title={''}
            name="rating-checkbox"
            mr="20px"
            checked={updateSelectedListings.includes(row)}
            onChange={() => {
              if (updateSelectedListings.includes(row)) {
                setUpdateSelectedListings(
                  updateSelectedListings.filter((i) => i !== row),
                );
              } else {
                setUpdateSelectedListings([...updateSelectedListings, row]);
              }
            }}
          />
        );
      },
    },
    {
      name: (
        <Checkbox
          title="Bulk"
          name="rating-checkbox"
          mr="20px"
          checked={checkAllForBulk}
          onChange={(e: any) => {
            setCheckAllForBulk(e.target.checked);
            if (e.target.checked) {
              setBulkSelectedListings(listings);
            } else {
              setBulkSelectedListings([]);
            }
          }}
        />
      ),
      width: '100px',
      selector: (row: SolrResponseDoc) => {
        return (
          <Checkbox
            title={''}
            name="rating-checkbox"
            mr="20px"
            checked={bulkSelectedListings.includes(row)}
            onChange={() => {
              if (bulkSelectedListings.includes(row)) {
                setBulkSelectedListings(
                  bulkSelectedListings.filter((i) => i !== row),
                );
              } else {
                setBulkSelectedListings([...bulkSelectedListings, row]);
              }
            }}
          />
        );
      },
    },
    {
      name: 'Listing ID',
      width: '120px',
      sortable: true,
      sortFunction: (a: SolrResponseDoc, b: SolrResponseDoc) => {
        return a.listing_id - b.listing_id;
      },
      selector: (row: SolrResponseDoc) => {
        return (
          <Link
            to={`/products/listing-details/${row.listing_id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#002733',
              textDecoration: 'underline',
              fontFamily: Fonts.primary,
              fontWeight: 600,
              fontSize: '13px',
            }}
          >
            #{row.listing_id}
          </Link>
        );
      },
    },
    {
      name: 'Listing Name',
      width: '250px',
      selector: (row: SolrResponseDoc) => {
        return <>{row.name_en}</>;
      },
    },
    {
      name: 'Current Value',
      width: '200px',
      selector: (row: any) => {
        if (!selectedAttribute) {
          return <>SELECT ATTR FIRST</>;
        }
        return renderTypeFormat(row);
      },
    },
    {
      name: (
        <>
          <p
            style={{
              fontFamily: Fonts.secondary,
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: '10px',
              textTransform: 'uppercase',
              height: '10px',
              color: 'rgba(0, 39, 51, 0.5)',
            }}
          >
            Null Only({listingsWithNull.length})
          </p>
          <Checkbox
            title={''}
            name="rating-checkbox"
            checked={showNullOnly}
            onChange={() => setShowNullOnly(!showNullOnly)}
          />
        </>
      ),
      width: '100px',
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: '13px',
        fontWeight: 700,
        overflowWrap: 'break-word',
      },
    },
    'headCells:last-child': {
      style: {
        color: 'orange',
      },
    },
  };

  function applyBulkEdit() {
    const updatedListings = listings?.map((i: any) => {
      if (bulkSelectedListings.includes(i)) {
        i[attrKey] = bulkValue;
      }
      return i;
    });
    setListings(updatedListings);
  }

  async function submit() {
    if (!selectedAttribute) {
      toast.warn('Please select an attribute first');
      return;
    }
    if (!updateSelectedListings.length) {
      toast.warn('Please select listings to update');
      return;
    }
    await CategoriesRepository.bulkEditAttributes(
      listings
        .filter((i: any) => updateSelectedListings.includes(i))
        ?.map((i: any) => {
          return {
            listing_id: i.listing_id,
            new_value_en:
              selectedAttribute.type === AttributeType.NUMERIC
                ? parseFloat(i[attrKey])
                : selectedAttribute.type === AttributeType.BOOLEAN
                  ? i[attrKey] === 'true' || i[attrKey] === true
                  : i[attrKey],
          };
        }),
      selectedAttribute?.id,
      selectedAttribute?.type,
    );
  }
  return (
    <Wrapper>
      {' '}
      <div className="d-flex">
        <img
          src={backarrow}
          onClick={() => navigate(-1)}
          alt="arrow"
          style={{ cursor: 'pointer', marginRight: '10px' }}
        />

        <MainHeading text={'Review Attributes Values'} />
      </div>
      <div className="body">
        <div className="description">
          <SubHeading
            text={`We have noticed that ${listingsWithNull.length} items are missing the newly added ${selectedAttribute?.name_en} attribute, please add attribute values to them before continuing.`}
          />
        </div>
        <div className="bg-white">
          <div className="attribute_search_head">
            <div className="heading">
              <Heading text={`Missing Values Table for Attribute:`} />
            </div>
            <div className="attribute_dropdown">
              <Customselect
                value={selectedCategory?.name_en}
                options={
                  minimalCategoriesList?.map(
                    (i) =>
                      ({
                        label: i.id.toString(),
                        value: i.name_en,
                      }) as Option,
                  ) || []
                }
                onChange={async (val: Option) => {
                  if (isNaN(parseInt(val.label))) return;
                  await SharedRepository.getCategoryBrief(
                    parseInt(val.label),
                  ).then((category: any) => {
                    if (!category) return;
                    setSelectedCategory(category);
                    setSelectedCategoryAttributesOptions(
                      selectedCategory?.attributes?.map(
                        (i: Attribute) =>
                          ({
                            label: i,
                            value: i.name_en + ' - ' + i.name_ar,
                          }) as OptionWithObj,
                      ) || [],
                    );
                    setSelectedAttribute(undefined);
                    setAttrKey('');
                  });
                }}
                className="new_select"
                style={{ padding: '15px', width: '100%' }}
                dropdownIcon={<Icon icon="ant-design:caret-down-outlined" />}
              />
            </div>
            <div className="attribute_dropdown">
              {selectedCategoryAttributesOptions.length > 0 && (
                <Customselect
                  value={'Select Attribute'}
                  options={selectedCategoryAttributesOptions || []}
                  onChange={(val: OptionWithObj) => {
                    if (!val.label) return;
                    setSelectedAttribute(val.label);
                    setAttrKey(buildAttributeKey(val.label));
                    setListingsWithNull(
                      listings.filter((i: any) => !i[attrKey]),
                    );
                  }}
                  className="new_select"
                  style={{ padding: '15px', width: '100%' }}
                  dropdownIcon={<Icon icon="ant-design:caret-down-outlined" />}
                />
              )}
            </div>
            <div className="search_bar">
              <SearchBox
                placeholder="TODO.."
                className=""
                type="text"
                searchValue=""
                // onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
          </div>

          <div className="set-value">
            <SubHeading
              text={`If many items share the same values you may do bulk update:`}
            />
            <div className="inputs">
              <div className="title">
                <SubHeading text={`Bulk Value: `} />
              </div>
              {selectedAttribute !== undefined && (
                <div
                  className="value-input"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {renderTypeFormat({ [attrKey]: bulkValue }, setBulkValue)}
                  <div style={{ width: '10px' }} />
                  <Button
                    text={`Apply to selected`}
                    style={{
                      width: '150px',
                      height: '40px',
                      borderRadius: '5px',
                      fontSize: '12px',
                      backgroundColor: Colors.greenMain,
                    }}
                    onClick={() => applyBulkEdit()}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="table">
            <TableStyled>
              <DataTable
                columns={columns as any}
                data={showNullOnly ? listingsWithNull : listings}
                customStyles={customStyles as any}
                pagination
                paginationPerPage={30}
                fixedHeader={true}
                persistTableHead
                // selectableRows
              />
            </TableStyled>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button text={`Submit Changes`} primary onClick={() => submit()} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default ReviewAttributes;

const TableStyled = styled.div`
  .h3 {
    font-size: 25px;
    font-weight: 700;
    color: #002733;
    font-family: ${Fonts.primary}, sans-serif;
    margin: 15px 0px;
  }
  font-family: 'Lato', sans-serif;
  button[data-testid] {
    background: #f2f4f5;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin: auto;
  }
  &.modal-table {
    background: #fafafa;
    max-height: 228px;
    overflow-y: auto;

    padding: 14px;
    border-radius: 8px;
    .rdt_Table {
      padding: 0;
    }
    .rdt_TableHeadRow {
      min-height: 30px;
      height: 30px;
    }
    .rdt_TableRow {
      background: #fafafa;
      min-height: 42px;
    }
  }
  .rdt_Table {
    padding: 20px;
    background: #ffffff;
    border-radius: 5px;
  }
  .rdt_TableHeadRow {
    background: #f2f4f5;
    box-shadow: 0px 1px 1px rgba(0, 39, 51, 0.25);
  }
  .rdt_TableRow {
    border-color: rgba(0, 39, 51, 0.08);
    &:hover {
      background: rgba(90, 179, 168, 0.15);
    }
  }
  .rdt_TableCol {
    font-weight: 700;
    font-size: 13px;
  }
  .rdt_TableCell {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
  }
  .rdt_Pagination {
    justify-content: inherit;
    background-color: #f4f7f8;
    border-top: 0;
    font-size: 15px;
    color: #4c6870;
    button {
      &:disabled {
        pointer-events: none;
      }
      &:hover {
        background-color: #4c6870;
        svg {
          fill: white;
        }
      }
    }
    & > *:last-child {
      margin-left: auto;
    }
  }
  .stock-status {
    padding: 3px 9px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 11px;
    color: white;
    min-height: 15px;
    display: block;
    &.low {
      background: #ffab00;
    }
    &.out {
      background: #ff5630;
    }
    &.bank {
      background: #fffae6;
      color: #ff8b00;
    }
    &.credit {
      background: #e6fcff;
      color: #008da6;
    }
  }
  .action-btn {
    border: none;
    background-color: transparent;
    padding: 0 10px;
    margin: 0 10px;
    &:hover {
      svg,
      path {
        fill: var(--theme-clr);
      }
    }
  }
  .table-product-img {
    height: 44px;
    width: 44px;
    border-radius: 4px;
  }
  .rdt_TableCell {
    &:last-child {
      div {
        overflow: visible !important;
      }
    }
  }
  .dropdown {
    overflow: visible !important;
    button {
      border: none !important;
      background-color: transparent !important;
      padding: 0 10px;
      margin: 0 10px;
      &::after {
        display: none;
      }
    }
  }
  input[type='checkbox'] {
    transform: scale(1.16);
  }
`;
