import { FilterBtnsStyled } from 'silal_app_base_react/src/pages/orders_archive/components/archived_orders_components.styled';
import TransactionsRepository from 'data/repositories/transactions_repository';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { PaymentTab } from '../payments_page';
import { MenuItem, Select } from '@mui/material';
import { Button, DateRangePicker, RangeValue } from '@nextui-org/react';
import { DateValue } from '@internationalized/date';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';

enum UploadAction {
  NONE = 0,
  driversDas = 1,
  storesDas = 2,
  driversPayments = 3,
  storesPayments = 4,
}

type ActionBtnProps = {
  currentDatabase: string;
  ids: number[];
  refreshData: () => void;
  dateRange: RangeValue<DateValue> | undefined;
  setDate: (value: RangeValue<DateValue>) => void;
};
export const ActionBtn = ({
  currentDatabase,
  ids,
  refreshData,
  dateRange,
  setDate,
}: ActionBtnProps) => {
  const [dasFileDrivers, setDasFileDrivers] = useState(null);
  const [dasFileStores, setDasFileStores] = useState(null);

  const [paymentsFileDrivers, setPaymentsFileDrivers] = useState(null);
  const [paymentsFileStores, setPaymentsFileStores] = useState(null);

  const [action, setAction] = useState(UploadAction.NONE);

  const handleDASFileChangeDrivers = (e: any) => {
    setDasFileDrivers(e.target.files[0]);
  };
  const handleDASFileChangeStores = (e: any) => {
    setDasFileStores(e.target.files[0]);
  };

  const handlePaymentsFileChangeDrivers = (e: any) => {
    setPaymentsFileDrivers(e.target.files[0]);
  };
  const handlePaymentsFileChangeStores = (e: any) => {
    setPaymentsFileStores(e.target.files[0]);
  };
  return (
    <FilterBtnsStyled
      style={{
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      <PopupWithConfirmation
        open={action !== UploadAction.NONE}
        onButtonOneClick={() => {
          setAction(UploadAction.NONE);
        }}
        onButtonTwoClick={async () => {
          if (action === UploadAction.driversDas) {
            if (!dasFileDrivers) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', dasFileDrivers!);
            toast.warn('Please wait while we upload your file');
            const res =
              await TransactionsRepository.uploadDriverDasCSV(formData);
            if (!res) return;
            setDasFileDrivers(null);
            refreshData();
          } else if (action === UploadAction.storesDas) {
            if (!dasFileStores) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', dasFileStores);
            const res =
              await TransactionsRepository.uploadStoreDasCSV(formData);
            if (!res) return;
            setDasFileStores(null);
            refreshData();
          } else if (action === UploadAction.driversPayments) {
            if (!paymentsFileDrivers) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', paymentsFileDrivers!);
            const res =
              await TransactionsRepository.uploadDriverPaymentsCSV(formData);
            if (!res) return;
            setPaymentsFileDrivers(null);
            refreshData();
          } else if (action === UploadAction.storesPayments) {
            if (!paymentsFileStores) {
              toast.warn('Please select a file');
              return;
            }
            const formData = new FormData();
            formData.append('csv_file', paymentsFileStores);
            const res =
              await TransactionsRepository.uploadStorePaymentsCSV(formData);
            if (!res) return;
            setPaymentsFileStores(null);
            refreshData();
          }
        }}
        headerTitle={`Upload the file as ${
          action === UploadAction.driversDas
            ? 'Drivers DAS'
            : action === UploadAction.storesDas
              ? 'Stores DAS'
              : action === UploadAction.driversPayments
                ? 'Drivers Payments'
                : 'Stores Payments'
        }?`}
        bodyText="Make sure you're the correct file to the correct database. Once you upload the file, the data will be added to the database and cannot be undone."
        buttonOneText="Cancel"
        buttonTwoText={`Upload`}
        withTimerOfNSeconds={10}
      />
      <DownloadExamples />
      <Button
        variant="flat"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        color="success"
        key="downloadCSV"
        onClick={async () => {
          if (ids.length === 0) {
            toast.warn('Please select at least one item');
            return;
          }
          if (currentDatabase === PaymentTab.STORES) {
            const res =
              await TransactionsRepository.getStoresPaymentReportsCSV(ids);
            if (!res) return;
          } else if (currentDatabase === PaymentTab.DRIVERS) {
            const res =
              await TransactionsRepository.getDriversPaymentReportsCSV(ids);
            if (!res) return;
          }
        }}
      >{`Selected ${
        currentDatabase === PaymentTab.DRIVERS ? 'Captains' : 'Stores'
      } (${ids.length})`}</Button>
      <Button
        variant="flat"
        color="success"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        onClick={async () => {
          await TransactionsRepository.getStoresPaymentReportsCSV([]);
        }}
      >
        All Stores Report
      </Button>
      <Button
        variant="flat"
        color="success"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        onClick={async () => {
          await TransactionsRepository.getDriversPaymentReportsCSV([]);
        }}
      >
        All Captains Report
      </Button>
      {currentDatabase !== PaymentTab.STORES ? (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv,.xlsx"
            onChange={handleDASFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileDrivers ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={dasFileDrivers === null}
            onClick={async () => {
              setAction(UploadAction.driversDas);
            }}
          >
            Captains DAS +
          </Button>
        </div>
      ) : (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handleDASFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: dasFileStores ? 'pointer' : 'not-allowed',
            }}
            // variant={dasFileStores !== null ? "solid" : "outline"}
            isDisabled={dasFileStores === null}
            variant="bordered"
            onClick={async () => {
              setAction(UploadAction.storesDas);
            }}
          >
            Stores DAS +
          </Button>
        </div>
      )}

      {currentDatabase !== PaymentTab.STORES ? (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeDrivers}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileDrivers ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={paymentsFileDrivers === null}
            onClick={async () => {
              setAction(UploadAction.driversPayments);
            }}
          >
            Captains Payments +
          </Button>
        </div>
      ) : (
        <div className="filter">
          <input
            type="file"
            name="file"
            accept=".csv"
            onChange={handlePaymentsFileChangeStores}
            style={{
              marginBottom: '10px',
            }}
          />
          <Button
            style={{
              fontSize: '14px',
              height: '40px',
              cursor: paymentsFileStores ? 'pointer' : 'not-allowed',
            }}
            variant="bordered"
            isDisabled={paymentsFileStores === null}
            onClick={async () => {
              setAction(UploadAction.storesPayments);
            }}
          >
            Stores Payments +
          </Button>
        </div>
      )}
      <div />
      <DateRangePicker
        label="Orders Report Date Range"
        variant="faded"
        className="align-items-center"
        value={dateRange as any}
        onChange={setDate as any}
      />
    </FilterBtnsStyled>
  );
};

export const DownloadExamples = () => {
  const [selectedExample, setSelectedExample] = useState('');

  const examples = [
    {
      label: 'Captains DAS CSV Example',
      headers: ['driver_id', 'das_percentage'],
      filename: 'drivers_das_example.csv',
    },
    {
      label: 'Stores DAS CSV Example',
      headers: ['store_id', 'das_percentage'],
      filename: 'stores_das_example.csv',
    },
    {
      label: 'Captains Payments CSV Example',
      headers: [
        'driver_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'drivers_payments_example.csv',
    },
    {
      label: 'Stores Payments CSV Example',
      headers: [
        'store_id',
        'paid_amount',
        'transaction_id',
        'transaction_date',
      ],
      filename: 'stores_payments_example.csv',
    },
  ];

  const downloadEmptyCsv = () => {
    const example = examples.find((item) => item.label === selectedExample);

    if (example) {
      const { headers, filename } = example;
      const emptyRow = headers.map(() => '').join(',');

      const csvContent = `data:text/csv;charset=utf-8,${headers.join(
        ',',
      )}\n${emptyRow}`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Select
        value={selectedExample}
        onChange={(e) => setSelectedExample(e.target.value)}
        displayEmpty
        style={{
          marginRight: '10px',
          height: '40px',
          fontSize: '14px',
          minWidth: '220px',
          maxWidth: '220px',
        }}
        color="success"
      >
        <MenuItem value="" disabled>
          Select an empty example
        </MenuItem>
        {examples.map((example, index) => (
          <MenuItem key={index} value={example.label}>
            {example.label}
          </MenuItem>
        ))}
      </Select>

      <Button
        variant="flat"
        style={{
          fontSize: '14px',
          height: '40px',
        }}
        // onClick={downloadEmptyCsv}
        onClick={() => downloadEmptyCsv()}
        disabled={!selectedExample}
      >
        Download
      </Button>
    </div>
  );
};
