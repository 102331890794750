import { Card, CardHeader, CardBody } from '@nextui-org/react';
import { Divider } from 'antd';
import { RootState } from 'data/types/state';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CategoryParentsBreadcrumbs } from 'silal_app_base_react/src/components/categories_breadcrubmbs';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import SolrRepository from 'silal_app_base_react/src/data/repositories/solr_repository';
import { SolrBuyerItemsResponse } from 'silal_app_base_react/src/data/types/listings';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';

export const SimilarListingsCard = ({
  listing_name_en,
  listing_name_ar,
  store_id,
}: {
  listing_name_en: string;
  listing_name_ar: string;
  store_id: string;
}) => {
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );
  const [items, setItems] = useState<SolrBuyerItemsResponse[] | null>(null);

  const [image, setImage] = useState<
    SolrBuyerItemsResponse['image_public_url'] | null
  >(null);
  const [currentImage, setCurrentImage] =
    useState<SolrBuyerItemsResponse | null>(null);

  const handleClose = () => {
    setImage(null);
    setCurrentImage(null);
  };

  useEffect(() => {
    async function fetchSolrData() {
      await SolrRepository.searchBuyerQuery({
        query: `name_en:${listing_name_en} OR name_ar:${listing_name_ar}`,
        filter: [`store_id:${store_id}`],
        fields: ['*'],
        limit: 9,
        sortField: 'score',
        offset: 0,
      }).then((data) => {
        if (data) setItems(data);
      });
    }
    fetchSolrData();
  }, [listing_name_ar, listing_name_en, store_id]);
  return (
    <>
      {image && (
        <Lightbox
          mainSrc={image}
          imageTitle={`# ${currentImage?.id}`}
          mainSrcThumbnail={image}
          onCloseRequest={handleClose}
        />
      )}
      <Card
        className="min-h-full my-2"
        style={{
          maxHeight: 'fit-content',
        }}
      >
        <CardHeader
          className="mb-0 pb-0"
          style={{
            color: Colors.primaryText,
            marginBottom: '5px',
            fontFamily: Fonts.primary,
          }}
        >
          Similar Listings Found in Store
        </CardHeader>
        <CardBody>
          {items?.length ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
                gap: '10px',
              }}
            >
              {items.map((item, index) => (
                <Card className="pt-4 pb-2" key={index} shadow="sm">
                  <CardHeader className="pb-0 pt-2 px-4 flex-row items-start gap-2 justify-between">
                    <div>
                      <h6
                        className="font-bold pb-1"
                        style={{
                          fontSize: '0.9rem',
                        }}
                      >
                        {item.name_en}
                      </h6>
                      <h6
                        className="font-bold  pt-1"
                        style={{
                          fontSize: '0.9rem',
                          direction: 'rtl',
                          textAlign: 'right',
                        }}
                      >
                        {item.name_ar}
                      </h6>
                    </div>
                    <ShowImage
                      imageSrc={item.image_public_url}
                      onClick={() => {
                        setImage(item.image_public_url);
                        setCurrentImage(item);
                      }}
                      style={{
                        width: '100%',
                        height: '100px',
                        borderRadius: '10px',
                        objectFit: 'scale-down',
                        border: `1px dashed ${Colors.greenMain}`,
                      }}
                    />
                  </CardHeader>

                  <CardBody className="overflow-visible py-2 flex flex-row">
                    <CategoryParentsBreadcrumbs
                      style={{
                        color: Colors.greenAlternate,
                        fontFamily: Fonts.primary,
                        fontSize: '0.8rem',
                        fontWeight: 500,
                      }}
                      category={
                        minimalCategoriesList.find(
                          (category) => category.id === item.category_id,
                        )!
                      }
                    />
                    <div className="flex  flex-col justify-around">
                      <Link
                        to={`/products/listing-details/${item.id}`}
                        target="_blank"
                        style={{
                          color: Colors.greenMain,
                          fontFamily: Fonts.primary,
                          fontSize: '0.8rem',
                          fontWeight: 500,
                        }}
                      >
                        #{item.id}
                      </Link>
                      <small className="text-default-500">
                        {formatPrice(item.price)}
                      </small>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          ) : (
            <p>No similar listings found</p>
          )}
        </CardBody>
      </Card>
    </>
  );
};
