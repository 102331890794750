import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import Button from 'silal_app_base_react/src/components/buttons';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import { styled } from 'styled-components';

import * as React from 'react';
import ButtonMUI from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
  title,
  subtitle,
  onAgree,
  onDisagree,
}: {
  title: string;
  subtitle: string;
  onAgree: () => void;
  onDisagree: () => void;
}) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          borderRadius: '10px',
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontFamily: Fonts.primary,
            textAlign: 'center',
          }}
        >
          {title}
          <br />
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              fontFamily: Fonts.secondary,
              textAlign: 'center',
            }}
          >
            {subtitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonMUI
            onClick={() => {
              onDisagree();
              handleClose();
            }}
            style={{
              fontFamily: Fonts.secondary,
              textAlign: 'center',
              color: Colors.grey,
            }}
          >
            Cancel
          </ButtonMUI>
          <ButtonMUI
            onClick={() => {
              onAgree();
              handleClose();
            }}
            autoFocus
            style={{
              fontFamily: Fonts.secondary,
              textAlign: 'center',
              color: Colors.tomatoRed,
            }}
          >
            Yes
          </ButtonMUI>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export function DoubleInputPopupModal({
  hideModal,
  changeInput1,
  changeInput2,
  submit,
  title,
  subtitle,
  info1,
  info2,
  buttonText,
  inputText1,
  inputType1 = 'text',
  inputText2,
  inputType2 = 'text',
  inputValue1,
  inputValue2,
  useConfirm = false,
}: {
  hideModal: () => void;
  changeInput1: (value: string) => void;
  changeInput2: (value: string) => void;
  submit: () => void;
  title: string;
  subtitle: string;
  info1: string;
  info2: string;
  buttonText: string;
  inputText1: string;
  inputType1: string;
  inputText2: string;
  inputType2: string;
  inputValue1: string;
  inputValue2: string;
  useConfirm?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  // modalbox animation
  const animate1 = {
    y: [100, 0],
    opacity: [0, 1],
    transition: { duration: 0.5 },
  };

  return (
    <Wrapper>
      <div
        className="modal-container"
        style={{
          zIndex: 1000,
        }}
      >
        <motion.div className="modal-box" animate={animate1}>
          <div
            className="header"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <h3 className="poppins-font mb-2">{title}</h3>
              <h3
                className="lato-font"
                style={{
                  color: Colors.grey,
                }}
              >
                {subtitle}
              </h3>
              <br />
              <h6
                className="lato-font"
                style={{
                  color: Colors.grey,
                }}
              >
                {info1}
              </h6>
              <h6
                className="lato-font"
                style={{
                  color: Colors.grey,
                }}
              >
                {info2}
              </h6>
            </div>
            <button
              onClick={() => {
                changeInput1('');
                changeInput2('');
                hideModal();
              }}
            >
              <Icon icon="akar-icons:cross" />
            </button>
          </div>
          <div className="body">
            <p
              className="lato-font"
              style={{
                marginBottom: '10px',
              }}
            >
              {inputText1}
            </p>
            {inputType1 === 'textarea' ? (
              <textarea
                style={{
                  marginBottom: '10px',
                  padding: '10px',
                  fontSize: '14px',
                  width: '500px',
                  height: '100px',
                  borderColor: Colors.grayLight,
                  borderRadius: '5px',
                  opacity: 1,
                }}
                onChange={(e) => changeInput1(e.target.value)}
                value={inputValue1}
              />
            ) : (
              <input
                type={inputType1}
                style={{
                  marginBottom: '10px',
                  padding: '0px 10px',
                  fontSize: '14px',
                  width: '500px',
                  height: '35px',
                  borderColor: Colors.grayLight,
                  borderRadius: '5px',
                  opacity: 1,
                }}
                onChange={(e) => changeInput1(e.target.value)}
                value={inputValue1}
              />
            )}
            <br />
            <p
              className="lato-font"
              style={{
                marginTop: '20px',
              }}
            >
              {inputText2}
            </p>
            <input
              type={inputType2}
              style={{
                marginBottom: '10px',
                padding: '0px 10px',
                fontSize: '14px',
                width: '500px',
                height: '35px',
                borderColor: Colors.grayLight,
                borderRadius: '5px',
                opacity: 1,
              }}
              onChange={(e) => changeInput2(e.target.value)}
              value={inputValue2}
            />
          </div>
          <div className="footer">
            <Button
              text={buttonText}
              onClick={() => {
                if (useConfirm) {
                  setOpen(true);
                } else {
                  submit();
                }
              }}
            />
          </div>
        </motion.div>
      </div>
      {open && (
        <AlertDialog
          title={`Are you sure you want to ${title}?`}
          subtitle={"You can't undo this action later."}
          onAgree={submit}
          onDisagree={() => {
            setOpen(false);
          }}
        />
      )}
    </Wrapper>
  );
}

export function ThreeButtonsAndInfoPopup({
  hideModal,
  submit1,
  submit2,
  submit3,
  inputTitle,
  title,
  subtitle,
  buttonText1,
  buttonText2,
  buttonText3,
  modalInfo,
}: {
  hideModal: () => void;
  submit1: () => void;
  submit2: () => void;
  submit3: () => void;
  title: string;
  inputTitle?: JSX.Element;
  subtitle: string;
  buttonText1: string;
  buttonText2: string;
  buttonText3: string;
  modalInfo: string;
}) {
  // modalbox animation
  const animate1 = {
    y: [100, 0],
    opacity: [0, 1],
    transition: { duration: 0.5 },
  };

  return (
    <Wrapper>
      <div className="modal-container">
        <motion.div className="modal-box" animate={animate1}>
          <div
            className="header"
            style={{
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <h3 className="poppins-font mb-2">{title}</h3>
              <h3
                className="lato-font"
                style={{
                  color: Colors.grey,
                }}
              >
                {subtitle}
              </h3>
            </div>
            <button onClick={hideModal}>
              <Icon icon="akar-icons:cross" />
            </button>
          </div>
          {inputTitle && <div>{inputTitle}</div>}
          <div
            className="body"
            style={{
              marginBottom: '20px',
            }}
          >
            Email
            <br />
            <p>{modalInfo.split('\n')[1]}</p>
            Password
            <br /> <p>{modalInfo.split('\n')[3]}</p>
          </div>
          <div className="footer">
            <div style={{ display: 'flex' }}>
              <Button text={buttonText1} onClick={submit1} secondaryButton />
              <div style={{ width: '10px' }} />
              <Button text={buttonText2} onClick={submit2} secondaryButton />
            </div>
            <div style={{ height: '10px' }} />
            <Button
              text={buttonText3}
              onClick={submit3}
              style={{
                width: '500px',
              }}
            />
          </div>
        </motion.div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .modal-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(4, 26, 33, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-box {
      padding: 20px;
      border-radius: 5px;
      max-width: 546px;
      width: 100%;
      background: var(--color-light);
      .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 20px;
        h3 {
          font-size: 18px;
          font-weight: 700;
          color: var(--color-dark);
          width: 100%;
          text-align: center;
        }
        button {
          border: none;
          background: transparent;
          font-size: 25px;
          cursor: pointer;
        }
      }
      .body {
        margin-bottom: 80px;
        p {
          margin-bottom: 20px;
          text-align: center;
          font-size: 15px;
          font-weight: 500;
          color: #4c6870;
        }
        .form-control {
          text-align: center;
          input {
            width: 45px;
            height: 55px;
            border: 1px solid #cdcdd0;
            border-radius: 5px;
            text-align: center;
            font-size: 18px;
            padding: 10px;
            opacity: 1;
            outline: none;
            :focus {
              border: 1px solid #36b27d;
            }
            :not(:first-child) {
              margin-left: 10px;
            }
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type='number'] {
            -moz-appearance: textfield;
          }
        }
      }
      .footer {
        text-align: center;
        button {
          max-width: 329px;
          width: 100%;
          border: 1px solid transparent;
          transition: 0.3s ease;
          :hover {
            background: transparent;
            border: 1px solid var(--app-color-dark);
            color: var(--app-color-dark);
          }
        }
      }
    }
  }
`;
