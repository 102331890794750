import { Icon } from '@iconify/react';

import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ShowImage from 'silal_app_base_react/src/components/show_image';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import ApplicationChecklist from '../components/application_checklist';
import { missingInfoStyle } from '../components/request_base_view.styled';
import { WrapperViewDetail } from '../components/request_base_view.styled';
import { ImageType } from 'silal_app_base_react/src/data/types/files';
import { BusinessEditListingRequest } from 'data/types/requests';
import {
  Listing,
  ListingItem,
} from 'silal_app_base_react/src/data/types/listings';
import { AttributeAssociation } from 'silal_app_base_react/src/data/types/listings';
import {
  formatAttributeName,
  formatAttributeValues,
  formatDecimals,
  formatPrice,
  sanitizeHTML,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';
import StoresRepository from 'data/repositories/stores_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { Link } from 'react-router-dom';
import RequestsRepository from 'data/repositories/requests_repository';
import { getUniqueValues } from 'silal_app_base_react/src/pages/listing_profile/screens/listing_info_screen';
import { DoubleInputPopupModal } from 'components/popup/popup_modal';
import { toast } from 'react-toastify';
import CategoriesRepository from 'data/repositories/categories_repository';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { SanitizedHTMLStyle } from 'silal_app_base_react/src/styles/style';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import { actionGetCategoriesMinimalList } from 'silal_app_base_react/src/store/categories/categories_actions';
import { EN_AR_TRANSLATION_SPLIT_CHAR } from 'silal_app_base_react/src/pages/create_listings/constants';
import { CategoryParentsBreadcrumbs } from 'silal_app_base_react/src/components/categories_breadcrubmbs';
import { AbstractCategory } from 'silal_app_base_react/src/data/types/categories';
import { FaPencil } from 'react-icons/fa6';
import { UpdateNameOrDescriptionModal } from 'silal_app_base_react/src/pages/listing_profile/modals/update_name_desc_modal';
import DataEditingRepository from 'data/repositories/data_editing_repository';

type ListingsRequestViewProps = {
  cardData: BusinessEditListingRequest;
  isChecked: boolean;
  setIsChecked: () => void;
};

export default function UpdateListingsRequestView({
  cardData,
  isChecked,
  setIsChecked,
}: ListingsRequestViewProps) {
  const dispatch = useDispatch();
  const [image, setImage] = useState<ImageType | null>();
  const [lists, setList] = useState<number[]>([]);
  const [fieldsNullFlags, setfieldsNullFlags] = useState({
    has_name: false,
    has_second_name: false,
    has_category_name: false,
    has_rich_text_description_en: false,
    has_rich_text_description_ar: false,
    has_images: false,
    has_combinations: false,
    has_attributes: false,
    has_whats_included: false,
    has_weight: false,
    has_width: false,
    has_height: false,
    has_length: false,
    has_item_qualities: false,
  });
  const [same_attributes, setSameAttributes] = useState<string[]>([]);
  const [category, setCategory] = useState<AbstractCategory>();
  const [loaded, setLoaded] = useState(false);
  const [storeInfo, setStoreInfo] = useState<StoreDetails>();
  const [newListing, setNewListing] = useState<any>();
  const [parentListing, setParentListing] = useState<any>();

  const [showAppendAttributeValuesModal, setShowAppendAttributeValuesModal] =
    useState(false);

  const [attributeToUpdate, setAttributeToUpdate] =
    useState<AttributeAssociation>();
  const [arAttributeValue, setArAttributeValue] = useState<string>('');
  const [enAttributeValue, setEnAttributeValue] = useState<string>('');
  const minimalCategoriesList = useSelector(
    (state: RootState) => state.CategoriesBase.minimalCategoriesList,
  );

  useEffect(() => {
    const fetchParentListing = async () => {
      setLoaded(false);
      await RequestsRepository.getFullRequestDetails(cardData.id).then(
        (res: any) => {
          if (!res) return;
          setNewListing(res.new_listing);
          setParentListing(res.parent_listing);
          setLoaded(true);
        },
      );
    };
    fetchParentListing();
  }, [cardData]);

  useEffect(() => {
    const fetchStoreInfo = async () => {
      setLoaded(false);
      await StoresRepository.getStoreDetails(cardData.store_id.toString()).then(
        (res) => {
          if (!res) return;
          setStoreInfo(res);
          setLoaded(true);
          setTimeout(() => {
            setList([0, 1, 2, 3, 4, 5, 6]);
          }, 100);
        },
      );
    };
    fetchStoreInfo();
  }, [cardData?.id]);

  useEffect(() => {
    dispatch(
      actionGetCategoriesMinimalList({
        success: (minimalCategoriesList: AbstractCategory[]) =>
          setCategory(
            minimalCategoriesList.find((c) => c.id === newListing?.category_id),
          ),
      }),
    );
  }, [
    cardData,
    newListing?.category_id,
    newListing?.category_id,
    newListing?.name_en,
    newListing?.name_ar,
  ]);

  useEffect(() => {
    setSameAttributes(
      newListing?.items[0].attributes?.map(
        (i: AttributeAssociation) => i.attr_name_en,
      ),
    );
    const cat = minimalCategoriesList?.find(
      (i) => i.id === newListing?.category_id,
    );
    if (cat) setCategory(cat);
    setfieldsNullFlags({
      has_name: newListing?.name_en ? true : false,
      has_second_name: newListing?.name_ar ? true : false,
      has_category_name: true,
      has_rich_text_description_en: newListing?.rich_text_description_en
        ? true
        : false,
      has_rich_text_description_ar: newListing?.rich_text_description_ar
        ? true
        : false,
      has_images: newListing?.images ? true : false,
      has_combinations: true,
      has_attributes: newListing?.attributes ? true : false,
      has_whats_included: newListing?.whats_included ? true : false,
      has_weight: newListing?.weight ? true : false,
      has_width: newListing?.width ? true : false,
      has_height: newListing?.height ? true : false,
      has_length: newListing?.length ? true : false,
      has_item_qualities: true,
    });
  }, [newListing, minimalCategoriesList, loaded]);

  const toggleCollapse = (number: number) => {
    if (lists.includes(number)) {
      setList(lists.filter((i) => i !== number));
    } else {
      setList([...lists, number]);
    }
  };

  const handleClose = () => setImage(null);
  const openLightBox = (image: ImageType) => {
    setImage(image);
  };

  function getAppropriateItemQualities(listing: Listing) {
    const qualities: string[] = [];
    if (listing?.fragile) qualities.push('Breakable');
    if (listing?.keep_dry) qualities.push('Contain Liquid');
    if (listing?.flammable) qualities.push('Flammable');

    return qualities.length === 0
      ? 'Nothing was specified'
      : qualities.join(', ');
  }

  function renderWithSignifierIfValuesAreDifferent(
    parentValue: string,
    newValue: string,
    html: boolean = false,
  ) {
    return parentValue === newValue ? (
      html ? (
        <SanitizedHTMLStyle
          style={{
            maxHeight: '500px',
            overflow: 'auto',
          }}
          dangerouslySetInnerHTML={sanitizeHTML(parentValue)}
        />
      ) : (
        <p>{parentValue}</p>
      )
    ) : html ? (
      <SanitizedHTMLStyle
        style={{
          maxHeight: '500px',
          overflow: 'auto',
        }}
      >
        <span
          style={{ color: Colors.blueMain }}
          dangerouslySetInnerHTML={sanitizeHTML(parentValue)}
        />
        {'--->'}
        <span
          style={{ color: Colors.greenMain }}
          dangerouslySetInnerHTML={sanitizeHTML(newValue)}
        />
      </SanitizedHTMLStyle>
    ) : (
      <p>
        <span style={{ color: Colors.blueMain }}>{parentValue}</span>
        {'--->'}
        <span style={{ color: Colors.greenMain }}>{newValue}</span>
      </p>
    );
  }

  function renderImagesSection() {
    const newImagesSet = new Set();
    const parentImagesSet = new Set();

    newListing?.images?.forEach((image: ImageType) => {
      newImagesSet.add(image.id);
    });
    parentListing?.images?.forEach((image: ImageType) => {
      parentImagesSet.add(image.id);
    });

    let hasDifferentImage = false;
    for (const item of newImagesSet) {
      if (!parentImagesSet.has(item)) {
        hasDifferentImage = true;
        break;
      }
    }

    const hasDifferentImages =
      newListing?.images?.length !== parentListing?.images?.length ||
      hasDifferentImage;

    if (!hasDifferentImages) {
      return newListing?.images?.map((image: ImageType, index: number) => {
        return (
          <ShowImage
            key={index}
            image={image}
            onClick={() => {
              openLightBox(image);
            }}
          />
        );
      });
    }

    return (
      <>
        <>
          {fieldsNullFlags.has_images
            ? parentListing?.images?.map((image: ImageType, index: number) => {
                return (
                  <ShowImage
                    key={index}
                    image={image}
                    style={{
                      border: `1px solid ${
                        !newImagesSet.has(image.id) &&
                        parentImagesSet.has(image.id)
                          ? Colors.tomatoRed
                          : '#ccd4d6'
                      }`,
                    }}
                    onClick={() => {
                      openLightBox(image);
                    }}
                  />
                );
              })
            : 'Not Specified'}
        </>
        <br />
        {' NEW PHOTOS START HERE '}
        <br />
        <>
          {fieldsNullFlags.has_images
            ? newListing?.images?.map((image: ImageType, index: number) => {
                return (
                  <ShowImage
                    key={index}
                    image={image}
                    style={{
                      border: `1px solid ${
                        !parentImagesSet.has(image.id) &&
                        newImagesSet.has(image.id)
                          ? Colors.greenMain
                          : '#ccd4d6'
                      }`,
                    }}
                    onClick={() => {
                      openLightBox(image);
                    }}
                  />
                );
              })
            : 'Not Specified'}
        </>
      </>
    );
  }

  return (
    <>
      {image && (
        <Lightbox
          mainSrc={image.public_s3_url}
          imageTitle={image.alt}
          mainSrcThumbnail={image.public_s3_url}
          onCloseRequest={handleClose}
        />
      )}
      <WrapperViewDetail>
        <div className="container">
          <motion.div className="left-side">
            <div className="grid-row-three">
              <div className="grid-column">
                <button
                  className="lato-font"
                  style={{ padding: '10px' }}
                  onClick={() => toggleCollapse(0)}
                >
                  STORE INFORMATION
                  <span>
                    {lists.includes(0) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(0) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">Store ID </p>
                          <Link
                            to={`/databases/stores/${cardData?.store_id}/profile`}
                            style={{
                              color: Colors.greenMain,
                              textDecoration: 'underline',
                            }}
                          >
                            #{storeInfo?.id}
                          </Link>
                        </div>
                        <div className="row">
                          <p className="left-column">Store name </p>
                          <p className="right-column">
                            {storeInfo?.name_en + ' | ' + storeInfo?.name_ar}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Tax-DAS-Bank</p>
                          <div
                            className="right-column"
                            style={{
                              display: 'flex',
                            }}
                          >
                            <a
                              href={
                                storeInfo?.business_tax_certificate
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                            <div style={{ width: '10px' }} />
                            <a
                              href={
                                storeInfo?.business_das_certificate
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                            <div style={{ width: '10px' }} />
                            <a
                              href={
                                storeInfo?.business_bank_statement
                                  ?.get_object_presigned_url
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Icon icon="akar-icons:eye" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              <div className="grid-column">
                <button
                  className="lato-font"
                  style={{ padding: '10px' }}
                  onClick={() => toggleCollapse(1)}
                >
                  ITEM INFORMATION
                  <span>
                    {lists.includes(1) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(1) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">
                            Listing ID (parent/new){' '}
                          </p>
                          <p className="right-column">
                            <Link
                              to={`/products/listing-details/${cardData?.parent_listing_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: Colors.greenMain,
                                textDecoration: 'underline',
                                fontFamily: Fonts.primary,
                                fontWeight: 600,
                                fontSize: '13px',
                              }}
                            >
                              #{cardData?.parent_listing_id}
                            </Link>{' '}
                            /{' '}
                            <Link
                              to={`/products/listing-details/${cardData?.new_listing_id}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: Colors.greenMain,
                                textDecoration: 'underline',
                                fontFamily: Fonts.primary,
                                fontWeight: 600,
                                fontSize: '13px',
                              }}
                            >
                              #{cardData?.new_listing_id}
                            </Link>
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Item name </p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={newListing as any}
                            typeOfText="name"
                            onUpdated={async () => {
                              toast.success(
                                'Name updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_name
                                      ? {}
                                      : missingInfoStyle
                                  }
                                >
                                  {parentListing?.name_en +
                                    ' --> ' +
                                    newListing?.name_en}
                                </p>
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                        <div className="row">
                          <p className="left-column">
                            Item name (2nd language)
                          </p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={newListing as any}
                            typeOfText="name"
                            onUpdated={async () => {
                              toast.success(
                                'Name updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_second_name
                                      ? {}
                                      : missingInfoStyle
                                  }
                                >
                                  {parentListing?.name_ar +
                                    ' --> ' +
                                    newListing?.name_ar}
                                </p>
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>

                        <div className="row">
                          <p className="left-column">Category</p>
                          <div
                            className="right-column"
                            style={
                              fieldsNullFlags.has_category_name
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {category && (
                              <CategoryParentsBreadcrumbs category={category} />
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <p className="left-column">Condition</p>
                          <p className="right-column">
                            {renderWithSignifierIfValuesAreDifferent(
                              parentListing?.is_used === true ? 'Used' : 'New ',

                              newListing?.is_used === true ? 'Used' : 'New ',
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">
                            About the item (ENGLISH)
                          </p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={newListing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              toast.success(
                                'Description updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_rich_text_description_en
                                      ? {}
                                      : missingInfoStyle
                                  }
                                >
                                  {fieldsNullFlags.has_rich_text_description_en
                                    ? renderWithSignifierIfValuesAreDifferent(
                                        parentListing?.rich_text_description_en?.replace(
                                          /<[^>]*>?/gm,
                                          '',
                                        ),
                                        newListing?.rich_text_description_en?.replace(
                                          /<[^>]*>?/gm,
                                          '',
                                        ),
                                        true,
                                      )
                                    : 'Not Specified'}
                                </p>
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                        <div className="row">
                          <p className="left-column">About the item (عربي)</p>
                          <UpdateNameOrDescriptionModal
                            dataEditingRepository={DataEditingRepository}
                            listing={newListing as any}
                            typeOfText="description"
                            onUpdated={async () => {
                              toast.success(
                                'Description updated successfully, refresh page (or move to prev/next request) to see changes',
                              );
                            }}
                            children={
                              <div style={{ position: 'relative' }}>
                                <p
                                  className="right-column"
                                  style={
                                    fieldsNullFlags.has_rich_text_description_ar
                                      ? { direction: 'rtl' }
                                      : missingInfoStyle
                                  }
                                >
                                  {fieldsNullFlags.has_rich_text_description_ar
                                    ? renderWithSignifierIfValuesAreDifferent(
                                        parentListing?.rich_text_description_ar?.replace(
                                          /<[^>]*>?/gm,
                                          '',
                                        ),
                                        newListing?.rich_text_description_ar?.replace(
                                          /<[^>]*>?/gm,
                                          '',
                                        ),
                                        true,
                                      )
                                    : 'Not Specified'}
                                </p>
                                <FaPencil
                                  className="cursor-pointer"
                                  color="gray"
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                  }}
                                />
                              </div>
                            }
                          />
                        </div>
                        <div className="row">
                          <p className="left-column">Photos</p>
                          <div className="right-column">
                            <div // <Icon
                              className="images_grid"
                              style={
                                fieldsNullFlags.has_images
                                  ? {}
                                  : missingInfoStyle
                              }
                            >
                              {renderImagesSection()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(4)}
                  style={{
                    padding: '10px',
                    marginTop: '5px',
                  }}
                >
                  ATTRIBUTES
                  <span>
                    {lists.includes(4) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(4) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info lato-font center-text">
                        <div className="row">
                          <p className="right-column header">Attribute Name</p>
                          <p className="right-column header">
                            Attribute value(s)
                          </p>
                          <p className="third-column header">Images</p>
                          <p className="third-column header">Added By</p>
                        </div>

                        <div className="row">
                          {Object.keys(
                            newListing?.official_attributes || {},
                          ).map((keyName, index) => {
                            const obj = newListing?.official_attributes[
                              keyName
                            ] as AttributeAssociation;
                            const imageSet = new Set();
                            return (
                              <Fragment key={index}>
                                <p className="left-column tab">
                                  {formatAttributeName(
                                    obj.attr_name_en,
                                    obj.attr_name_ar,
                                  )}
                                  {obj?.attribute_full_object?.unit && (
                                    <span
                                      style={{
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {' (' +
                                        obj?.attribute_full_object?.unit +
                                        ')'}
                                    </span>
                                  )}
                                </p>
                                <div className="right-column tab">
                                  {getUniqueValues(obj).map((item, i) => {
                                    if (
                                      obj.attribute_full_object.type ===
                                        AttributeType.SELECT &&
                                      (!obj.attribute_full_object.official_values_en?.includes(
                                        item
                                          .split(
                                            EN_AR_TRANSLATION_SPLIT_CHAR,
                                          )[0]
                                          ?.trim(),
                                      ) ||
                                        !obj.attribute_full_object.official_values_ar?.includes(
                                          item
                                            .split(
                                              EN_AR_TRANSLATION_SPLIT_CHAR,
                                            )[1]
                                            ?.trim(),
                                        ))
                                    ) {
                                      return (
                                        <span
                                          title="This value is not official, click to add it to the official values"
                                          key={i}
                                          style={{
                                            color: Colors.blue,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            setAttributeToUpdate(obj);
                                            setEnAttributeValue(
                                              item
                                                .split(
                                                  EN_AR_TRANSLATION_SPLIT_CHAR,
                                                )[0]
                                                ?.trim(),
                                            );
                                            setArAttributeValue(
                                              item
                                                .split(
                                                  EN_AR_TRANSLATION_SPLIT_CHAR,
                                                )[1]
                                                ?.trim(),
                                            );
                                            setShowAppendAttributeValuesModal(
                                              true,
                                            );
                                          }}
                                        >
                                          {item},{' '}
                                        </span>
                                      );
                                    }
                                    return (
                                      <Fragment key={i}>{item}, </Fragment>
                                    );
                                  })}
                                </div>
                                <div className="third-column">
                                  <div className="images">
                                    {obj.values_images_items
                                      ?.filter((i) => i.image)
                                      ?.map((item, j) => {
                                        if (imageSet.has(item.value_en)) {
                                          return;
                                        }
                                        imageSet.add(item.value_en);
                                        return (
                                          <Fragment key={j}>
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(item.image!);
                                              }}
                                            />
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <p
                                  className="forth-column tab"
                                  style={{
                                    color: Colors.greenMain,
                                  }}
                                >
                                  SILAL
                                </p>
                              </Fragment>
                            );
                          })}
                          {Object.keys(newListing?.custom_attributes || {}).map(
                            (keyName, k) => {
                              const obj = newListing?.custom_attributes[
                                keyName
                              ] as AttributeAssociation;
                              const imageSet = new Set();
                              return (
                                <Fragment key={k}>
                                  <p className="left-column tab">
                                    {formatAttributeName(
                                      obj.attr_name_en,
                                      obj.attr_name_ar,
                                    )}
                                  </p>
                                  <div className="right-column tab">
                                    {getUniqueValues(obj).map((item, l) => {
                                      return (
                                        <Fragment key={l}>{item}, </Fragment>
                                      );
                                    })}
                                  </div>
                                  <div className="third-column">
                                    <div className="images">
                                      {obj.values_images_items
                                        ?.filter((i) => i.image)
                                        ?.map((item, m) => {
                                          if (imageSet.has(item.value_en)) {
                                            return;
                                          }
                                          imageSet.add(item.value_en);
                                          return (
                                            <Fragment key={m}>
                                              <ShowImage
                                                image={item.image}
                                                onClick={() => {
                                                  openLightBox(item.image!);
                                                }}
                                              />
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  </div>
                                  <p className="forth-column tab">Customer</p>
                                </Fragment>
                              );
                            },
                          )}
                        </div>
                        {Object.keys(newListing?.custom_attributes || {})
                          .length +
                          Object.keys(newListing?.official_attributes || {})
                            .length <
                          1 && (
                          <h5
                            style={
                              fieldsNullFlags.has_attributes
                                ? {
                                    padding: '10px',
                                    background: 'white',
                                    color: Colors.blueMain,
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                  }
                                : missingInfoStyle
                            }
                          >
                            No Attributes Were Given For This Item
                          </h5>
                        )}
                      </div>
                      <div
                        style={{
                          width: '700px',
                          height: '30px',
                          margin: 'auto',
                          color: Colors.blueAlternate,
                          borderRadius: '5px',
                          border: '1px solid #E5E5E5',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Old Version Below
                      </div>
                      <div className="item-info lato-font center-text">
                        <div className="row">
                          <p className="right-column header">Attribute Name</p>
                          <p className="right-column header">
                            Attribute value(s)
                          </p>
                          <p className="third-column header">Images</p>
                          <p className="third-column header">Added By</p>
                        </div>
                        <div className="row">
                          {Object.keys(
                            parentListing?.official_attributes || {},
                          ).map((keyName, n) => {
                            const obj = parentListing?.official_attributes[
                              keyName
                            ] as AttributeAssociation;
                            const imageSet = new Set();
                            return (
                              <Fragment key={n}>
                                <p className="left-column tab">
                                  {formatAttributeName(
                                    obj.attr_name_en,
                                    obj.attr_name_ar,
                                  )}
                                </p>
                                <div className="right-column tab">
                                  {getUniqueValues(obj).map((item, nn) => {
                                    return (
                                      <Fragment key={nn}>{item}, </Fragment>
                                    );
                                  })}
                                </div>
                                <div className="third-column">
                                  <div className="images">
                                    {obj.values_images_items
                                      ?.filter((i) => i.image)
                                      ?.map((item, mm) => {
                                        if (imageSet.has(item.value_en)) {
                                          return;
                                        }
                                        imageSet.add(item.value_en);
                                        return (
                                          <Fragment key={mm}>
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(item.image!);
                                              }}
                                            />
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <p
                                  className="forth-column tab"
                                  style={{
                                    color: Colors.greenMain,
                                  }}
                                >
                                  SILAL
                                </p>
                              </Fragment>
                            );
                          })}
                          {Object.keys(
                            parentListing?.custom_attributes || {},
                          ).map((keyName, x) => {
                            const obj = parentListing?.custom_attributes[
                              keyName
                            ] as AttributeAssociation;
                            const imageSet = new Set();
                            return (
                              <Fragment key={x}>
                                <p className="left-column tab">
                                  {formatAttributeName(
                                    obj.attr_name_en,
                                    obj.attr_name_ar,
                                  )}
                                </p>
                                <div className="right-column tab">
                                  {getUniqueValues(obj).map((item) => {
                                    return (
                                      <Fragment key={item}>{item}, </Fragment>
                                    );
                                  })}
                                </div>
                                <div className="third-column">
                                  <div className="images">
                                    {obj.values_images_items
                                      ?.filter((i) => i.image)
                                      ?.map((item, h) => {
                                        if (imageSet.has(item.value_en)) {
                                          return;
                                        }
                                        imageSet.add(item.value_en);
                                        return (
                                          <Fragment key={h}>
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(item.image!);
                                              }}
                                            />
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <p className="forth-column tab">Customer </p>
                              </Fragment>
                            );
                          })}
                        </div>
                        {Object.keys(parentListing?.custom_attributes || {})
                          .length +
                          Object.keys(parentListing?.official_attributes || {})
                            .length <
                          1 && (
                          <h5
                            style={
                              fieldsNullFlags.has_attributes
                                ? {
                                    padding: '10px',
                                    background: 'white',
                                    color: Colors.blueMain,
                                    borderRadius: '5px',
                                    fontSize: '14px',
                                  }
                                : missingInfoStyle
                            }
                          >
                            No Attributes Were Given For This Item
                          </h5>
                        )}
                        .
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(5)}
                  style={{
                    padding: '10px',
                  }}
                >
                  COMBINATION TABLE
                  <span>
                    {lists.includes(5) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(5) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row">
                        <span>
                          All <span className="green">VALID</span> Combinations
                          of attributes that have{' '}
                          <span className="green">multiple</span> values
                        </span>

                        <div className="row-3">
                          <motion.table className="lato-font">
                            <thead>
                              <tr>
                                <th>ID</th>
                                {same_attributes?.map((attr, index) => {
                                  return <th key={index}>{attr}</th>;
                                })}
                                <th>Price</th>
                                <th>Image</th>
                                <th>In Stock</th>
                              </tr>
                            </thead>
                            <tbody>
                              {newListing?.items?.map(
                                (item: ListingItem, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check">
                                          #{item.id}
                                        </div>
                                      </td>
                                      {same_attributes?.map((attr, index) => {
                                        const attrObj = item.attributes?.find(
                                          (i) => i.attr_name_en === attr,
                                        );
                                        return (
                                          <td key={index}>
                                            {formatAttributeValues(
                                              attrObj?.attr_value_en?.toString(),
                                              attrObj?.attr_value_ar?.toString(),
                                              attrObj?.attribute_type || 0,
                                            )}
                                          </td>
                                        );
                                      })}

                                      <td>{formatPrice(item.price)}</td>
                                      <td>
                                        <div className="images">
                                          {item.image ? (
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(item.image);
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-check">
                                          {item.in_stock ? 'True' : 'False'}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </tbody>
                          </motion.table>
                        </div>
                      </div>
                      <div
                        style={{
                          width: '700px',
                          height: '30px',
                          margin: '10px',
                          color: Colors.blueAlternate,
                          borderRadius: '5px',
                          border: '1px solid #E5E5E5',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Old Version Below
                      </div>
                      <div className="item-info row">
                        <span>
                          All <span className="green">VALID</span> Combinations
                          of attributes that have{' '}
                          <span className="green">multiple</span> values
                        </span>

                        <div className="row-3">
                          <motion.table className="lato-font">
                            <thead>
                              <tr>
                                <th>ID</th>
                                {same_attributes?.map((attr, index) => {
                                  return <th key={index}>{attr}</th>;
                                })}
                                <th>Price</th>
                                <th>Image</th>
                                <th>In Stock</th>
                              </tr>
                            </thead>
                            <tbody>
                              {parentListing?.items?.map(
                                (item: ListingItem, index: number) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <div className="form-check">
                                          #{item.id}
                                        </div>
                                      </td>
                                      {same_attributes?.map((attr, index) => {
                                        const attrObj = item.attributes?.find(
                                          (i) => i.attr_name_en === attr,
                                        );
                                        return (
                                          <td key={index}>
                                            {formatAttributeValues(
                                              attrObj?.attr_value_en?.toString(),
                                              attrObj?.attr_value_ar?.toString(),
                                              attrObj?.attribute_type || 0,
                                            )}
                                          </td>
                                        );
                                      })}

                                      <td>{formatPrice(item.price)}</td>
                                      <td>
                                        <div className="images">
                                          {item.image ? (
                                            <ShowImage
                                              image={item.image}
                                              onClick={() => {
                                                openLightBox(item.image);
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="form-check">
                                          {item.in_stock ? 'True' : 'False'}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                },
                              )}
                            </tbody>
                          </motion.table>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="grid-column">
                <button
                  className="lato-font"
                  onClick={() => toggleCollapse(6)}
                  style={{
                    padding: '10px',
                  }}
                >
                  SHIPPING
                  <span>
                    {lists.includes(6) ? (
                      <Icon icon="akar-icons:chevron-up" />
                    ) : (
                      <Icon icon="akar-icons:chevron-down" />
                    )}
                  </span>
                </button>
                <AnimatePresence initial={false}>
                  {lists.includes(6) && (
                    <motion.div
                      className="dataPanel"
                      initial="closed"
                      animate="open"
                      exit="closed"
                      variants={{
                        open: {
                          opacity: 1,
                          height: 'auto',
                        },
                        closed: {
                          opacity: 0,
                          height: 0,
                        },
                      }}
                      transition={{
                        duration: 0.3,
                        ease: [0.4, 0.62, 0.23, 0.4],
                      }}
                    >
                      <div className="item-info row-2 lato-font">
                        <div className="row">
                          <p className="left-column">What’s in Box</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_whats_included
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {fieldsNullFlags.has_whats_included
                              ? renderWithSignifierIfValuesAreDifferent(
                                  parentListing?.whats_included,
                                  newListing?.whats_included,
                                )
                              : 'Not Specified'}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">
                            Package Dimensions (w/d/h){' '}
                          </p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_length &&
                              fieldsNullFlags.has_height &&
                              fieldsNullFlags.has_width
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {renderWithSignifierIfValuesAreDifferent(
                              fieldsNullFlags.has_width
                                ? formatDecimals(parentListing?.width) + 'cm'
                                : 'NA',
                              fieldsNullFlags.has_width
                                ? formatDecimals(newListing?.width) + 'cm'
                                : 'NA',
                            )}
                            {renderWithSignifierIfValuesAreDifferent(
                              fieldsNullFlags.has_height
                                ? formatDecimals(parentListing?.height) + 'cm'
                                : 'NA',
                              fieldsNullFlags.has_width
                                ? formatDecimals(newListing?.height) + 'cm'
                                : 'NA',
                            )}
                            {renderWithSignifierIfValuesAreDifferent(
                              fieldsNullFlags.has_length
                                ? formatDecimals(parentListing?.length) + 'cm'
                                : 'NA',
                              fieldsNullFlags.has_width
                                ? formatDecimals(newListing?.length) + 'cm'
                                : 'NA',
                            )}
                          </p>
                        </div>{' '}
                        <div className="row">
                          <p className="left-column">Package Weight</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_weight ? {} : missingInfoStyle
                            }
                          >
                            {renderWithSignifierIfValuesAreDifferent(
                              fieldsNullFlags.has_weight
                                ? parseFloat(parentListing?.weight) > 1000
                                  ? (
                                      parseFloat(parentListing?.weight) / 1000
                                    ).toFixed(3) + 'kg'
                                  : formatDecimals(parentListing?.weight) + 'g'
                                : 'NA',
                              fieldsNullFlags.has_weight
                                ? parseFloat(newListing?.weight) > 1000
                                  ? (
                                      parseFloat(newListing?.weight) / 1000
                                    ).toFixed(3) + 'kg'
                                  : formatDecimals(newListing?.weight) + 'g'
                                : 'NA',
                            )}
                          </p>
                        </div>
                        <div className="row">
                          <p className="left-column">Item qualities</p>
                          <p
                            className="right-column"
                            style={
                              fieldsNullFlags.has_item_qualities
                                ? {}
                                : missingInfoStyle
                            }
                          >
                            {renderWithSignifierIfValuesAreDifferent(
                              fieldsNullFlags.has_item_qualities
                                ? getAppropriateItemQualities(parentListing)
                                : 'Not Specified',

                              fieldsNullFlags.has_item_qualities
                                ? getAppropriateItemQualities(newListing)
                                : 'Not Specified',
                            )}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </motion.div>
          {!(cardData?.status_name === 'pending') ? null : (
            <ApplicationChecklist
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              requestType={cardData?.request_type}
            />
          )}
          {showAppendAttributeValuesModal && (
            <DoubleInputPopupModal
              hideModal={() => {
                setArAttributeValue('');
                setEnAttributeValue('');
                setAttributeToUpdate(undefined);
                setShowAppendAttributeValuesModal(false);
              }}
              title="Append Attribute Values"
              subtitle={`Enter the English and Arabic values you want to append to the attribute.`}
              info1={`Current English Values: ${attributeToUpdate?.attribute_full_object.official_values_en?.join(
                ', ',
              )}`}
              info2={`Current Arabic Values: ${attributeToUpdate?.attribute_full_object.official_values_ar?.join(
                ', ',
              )}`}
              inputText1="New English Value"
              inputText2="New Arabic Value"
              changeInput1={(val) => {
                setEnAttributeValue(val);
              }}
              changeInput2={(val) => {
                setArAttributeValue(val);
              }}
              submit={async () => {
                if (!enAttributeValue || !arAttributeValue) {
                  toast.warn('Please fill in both fields');
                }
                if (!attributeToUpdate) {
                  toast.warn('Please select an attribute');
                }
                if (
                  attributeToUpdate?.attribute_full_object.official_values_en?.includes(
                    enAttributeValue,
                  ) ||
                  attributeToUpdate?.attribute_full_object.official_values_ar?.includes(
                    arAttributeValue,
                  )
                ) {
                  toast.warn('This value already exists');
                  return;
                }
                await CategoriesRepository.appendSelectAttributeValues(
                  attributeToUpdate!.attr_id,
                  [enAttributeValue],
                  [arAttributeValue],
                ).then(async (res) => {
                  if (!res) return;
                  await CategoriesRepository.bulkEditAttributes(
                    [
                      {
                        listing_id: cardData.new_listing_id,
                        new_value_en: enAttributeValue,
                      },
                    ],
                    attributeToUpdate!.attr_id,
                    attributeToUpdate!.attribute_type,
                  ).then((res) => {
                    if (!res) return;
                    setArAttributeValue('');
                    setEnAttributeValue('');
                    setAttributeToUpdate(undefined);
                    setShowAppendAttributeValuesModal(false);
                    window.location.reload();
                  });
                });
              }}
              buttonText={`Append ${enAttributeValue} ${EN_AR_TRANSLATION_SPLIT_CHAR} ${arAttributeValue}`}
              inputType1={'text'}
              inputType2={'text'}
              inputValue1={enAttributeValue}
              inputValue2={arAttributeValue}
            />
          )}
        </div>
      </WrapperViewDetail>
    </>
  );
}
