import IsUserAuthenticated from './functions/routes_functions';
import AuthenticatedViewRouter from './views/authenticated_view';
import UnauthenticatedViewRouter from './views/unauthenticated_view';

function AppRouter() {
  const userFullyAuthenticated = IsUserAuthenticated();
  return userFullyAuthenticated ? (
    <AuthenticatedViewRouter />
  ) : (
    <UnauthenticatedViewRouter />
  );
}

export default AppRouter;
