import { useState } from 'react';
import { Card, CardBody, CardFooter, Button, Spacer } from '@nextui-org/react';
import { FaLock } from 'react-icons/fa6';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { Shipment } from 'silal_app_base_react/src/data/types/shipping';
import { PackageCard } from 'silal_app_base_react/src/components/package_editing_modal/package_editing_modal_components';
import DeliveryRepository from 'data/repositories/delivery_repository';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { Divider, Row } from 'antd';
import {
  PackageDimensionsAndTracking,
  ShipmentStatusTag,
  ShipmentTimeline,
} from 'silal_app_base_react/src/pages/orders_archive/components/shared_components';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';
import ReactFlipCard from 'reactjs-flip-card';
import { Colors } from 'silal_app_base_react/src/config/theme';

export function ShipmentCard({ shipment }: { shipment: Shipment }) {
  const navigate = useNavigate();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [pkgTrackingCode, setPkgTrackingCode] = useState('');

  return (
    <>
      <PopupWithConfirmation
        open={confirmDelete}
        onButtonOneClick={() => setConfirmDelete(false)}
        onButtonTwoClick={async () => {
          if (!pkgTrackingCode) return;
          await DeliveryRepository.getShipmentByTrackingCode(
            pkgTrackingCode,
          ).then((res) => {
            if (!res || !res.shipments || !res.shipments.length) {
              toast.warn('No shipment found with this code');
              return;
            }
            toast.success(res.msg);
            setConfirmDelete(false);
            navigate(
              `/databases/orders-archive/${res.shipments[0].order_id}/shipments`,
            );
          });
        }}
        headerTitle={'Edit package?'}
        bodyText={
          'To edit this package, you will be directed to orders shipments page?'
        }
        buttonTwoText={'Go'}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'baseline',
          border: `1px dashed ${Colors.blueMain}`,
          margin: '20px 0px',
          borderRadius: '10px',
        }}
      >
        <div style={{ width: '30%', margin: '0px 15px' }}>
          <BaseShipmentCard shipment={shipment} />
        </div>
        <div
          // horizontal scroll
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 'var(--lg)',
            maxWidth: '70vw',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            borderLeft: `1px dashed ${Colors.blueSecondary}`,
          }}
        >
          {shipment.packages.map((pkg) => (
            <PackageCard
              key={pkg.tracking_code}
              packageData={pkg}
              customCardClasses="w-64 h-96 mx-3 my-4"
              customCardFooter={
                <CardFooter
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    className={`flex-1 'text-white'`}
                    isIconOnly
                    variant={'ghost'}
                    color={'warning'}
                    onClick={() => {
                      setPkgTrackingCode(pkg.tracking_code);
                      setConfirmDelete(true);
                    }}
                  >
                    Edit Package
                  </Button>
                </CardFooter>
              }
            />
          ))}
        </div>
      </div>
    </>
  );
}

export function BaseShipmentCard({
  shipment,
  customCardFooter,
  containerStyle,
}: {
  shipment: Shipment;
  customCardFooter?: JSX.Element;
  containerStyle?: React.CSSProperties;
}) {
  return (
    <ReactFlipCard
      key={shipment.id}
      containerStyle={
        containerStyle || {
          width: '100%',
        }
      }
      flipTrigger="onClick"
      direction="diagonal"
      frontComponent={
        <Card>
          <CardBody>
            <Col>
              <Row align="middle" className="justify-between">
                <Col>
                  <Row>
                    <h6>Shipment #{shipment.id} </h6>{' '}
                    {shipment.requires_security_code && (
                      <FaLock color="gray" className="mt-1 ml-2" />
                    )}
                  </Row>
                  <ShipmentStatusTag
                    status={shipment.status}
                    shipment={shipment}
                  />
                </Col>
                <p
                  className="my-2 text-gray-400"
                  style={{
                    fontSize: '6x',
                    cursor: 'pointer',
                  }}
                >
                  More Details
                </p>
              </Row>
              <p className="text-xs my-2 text-gray-400">
                From: {shipment.source_address.display_name},{' '}
                {shipment.source_address.town.name_en}
              </p>
              <p className="text-xs my-2 text-gray-400">
                To: {shipment.planned_destination_address.display_name},{' '}
                {shipment.planned_destination_address.town.name_en}
              </p>{' '}
              <p className="text-xs my-2 text-gray-400 mb-4">
                Currently with Carried ID:{' '}
                {shipment.current_carrier_id || 'None'}
              </p>{' '}
              <ShipmentTimeline shipmentsActions={shipment.shipment_actions} />
            </Col>
          </CardBody>
        </Card>
      }
      backComponent={
        <Card>
          <CardBody>
            <Col>
              <Row align="middle" className="justify-between">
                <Row>
                  <h6>Shipment #{shipment.id} Details </h6>{' '}
                  {shipment.requires_security_code && (
                    <FaLock color="gray" className="mt-1 ml-2" />
                  )}
                </Row>
                <p
                  className="my-2 text-gray-400"
                  style={{
                    fontSize: '6x',
                    cursor: 'pointer',
                  }}
                >
                  Back
                </p>
              </Row>
              <p className="text-xs my-2 mt-1 text-gray-400">
                From: {shipment.source_address.address_line_1},{' '}
                {shipment.source_address.town.name_en}
              </p>
              {shipment.dropped_off_address && (
                <p className="text-xs my-2 text-gray-400">
                  Currently at:
                  {shipment.dropped_off_address.address_line_1},{' '}
                  {shipment.dropped_off_address.town.name_en}
                </p>
              )}
              <Spacer />
              <p className="text-xs my-2 text-gray-400">
                To:
                {shipment.planned_destination_address.address_line_1},{' '}
                {shipment.planned_destination_address.town.name_en}
              </p>{' '}
              <Divider />
              <p className="text-xs my-2 text-gray-400">
                Cash to Collect: {formatPrice(shipment.cash_to_collect)}
              </p>{' '}
              <Spacer />
              {shipment.current_carrier_id && (
                <p className="text-xs my-2 text-gray-400">
                  Current Carrier: #{shipment.current_carrier_id}
                </p>
              )}
              <Spacer />
              <p className="text-xs my-2 text-gray-400">
                Assigned Shift:{' '}
                <Link
                  to={`/logistics/shifts/${shipment.last_mile_shift?.id}`}
                  style={{
                    color: Colors.greenMain,
                  }}
                >
                  #{shipment.last_mile_shift?.id}
                </Link>
                <br />
                {formateServerDateToLocaleDate(
                  shipment.last_mile_shift?.start_datetime,
                )}{' '}
                -{' '}
                {formateServerDateToLocaleDate(
                  shipment.last_mile_shift?.end_datetime,
                )}
              </p>
              <Divider />
              {PackageDimensionsAndTracking(shipment)}
            </Col>
          </CardBody>
          {customCardFooter ? customCardFooter : null}
        </Card>
      }
    />
  );
}
