import styled from 'styled-components';

const Wrapper = styled.label`
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 14px;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid #cdcdd0;
    border-radius: 50%;
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: white;
    border: 1px solid var(--app-color-dark);
  }
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--app-color-dark);
  }
`;

export default Wrapper;
