import { API_BASE } from 'silal_app_base_react/src/config/constants';
import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { AttributeType } from 'silal_app_base_react/src/data/types/attributes';
import { CATEGORIES_MESSAGES } from 'data/constants/messages';
class CategoriesApiRepository extends AuthenticatedApiRepository {
  private static instance: CategoriesApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): CategoriesApiRepository {
    if (!CategoriesApiRepository.instance) {
      CategoriesApiRepository.instance = new CategoriesApiRepository(
        axiosInstance,
      );
    }
    return CategoriesApiRepository.instance;
  }

  addCategory = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/categories/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryAdded,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  editCategory = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/categories/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryUpdated,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  deleteCategory = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/categories/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryDeleted,
        },
      );
      return res.status === 204;
    } catch (error: any) {
      return false;
    }
  };

  hideCategory = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/categories/hide/${payload.category_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryHidden,
        },
      );
      return res.status === 202;
    } catch (error: any) {
      return false;
    }
  };

  unhideCategory = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/categories/unhide/${payload.category_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryUnhidden,
        },
      );
      return res.status === 202;
    } catch (error: any) {
      return false;
    }
  };

  addNewAttributeBool = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'POST',
          `/categories/${payload.category_id}/attributes/boolean/`,
          {
            baseApiUrl: API_BASE,
            data: payload,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AddedNewAttribute,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  addNewAttributeSelect = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'POST',
          `/categories/${payload.category_id}/attributes/select/`,
          {
            baseApiUrl: API_BASE,
            data: payload,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AddedNewAttribute,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  addNewAttributeNumeric = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'POST',
          `/categories/${payload.category_id}/attributes/numeric/`,
          {
            baseApiUrl: API_BASE,
            data: payload,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AddedNewAttribute,
        },
      );

      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  editAttribute = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/attributes/${payload.id}/`, {
          baseApiUrl: API_BASE,
          data: payload,
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AttributeUpdated,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };
  removeAttribute = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'DELETE',
          `/categories/${payload.category_id}/attribute/${payload.attribute_id}/`,
          {
            baseApiUrl: API_BASE,
            data: payload,
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AttributeDeleted,
        },
      );
      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  //TODO everything below should be added to saga!!
  appendSelectAttributeValues = async (
    attribute_id: number,
    new_values_en: string[],
    new_values_ar: string[],
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('PUT', `/attributes/values/${attribute_id}/`, {
          baseApiUrl: API_BASE,
          data: {
            new_values_en,
            new_values_ar,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AttributeValuesAppended,
        },
      );

      return res.data;
    } catch (error: any) {
      return false;
    }
  };

  replaceSelectAttributeValues = async (payload: any) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/attributes/values/${payload.id}/replace_values/`,
          {
            baseApiUrl: API_BASE,
            data: payload,
          },
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AttributeValuesReplaced,
        },
      );

      return res.data;
    } catch (error: any) {
      return false;
    }
  };
  bulkEditAttributes = async (
    listingsValuesTuples: {
      listing_id: number;
      new_value_en: any;
    }[] = [],
    attribute_id: number,
    attribute_type: number,
  ) => {
    const type =
      attribute_type === AttributeType.NUMERIC
        ? 'new_numeric_value'
        : attribute_type === AttributeType.BOOLEAN
          ? 'new_boolean_value'
          : 'new_select_value_en';
    const changed_listings = listingsValuesTuples.map((listing) => ({
      listing_id: listing.listing_id,
      [type]: listing.new_value_en,
    }));

    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/attributes/bulk_edit/${attribute_id}`, {
          baseApiUrl: API_BASE,
          data: { changed_listings },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.AttributesBulkEdited,
        },
      );

      return res.status === 202;
    } catch (e: any) {
      return false;
    }
  };

  linkCategoryToParentCategory = async (
    parentCategory: number,
    categoryToLink: number,
  ) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('POST', `/categories/links/`, {
          baseApiUrl: API_BASE,
          data: {
            parent_category_id: parentCategory,
            linked_category_id: categoryToLink,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryLinked,
        },
      );

      return res.status === 201;
    } catch (e: any) {
      return false;
    }
  };

  deleteCategoryLinkOfID = async (link_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest('DELETE', `/categories/links/${link_id}/`),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
          customSuccessMsg: CATEGORIES_MESSAGES.CategoryLinkDeleted,
        },
      );

      return res.status === 204;
    } catch (e: any) {
      return false;
    }
  };
}

const CategoriesRepository = CategoriesApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);

export default CategoriesRepository;
