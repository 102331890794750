import { Col } from 'react-bootstrap';
import { Link, useOutletContext } from 'react-router-dom';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { ScreenWrapper } from '../../screens_wrapper';
import { Button, CardFooter } from '@nextui-org/react';
import { Row } from 'antd';
import {
  DELIVERY_SHIPMENT_STATUS,
  Shipment,
} from 'silal_app_base_react/src/data/types/shipping';
import {
  ManagementOrderActions,
  ManagementOrderActionsTypes,
} from '../../functions/orders_functions';
import { Fragment, useEffect, useState } from 'react';
import { ShiftsAddOrChoosePopup } from 'pages/logistics/routing_schedule/modals/shifts_modal';
import moment from 'moment';
import {
  changeShiftsTimezone,
  getFilteredShifts,
} from 'silal_app_base_react/src/utils/functions/shifts_functions';
import { PackageEditingModal } from 'silal_app_base_react/src/components/package_editing_modal';
import { toast } from 'react-toastify';
import DeliveryRepository from 'data/repositories/delivery_repository';
import { PopupWithDropdownMenuInput } from 'silal_app_base_react/src/components/popups';
import { BaseShipmentCard } from 'pages/logistics/components/shipment_card';
import ShiftsRepository from 'data/repositories/shifts_repository';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import { DEFAULT_CITY } from 'silal_app_base_react/src/config/constants';
import { CaptainUserInfo } from 'silal_app_base_react/src/data/types/users';
import UsersRepository from 'data/repositories/users_repository';
import { formateServerDateToLocaleDate } from 'silal_app_base_react/src/utils/functions/time_date_functions';
import { Colors, Fonts } from 'silal_app_base_react/src/config/theme';
import { notFoundIllustration_path } from 'silal_app_base_react/src/assets';

function OrderShipmentsTab() {
  const [shifts, setShifts] = useState<Shift[]>([]);
  const { orderDetails, setRefresh } = useOutletContext<{
    orderDetails: OrderDetails;
    showManagementNote: boolean;
    setRefresh: (value: boolean) => void;
    setShowManagementNote: (value: boolean) => void;
  }>();
  const [failedPickup, setFailedPickup] = useState(false);
  const [failedDrop, setFailedDrop] = useState(false);
  const [bonusModal, setBonusModal] = useState(false);

  const [calendarDay, setCalendarDay] = useState(
    moment(new Date()).format('YYYY-M-d'),
  );
  const [showShiftsModal, setShowShiftsModal] = useState(false);
  const [showPackageEditingModal, setShowPackageEditingModal] = useState(false);
  const [choosenShift, setChoosenShift] = useState(
    orderDetails.last_mile_shift_id,
  );

  const [captains, setCaptains] = useState<CaptainUserInfo[]>([]);

  const canCollectFromCaptain = ManagementOrderActions[
    ManagementOrderActionsTypes.collect_shipment_from_captain
  ].allowedToBeCalledWhenOrder.has(orderDetails.status);

  const canMarkShipmentAsFailedPickup = ManagementOrderActions[
    ManagementOrderActionsTypes.mark_shipment_as_failed_pickup
  ].allowedToBeCalledWhenOrder.has(orderDetails.status);

  const canMarkShipmentAsFailedDropoff = ManagementOrderActions[
    ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff
  ].allowedToBeCalledWhenOrder.has(orderDetails.status);

  useEffect(() => {
    const getFutureShifts = async () => {
      await ShiftsRepository.getShifts({
        city_id: DEFAULT_CITY,
        futureOnly: true,
      }).then((res) => {
        if (!res) {
          return;
        }
        setShifts(res);
      });
    };
    getFutureShifts();
  }, []);

  useEffect(() => {
    const getCaptains = async () => {
      await UsersRepository.getAllCarriers().then((res) => {
        if (!res) return;
        setCaptains(res);
      });
    };
    getCaptains();
  }, []);

  const renderShipmentCard = (shipment: Shipment) => {
    return (
      <Fragment key={shipment.id}>
        <ShiftsAddOrChoosePopup
          modal={showShiftsModal}
          closeModal={() => setShowShiftsModal(false)}
          date={moment(calendarDay).toISOString()}
          setDate={setCalendarDay}
          getFilteredShifts={(day_number: string) =>
            getFilteredShifts(
              changeShiftsTimezone(shifts),
              new Date(day_number),
            )
          }
          customTitle={<>Select new shift for Shipment #{shipment.id}:</>}
          customFirstButton={() => (
            <Button
              color="default"
              variant="solid"
              onClick={async () => {
                setShowShiftsModal(false);
                setShowPackageEditingModal(true);
              }}
            >
              Keep Selected Shift
            </Button>
          )}
          allShifts={changeShiftsTimezone(shifts)}
          selectShift={(shift: any) => {
            if (!shift || isNaN(shift)) {
              toast.warning('Please select a shift');
              return;
            }
            setChoosenShift(shift);
            setShowPackageEditingModal(true);
          }}
        />
        <PackageEditingModal
          open={showPackageEditingModal}
          headerTitle={`Edit Packages for Shipment #${shipment.id}`}
          onButtonOneClick={async () => setShowPackageEditingModal(false)}
          orderDetails={orderDetails}
          confirmAction
          onButtonTwoClick={async (packages) => {
            if (!packages || !packages.length) {
              toast.warning('Please add at least one package');
              return;
            }
            await DeliveryRepository.editShipment(
              shipment.id,
              packages,
              choosenShift,
            ).then((res) => {
              if (res) {
                setShowPackageEditingModal(false);
                setRefresh(true);
              }
            });
          }}
        />
        <PopupWithDropdownMenuInput
          open={bonusModal}
          headerTitle={`Captain to give shipment #${shipment.id} bonus for?`}
          onButtonOneClick={() => setBonusModal(false)}
          dropdownMenuButtonText={'Select Captain'}
          items={
            captains.map((captain) => ({
              key: captain.id.toString(),
              label: `${captain.first_name} ${captain.last_name}`,
            })) || []
          }
          onButtonTwoClick={async (driverId: number) => {
            if (failedPickup) {
              await ManagementOrderActions[
                ManagementOrderActionsTypes.mark_shipment_as_failed_pickup
              ]
                .callback({
                  shipment_id: shipment.id,
                  driver_id: driverId,
                })
                .then((res) => {
                  if (!res) return;
                  setBonusModal(false);
                  setRefresh(true);
                });
            }
            if (failedDrop) {
              await ManagementOrderActions[
                ManagementOrderActionsTypes.mark_shipment_as_failed_dropoff
              ]
                .callback({
                  shipment_id: shipment.id,
                  driver_id: driverId,
                })
                .then((res) => {
                  if (!res) return;
                  setBonusModal(false);
                  setRefresh(true);
                });
            }
            return;
          }}
        />

        <BaseShipmentCard
          key={shipment.id}
          shipment={shipment}
          containerStyle={{
            width: '400px',
          }}
          customCardFooter={
            <CardFooter className="justify-between">
              <Col className="justify-between align-middle">
                {canCollectFromCaptain &&
                  shipment.status ===
                    DELIVERY_SHIPMENT_STATUS.failed_dropoff && (
                    <Button
                      color="success"
                      variant="solid"
                      fullWidth
                      className="mb-1"
                      onClick={async () => {
                        await ManagementOrderActions[
                          ManagementOrderActionsTypes
                            .collect_shipment_from_captain
                        ].callback({
                          shipment_id: shipment.id,
                        });
                        setRefresh(true);
                      }}
                    >
                      Collect From Captain
                    </Button>
                  )}
                {(canMarkShipmentAsFailedDropoff ||
                  canMarkShipmentAsFailedPickup) && (
                  <Row className="my-1 justify-between">
                    {canMarkShipmentAsFailedPickup &&
                      shipment.status ===
                        DELIVERY_SHIPMENT_STATUS.ready_for_pickup && (
                        <Button
                          color="danger"
                          variant="ghost"
                          style={{
                            width: !canMarkShipmentAsFailedDropoff
                              ? '100%'
                              : '48%',
                          }}
                          onClick={() => {
                            setFailedPickup(true);
                            setBonusModal(true);
                          }}
                        >
                          Failed Pick
                        </Button>
                      )}
                    {canMarkShipmentAsFailedDropoff &&
                      shipment.status ===
                        DELIVERY_SHIPMENT_STATUS.picked_up && (
                        <Button
                          color="danger"
                          variant="flat"
                          style={{
                            width: !canMarkShipmentAsFailedPickup
                              ? '100%'
                              : '48%',
                          }}
                          onClick={async () => {
                            setFailedDrop(true);
                            setBonusModal(true);
                          }}
                        >
                          Failed Drop
                        </Button>
                      )}
                  </Row>
                )}
                <Button
                  color="warning"
                  variant="ghost"
                  fullWidth
                  className="mt-1"
                  onClick={() => setShowShiftsModal(true)}
                >
                  Update Shipment
                </Button>
              </Col>
            </CardFooter>
          }
        />
      </Fragment>
    );
  };

  return (
    orderDetails && (
      <ScreenWrapper>
        <Row>
          <Col>
            <h5
              style={{
                fontFamily: Fonts.secondary,
                fontWeight: 'bold',
                fontSize: '16px',
              }}
            >
              Found:{' '}
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >
                {orderDetails.shipments.length} shipment
              </span>
            </h5>
          </Col>{' '}
          <Col>
            <h5
              style={{
                fontFamily: Fonts.secondary,
                fontWeight: 'bold',
                fontSize: '16px',
              }}
            >
              Destination is:{' '}
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >
                {orderDetails.destination_address.address_line_1},{' '}
                {orderDetails.destination_address.town.name_en},{' '}
                {orderDetails.destination_address.city.name_en}
              </span>
            </h5>
          </Col>{' '}
          <Col>
            <h5
              style={{
                fontFamily: Fonts.secondary,
                fontWeight: 'bold',
                fontSize: '16px',
              }}
            >
              Original shift{' '}
              <Link
                to={`/logistics/shifts/${orderDetails.last_mile_shift_id}`}
                style={{ color: 'inherit' }}
              >
                <span
                  style={{
                    color: Colors.greenMain,
                  }}
                >
                  #{orderDetails.last_mile_shift_id}
                </span>
              </Link>
              :{' '}
              <span
                style={{
                  fontWeight: 'normal',
                }}
              >
                {formateServerDateToLocaleDate(
                  orderDetails.last_mile_shift.start_datetime,
                )}{' '}
                -{' '}
                {formateServerDateToLocaleDate(
                  orderDetails.last_mile_shift.end_datetime,
                )}
              </span>
            </h5>
          </Col>
        </Row>
        {orderDetails.shipments.length === 0 ? (
          <div
            style={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img src={notFoundIllustration_path} />
            <br />
            <h4 className="not-found-text">No Shipments Found</h4>
          </div>
        ) : (
          <Row>
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                maxWidth: 'max-content',
              }}
            >
              <section
                style={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridAutoColumns: 'max-content',
                  overflowX: 'auto',
                  width: '78vw',
                  height: '80vh',
                }}
              >
                {orderDetails.shipments.map((shipment) => (
                  <Col key={shipment.id} className="m-3">
                    {renderShipmentCard(shipment)}
                  </Col>
                ))}{' '}
              </section>
            </div>
          </Row>
        )}
      </ScreenWrapper>
    )
  );
}

export default OrderShipmentsTab;
