import { Button } from '@nextui-org/react';
import {
  Checkbox,
  Col,
  Form,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Typography,
} from 'antd';
import { useState, useEffect } from 'react';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { ListingItem } from 'silal_app_base_react/src/data/types/listings';
import {
  OrderDetails,
  OrderItemDetailed,
} from 'silal_app_base_react/src/data/types/orders';
import {
  formatDecimals,
  formatPrice,
} from 'silal_app_base_react/src/utils/functions/formatting_functions';
import { getOrderItemsTableNumbers } from '../functions/orders_functions';
import {
  FaultOf,
  ReturnMethod,
  RefundType,
  RefundReason,
  getRefundAmounts,
  RefundPayload,
  REFUND_REASONS_NAME_MAP,
  isDataRelevantToRefundType,
  RefundDataType,
  FAULT_OF_NAME_MAP,
  RETURN_METHOD_NAME_MAP,
  REFUND_TYPE_NAME_MAP,
} from '../functions/refunds_functions';
import ManagementOrdersRepository from 'data/repositories/management_orders_repository';
import { PopupWithConfirmation } from 'silal_app_base_react/src/components/popups';
import { DropdownButtonGroup } from 'silal_app_base_react/src/components/dropdown_button';
import { toast } from 'react-toastify';
import { CURRENT_CURRENCY } from 'silal_app_base_react/src/config/constants';
import { Link } from 'react-router-dom';

type ExtendedOrderDetails = OrderItemDetailed & {
  return_quantity: number;
  refund_amount: number;
};

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'checkbox';
  record: OrderItemDetailed;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'checkbox' ? (
      <Checkbox />
    ) : (
      <InputNumber min={0} max={record?.quantity} />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={inputType === 'checkbox' ? 'checked' : 'value'}
          rules={
            inputType === 'checkbox'
              ? []
              : [
                  {
                    required: true,
                    message: `Please Input ${title}!`,
                  },
                ]
          }
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const OrderItemsTable = ({
  refunding,
  setRefunding,
  orderDetails,
  itemsOnly = false,
}: {
  refunding: boolean;
  setRefunding: (refunding: boolean) => void;
  orderDetails: OrderDetails;
  itemsOnly?: boolean;
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState(
    orderDetails.items.map((item) => ({
      ...item,
      return_quantity: 0,
      refund_amount: 0,
    })),
  );
  const deliveryFee = parseFloat(orderDetails.delivery_fee);
  const returnPickupFee = 0.5 * deliveryFee;

  const [confirmationModal, setConfirmationModal] = useState(false);

  const [editingKey, setEditingKey] = useState('');
  const [selectedFaultOf, setSelectedFaultOf] = useState(FaultOf.silal);
  const [selectedReturnMethod, setSelectedReturnMethod] = useState(
    ReturnMethod.pickup,
  );

  const [selectedRefundActionType, setSelectedRefundActionType] = useState(
    RefundReason.missing_item,
  );

  const [refundObj, setRefundObj] = useState<RefundPayload>({
    amount_to_refund_to_customer: 0,
    amount_to_deduct_from_store_debt: 0,
    refund_as_balance_credit: RefundType.balance_credit,
  });

  const isEditing = (record: ExtendedOrderDetails) =>
    record?.item?.id?.toString() === editingKey;

  useEffect(() => {
    const totalCustomerPaid =
      data
        .filter((item) => item.return_quantity > 0)
        .map((item) => item.return_quantity * parseFloat(item.unit_price))
        .reduce((a, b) => a + b, 0) + deliveryFee;

    const totalSilalFee = data
      .filter((item) => item.return_quantity > 0)
      .map(
        (item) =>
          item.return_quantity * (parseFloat(item.silal_fee) / item.quantity),
      )
      .reduce((a, b) => a + b, 0);

    const calculatedRefundObj = getRefundAmounts({
      customerPaidForSelectedOrdersItems: totalCustomerPaid - deliveryFee,
      sellerRecievesForSelectedOrdersItems:
        totalCustomerPaid - deliveryFee - totalSilalFee,
      deliveryFee,
      returnPickupFee,
      faultOf: selectedFaultOf,
      returnMethod: selectedReturnMethod,
      refundReason: selectedRefundActionType,
    });
    setRefundObj(calculatedRefundObj);
  }, [
    editingKey,
    selectedFaultOf,
    selectedReturnMethod,
    selectedRefundActionType,
  ]);

  const edit = (record: Partial<ExtendedOrderDetails>) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record?.item?.id?.toString() || '');
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: number) => {
    try {
      const row = (await form.validateFields()) as ExtendedOrderDetails;
      const newData = [...data];
      const index = newData.findIndex(
        (extendedOrderItemDetailed) =>
          key === extendedOrderItemDetailed.item.id,
      );
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Item',
      dataIndex: 'item',
      editable: false,
      key: 'item',
      width: '25%',
      render: (listingItem: ListingItem, record: OrderItemDetailed) => (
        <div className="item lato">
          <div className="d-flex">
            <img
              src={record.item.image.public_s3_url}
              alt={record.item.image.alt}
              style={{
                marginRight: '10px',
                width: '70px',
                height: '70px',
                objectFit: 'scale-down',
                borderRadius: '5px',
              }}
            />
            <Col>
              <p>
                <Link
                  to={`/products/listing-details/${listingItem.listing_id}`}
                  target="_blank"
                >
                  (#{listingItem.id})
                </Link>
                {record.item.name_en}
              </p>
              <p
                style={{
                  color: Colors.grey,
                  fontSize: '0.9rem',
                }}
              >
                {record.item.attributes.map(
                  (attr, i) =>
                    attr.attr_name_en +
                    ': ' +
                    attr.attr_value_en +
                    ` ${i < record.item.attributes.length - 1 ? ' - ' : ''}`,
                )}
              </p>
            </Col>
          </div>
        </div>
      ),
    },
    {
      title: 'Qnty',
      dataIndex: 'quantity',
      key: 'quantity',
      editable: false,
    },
    {
      title: 'Price',
      dataIndex: 'unit_price',
      key: 'unit_price',
      editable: false,
      render: (text: string) => formatPrice(text),
    },
    {
      title: `Items Total`,
      dataIndex: 'total',
      key: 'total',
      editable: false,
      render: (_text: string, record: OrderItemDetailed) => {
        const price = record.quantity * parseFloat(record.unit_price);
        return formatPrice(price.toString());
      },
    },
    {
      title: `Silal Fee Total`,
      dataIndex: 'silal_fee',
      key: 'silal_fee',
      editable: false,
      render: (_text: string, record: OrderItemDetailed) => {
        return formatPrice(record.silal_fee.toString());
      },
    },
    {
      title: 'Return Qnty',
      dataIndex: 'return_quantity',
      key: 'return_quantity',
      hidden: !refunding,
      editable: true,
    },
    {
      title: 'Refund Amount',
      dataIndex: 'refund_amount',
      key: 'refund_amount',
      hidden: !refunding,
      editable: false,
      render: (_text: string, record: ExtendedOrderDetails) => {
        // Multiply the return quantity by the unit price by 0 if From Store is false, and by 1 if From Store is true
        const price = record.return_quantity * parseFloat(record.unit_price);
        return (
          <p className={`${price <= 0 ? 'text-gray' : 'text-warning'}`}>
            {formatPrice(price)}
          </p>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      hidden: !refunding,
      width: '12%',
      render: (_: any, record: ExtendedOrderDetails) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            {' '}
            <Typography.Link
              style={{
                marginRight: 8,
                color: Colors.grey,
              }}
              onClick={() => cancel()}
            >
              Cancel
            </Typography.Link>
            <Popconfirm title="Save refund info?">
              <Typography.Link
                onClick={() => save(record.item.id)}
                style={{
                  color: Colors.greenMain,
                }}
              >
                Save
              </Typography.Link>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
            style={{
              color: Colors.grey,
            }}
          >
            Refund
          </Typography.Link>
        );
      },
    },
  ];

  const mergedColumns: TableProps['columns'] = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ExtendedOrderDetails) => ({
        record,
        inputType: col.dataIndex === 'from_silal' ? 'checkbox' : 'number',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    } as any;
  });

  return (
    <>
      <PopupWithConfirmation
        open={confirmationModal}
        headerTitle={'Confirm Refund'}
        bodyText={`Are you sure you want to refund the selected items? You will refund ${formatPrice(refundObj?.amount_to_refund_to_customer)} to the customer and deduct ${formatPrice(refundObj?.amount_to_deduct_from_store_debt)} from the store debt. Refund will be of type ${
          REFUND_TYPE_NAME_MAP[RefundType[refundObj?.refund_as_balance_credit]]
        }.`}
        buttonTwoText={'Refund'}
        onButtonOneClick={() => setConfirmationModal(false)}
        onButtonTwoClick={async () => {
          await ManagementOrdersRepository.refundOrder(
            orderDetails.id,
            refundObj,
          ).then((res) => {
            if (!res) return;
            window.location.reload();
          });
        }}
        withTimerOfNSeconds={10}
      />
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
            pageSize: 10,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            hideOnSinglePage: true,
          }}
          summary={
            !refunding || itemsOnly
              ? undefined
              : (data) =>
                  TableSummary(data as ExtendedOrderDetails[], orderDetails)
          }
          footer={
            itemsOnly
              ? undefined
              : (_data) => (
                  <TableFooter
                    setRefunding={setRefunding}
                    refunding={refunding}
                    selectedRefundActionType={selectedRefundActionType}
                    setSelectedRefundActionType={setSelectedRefundActionType}
                    selectedFaultOf={selectedFaultOf}
                    setSelectedFaultOf={setSelectedFaultOf}
                    selectedReturnMethod={selectedReturnMethod}
                    setSelectedReturnMethod={setSelectedReturnMethod}
                    refundObj={refundObj}
                    setRefundObj={setRefundObj}
                    setConfirmationModal={setConfirmationModal}
                  />
                )
          }
        />
      </Form>
    </>
  );
};

const TableSummary = (data: readonly any[], orderDetails: OrderDetails) => {
  const { itemsInOrder, returnedItems, refundAmount } =
    getOrderItemsTableNumbers(data);

  return (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0}>After Refund</Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <Typography>{itemsInOrder - returnedItems}</Typography>
      </Table.Summary.Cell>{' '}
      <Table.Summary.Cell index={3} colSpan={3}>
        <Typography>
          {formatDecimals(orderDetails.customer_pays)} - {refundAmount} ={' '}
          {formatPrice(parseFloat(orderDetails.customer_pays) - refundAmount)}
        </Typography>
      </Table.Summary.Cell>{' '}
      <Table.Summary.Cell index={2} />
      <Table.Summary.Cell index={1} />
      <Table.Summary.Cell index={6} />
    </Table.Summary.Row>
  );
};

const TableFooter = ({
  refunding,
  setRefunding,
  selectedRefundActionType,
  setSelectedRefundActionType,
  selectedFaultOf,
  setSelectedFaultOf,
  selectedReturnMethod,
  setSelectedReturnMethod,
  refundObj,
  setRefundObj,
  setConfirmationModal,
}: {
  refunding: boolean;
  setRefunding: (value: boolean) => void;
  selectedRefundActionType: RefundReason;
  setSelectedRefundActionType: (value: RefundReason) => void;
  selectedFaultOf: FaultOf;
  setSelectedFaultOf: (faultOf: FaultOf) => void;
  selectedReturnMethod: ReturnMethod;
  setSelectedReturnMethod: (returnMethod: ReturnMethod) => void;
  refundObj: RefundPayload;
  setRefundObj: (refundObj: RefundPayload) => void;
  setConfirmationModal: (confirmationModal: boolean) => void;
}) => {
  const rowClassName = 'd-flex justify-content-between m-3';
  const colClassName =
    'd-flex flex-column justify-content-between text-lg text-bold';
  const mustSelectReturnMethod = isDataRelevantToRefundType(
    selectedRefundActionType,
    RefundDataType.return_method,
  );
  const mustSelectFaultOf = isDataRelevantToRefundType(
    selectedRefundActionType,
    RefundDataType.fault_of,
  );

  const [customInputEnabled, setCustomInputEnabled] = useState(false);

  return (
    <Col className={colClassName}>
      <Row className={rowClassName}>
        <Col className={colClassName}>
          <Button
            color={refunding ? 'warning' : 'primary'}
            variant={refunding ? 'flat' : 'solid'}
            onClick={() => {
              setRefunding(!refunding);
            }}
          >
            {refunding ? 'Cancel Refund' : 'Start Refund Process'}
          </Button>{' '}
        </Col>
      </Row>
      {refunding && (
        <>
          <Row className={rowClassName}>
            <Col className={colClassName}>
              Reason of refund: <br />
              <DropdownButtonGroup
                buttonText={REFUND_REASONS_NAME_MAP[selectedRefundActionType]}
                options={REFUND_REASONS_NAME_MAP}
                selectedKeys={[selectedRefundActionType.toString()] as string[]}
                setSelectedKey={setSelectedRefundActionType}
                disabled={customInputEnabled}
              />
            </Col>
            {mustSelectFaultOf && (
              <Col className={colClassName}>
                Fault of: <br />
                <DropdownButtonGroup
                  buttonText={FAULT_OF_NAME_MAP[selectedFaultOf]}
                  options={FAULT_OF_NAME_MAP}
                  selectedKeys={[selectedFaultOf.toString() as string]}
                  setSelectedKey={setSelectedFaultOf}
                  disabled={customInputEnabled}
                />
              </Col>
            )}

            {mustSelectReturnMethod && (
              <Col className={colClassName}>
                Return Method: <br />
                <DropdownButtonGroup
                  buttonText={RETURN_METHOD_NAME_MAP[selectedReturnMethod]}
                  options={RETURN_METHOD_NAME_MAP}
                  selectedKeys={[selectedReturnMethod.toString() as string]}
                  setSelectedKey={setSelectedReturnMethod}
                  disabled={customInputEnabled}
                />
              </Col>
            )}
          </Row>
          <Row className={rowClassName}>
            <Col className={colClassName}>
              Refund to Customer:
              <br />
              {formatPrice(refundObj?.amount_to_refund_to_customer)}
            </Col>{' '}
            <Col className={colClassName}>
              Deduct from Store Debt:
              <br />
              {formatPrice(refundObj?.amount_to_deduct_from_store_debt)}
            </Col>{' '}
            <Col className={colClassName}>
              Refund will be of type: <br />
              {
                REFUND_TYPE_NAME_MAP[
                  RefundType[refundObj?.refund_as_balance_credit]
                ]
              }
            </Col>
          </Row>
          <Row className={rowClassName}>
            <Row>
              <Button
                color="warning"
                variant={customInputEnabled ? 'ghost' : 'solid'}
                onClick={async () => {
                  setCustomInputEnabled(!customInputEnabled);
                }}
              >
                {!customInputEnabled
                  ? 'Manually Add Refund Input?'
                  : 'Cancel Custom Input'}
              </Button>
              {customInputEnabled && (
                <>
                  <InputNumber
                    className="mx-2 w-40"
                    placeholder="Refund to customer"
                    value={refundObj?.amount_to_refund_to_customer}
                    onChange={(value) => {
                      setRefundObj({
                        ...refundObj,
                        amount_to_refund_to_customer: value as number,
                      });
                    }}
                    addonAfter={CURRENT_CURRENCY}
                  />
                  <InputNumber
                    className="mx-2 w-40"
                    placeholder="Deduct from store debt"
                    value={refundObj?.amount_to_deduct_from_store_debt}
                    onChange={(value) => {
                      setRefundObj({
                        ...refundObj,
                        amount_to_deduct_from_store_debt: value as number,
                      });
                    }}
                    addonAfter={CURRENT_CURRENCY}
                  />
                  <Col>
                    <Checkbox disabled={true} className="mx-2" checked={true}>
                      As Balance Credit
                    </Checkbox>
                  </Col>
                </>
              )}
            </Row>
            <Button
              color="danger"
              onClick={async () => {
                if (
                  !customInputEnabled &&
                  (!selectedRefundActionType ||
                    typeof selectedRefundActionType === 'number')
                ) {
                  toast.warn('Please select a refund reason');
                  return;
                }

                if (
                  !customInputEnabled &&
                  mustSelectFaultOf &&
                  typeof selectedFaultOf === 'number'
                ) {
                  toast.warn('Please select fault of');
                  return;
                }

                if (
                  !customInputEnabled &&
                  mustSelectReturnMethod &&
                  typeof selectedReturnMethod === 'number'
                ) {
                  toast.warn('Please select a return method');
                  return;
                }

                if (refundObj?.amount_to_refund_to_customer <= 0) {
                  toast.warn('Please select items to refund');
                  return;
                }
                if (customInputEnabled) {
                  setRefundObj({
                    ...refundObj,
                    refund_as_balance_credit: RefundType.balance_credit,
                  });
                }
                setConfirmationModal(true);
              }}
            >
              Refund
            </Button>
          </Row>
        </>
      )}
    </Col>
  );
};
