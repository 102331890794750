import { Col } from 'antd';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
import { formatPrice } from 'silal_app_base_react/src/utils/functions/formatting_functions';

export const OrderPaymentInfoRow = ({
  orderDetails,
}: {
  orderDetails: OrderDetails;
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0',
      }}
    >
      <Col>
        <label>Items Total</label>
        <p>{formatPrice(orderDetails.items_total)}</p>
      </Col>
      <Col>
        <label>Delivery Fee</label>
        <p>{formatPrice(orderDetails.delivery_fee)}</p>
      </Col>
      <Col>
        <label>Customer Paid</label>
        <p>{formatPrice(orderDetails.order_ledger.amount_paid_by_customer)}</p>
      </Col>
      <Col>
        <label>Store VAT</label>
        <p>{formatPrice(orderDetails.vat)}</p>
      </Col>
      <Col>
        <label>Discounts</label>
        <p>{formatPrice(orderDetails.discounts_total)}</p>
      </Col>{' '}
      <Col>
        <label>Refunded</label>
        <p
          style={{
            fontWeight: 'bold',
            color:
              orderDetails.order_ledger.amount_refunded > 0 ? 'red' : 'black',
          }}
        >
          {formatPrice(orderDetails.order_ledger.amount_refunded)}
        </p>
      </Col>
      <Col>
        <label>Store Recieves</label>
        <p>{formatPrice(orderDetails.store_recieves)}</p>
      </Col>
      <Col>
        <label>Silal Recieves</label>
        <p>{formatPrice(orderDetails.silal_fees_total)}</p>
      </Col>{' '}
    </div>
  );
};
