import { Outlet } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Spinner from 'silal_app_base_react/src/components/spinner';
import OrderProfileToprow from './components/top_row';
import OrdersRepository from 'data/repositories/orders_repository';
import { OrderDetails } from 'silal_app_base_react/src/data/types/orders';
export default function OrderProfileParentRouteComponent() {
  const { orderId } = useParams<{ orderId: string }>();
  const [refresh, setRefresh] = useState(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);

  useEffect(() => {
    const fetchOrderData = async () => {
      await OrdersRepository.getOrderDetailsByID({
        order_id: parseInt(orderId!),
      }).then((res) => {
        if (res) {
          setOrderDetails(res);
          return;
        }
      });
    };
    setRefresh(false);
    fetchOrderData();
  }, [orderId, refresh]);
  const context = {
    orderDetails,
    setRefresh,
  };

  return orderDetails ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <OrderProfileToprow
        orderDetails={orderDetails}
        doRefresh={() => setRefresh(!refresh)}
      />

      <Outlet context={context} />
    </div>
  ) : (
    <Spinner />
  );
}
