import * as BannerActions from './banners_actions';
const init = {
  banners: [],
  loading: false,
  error: '',
  success: '',
};
const reducer = (state = init, action: any) => {
  switch (action.type) {
    case BannerActions.GET_BANNERS: {
      return { ...state, loading: true, error: '' };
    }
    case BannerActions.BANNERS_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        ...payload,
        loading: false,
      };
    }

    case BannerActions.LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
