import { Icon } from '@iconify/react';

import React, { useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label } from 'styles/style';
import {
  actionAddCity,
  actionAddTown,
  actionDeleteCity,
  actionDeleteTown,
  actionGetCities,
  actionGetTowns,
  actionSetCity,
  actionSetRegion,
  actionUpdateCity,
  actionUpdateTown,
} from 'store/locations/locations_actions';
import RightArrow from 'assets/images/right-arrow.svg';
import FormControl from 'silal_app_base_react/src/components/form_control';
import Heading from 'components/headings/main_heading';
import RadioInput from 'components/radio_button';
import { Wrapper } from './operating_locations.styled';
import { RootState } from 'data/types/state';
import { SELECT_CITY_TOWN_MESSAGE } from 'data/constants/messages';
import { FormValidators } from 'silal_app_base_react/src/utils/functions/validation_functions';
import { toast } from 'react-toastify';
import AlertDialog from 'components/popup/popup_modal';
import NewOperatingLocationPopup from 'pages/settings/components/add_new_town_or_city_modal';
import { Link } from 'react-router-dom';

function OperatingLocations() {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [addNewModal, setAddNewModal] = useState(false);
  const [search, setSearch] = useState('');
  const [type, setType] = useState('city');
  const [data, setData] = useState<any>({
    name_ar: '',
    name_en: '',
    delivery_fee: '',
    cross_order_delivery_fee_discount: '',
    lat: 0,
    lng: 0,
    region: 1,
    city_id: '',
    town_id: '',
    active: true,
  });

  const { cities = [], towns = [] } = useSelector(
    (state: RootState) => state?.Locations,
  );

  const filteredCities = () => {
    return cities?.filter(
      (item) =>
        item.name_en.toLowerCase().match(search.toLowerCase()) ||
        item.name_ar.toLowerCase().match(search.toLowerCase()),
    );
  };
  const filteredTowns = () => {
    return towns?.filter(
      (item) =>
        item.name_en.toLowerCase().match(search.toLowerCase()) ||
        item.name_ar.toLowerCase().match(search.toLowerCase()),
    );
  };

  useEffect(() => {
    dispatch(actionGetCities({ regionId: data?.region }));
    dispatch(actionSetRegion(data?.region));
    setData({ ...data, town_id: '', city_id: '' });
  }, [data?.region]);

  useEffect(() => {
    if (data?.city_id !== '' && data?.city_id)
      dispatch(actionGetTowns({ city_id: data.city_id }));
    dispatch(actionSetCity(data?.city_id));
    setData({ ...data, town_id: '' });
  }, [data?.city_id]);

  const save = (updatedInfo: any) => {
    // Validate location data
    if (!FormValidators.isEnglish(updatedInfo.name_en)) {
      toast.warn('Please enter a valid English name');
      return false;
    }
    if (!FormValidators.isArabic(updatedInfo.name_ar)) {
      toast.warn('Please enter a valid Arabic name');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.delivery_fee))) {
      toast.warn('Please enter a valid delivery fee');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.cross_order_delivery_fee_discount))) {
      toast.warn('Please enter a valid cross order delivery fee discount');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.lng))) {
      toast.warn('Please enter a valid longitude');
      return false;
    }
    if (isNaN(parseFloat(updatedInfo.lat))) {
      toast.warn('Please enter a valid latitude');
      return false;
    }
    if (updatedInfo?.town_id !== '') {
      dispatch(
        actionUpdateTown({
          ...updatedInfo,
          lat: parseFloat(updatedInfo.lat),
          lng: parseFloat(updatedInfo.lng),
        }),
      );
      return true;
    } else if (updatedInfo?.city_id !== '') {
      dispatch(
        actionUpdateCity({
          ...updatedInfo,
          lat: parseFloat(updatedInfo.lat),
          lng: parseFloat(updatedInfo.lng),
        }),
      );
      return true;
    } else {
      alert('please select region!');
    }
    return false;
  };

  const addNew = () => {
    if (type === 'city') {
      dispatch(
        actionAddCity({
          ...data,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        }),
      );
    } else if (type === 'town') {
      dispatch(
        actionAddTown({
          ...data,
          lat: parseFloat(data.lat),
          lng: parseFloat(data.lng),
        }),
      );
    } else {
      toast.warn(SELECT_CITY_TOWN_MESSAGE);
    }
  };

  const remove = () => {
    if (data?.town_id !== '') {
      dispatch(actionDeleteTown(data));
    } else if (data?.city_id !== '') {
      dispatch(actionDeleteCity(data));
    } else {
      alert('please select town or city!');
    }
  };

  return (
    <Wrapper>
      {deleteModal && (
        <AlertDialog
          title="Delete"
          subtitle={`Are you sure you want to delete ${data?.name_en}?`}
          onAgree={remove}
          onDisagree={() => {
            setDeleteModal(false);
          }}
        />
      )}
      {addNewModal && (
        <AlertDialog
          title="Add New Location"
          subtitle={`Are you sure you want to add ${
            data?.name_en ? data?.name_en : data?.name_ar
          }?`}
          onAgree={addNew}
          onDisagree={() => {
            setAddNewModal(false);
          }}
        />
      )}
      {saveModal && (
        <NewOperatingLocationPopup
          open={saveModal}
          handleOpen={setSaveModal}
          data={data}
          setData={setData}
          onSubmit={save}
        />
      )}
      <Offcanvas.Header>
        <Offcanvas.Title>Set operating location</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p className="descp">
          Select the regions, cities and towns in which you are going to operate{' '}
        </p>
        <Label color="#00273380">Search by name</Label>
        <div className="search-div">
          <Icon icon="fe:search" className="icon-label " />
          <input
            type="text"
            placeholder="Region > City > Town"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="main-body">
          <div className="categories-main">
            <div className="category-col">
              <div
                className={`item ${data?.region === 1 ? 'active' : ''}`}
                onClick={() => {
                  setType('city');
                  setData({ ...data, region: 1 });
                }}
              >
                Region 1
                <img src={RightArrow} alt="right_icon" />
              </div>
              <div
                className={`item ${data?.region === 2 ? 'active' : ''}`}
                onClick={() => {
                  setType('city');
                  setData({ ...data, region: 2 });
                }}
              >
                Region 2
                <img src={RightArrow} alt="right_icon" />
              </div>
            </div>
            <div className="category-col">
              {filteredCities().map((city, index) => {
                return (
                  <div
                    key={index}
                    className={`item ${
                      data?.city_id?.toString() === city?.id.toString()
                        ? 'active'
                        : ''
                    }`}
                    title="click to show towns"
                    onClick={() => {
                      setType('town');
                      setData({
                        ...data,
                        name_ar: city.name_ar,
                        name_en: city.name_en,
                        city_id: city.id,
                        delivery_fee: city.delivery_fee,
                        cross_order_delivery_fee_discount:
                          city.cross_order_delivery_fee_discount,
                        lat: parseFloat(city.lat),
                        lng: parseFloat(city.lng),
                        id: city.id,
                      });
                    }}
                  >
                    {city.name_en} - {city.name_ar}{' '}
                    <div>
                      <Link
                        to={`https://www.google.com/maps/search/?api=1&query=${city.lat},${city.lng}`}
                        target="_blank"
                        title="Click to show on map"
                      >
                        <Icon
                          icon="ci:location"
                          href="https://iconscout.com/icons/location"
                        />
                      </Link>
                      <img
                        src={RightArrow}
                        alt="right_icon"
                        title="Click to show towns"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="category-col">
              {filteredTowns().map((town, index) => {
                return (
                  <div
                    key={index}
                    className={`item ${
                      data?.town_id?.toString() === town?.id.toString()
                        ? 'active'
                        : ''
                    }`}
                    title="click to show"
                    onClick={() => {
                      setData({
                        ...data,
                        name_ar: town.name_ar,
                        name_en: town.name_en,
                        town_id: town.id,
                        lat: parseFloat(town.lat),
                        lng: parseFloat(town.lng),
                        id: town.id,
                      });
                    }}
                  >
                    {town.name_en} - {town.name_ar}
                    <Link
                      to={`https://www.google.com/maps/search/?api=1&query=${town.lat},${town.lng}`}
                      target="_blank"
                      title="Click to show on map"
                    >
                      <Icon
                        icon="ci:location"
                        href="https://iconscout.com/icons/location"
                      />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="set-details">
            <Heading text={'Set new location info'} />
            <FormControl
              input={true}
              placeholder={'Town name'}
              labelValue={'Name'}
              value={data?.name_en}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!FormValidators.isEnglish(e.target.value)) {
                  toast.warn('Please enter English only');
                  return;
                }
                setData({ ...data, name_en: e.target.value });
              }}
            />{' '}
            <FormControl
              input={true}
              placeholder={'اسم البلدة'}
              labelValue={'Name in Arabic'}
              value={data?.name_ar}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!FormValidators.isArabic(e.target.value)) {
                  toast.warn('Please enter Arabic only');
                  return;
                }
                setData({ ...data, name_ar: e.target.value });
              }}
            />
            {type === 'city' && (
              <>
                <FormControl
                  input={true}
                  placeholder={'سعر التوصيل'}
                  labelValue={'Delivery Fee'}
                  value={data?.delivery_fee}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setData({ ...data, delivery_fee: e.target.value });
                  }}
                />
                <FormControl
                  input={true}
                  placeholder={'تخفيض التوصيل من متاجر عدّة'}
                  labelValue={'Cross Order Delivery Fee Discount'}
                  value={data?.cross_order_delivery_fee_discount}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setData({
                      ...data,
                      cross_order_delivery_fee_discount: e.target.value,
                    });
                  }}
                />
              </>
            )}
            <FormControl
              input={true}
              placeholder={'34.4211123'}
              labelValue={'Latitude'}
              value={data?.lat?.toString()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, lat: e.target.value });
              }}
            />
            <FormControl
              input={true}
              placeholder={'-2.1232131'}
              labelValue={'Longitude'}
              value={data?.lng?.toString()}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setData({ ...data, lng: e.target.value });
              }}
            />
            <div className="mt-2">
              <FormControl
                toggle={true}
                labelValue="Active"
                checked={data?.active}
                onChange={() => setData({ ...data, active: !data?.active })}
              />
            </div>
            <h5>Location type</h5>
            <div className="mt-2 d-flex justify-content-between">
              {/* <RadioInput // TODO: add region to the backend later
                title="Region"
                name="rating-radio"
                checked={type === 'region' && true}
                onChange={() => setType('region')}
              /> */}
              <RadioInput
                title="City"
                name="rating-radio"
                checked={type === 'city' && true}
                onChange={() => {
                  if (data?.region === 0) {
                    toast.warn('Please select region first');
                    return;
                  }
                  setType('city');
                }}
                disabled={data?.region === 0}
              />
              <RadioInput
                title="Town"
                name="rating-radio"
                checked={type === 'town' && true}
                onChange={() => {
                  if (data?.city_id === '') {
                    toast.warn('Please select city first');
                    return;
                  }
                  setType('town');
                }}
                disabled={data?.city_id === ''}
              />
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                paddingBottom: '10px',
                borderBottom: '1px solid #00273380',
              }}
            >
              <Button
                className="add-new"
                onClick={() => {
                  setAddNewModal(true);
                }}
              >
                Add New
              </Button>
            </div>
            <div className="buttons d-flex justify-content-between">
              <Button className="delete" onClick={() => setDeleteModal(true)}>
                <Icon icon="ci:trash-full" /> Delete {data?.name_en}
              </Button>
              <Button
                className="save"
                onClick={() => {
                  setSaveModal(true);
                }}
              >
                Update {data?.name_en}
              </Button>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Wrapper>
  );
}

export default OperatingLocations;
