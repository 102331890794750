import { Button, Chip } from '@nextui-org/react';
import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import moment from 'moment';
import { IoAdd, IoPencil } from 'react-icons/io5';
import { Colors } from 'silal_app_base_react/src/config/theme';
import { Link } from 'react-router-dom';

export default function RoutingScheduleDayRow({
  day,
  setModal,
  setShiftDate,
}: {
  day: {
    full_date: string;
    name: string;
    number: string;
    shifts: Shift[];
  };
  setModal: (arg: boolean) => void;
  setShiftDate: (arg: string) => void;
}) {
  return (
    <div
      className={`wrap single-day ${day.shifts.length && 'active'}`}
      onClick={() => {
        setModal(true);
        setShiftDate(day.full_date);
      }}
    >
      <p className="day_name">{day.name}</p>

      {day.shifts.length ? (
        <>
          {day.shifts.map((shift: any) => {
            return (
              <Chip
                key={shift.id}
                className="no-break-text mx-2"
                color={shift.is_recieving_new_orders ? 'success' : 'warning'}
                variant="dot"
              >
                <span>
                  <Link
                    to={`/logistics/shifts/${shift.id}`}
                    style={{
                      color: Colors.greenMain,
                    }}
                  >
                    #{shift?.id}
                  </Link>
                  {' - '} {moment(shift.start_datetime).format('HH:mm')}-
                  {moment(shift.end_datetime).format('HH:mm')} -{' '}
                  {shift.shipments_count} shipments -{' '}
                  {shift.registered_carriers.length}/{shift.desired_carriers}{' '}
                  captains
                </span>
              </Chip>
            );
          })}
        </>
      ) : (
        <p className="no-shift">NO SHIFTS ASSIGNED</p>
      )}
      <div className="dropdown">
        {day.shifts.length ? (
          <Button
            variant="flat"
            isIconOnly
            color="warning"
            onClick={() => {
              setModal(true);
              setShiftDate(day.full_date);
            }}
          >
            <IoPencil className="text-danger" />
          </Button>
        ) : (
          <div // <Icon
            className="add-schedule d-flex justify-content-between"
            onClick={() => {}}
          >
            <Button
              isIconOnly
              color="success"
              variant="flat"
              onClick={() => {
                setModal(true);
                setShiftDate(day.full_date);
              }}
            >
              <IoAdd
                style={{
                  color: `${Colors.greenMain}`,
                }}
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
