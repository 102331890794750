import styled from 'styled-components';
import { Fonts } from 'silal_app_base_react/src/config/theme';

export const Wrapper = styled.div`
  .end {
    display: flex;
    justify-content: end;
  }
  .h5 {
    font-family: ${Fonts.primary};
    font-weight: 600;
    font-size: 19px;
  }
  .position-absolute {
    position: absolute;
    margin-right: 10px;
  }
  .offcanvas-header {
    padding-bottom: 10px;
  }
  .descp {
    max-width: 500px;
    margin-bottom: 20px;
    font-family: ${Fonts.secondary};
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: rgba(0, 39, 51, 0.8);
    a {
      text-decoration: none;
      color: #05ae4b;
    }
  }
  .search-div {
    width: 100%;
    height: 36px;
    background: #ffffff;
    border: 1px solid #05ae4b;
    border-radius: 5px;
    padding: 0 0 0 11px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    /* margin-right: 10px; */
    svg {
      width: 17px;
      path {
        fill: #05ae4b;
      }
    }
    input {
      flex: 1;
      width: 70%;
      border: none;
      padding-left: 11px;
      font-family: ${Fonts.secondary};
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 125%;
      margin-right: 5px;
      height: 34px;
      outline: none;
      &::placeholder {
        color: #b3bec2;
      }
    }
  }
  .main-body {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .categories-main {
      background: #fafafa;
      border: 1px solid rgba(0, 39, 51, 0.1);
      border-radius: 5px;
      margin-bottom: 20px;
      height: 460px;
      width: 70%;
      /* flex-wrap: wrap; */
      overflow-x: scroll;
      display: flex;
      justify-content: start;

      .category-col {
        height: 100%;
        min-width: calc(100% / 3);
        border-right: 1px solid rgba(0, 39, 51, 0.1);
        overflow-y: auto;
        &:last-of-type {
          border-right: none;
        }
        ::-webkit-scrollbar {
          width: 8px;
        }
        ::-webkit-scrollbar-track {
          background: #0027330d;
        }
        ::-webkit-scrollbar-thumb {
          background: #00273326;
          border-radius: 5px;
        }
        .item {
          height: 45px;
          padding: 10px 20px;
          font-family: ${Fonts.secondary};
          font-weight: 400;
          font-size: 15px;
          line-height: 104%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #002733;
          border-bottom: 1px solid rgba(0, 39, 51, 0.1);
          cursor: pointer;
          &:last-of-type {
            border-bottom: none;
          }
          &:hover,
          &.active {
            background: #daecea;
          }
          svg {
            path {
              fill: #0027331a;
            }
          }
        }
      }
    }

    .set-details {
      width: 30%;

      label {
        font-family: ${Fonts.secondary};
        font-weight: 600;
        font-size: 12px;
        vertical-align: middle;
        line-height: 2;
      }

      .buttons {
        margin: 20px;
        button {
          svg {
            margin: 0px;
            line-height: 2;
            vertical-align: middle;
          }
          width: 43%;
        }

        .save {
          color: var(--app-color-dark);
          background-color: var(--app-color-light);
        }
      }
    }

    input {
      height: 30px !important;
      font-size: 14px !important;
    }
    .add-new {
      width: 80%;
    }
  }
`;
