import Sidenav from 'components/sidebar';
import { AuthenticatedPagesHandler } from 'routes/handlers/authenticated_pages_handler';
import UserAuthenticatedViewRouter from '../views/user_authenticated_view';
import { Content, GlobalStyle } from 'styles/global_styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'data/types/state';
import Spinner from 'silal_app_base_react/src/components/spinner';
import { useEffect, useState } from 'react';
import UsersRepository from 'data/repositories/users_repository';
import { actionSetUser } from 'store/authentication/authentication_actions';

import Button from 'silal_app_base_react/src/components/buttons';
import { useNavigate } from 'react-router-dom';
import { fullLogout } from 'routes/functions/routes_functions';

import {
  getAppropriateSidebar,
  getRoutingPermissionsMap,
} from 'routes/functions/permissions_functions';
import { LOCAL_STORAGE_TOKEN_KEY } from 'silal_app_base_react/src/config/constants';

const AuthenticatedViewRouter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButton(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const { bearer, current_user_id } = useSelector(
    (state: RootState) => state.Auth.user,
  );
  const { level_enum } = useSelector((state: RootState) => state.Auth.user);
  const localStorageToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);

  useEffect(() => {
    async function getSetUserPermissionLevel() {
      const user = await UsersRepository.getMyProfileDetails();
      const payload = {
        user: {
          bearer: bearer,
          current_user_id: current_user_id,
          level_enum: user?.level_enum,
          first_name: user?.first_name,
          last_name: user?.last_name,
        },
        userManagementAvailableRoutes: getRoutingPermissionsMap(
          level_enum || 0,
        ),
      };
      dispatch(actionSetUser(payload));
    }
    if (localStorageToken) getSetUserPermissionLevel();
    else {
      fullLogout(dispatch, navigate);
    }
  }, [level_enum, bearer, current_user_id, dispatch, localStorageToken]);

  // Each user has a level_enum that determines their permissions, for controlling users level, we need to determine 3 things, 2 are global and 1 is local.
  // The globals are: The router and the sidebar, i.e blocking the user from being able to access certain pages via the links (router) and via the side nav (sidebar). We handle these two in this file, using the level_enum which we get from the server using getSetUserPermissionLevel in the useEffect above, we store it in the redux store and use it here, to determine router we use the getAppropriateRouter function and to determine sidebar we use the getAppropriateSidebar function.
  // The third thing we need to determine is the actions in each page, due to the fact that these actions are inside the routes and the components, the developer will be responsibile to handle them, however, to reduct the duplicacy and to make sure a single source of truth is implemented, one class/function will be responsible for this, named: PermissionLevelController.
  const getAppropriateRouter = () => {
    return <UserAuthenticatedViewRouter />;
  };

  return level_enum === undefined ? (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Spinner />
      {showButton && (
        <>
          <h6>
            You've been logged out or your token has expired!
            <br /> Click on the button to re-login and continue using the
            website
          </h6>
          <br />
          <Button
            onClick={() => {
              fullLogout(dispatch, navigate);
              window.location.reload();
            }}
          >
            Re-Login
          </Button>
        </>
      )}
    </div>
  ) : (
    <AuthenticatedPagesHandler>
      <GlobalStyle />
      <Content>
        <DisplaySidebar child={getAppropriateSidebar(level_enum || 0)} />
        <DisplayContent child={getAppropriateRouter()} />
      </Content>
    </AuthenticatedPagesHandler>
  );
};

const DisplaySidebar = ({ child }: { child: any }) => {
  return <Sidenav menu={child} />;
};

const DisplayContent = ({ child }: { child: JSX.Element }) => {
  return <main>{child}</main>;
};

export default AuthenticatedViewRouter;
