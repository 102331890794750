import { Shift } from 'silal_app_base_react/src/data/types/shifts';
import * as ShiftsActions from './shifts_actions';

const init: {
  shifts: Shift[];
} = {
  shifts: [],
};

const reducer = (state = init, action: any) => {
  switch (action.type) {
    case ShiftsActions.GET_SHIFTS_IN_CITY: {
      return { ...state };
    }

    case ShiftsActions.SET_SHIFTS_IN_CITY: {
      return { ...state, shifts: action.payload };
    }

    case ShiftsActions.CREATE_SHIFTS_IN_CITY: {
      return { ...state };
    }

    case ShiftsActions.LOGOUT: {
      return {};
    }

    default:
      return state;
  }
};

export default reducer;
