export const business_constants = {
  TITLE: 'Business Starting',
  CARD_TITLE: 'Request ID',
  CARD_TYPES: { store: 'store_color', resturant: 'resturant_color' },
  INQUIRY_VIEW: 'requests/businesses',
};
export const new_listings_constants = {
  TITLE: 'New Listing Requests',
  CARD_TITLE: 'Request ID',
  CARD_TYPES: { store: 'store_color', resturant: 'resturant_color' },
  INQUIRY_VIEW: 'requests/new-listings',
};
export const update_listings_constants = {
  TITLE: 'Update Listing Requests',
  CARD_TITLE: 'Request ID',
  CARD_TYPES: { store: 'store_color', resturant: 'resturant_color' },
  INQUIRY_VIEW: 'requests/update-listings',
};
export const drivers_constants = {
  TITLE: 'Captains Requests',
  CARD_TITLE: 'Request ID',
  CARD_TYPES: { store: 'store_color', resturant: 'resturant_color' },
  INQUIRY_VIEW: 'requests/drivers',
};
export const promotions_constants = {
  TITLE: 'Business Starting',
  CARD_TITLE: 'Request ID',
  CARD_TYPES: { store: 'store_color', resturant: 'resturant_color' },
  INQUIRY_VIEW: 'requests/promotions',
};

export const STATUS_COLORS: {
  [key: string]: string;
} = {
  pending: '#FF8F73',
  rejected: '#FB5C5C',
  accepted: '#05AE4B',
};
