import { managementAxiosInstance } from 'silal_app_base_react/src/utils/axios/axios';
import { AuthenticatedApiRepository } from 'silal_app_base_react/src/data/repositories/authenticated_base_repository';
import { StoreDetails } from 'silal_app_base_react/src/data/types/stores';
import { AxiosInstance } from 'axios';
import { FeedbackToastHandler } from 'silal_app_base_react/src/config/errors/feedback';
import { Listing } from 'silal_app_base_react/src/data/types/listings';
import { API_BASE } from 'silal_app_base_react/src/config/constants';

class DataEditingApiRepository extends AuthenticatedApiRepository {
  private static instance: DataEditingApiRepository | null = null;
  constructor(axiosInstance: AxiosInstance) {
    super(axiosInstance);
  }

  public static getInstance(
    axiosInstance: AxiosInstance,
  ): DataEditingApiRepository {
    if (!DataEditingApiRepository.instance) {
      DataEditingApiRepository.instance = new DataEditingApiRepository(
        axiosInstance,
      );
    }
    return DataEditingApiRepository.instance;
  }

  getListingsInNonleadCategory = async (category_id: number) => {
    try {
      const res = await FeedbackToastHandler(
        this.sendRequest(
          'GET',
          `/data_editing/listings/not_leaf_category/${category_id}/`,
        ),
        {
          noSuccessMsg: true,
          noFailureMsg: false,
        },
      );
      return res.data.listings as Listing[];
    } catch (e: any) {
      return false;
    }
  };

  changeListingsCategory = async (
    listing_ids: number[],
    destination_category_id: number,
  ) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest('POST', `/data_editing/listings/move_to_category/`, {
          baseApiUrl: API_BASE,
          data: {
            listing_ids,
            destination_category_id,
          },
        }),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return true;
    } catch (e: any) {
      return false;
    }
  };

  updateListingNameOrDescription = async ({
    listing_id,
    name_en,
    name_ar,
    description_en,
    description_ar,
  }: {
    listing_id: number;
    name_en?: string;
    name_ar?: string;
    description_en?: string;
    description_ar?: string;
  }) => {
    try {
      await FeedbackToastHandler(
        this.sendRequest(
          'PUT',
          `/data_editing/listings/edit_name_desc/${listing_id}/`,
          {
            baseApiUrl: API_BASE,
            data: {
              name_en: name_en !== undefined ? name_en : undefined,
              name_ar: name_ar !== undefined ? name_ar : undefined,
              description_en:
                description_en !== undefined ? description_en : undefined,
              description_ar:
                description_ar !== undefined ? description_ar : undefined,
            },
          },
        ),
        {
          noSuccessMsg: false,
          noFailureMsg: false,
        },
      );
      return true;
    } catch (e: any) {
      return false;
    }
  };
}

const DataEditingRepository = DataEditingApiRepository.getInstance(
  managementAxiosInstance as AxiosInstance,
);
export default DataEditingRepository;
